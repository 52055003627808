import React, { Component } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { connect } from 'react-redux';

import * as actions from '../../actions';

class PromoCode extends Component {
  state = {
    promoCode: '',
    error: false
  };

  handleChange = event => {
    event.persist();
    this.setState(prevState => ({
      promoCode: event.target.value
    }));
  };

  render() {
    const {
      checkPromoCode,
      isPromoCodeApplied,
      errorMessage,
      errorType
    } = this.props;
    const { promoCode } = this.state;
    let error = errorType ? true : false;

    return (
      <Grid
        justify="center"
        alignItems="flex-end"
      >
        <form>
          <TextField
            name="promo-code"
            label="Promo Code"
            type="text"
            error={error}
            helperText={errorMessage}
            onChange={this.handleChange}
          />
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              if (promoCode.length !== 0) {
                checkPromoCode(promoCode);
              }
            }}
          >
            Apply
          </Button>
        </form>
      </Grid>
    );
  }
}

export default connect(
  null,
  actions
)(PromoCode);
