import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  Button,
  CssBaseline,
  Paper,
  Typography,
  Grid
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as actions from '../../actions';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600
    }
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${theme
      .spacing.unit * 5}px`,
    borderRadius: '7px'
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  },
  alignButton: {
    float: 'right',
    marginTop: 40
  },
  alignButtonLeft: {
    float: 'left',
    marginTop: 40
  },

  typography: {
    marginTop: 15
  }
});

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberOfFamilyMembersAllowed: false,
      numberActive: false
    };
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.checked;

    if (value) {
      this.setState(state => ({ numberActive: state.numberActive + 1 }));
    } else {
      this.setState(state => ({ numberActive: state.numberActive - 1 }));
    }

    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  componentDidMount() {
    for (let i = 0; i < this.props.smartFamilyPlan.length; i++) {
      if (
        this.props.smartFamilyPlan[i].name === 'numberOfAdditionalFamilyMembers'
      ) {
        this.setState({
          numberOfFamilyMembersAllowed: this.props.smartFamilyPlan[i].inputData
        });
      }
    }

    const numberActive = this.props.auth.familyMembers.filter(
      ({ isActive }) => isActive
    ).length;

    this.setState({
      numberActive
    });
  }

  render() {
    const { handleSubmit, isFamilyAccount, wizard, classes } = this.props;

    const familyMembers = this.props.auth.familyMembers[0].name
      ? this.props.auth.familyMembers.map(
        ({ name: { first, last }, _id, isActive }, index) => {
          return (
            <Grid item container>
              <Grid item xs={6}>
                <Typography variant="body1">Name</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Active?</Typography>
              </Grid>
              <Grid item xs={6} className={classes.textField}>
                <Typography variant="h6" color="primary">
                  {`${first} ${last}`}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.textField}>
                <input
                  name={_id}
                  id="textOptIn"
                  component="input"
                  type="checkbox"
                  onChange={this.handleInputChange}
                  defaultChecked={
                    this.state[_id] ? this.state[_id] : isActive
                  }
                />
              </Grid>
            </Grid>
          );
        }
      )
      : [];

    if (this.props.auth.familyMembers.length > 0) {
      return (
        <Fragment>
          <CssBaseline />
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <form
                className={classes.form}
                onSubmit={handleSubmit(values => {
                  if (
                    this.state.numberActive >
                    this.state.numberOfFamilyMembersAllowed
                  ) {
                    alert(
                      `Only ${this.state.numberOfFamilyMembersAllowed} are allowed to be active at a given time`
                    );
                  } else {
                    let filteredState = {};
                    let currentState = this.state;
                    for (const key in currentState) {
                      if (
                        key != 'numberOfFamilyMembersAllowed' &&
                        key != 'numberActive'
                      ) {
                        filteredState[key] = currentState[key];
                      }
                    }

                    this.props.modifyFamilyMembers(filteredState);
                    this.props.history.push('/');
                  }
                })}
              >
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="primary">
                      {' '}
                      Activate or Disable Family Members
                    </Typography>
                  </Grid>

                  {familyMembers}

                  <Grid item xs={12} container direction="row">
                    <Grid item xs={12} container direction="row">
                      <Grid item xs={12} sm={6}></Grid>
                      <Grid item xs={12}>
                        <Link
                          style={{ textDecoration: 'none' }}
                          to="/dashboard"
                        >
                          <Button
                            variant="contained"
                            className={classes.alignButtonLeft}
                          >
                            Back
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.alignButton}
                          type="submit"
                        >
                          {' '}
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </main>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <CssBaseline />

          <Paper
            style={{
              maxWidth: 450
            }}
          >
            <Grid
              container
              direction="column"
              style={{ padding: '5%' }}
              alignItems="center"
            >
              <Grid item style={{ marginBottom: '20px' }}>
                <Typography style={{ textAlign: 'center' }}>
                  You do not yet have family members
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary">
                  <Link
                    style={{ color: 'white', textDecoration: 'none' }}
                    to="/dashboard"
                  >
                    Go Back
                  </Link>
                </Button>
              </Grid>
              <br />
            </Grid>
          </Paper>
        </Fragment>
      );
    }
  }
}

const ProfileFormRedux = connect(
  state => ({
    form: state.form,
    auth: state.auth,
    smartFamilyPlan: state.smartFamilyPlan,
    isFamilyAccount: true
  }),
  actions
)(withRouter(ProfileForm));

ProfileForm = reduxForm({
  form: 'profileForm',
  destroyOnUnmount: false
})(ProfileFormRedux);

export default withStyles(styles)(ProfileForm);
