import { ISSUE, SUBMIT_APPOINTMENT_NOTES } from '../actions/types';

const initialState = {
  user: {},
};

export default function (state = {}, action) {
  switch (action.type) {
    case ISSUE:
      return action.payload || false;
    case SUBMIT_APPOINTMENT_NOTES:
      if (action.status == 'okay') {
        return true;
      } else {
        return action.status;
      }

    default:
      return state;
  }
}
