import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
  clearFields,
  Field,
  reset,
} from 'redux-form';
import {
  Button,
  CssBaseline,
  FormControl,
  MenuItem,
  Select,
  Paper,
  Typography,
  TextField,
  Grid,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from '@material-ui/core/styles/withStyles';
import { createTextMask } from 'redux-form-input-masks';
import countryRegionFields from '../dashboard/CountryRegionFields';
import { Prompt } from 'react-router';
import { BorderBottom } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import * as actions from '../../actions';
import DatePicker from 'react-datepicker';
import Autocomplete from '@material-ui/lab/Autocomplete';

import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/safariDatePicker.css';

let accountAdultNumber = 0;
const customPhoneMask = {
  9: {
    regExp: /[0-9]/,
  },
};

const phoneMask = createTextMask({
  pattern: '+9 (999) 999-9999',
  guide: false,
  allowEmpty: true,
});

const phoneMaskInternational = createTextMask({
  pattern: '+999999999999999',
  guide: false,
  allowEmpty: true,
  maskDefinitions: customPhoneMask,
});

const customPostalCodeMaskDefinitions = {
  9: {
    regExp: /[0-9]/,
  },
  A: {
    regExp: /[A-Za-z]/,
    transform: (char) => char.toUpperCase(),
  },
};

const postalCodeMask = createTextMask({
  pattern: 'A9A 9A9',
  guide: false,
  maskDefinitions: customPostalCodeMaskDefinitions,
});

const styles = (theme) => ({
  layout: {
    //  width: 'auto',
    //  height: '100%',
    //  display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${
      theme.spacing.unit * 5
    }px`,
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    float: 'right',
    marginTop: 40,
  },

  alignButtonLeft: {
    float: 'left',
    marginTop: 40,
  },

  typography: {
    marginTop: 15,
  },
});

const postalCodeRegExp = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
const zipCodeRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;

const ProfileField = ({
  input,
  name,
  type,
  meta: { touched, error },
  placeholder,
  disabled,
}) => (
  <TextField
    {...input}
    //required
    type={type}
    id={name}
    fullWidth
    placeholder={placeholder}
    disabled={disabled}
    error={touched && error}
    helperText={touched && error}
    variant="outlined"
  />
);

const DateField = ({ input, name, type, meta: { touched, error } }) => (
  <TextField
    {...input}
    //required
    type={type}
    id={name}
    fullWidth
    error={touched && error}
    helperText={touched && error}
    variant="outlined"
  />
);

const FamilyAccountCheckField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <div>
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            {...input}
            id={name}
            color="primary"
            checked={input.value ? label : false}
          />
        }
      />
    </FormControl>
  </div>
);

const FamilyAccountSelectField = ({
  name,
  input,
  label,
  type,
  familyMembers,
  meta: { touched, error },
}) => {
  let memberArray = [];
  for (let i = 1; i <= familyMembers; i++) {
    memberArray[i] = i;
  }
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor={name} style={{ fontSize: '0.9rem' }}>
        How many family members?
      </InputLabel>
      <Select
        {...input}
        id={name}
        input={<OutlinedInput id={name} label="How many family members?" />}
      >
        {' '}
        <MenuItem value="">None</MenuItem>
        {memberArray.map((member) => (
          <MenuItem value={member}>{member}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const userGender = [
  'Male',
  'Female',
  'Transgender Male / FTM',
  'Transgender Female / MTF',
  'Non-binary / Other',
  'Rather not say',
];
const userSex = ['Male', 'Female'];

const GenderField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <FormControl variant="outlined" fullWidth>
    <Autocomplete
      {...input}
      id="genderFieldAutocomplete"
      options={userGender}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
    {touched && error ? (
      <FormHelperText>
        <span style={{ color: '#f44336' }}>{error}</span>
      </FormHelperText>
    ) : null}
  </FormControl>
);

const SexField = ({ name, input, label, type, meta: { touched, error } }) => (
  <FormControl variant="outlined" fullWidth>
    <Select {...input} error={touched && error} helperText={touched && error}>
      {userSex.map((gend) => (
        <MenuItem key={gend} value={gend}>
          {gend}
        </MenuItem>
      ))}
    </Select>
    {touched && error ? (
      <FormHelperText>
        <span style={{ color: '#f44336' }}>{error}</span>
      </FormHelperText>
    ) : null}
  </FormControl>
);

const language = [
  'English',
  // ' Mandarin',
  // ' Spanish',
  // ' Italian',
  // ' Hindi',
  // ' Arabic',
  // ' Portuguese',
  // ' Bengali',
  // ' Russian',
  // ' Japanese',
  // ' Punjabi',
  // ' German',
  // ' French',
  // ' Korean' //uncomment for future use
];

const LanguageField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <FormControl style={{ width: 250, marginTop: 16 }}>
    <Select
      {...input}
      //multiple //uncomment for future use
      error={touched && error}
      helperText={touched && error}
    >
      {language.map((lang) => (
        <MenuItem value={lang}>{lang}</MenuItem>
      ))}
    </Select>
  </FormControl>
);

const provinces = [
  'Ontario',
  'Quebec',
  'British Columbia',
  'AlbertmaxNumba',
  'Saskatchewan',
  'Nova Scotia',
  'New Brunswick',
  'Manitoba',
  'Prince Edward Island',
  'Newfoundland And Labrador',
  'Yukon',
  'Nunavut',
  'Northwest Territories',
];

const isDateSupported = () => {
  let i = document.createElement('input');
  i.setAttribute('type', 'date');
  return i.type !== 'text';
};

const SafariDatePicker = ({ id, input, meta: { touched, error } }) => {
  const [startDate, setStartDate] = useState();
  const CustomInput = ({ value, onClick }) => {
    return (
      <TextField
        value={value}
        onClick={onClick}
        {...input}
        fullWidth
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="yyyy-mm-dd"
        error={touched && error}
        helperText={touched && error}
      />
    );
  };
  return (
    <div>
      <DatePicker
        {...input}
        name={id}
        customInput={<CustomInput />}
        dateFormat="yyyy-MM-dd"
        selected={startDate}
        showYearDropdown
        showMonthDropdown
        onChange={(date) => {
          setStartDate(date);
          const newDate = date.toLocaleDateString('en-CA');
          input.onChange(newDate);
        }}
      />
    </div>
  );
};

class ProfileForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      country: false,
      smartFamilyPlan: this.props.smartFamilyPlan,
      minimumMembers: 1,
      familyMembers: ['false'],
      maxNumberOfFamilyMembers: 0,
      numberOfAllowedAdditionalMembers: 0,
      disableSubmit: false,
    };
    var previousNumberOfFamilyMembers = 0;
  }

  componentDidMount() {
    accountAdultNumber = this.props.auth.accountAdultNumber;
    const activeMembers = this.props.auth.familyMembers.filter(
      (member) => member.isActive
    ).length;
    const allowedExtraMembers = this.props.smartFamilyPlan - activeMembers;
    this.setState({ numberOfAllowedAdditionalMembers: allowedExtraMembers });
  }

  addFamilyMemberFields() {
    var familyMemberInputFields = [];

    //gets rid of values
    for (var i = 0; i < this.previousNumberOfFamilyMembers; i++) {
      if (i >= this.props.numberOfFamilyMembersSelected) {
        this.props.dispatch(
          clearFields(
            'profileForm',
            false,
            false,
            'family_member_first_name_' + i
          )
        );
        this.props.dispatch(
          clearFields(
            'profileForm',
            false,
            false,
            'family_member_last_name_' + i
          )
        );
        this.props.dispatch(
          clearFields(
            'profileForm',
            false,
            false,
            'family_member_date_of_birth_' + i
          )
        );
        this.props.dispatch(
          clearFields('profileForm', false, false, 'family_member_gender_' + i)
        );
      }
    }

    this.previousNumberOfFamilyMembers =
      this.props.numberOfFamilyMembersSelected;

    for (var i = 1; i <= this.props.numberOfFamilyMembersSelected; i++) {
      familyMemberInputFields.push(this.addOneFamilyMember(i));
    }

    return familyMemberInputFields;
  }

  addOneFamilyMember(i) {
    const { classes } = this.props;
    return (
      <Fragment>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary">
              Family Member {this.props.auth.familyMembers.length + 1 + i}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.typography} variant="body1">
              First Name
            </Typography>
            {this.renderFields('family_member_first_name_' + i, 'text')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.typography} variant="body1">
              Last Name
            </Typography>
            {this.renderFields('family_member_last_name_' + i, 'text')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.typography} variant="body1">
              Date of Birth
            </Typography>
            {this.renderDateField(
              'family_member_date_of_birth_' + i,
              'date',
              isDateSupported()
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.typography} variant="body1">
              Sex Assigned at Birth
            </Typography>
            {this.renderSexField('family_member_sex_' + i, 'input')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.typography} variant="body1">
              Gender Identity
            </Typography>
            {this.renderGenderField('family_member_gender_' + i, 'input')}
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  clearFamilyFields() {
    for (var i = 1; i < 8; i++) {
      this.props.dispatch(
        clearFields(
          'profileForm',
          false,
          false,
          'family_member_first_name_' + i
        )
      );

      this.props.dispatch(
        clearFields('profileForm', false, false, 'family_member_last_name_' + i)
      );
      this.props.dispatch(
        clearFields(
          'profileForm',
          false,
          false,
          'family_member_date_of_birth_' + i
        )
      );
      this.props.dispatch(
        clearFields('profileForm', false, false, 'family_member_gender_' + i)
      );
    }
    this.props.dispatch(
      clearFields('profileForm', false, false, 'number_of_family_members')
    );
    this.props.dispatch(
      clearFields(
        'profileForm',
        false,
        false,
        'is_this_family_account_checkbox'
      )
    );
  }

  renderLanguageField() {
    return (
      <Field
        name="spoken_languages"
        type="text"
        multiple
        format={
          (value) =>
            value ? value : '' /*(Array.isArray(value) ? value : [])*/
        }
        onBlur={null}
        component={LanguageField}
      />
    );
  }

  renderSexField(name, type) {
    return (
      <Field
        name={name}
        type={type}
        // placeholder={placeholder}
        multiple
        format={(value) => (value ? value : '')}
        onBlur={null}
        component={SexField}
      />
    );
  }

  renderGenderField(name, type) {
    return (
      <Field
        name={name}
        type={type}
        // placeholder={placeholder}
        multiple
        format={(value) => (value ? value : '')}
        onBlur={null}
        component={GenderField}
      />
    );
  }

  renderFields(name, type) {
    return <Field name={name} type={type} component={ProfileField} />;
  }

  renderPostalCodeFields(name, type) {
    if (this.state.country === 'Canada') {
      return (
        <Field
          name={name}
          type={type}
          component={ProfileField}
          {...postalCodeMask}
        />
      );
    } else {
      return <Field name={name} type={type} component={ProfileField} />;
    }
  }
  renderPhoneFields(name, type) {
    if (this.state.country === 'Canada') {
      return (
        <Field
          name={name}
          type={type}
          component={ProfileField}
          {...phoneMask}
        />
      );
    } else {
      return (
        <Field
          name={name}
          type={type}
          component={ProfileField}
          {...phoneMaskInternational}
        />
      );
    }
  }

  ProvinceField = ({
    name,
    regions,
    input,
    label,
    type,
    meta: { touched, error },
  }) => (
    <FormControl style={{ width: 250, marginTop: 16 }}>
      <Select {...input} error={touched && error} helperText={touched && error}>
        {this.state.regionsForCountry.map((obj) => (
          <MenuItem value={obj['name']}>{obj['name']}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  renderProvinceFields = (name, type) => {
    if (this.state.country) {
      return (
        <Field
          name={name}
          type={type}
          format={(value) => (value ? value : [])}
          regionsForCountry={this.state.regionsForCountry}
          component={this.ProvinceField}
        />
      );
    } else {
      return (
        <Field
          name={name}
          type={type}
          placeholder={'Please Input Your Country First'}
          disabled={true}
          component={ProfileField}
        />
      );
    }
  };

  CountryField = ({ name, input, label, type, meta: { touched, error } }) => (
    <FormControl style={{ width: 250, marginTop: 16 }}>
      <Select {...input} error={touched && error} helperText={touched && error}>
        {countryRegionFields.map((lang) => (
          <MenuItem value={lang['countryName']}>{lang['countryName']}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
  renderCountryFields(name, type) {
    return (
      <Field
        name={name}
        type={type}
        format={(value) => (value ? value : [])}
        component={this.CountryField}
        onChange={(event) => {
          this.setState({
            country: event.target.value,
          });
          countryRegionFields.map((obj) => {
            if (obj['countryName'] === event.target.value) {
              this.setState({
                regionsForCountry: obj['regions'],
              });
            }
          });
        }}
      />
    );
  }

  renderDateField(name, type, isDateSupported) {
    return (
      <Field
        name={name}
        type={type}
        component={isDateSupported ? DateField : SafariDatePicker}
      />
    );
  }

  renderFamilyCheckbox(id, name, type, label) {
    return (
      <Field
        id={id}
        name={name}
        type={type}
        label={label}
        parse={(value, name) => (value ? label : false)}
        component={FamilyAccountCheckField}
      />
    );
  }

  renderFamilySelect(id, name, type, familyMembers) {
    return (
      <Field
        id={id}
        name={name}
        type={type}
        familyMembers={familyMembers}
        component={FamilyAccountSelectField}
      />
    );
  }

  render() {
    const {
      handleSubmit,
      isFamilyAccount,
      smartFamilyPlan,
      classes,
      numberOfFamilyMembersSelected,
    } = this.props;
    const { numberOfAllowedAdditionalMembers, disableSubmit } = this.state;

    if (this.props.isFamilyAccount === false) {
      this.clearFamilyFields();
    }

    if (numberOfAllowedAdditionalMembers > 0) {
      return (
        <Fragment>
          <CssBaseline />
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              <form
                className={classes.form}
                onSubmit={handleSubmit(async (values) => {
                  this.setState({ disableSubmit: true });
                  const res = await this.props.submitNewFamilyMembers(
                    values,
                    this.props.history
                  );
                  if (res && res.error) this.setState({ disableSubmit: false });
                })}
              >
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="primary">
                      {' '}
                      Add Family Members
                    </Typography>
                  </Grid>

                  <Grid item xs={12} container direction="row">
                    <Grid item xs={12} container direction="row">
                      <Grid item xs={12} sm={6}>
                        {this.props.isFamilyAccount && (
                          <div>
                            {this.renderFamilySelect(
                              'number_of_family_members',
                              'number_of_family_members',
                              'select',
                              numberOfAllowedAdditionalMembers
                            )}
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {this.props.numberOfFamilyMembersSelected &&
                        this.props.isFamilyAccount ? (
                          <div>{this.addFamilyMemberFields()}</div>
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Link
                          style={{ textDecoration: 'none' }}
                          to="/dashboard"
                        >
                          <Button
                            variant="contained"
                            className={classes.alignButtonLeft}
                            onClick={() => reset()}
                            disabled={disableSubmit}
                          >
                            Back
                          </Button>
                        </Link>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.alignButton}
                          type="submit"
                          disabled={
                            (numberOfFamilyMembersSelected ? false : true) ||
                            disableSubmit
                          }
                        >
                          Submit
                          {disableSubmit && (
                            <CircularProgress
                              size={15}
                              color="white"
                              style={{
                                marginLeft: 5,
                              }}
                            />
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </main>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <CssBaseline />

          <Paper
            style={{
              maxWidth: 450,
            }}
          >
            <Grid
              container
              direction="column"
              style={{ padding: '5%' }}
              alignItems="center"
            >
              <Grid item style={{ marginBottom: '20px' }}>
                <Typography style={{ textAlign: 'center' }}>
                  You have already added the maximum number of family members
                </Typography>
              </Grid>
              <Grid item>
                <Link style={{ textDecoration: 'none' }} to="/dashboard">
                  <Button variant="contained">Back</Button>
                </Link>
              </Grid>
              <br />
              <Typography>{this.state.errorMessage}</Typography>
            </Grid>
          </Paper>
        </Fragment>
      );
    }
  }
}

function validate(values) {
  const errors = {};

  let today = new Date();

  let numberAbove18 = accountAdultNumber;

  if (!values.number_of_family_members) {
    errors.number_of_family_members = 'None selected';
  }

  for (var i = 1; i <= values.number_of_family_members; i++) {
    if (!values['family_member_first_name_' + i]) {
      errors['family_member_first_name_' + i] = 'Field required';
    }
    if (!values['family_member_last_name_' + i]) {
      errors['family_member_last_name_' + i] = 'Field required';
    }
    if (!values['family_member_date_of_birth_' + i]) {
      errors['family_member_date_of_birth_' + i] = 'Field required';
    }
    if (!values['family_member_gender_' + i]) {
      errors['family_member_gender_' + i] = 'Field required';
    }
    if (!values['family_member_sex_' + i]) {
      errors['family_member_sex_' + i] = 'Field required';
    }

    var dateOfBirth = new Date(values['family_member_date_of_birth_' + i]);
    dateOfBirth = new Date(
      dateOfBirth.getTime() + dateOfBirth.getTimezoneOffset() * 60 * 1000
    );

    if (dateOfBirth > today) {
      errors['family_member_date_of_birth_' + i] = 'Invalid date';
    }
    // validating age
    if (today.getFullYear() - dateOfBirth.getFullYear() > 18) {
      numberAbove18++;
      if (numberAbove18 > 2) {
        errors['family_member_date_of_birth_' + i] =
          'Only 2 adults per account are allowed.';
      }
    } else if (today.getFullYear() - dateOfBirth.getFullYear() === 18) {
      if (today.getMonth() > dateOfBirth.getMonth()) {
        numberAbove18++;
        if (numberAbove18 > 2) {
          errors['family_member_date_of_birth_' + i] =
            'Only 2 adults per account are allowed.';
        }
      } else if (
        today.getMonth() === dateOfBirth.getMonth() &&
        today.getDate() >= dateOfBirth.getDate()
      ) {
        numberAbove18++;
        if (numberAbove18 > 2) {
          errors['family_member_date_of_birth_' + i] =
            'Only 2 adults per account are allowed.';
        }
      }
    }
  }

  return errors;
}
const selector = formValueSelector('profileForm');

const ProfileFormRedux = connect(
  (state) => ({
    form: state.form,
    auth: state.auth,
    smartFamilyPlan: state.smartFamilyPlan,
    isFamilyAccount: true,
    numberOfFamilyMembersSelected: selector(state, 'number_of_family_members'),
  }),
  actions
)(ProfileForm);

ProfileForm = reduxForm({
  validate,
  form: 'profileForm',
})(ProfileFormRedux);

export default withStyles(styles)(ProfileForm);
