import { Grid, Typography, withStyles } from '@material-ui/core';
import React, { Component, Fragment } from 'react';

const styles = (theme) => ({
  amount: {
    marginRight: theme.spacing.unit * 2,
    float: 'right',
  },
  label: {
    marginLeft: theme.spacing.unit * 2,
  },
  total: {
    borderTop: '1px solid lightgray',
  },
  disclaimer: {
    //float: 'right',
  },
  header: {
    borderBottom: '1px solid lightgray',
  },
  container: {
    marginTop: theme.spacing.unit * 2,
  },
});

class CheckoutPaymentInfo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { product, classes } = this.props;
    const price = product.unit_amount / 100;
    const hst = price * 0.13;
    const total = price + hst;

    return (
      <Fragment>
        <Grid container spacing={1} className={classes.container}>
          <Grid item xs={12} className={classes.header}>
            <Typography variant="body1" color="primary">
              <b>Order Summary</b>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography color="primary" className={classes.label}>
              Subtotal
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.amount}>
              ${(Math.round(price * 100) / 100).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography color="primary" className={classes.label}>
              HST
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.amount}>
              ${(Math.round(hst * 100) / 100).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={8} className={classes.total}>
            <Typography color="primary" className={classes.label}>
              <b>Total</b>
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.total}>
            <Typography className={classes.amount}>
              ${(Math.round(total * 100) / 100).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.disclaimer}>
              *All prices in CAD
            </Typography>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(CheckoutPaymentInfo);
