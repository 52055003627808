import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Field } from 'redux-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Delete, Add } from '@material-ui/icons';
import Fab from '@material-ui/core/Fab';
import Resizer from 'react-image-file-resizer';

const styles = (theme) => ({
  inputStyle: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    fontFamily: 'Roboto',
    cursor: 'pointer',
    border: 'solid 1px',
    borderStyle: 'dashed',
    padding: '10px',
  },
});

const renderField = (props) => {
  return (
    <img
      style={{ width: '100%' }}
      alt="image"
      key={props.files.name}
      src={'data:image/png;base64, ' + props.files.base64File}
    />
  );
};

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });

class renderDropzoneInputOhip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectedFiles: [],
    };
    const {
      fields,
      meta: { error, submitFailed },
    } = this.props;
  }

  renderMedia() {
    var result = [];
    if (this.props.fields.length > 0 && this.props.deleteThumbnail) {
      this.props.fields.remove(0);
    }
    this.props.fields.forEach((name, index, fields) => {
      result.push(
        <Grid
          item
          container
          spacing={1}
          style={{
            backgroundColor: '#eeeeee',
            padding: '10px',
          }}
        >
          <Grid item xs={10}>
            <Field
              name={fields.get(index).name}
              key={fields.get(index).name}
              component={renderField}
              props={{ files: fields.get(index) }}
            />
          </Grid>
          <Grid item xs={2}>
            <Fab
              size="small"
              color="secondary"
              aria-label="Delete"
              type="button"
              title="Remove"
              onClick={() => {
                this.props.fields.remove(index);
              }}
            >
              <Delete />
            </Fab>
          </Grid>
        </Grid>
      );
    });
    return result;
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container direction="row">
        <Grid item xs={12}>
          {this.props.fields.length < 1 && (
            <Dropzone
              addRemoveLinks="true"
              maxSize={5e6} // 5MB
              maxFiles={1}
              accept="image/*"
              name={this.props.name}
              onDrop={async (files, rejectedFiles) => {
                try {
                  this.setState({ rejectedFiles: [] });
                  if (files.length > 0) {
                    let resultFile = { name: files[0].name };
                    const file = files[0];
                    const image = await resizeFile(file);
                    resultFile.base64File = image.substr(
                      image.indexOf(',') + 1
                    );
                    this.props.fields.push(resultFile);
                  }
                  if (rejectedFiles.length > 0) {
                    this.setState({ rejectedFiles: rejectedFiles });
                  }
                } catch (err) {
                  console.log(err);
                }
                // const reader = new FileReader();
                // reader.onload = () => {
                //   const file = reader.result
                // };
                // reader.onabort = () => console.log('file reading was aborted');
                // reader.onerror = () => console.log('file reading has failed');

                // reader.read(files[0]);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  justify="center"
                  spacing={2}
                  {...getRootProps({ className: classes.inputStyle })}
                >
                  <Grid item>
                    <input {...getInputProps()} />
                    <Typography align="center">
                      Drop a picture here, or click/tap to upload (5MB Max.)
                    </Typography>
                  </Grid>
                  <Grid item container justify="center">
                    <Fab color="primary" aria-label="add">
                      <Add />
                    </Fab>
                  </Grid>
                </Grid>
              )}
            </Dropzone>
          )}
          {this.renderMedia()}
          {this.state.rejectedFiles.length > 0 && (
            <Grid item xs={12} style={{ marginTop: '10px' }}>
              <Typography color="error">
                {this.state.rejectedFiles[0].file.name} is not a valid image.
              </Typography>
            </Grid>
          )}
        </Grid>
        {this.props.meta.error && (
          <span className="error">{this.props.meta.error}</span>
        )}
        {this.files && Array.isArray(this.files) && (
          <ul style={{ overflow: 'visible' }}>
            {this.files.map((file, i) => (
              <li key={i}>{file.name}</li>
            ))}
          </ul>
        )}
      </Grid>
    );
  }
}

renderDropzoneInputOhip.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(renderDropzoneInputOhip);
