import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

import {
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  Slide,
  Snackbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import * as actions from '../../actions';

import SubscriptionAccordion from './SubscriptionAccordion';
import PaidVisitPurchase from '../dialogs/PaidVisitPurchase';
import StandardMembershipPurchase from '../dialogs/StandardMembershipPurchase';
import PremiumSubscriptionPurchase from '../dialogs/PremiumSubscriptionPurchase';
import CancelRenewal from '../dialogs/CancelRenewal';
import ResumeRenewal from '../dialogs/ResumeRenewal';
import UpgradePlan from '../dialogs/UpgradePlan';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  loadingIcon: {
    marginTop: theme.spacing.unit * 3,
  },
  memberContainer: {
    marginTop: theme.spacing.unit,
  },
  purchaseButton: {
    marginTop: theme.spacing.unit * 2,
  },
  header: {
    margin: 0,
    width: '100%',
  },
  title: {
    marginTop: '5%',
    marginBottom: 15,
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

class ManageSubscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      success: false,
      message: undefined,
      showPaidVisitDialog: false,
      showStandardDialog: false,
      showPremiumDialog: false,
      showCancelDialog: false,
      showRenewDialog: false,
      showUpgradeDialog: false,
      standardPlan: undefined,
      premiumPlan: undefined,
      fiveCreditPlan: undefined,
      selectedUser: undefined,
      familyMembers: [],
      creditBalance: undefined,
      accountType: props.auth.familyMembers.length > 0 ? 'family' : 'personal',
    };
  }

  componentDidMount = async () => {
    // start requests so we can resolve when needed
    let userPlans = this.getUserPlanData();
    let standardPlan = this.getStandardPlanData();
    let premiumPlan = this.getPremiumPlanData();
    let fiveCreditPlan = this.getFiveCreditData();

    let expires;

    const { auth } = this.props;
    this.setState({
      creditBalance: auth.credits,
    });

    // resolve promise
    userPlans = await userPlans;

    _.each(userPlans, (plan) => {
      if (
        plan.plan.id === 'price_1IeMetIWbJ87X7LwIKT7RS4e' ||
        plan.plan.id === 'price_1IeMlbIWbJ87X7LweyeJF2ep'
      ) {
        expires = new Date(plan.current_period_end * 1000);
        return false;
      }
    });

    this.state.familyMembers.push({
      id: auth._id,
      name: `${auth.name.first} ${auth.name.last}`,
      standardMembershipStatus: auth.isOhipMember,
      standardMembershipExpire: auth.ohipMembershipEnd,
      premiumSubscriptionStatus: auth.subscribedToPlan,
      premiumSubscriptionPlan: auth.subscriptionPlan,
      premiumSubscriptionBalance: auth.subscriptionVisitBalance,
      premiumSubscriptionExpire: expires || 0,
      premiumSubscriptionRenews: auth.subscriptionRenewalActive,
    });

    auth.familyMembers.forEach((member) => {
      this.state.familyMembers.push({
        id: member._id,
        name: `${member.name.first} ${member.name.last}`,
        standardMembershipStatus: member.isOhipMember,
        standardMembershipExpire: new Date(member.ohipMembershipEnd),
        premiumSubscriptionStatus: auth.subscribedToPlan,
        premiumSubscriptionPlan: auth.subscriptionPlan,
        premiumSubscriptionBalance: auth.subscriptionVisitBalance,
        premiumSubscriptionExpire: expires || 0,
        premiumSubscriptionRenews: auth.subscriptionRenewalActive,
      });
    });

    // resolve promises
    [standardPlan, premiumPlan, fiveCreditPlan] = await Promise.allSettled([
      standardPlan,
      premiumPlan,
      fiveCreditPlan,
    ]);

    this.setState({
      standardPlan: standardPlan.value,
      premiumPlan:
        this.state.accountType === 'family'
          ? {
              ...premiumPlan.value.familyPlan.product,
              prices: premiumPlan.value.familyPlan.prices,
            }
          : {
              ...premiumPlan.value.personalPlan.product,
              prices: premiumPlan.value.personalPlan.prices,
            },
      fiveCreditPlan: {
        ...fiveCreditPlan.value.product,
        prices: fiveCreditPlan.value.prices,
      },
    });

    this.setState({ loading: false });
  };

  getUserPlanData = async () => {
    try {
      const res = await axios.post('/api/billing/stripe/CheckPlan');
      return res.data;
    } catch (err) {
      this.handleError(err);
    }
  };

  getStandardPlanData = async () => {
    try {
      const res = await axios.get('/api/billing/stripe/ohip-plans');
      return res.data;
    } catch (err) {
      this.handleError(err);
    }
  };

  getPremiumPlanData = async () => {
    try {
      let personalPlan = axios.get('/api/billing/stripe/yearly-plan');
      let familyPlan = axios.get('/api/billing/stripe/yearly-family-plan');

      [personalPlan, familyPlan] = await Promise.allSettled([
        personalPlan,
        familyPlan,
      ]);

      return {
        personalPlan: personalPlan.value.data,
        familyPlan: familyPlan.value.data,
      };
    } catch (err) {
      this.handleError(err);
    }
  };

  getFiveCreditData = async () => {
    try {
      const res = await axios.get('/api/billing/stripe/five-credit-package');
      return res.data;
    } catch (err) {
      this.handleError(err);
    }
  };

  handleError = (err) => {
    this.setState({
      error: true,
      message: err.message,
    });
  };

  handleClose = () => this.setState({ error: false });

  handleStandardContinue = async (option) => {
    const { standardPlan, selectedUser } = this.state;

    await this.props.selectedUserChange({
      id: selectedUser.id,
      fullName: selectedUser.name,
    });

    this.props.history.push({
      pathname: '/checkout',
      state: {
        product: {
          id: standardPlan.id,
          name: standardPlan.name,
          description: standardPlan.description,
          duration_in_hours: option.metadata.duration_in_hours,
          currency: option.currency,
          unit_amount: option.unit_amount,
          interval: option.recurring.interval,
          price_id: option.id,
          plan_type: 'OHIP',
          product_type: 'Subscription',
        },
        origin: '/dashboard/manageSubscriptions',
        redirect: '/dashboard/manageSubscriptions',
      },
    });
  };

  handlePremiumContinue = () => {
    if (this.state.accountType === 'family') {
      this.props.history.push(
        '/PaymentPlanMembership/YearlyFamilyPlan/dashboard'
      );
    } else {
      this.props.history.push('/PaymentPlanMembership/YearlyPlan/dashboard');
    }
  };

  handlePaidVisitContinue = () => {
    this.props.history.push(
      '/PaymentPlanMembership/FiveCreditPackage/dashboard'
    );
  };

  handleCancelContinue = async () => {
    try {
      const url =
        this.state.accountType === 'family'
          ? '/api/billing/stripe/RemoveFamilyPlan'
          : '/api/billing/stripe/RemovePlan';

      const response = await axios.post(url, {
        customerId: this.props.auth.customerId,
      });

      if (response.data) {
        this.props.updateUser(response.data);
        this.setState(() => ({ showCancelDialog: false }));
        window.location.reload();
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  handleRenewContinue = async () => {
    try {
      const url =
        this.state.accountType === 'family'
          ? '/api/billing/stripe/ReAddFamilyPlan'
          : '/api/billing/stripe/ReAddPlan';

      const response = await axios.post(url, {
        customerId: this.props.auth.customerId,
      });

      if (response.data) {
        this.props.updateUser(response.data);
        this.setState(() => ({ showRenewDialog: false }));
        window.location.reload();
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  handleUpgradeContinue = async () => {
    try {
      await axios.post('/api/billing/stripe/UpdatePlanToYearlyFamilyPlan', {
        customerId: this.props.auth.customerId,
      });
      this.setState({
        showUpgradeDialog: false,
        success: true,
        message: 'Transaction successful. Window will now reload.',
      });
      setTimeout(() => {
        this.props.history.push('/dashboard/manageSubscriptions');
        window.location.reload();
      }, 2000);
    } catch (err) {
      this.handleError(err);
    }
  };

  openStandardDialog = (member) =>
    this.setState({
      showStandardDialog: true,
      selectedUser: { id: member.id, name: member.name },
    });

  openPremiumDialog = () => this.setState({ showPremiumDialog: true });

  openPaidVisitDialog = () => this.setState({ showPaidVisitDialog: true });

  openCancelDialog = () => this.setState({ showCancelDialog: true });

  openRenewDialog = () => this.setState({ showRenewDialog: true });

  openUpgradeDialog = () => this.setState({ showUpgradeDialog: true });

  render() {
    const { classes } = this.props;
    const { standardPlan, premiumPlan, fiveCreditPlan } = this.state;

    return (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid container className={classes.header} direction="row">
              <Grid item xs={12} className={classes.title}>
                <Typography variant="h5" color="primary">
                  Manage Subscriptions
                </Typography>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>
                    Subscription management for all account holders and family
                    members.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {this.state.loading ? (
              <Grid
                container
                justify="center"
                className={classes.memberContainer}
              >
                <Grid item>
                  <CircularProgress size={25} color="primary" />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} className={classes.memberContainer}>
                <Grid item xs={12}>
                  <Typography variant="h6" color="primary">
                    Subscriptions
                  </Typography>

                  {/* 
                    Wrap accoridion components in a grid to prevent 
                    typography from being considered the first child element, 
                    which causes styling issues 
                  */}
                  <Grid>
                    {this.state.familyMembers.map((member, i) => {
                      return (
                        <SubscriptionAccordion
                          key={i}
                          member={member}
                          standardDescription={standardPlan.description}
                          premiumDescription={premiumPlan.description}
                          openStandardDialog={() => {
                            this.openStandardDialog(member);
                          }}
                          openPremiumDialog={this.openPremiumDialog}
                          openCancelDialog={this.openCancelDialog}
                          openRenewDialog={this.openRenewDialog}
                          openUpgradeDialog={this.openUpgradeDialog}
                        />
                      );
                    })}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" color="primary">
                    Private Paid Visits Packages
                  </Typography>
                  <Typography variant="body2">
                    {this.state.fiveCreditPlan.description}
                  </Typography>
                  <br />
                  <Typography>
                    <b>Balance:</b> {this.state.creditBalance || 0} paid visits
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.purchaseButton}
                    onClick={this.openPaidVisitDialog}
                  >
                    Purchase
                  </Button>
                </Grid>
              </Grid>
            )}
          </main>
        </Slide>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={this.handleClose}
          open={this.state.error || this.state.success}
          autoHideDuration={5000}
        >
          <Alert severity={this.state.error ? 'error' : 'success'}>
            {this.state.message}
          </Alert>
        </Snackbar>

        <PaidVisitPurchase
          open={this.state.showPaidVisitDialog}
          onClose={() => this.setState({ showPaidVisitDialog: false })}
          onContinue={this.handlePaidVisitContinue}
          product={fiveCreditPlan}
        />

        <StandardMembershipPurchase
          open={this.state.showStandardDialog}
          onClose={() => this.setState({ showStandardDialog: false })}
          onContinue={this.handleStandardContinue}
          product={standardPlan}
        />

        <PremiumSubscriptionPurchase
          open={this.state.showPremiumDialog}
          onClose={() => this.setState({ showPremiumDialog: false })}
          onContinue={this.handlePremiumContinue}
          product={premiumPlan}
        />

        <CancelRenewal
          open={this.state.showCancelDialog}
          onClose={() => this.setState({ showCancelDialog: false })}
          onContinue={this.handleCancelContinue}
        />

        <ResumeRenewal
          open={this.state.showRenewDialog}
          onClose={() => this.setState({ showRenewDialog: false })}
          onContinue={this.handleRenewContinue}
        />

        <UpgradePlan
          open={this.state.showUpgradeDialog}
          onClose={(options) => {
            this.setState({ showUpgradeDialog: false });
            if (options && options.redirect) {
              if (options.path) {
                this.props.history.push(options.path);
              }
              this.props.changePage(options.page);
              setTimeout(
                () =>
                  document.querySelector('#toolbar-section').scrollIntoView({
                    behavior: 'smooth',
                  }),
                500
              );
            }
          }}
          onContinue={this.handleUpgradeContinue}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

ManageSubscriptions = withRouter(ManageSubscriptions);

ManageSubscriptions = connect(mapStateToProps, actions)(ManageSubscriptions);

ManageSubscriptions = withStyles(styles)(ManageSubscriptions);

export default ManageSubscriptions;
