import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, FormSection } from 'redux-form';
import withStyles from '@material-ui/core/styles/withStyles';
import * as actions from '../../../actions';
import ReactQuill from 'react-quill';
import {
  Typography,
  Grid,
  Tabs,
  Tab,
  Button,
  Slide,
  Paper,
} from '@material-ui/core';
import { Edit, ArrowBackIos, Save } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';

import EditNoteInfoCard from './EditNoteInfoCard';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 700,
    },
  },
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
    paddingLeft: '7px',
  },
  fieldMargins: {
    margin: '0 0 20px 0',
  },
  pageMargins: {
    marginBottom: '30px',
  },
  buttonMargins: {
    marginTop: '20px',
  },
  infoTabs: {
    flexGrow: 1,
  },
  cardPaper: {
    width: '100%',
    padding: '5px',
  },
});

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'indent',
];

const Quill = ({ name, editor, savedText, appointment }) => {
  //const [disableButton, setDisable] = useState(false);
  return (
    <Grid item container xs={12} direction="row">
      <Grid item xs={12}>
        <ReactQuill
          id={editor}
          formats={formats}
          modules={modules}
          theme={'snow'}
          placeholder="Write something"
          value={savedText ? savedText : null}
          style={{
            backgroundColor: 'white',
            overflow: 'hidden',
            height: 250,
            width: '100%',
            borderBottom: '1px solid #B0B0B0',
          }}
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        justify="flex-end"
        style={{ marginTop: '7px' }}
      >
        <Button
          form={name}
          size="small"
          startIcon={<Save />}
          variant="outlined"
          type="submit"
          //onClick={() => setDisable(true)}
        >
          Save
          {/* {disableButton && (
            <CircularProgress
              size={15}
              color='primary'
            />
          )} */}
        </Button>
      </Grid>
    </Grid>
  );
};
class EditDoctorNotes extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ updatedText: this.props.editNoteParams.doctorNotes });
  }
  async saveNotes() {
    const values = {};
    values.room = this.props.editNoteParams._id;
    values.chatNote =
      document.getElementById('note-editor').children[1].children[0].innerHTML;
    values.doctorNotesEditable = false;
    values.isEdit = true;
    this.props.updateUnfinishedNotes(values);
    setTimeout(() => this.props.getDoctorAppointmentHistory(), 500);
    this.props.disableToolbar(false);
    this.props.exitPage();
  }

  render() {
    const { classes, editNoteParams } = this.props;

    return (
      <Fragment>
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid container xs={12} spacing={1}>
              <Grid item container xs={12} style={{ margin: '5% 0 2% 0' }}>
                <Grid item xs={9} sm={10}>
                  <Typography variant="h5" color="primary" display="inline">
                    Edit Note
                  </Typography>
                </Grid>
                <Grid item container xs={3} sm={2} justify="flex-end">
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<ArrowBackIos />}
                    onClick={() => {
                      this.props.disableToolbar(false);
                      this.props.exitPage();
                    }}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography color="primary" variant="body2" display="inline">
                  ID:
                </Typography>{' '}
                <Typography
                  color="primary"
                  style={{ textTransform: 'uppercase' }}
                  display="inline"
                >
                  {editNoteParams._id.substring(19, 24)}
                </Typography>
              </Grid>

              {editNoteParams.ohipNumber && (
                <Fragment>
                  <Grid item xs={4} style={{ alignContent: 'center' }}>
                    <Typography
                      color="primary"
                      variant="body2"
                      display="inline"
                    >
                      OHIP:
                    </Typography>{' '}
                    <Typography
                      color="primary"
                      style={{ textTransform: 'uppercase' }}
                      display="inline"
                    >
                      {editNoteParams.ohipNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ alignContent: 'center' }}>
                    <Typography
                      color="primary"
                      variant="body2"
                      display="inline"
                    >
                      Exp. Date:
                    </Typography>{' '}
                    <Typography color="primary" display="inline">
                      {editNoteParams.ohipExpiration}
                    </Typography>
                  </Grid>
                </Fragment>
              )}

              <EditNoteInfoCard editNoteParams={editNoteParams} />
              <Grid item xs={12}>
                <form
                  id="note-editor-form"
                  onSubmit={() => {
                    this.saveNotes();
                  }}
                  style={{
                    width: '100%',
                  }}
                >
                  <Field
                    //name={name}
                    savedText={editNoteParams.doctorNotes}
                    component={Quill}
                    //appointment={apptID}
                    editor="note-editor"
                  />
                </form>
              </Grid>
            </Grid>
          </main>
        </Slide>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

EditDoctorNotes = connect(
  mapStateToProps,
  actions
)(withRouter(EditDoctorNotes));

export default withStyles(styles)(EditDoctorNotes);
