import { FETCH_MEDICAL_PROFILE_COMPLETION } from '../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_MEDICAL_PROFILE_COMPLETION:
      return action.payload || false;

    default:
      return state;
  }
}
