import React, { Component, Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Link } from 'react-router-dom';
import * as actions from '../../actions';
import PropTypes from 'prop-types';
import {
  Button,
  CssBaseline,
  Paper,
  Typography,
  TextField,
  Grid,
  CircularProgress,
  FormControl,
  Fade,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import { IconButton } from '@material-ui/core';
import logoMain from '../SnapMedLogoMain.png';
import logoMainRetina from '../SnapMedLogoMainRetina.png';
import { ArrowBack, Visibility } from '@material-ui/icons';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import { passwordStrength } from 'check-password-strength';
import PasswordStrengthBar from '../commonform/PasswordStrengthBar';
// import PasswordField from 'material-ui-password-field';
import RegistrationPasswordField from './RegistrationPasswordField';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      maxWidth: 450,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: 20,
    float: 'right',
  },
  cancel: {
    marginTop: 20,
    backgroundColor: '#ff4a4a',
    '&:hover': {
      backgroundColor: '#f9b0b9',
    },
  },
  dialog: {
    textDecoration: 'none',
  },
});

const confirmPasswordField = ({
  name,
  input,
  label,
  meta: { touched, error },
}) => (
  <FormControl fullWidth error={touched && error} margin="normal">
    <RegistrationPasswordField
      input={input}
      name={name}
      label={label}
      touched={touched}
      error={error}
      inputProps={{
        autoCapitalize: 'none',
      }}
    />
  </FormControl>
);

const passwordField = ({
  name,
  input,
  label,
  strength,
  meta: { touched, error },
}) => {
  return (
    <FormControl fullWidth error={touched && error} margin="normal">
      <RegistrationPasswordField
        input={input}
        name={name}
        label={label}
        touched={touched}
        error={error}
        inputProps={{
          autoCapitalize: 'none',
        }}
      />
      {input.value && (
        <PasswordStrengthBar
          strength={strength}
          style={{ marginTop: '10px' }}
        />
      )}
    </FormControl>
  );
};

const usernameField = ({
  input,
  label,
  name,
  type,
  meta: { touched, error },
}) => (
  <TextField
    {...input}
    size="small"
    //required
    fullWidth
    label={label}
    type={type}
    id={name}
    error={touched && error}
    helperText={touched && error}
    margin="normal"
    inputProps={{
      autoCapitalize: 'none',
    }}
    variant="outlined"
    onChange={(event) => input.onChange(event.target.value.toLowerCase())}
  />
);

const nameField = ({ input, label, name, type, meta: { touched, error } }) => (
  <TextField
    {...input}
    size="small"
    //required
    fullWidth
    label={label}
    type={type}
    id={name}
    error={touched && error}
    helperText={touched && error}
    margin="normal"
    inputProps={{
      autoCapitalize: 'none',
    }}
    variant="outlined"
  />
);

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        <Typography variant="h5">Help Menu</Typography>
      </DialogTitle>
      <List>
        <Link to="/login" style={{ textDecoration: 'none', color: 'black' }}>
          <ListItem
            button
            onClick={() => handleListItemClick('login')}
            key={'login'}
          >
            <div>
              <Typography variant="subtitle1">
                Are you a returning patient with an existing account?
              </Typography>
              <Typography variant="h6">Return to login screen</Typography>
            </div>
          </ListItem>
        </Link>
        <Link
          to="/registration/new"
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <ListItem
            button
            onClick={() => handleListItemClick('register')}
            key={'register'}
          >
            <div>
              <Typography variant="subtitle1">
                Are you trying to create a new account?
              </Typography>
              <Typography variant="h6">
                Register with a new email address
              </Typography>
            </div>
          </ListItem>
        </Link>
      </List>
      <br />
    </Dialog>
  );
}

function HelpDialogComponent(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('register');
  const { reset } = props;
  const { registrationFormResetHandler } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);

    reset();
    registrationFormResetHandler();
  };

  return (
    <div>
      <Grid container spacing={1} justify="center">
        <Grid item xs={12}>
          <Button color="primary" onClick={handleClickOpen}>
            Need help finding what you're looking for?
          </Button>
        </Grid>
      </Grid>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}

class RegistrationFormNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSubmit: false,
      loadingClear: false,
      turn: false,
      strength: { id: -1 }, // initial val = -1 because strenght starts @ 0
    };
    this.fetchDataSubmit = this.fetchDataSubmit.bind(this);
    this.fetchDataCancel = this.fetchDataCancel.bind(this);
    this.registrationFormReset = this.registrationFormReset.bind(this);
  }

  renderFields() {
    const fields = [
      { name: 'firstName', label: 'First Name', type: 'text' },
      { name: 'lastName', label: 'Last Name', type: 'text' },
    ];
    return _.map(fields, ({ label, name, type }) => {
      return (
        <Grid item xs={6} key={name}>
          <Field name={name} label={label} type={type} component={nameField} />
        </Grid>
      );
    });
  }

  turnOn = () => {
    this.setState({ turn: true });
  };

  fetchDataCancel() {
    this.setState({ loadingCancel: true });
    this.props.handleSubmit();
  }

  fetchDataSubmit = () => {
    this.setState({ loadingSubmit: true });
    this.props.handleSubmit();
  };

  disableRegisterButton = (errorOne, invalid, pristine) => {
    if (invalid === true) {
      return true;
    } else if (errorOne === true) {
      return true;
    } else if (pristine === true) {
      return true;
    } else {
      return false;
    }
  };

  registrationFormReset() {
    this.setState({ loadingSubmit: false });
    this.props.dispatch(actions.resetRegistrationError());
  }

  render() {
    const { handleSubmit, classes, errorOne, invalid, reset, pristine } =
      this.props;

    return (
      <Fragment>
        <Grid
          container
          spacing={1}
          style={{
            margin: 0,
            width: '100%',
          }}
        >
          <CssBaseline />
          <main className={classes.layout}>
            <Fade in={true} timeout={500}>
              <Paper className={classes.paper}>
                <img
                  alt="SnapMed Logo"
                  src={logoMain}
                  srcSet={`${logoMainRetina} 2x,`}
                />
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{ marginTop: '10px' }}
                  >
                    Registration
                  </Typography>
                </Grid>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <Grid item container spacing={1}>
                    {this.renderFields()}
                  </Grid>
                  <Field
                    name="email"
                    label="Email"
                    type="email"
                    component={usernameField}
                  />
                  <Field
                    label="Password"
                    name="password"
                    component={passwordField}
                    strength={this.state.strength}
                    onChange={(e, newValue) => {
                      const strength = passwordStrength(newValue);
                      this.setState({ strength: strength });
                    }}
                  />
                  <Field
                    label="Password Confirmation"
                    name="password_confirmation"
                    component={confirmPasswordField}
                  />
                  <Grid container>
                    <Grid item xs={2}>
                      <Link to="/login">
                        <IconButton
                          style={{
                            color: 'black',
                          }}
                          onClick={() => {
                            reset();
                            this.setState({ loadingSubmit: false });
                            this.props.dispatch(
                              actions.resetRegistrationError()
                            );
                          }}
                        >
                          <ArrowBack />
                        </IconButton>
                      </Link>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={10}
                      spacing={1}
                      alignContent="center"
                      justify="flex-end"
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        disabled={this.state.loadingClear}
                        onClick={() => {
                          reset();
                          this.setState({
                            loadingSubmit: false,
                            strength: { id: -1 },
                          });
                          this.props.dispatch(actions.resetRegistrationError());
                        }}
                        style={{ marginRight: '5px' }}
                      >
                        Clear
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="small"
                        onClick={() => {
                          this.setState({ loadingSubmit: true });
                        }}
                        disabled={this.disableRegisterButton(
                          errorOne,
                          invalid,
                          pristine
                        )}
                      >
                        Register
                        {this.state.loadingSubmit && !errorOne && (
                          <CircularProgress
                            size={15}
                            style={{
                              marginLeft: 5,
                            }}
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  {errorOne === true && (
                    <Typography color="error" variant="body1" align="center">
                      * This email is already registered.
                    </Typography>
                  )}
                  <br />
                  {errorOne === true && (
                    <Grid item xs={12} align="center">
                      <HelpDialogComponent
                        reset={this.props.reset}
                        registrationFormResetHandler={
                          this.registrationFormReset
                        }
                      />
                    </Grid>
                  )}
                </form>
              </Paper>
            </Fade>
          </main>
        </Grid>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'Field required';
  }
  if (!values.lastName) {
    errors.lastName = 'Field required';
  }
  if (!values.email) {
    errors.email = 'Field required';
  }
  if (!values.password) {
    errors.password = 'Field required';
  }
  if (!values.password_confirmation) {
    errors.password_confirmation = 'Field required';
  }
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = 'Please enter a valid email address';
  }
  if (
    values.password &&
    values.password_confirmation &&
    values.password != values.password_confirmation
  ) {
    errors.password_confirmation = 'Passwords must match';
  }

  const strength = passwordStrength(values.password);
  if (values.password && strength.id < 2) {
    errors.password = 'Password not strong enough';
  }

  return errors;
}

RegistrationFormNew = reduxForm({
  validate,
  form: 'loginForm',
  enableReinitialize: true,
})(RegistrationFormNew);

RegistrationFormNew.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(RegistrationFormNew);
