import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route, Switch, Router } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme from './SnapMedTheme';
import Header from './Header';
import LoginRoot from './login/LoginRoot';
import ResetPassword from './login/ResetPassword';
import DoctorLoginRoot from './doctors/login/DoctorLoginRoot';
import PharmacyLoginForm from '../components/pharmacies/login/PharmacyLoginForm';
import Registration from './registration/Registration';
import Dashboard from './dashboard/Dashboard';
import DoctorDashboard from './doctors/dashboard/Dashboard';
import PharmacyDashboard from './pharmacies/dashboard/PharmacyDashboard';
import Footer from './Footer';
import ProfileFormRoot from './profileform/ProfileFormRoot';
import AdditionalFamilyMembers from './profileform/AdditionalFamilyMembers';
import ModifyFamilyMembers from './profileform/ModifyFamilyMembers';
import WizardForm from './medicalform/wizardprofile/WizardForm';
import CheckEmailPromptPage from './registration/CheckEmailPromptPage';
import PaymentPlanRoot from './payment/PaymentPlanRoot';
import SeeDoctorRoot from './seeDoctor/SeeDoctorRoot';
import SeeDoctorRootOhip from './seeDoctor/SeeDoctorRootOhip';
import PatientAppointmentRoom from './seeDoctor/PatientAppointmentRoom';
import TestAppointmentRoom from './seeDoctor/TestAppointmentRoom';
import DoctorAppointmentRoom from './doctors/appointmentroom/DoctorAppointmentRoom';
import WizardProfileCompletionAcknowledgement from './medicalform/wizardprofile/WizardProfileCompletionAcknowledgement';
import { CssBaseline, Typography } from '@material-ui/core';
import DoctorSignature from './doctors/DoctorSignature';
import '../styles/stickyfooter.css';
import '../styles/main-style.css';
import '../styles/header.css';
import equal from 'fast-deep-equal';
import SignUpFailure from './SignUpFailure';
import CheckoutForm from './payment/CheckoutForm';
import PaymentAcknowledgement from './payment/PaymentAcknowledgement';
import TermsAndConditions from '../legal/TermsAndConditions';
import PrivacyPolicy from '../legal/PrivacyPolicy';
import LoggedOut from './login/LoggedOut';
import AccountTransferForm from './registration/AccountTransferForm';
import ResetPasswordPharmacy from './pharmacies/login/ResetPasswordPharmacy';
import NotificationProvider from './commonform/NotificationProvider';
import StandardMembership from './dashboard/StandardMembership';
import Checkout from './payment/Checkout';
import CheckoutConfirmation from './payment/CheckoutConfirmation';
import HTTPErrorHandler from './commonform/HTTPErrorHandler';
import EmailConfirmationMessage from './registration/EmailConfirmationMessage';
import { REACT_APP_JAAS_APP_ID } from '../constants';
import { QueueProvider } from '../contexts/QueueContext';
import { Toaster } from 'react-hot-toast';
import { DoctorListProvider } from '../contexts/DoctorListContext';

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.pagehide = undefined;
    window.popstate = undefined;
    this.props.fetchData();
    this.props.fetchUser();
  }

  //test

  componentDidUpdate = (prevProps) => {
    if (!equal(this.props.auth, prevProps.auth)) {
      this.setState({ display: 'none' });
    }
    if (
      this.props.appointmentParams &&
      this.props.appointmentParams.appointmentId &&
      prevProps !== this.props
    ) {
      if (this.props.auth.role === 'patient') {
        window.addEventListener('unload', () => {
          const data = {
            appointmentId: this.props.appointmentParams.appointmentId,
          };

          const blob = new Blob([JSON.stringify(data)], {
            type: 'text/plain; charset=UTF-8',
          });
          // navigator.sendBeacon('/api/patientleavesopentoken', blob);
          // window.location.replace('/');
        });
      }

      if (this.props.auth.role === 'doctor') {
        window.addEventListener('unload', async () => {
          const data = {
            appointment: this.props.appointmentParams.appointment,
          };

          const blob = new Blob([JSON.stringify(data)], {
            type: 'text/plain; charset=UTF-8',
          });
          // navigator.sendBeacon('/api/doctor_incorrectly_left', blob);
          // window.location.replace('/doctors');
        });
      }

      if (this.props.auth.role === 'pharmacy') {
        window.addEventListener('unload', async () => {
          window.location.replace('/pharmacy');
        });
      }

      window.addEventListener('popstate', async () => {
        if (this.props.auth.role === 'patient') {
          window.location.replace('/');
        }
        if (this.props.auth.role === 'doctor') {
          window.location.replace('/doctors');
        }
        if (this.props.auth.role === 'pharmacy') {
          window.location.replace('/pharmacy');
        }
      });
    } else {
      window.pagehide = undefined;
      window.popstate = undefined;
    }
  };

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Fragment>
          <CssBaseline />
          <Toaster position="bottom-right" />
          <BrowserRouter>
            <div className="Site">
              <NotificationProvider />
              <HTTPErrorHandler />
              <QueueProvider>
                <DoctorListProvider>
                  <div className="Site-content">
                    {!this.props.auth || !this.props.auth.name ? (
                      <Switch>
                        <Route
                          exact={true}
                          path="/registration/new"
                          component={Registration}
                        />
                        <Route
                          exact={true}
                          path="/emailverification/:email"
                          component={CheckEmailPromptPage}
                        />
                        <Route
                          exact
                          path="/doctors"
                          component={DoctorLoginRoot}
                        />
                        <Route
                          exact
                          path="/pharmacy"
                          component={PharmacyLoginForm}
                        />
                        <Route
                          exact
                          path="/login/:failure"
                          component={LoginRoot}
                        />
                        <Route
                          exact
                          path="/password-reset/:token"
                          component={ResetPassword}
                        />
                        <Route
                          exact={true}
                          path="/email-confirmation/:status?/:userId?"
                          component={EmailConfirmationMessage}
                        />
                        <Route
                          exact={true}
                          path="/privacy-policy"
                          component={PrivacyPolicy}
                        />
                        <Route
                          exact={true}
                          path="/terms-and-conditions"
                          component={TermsAndConditions}
                        />
                        <Route
                          exact={true}
                          path="/account-transfer-form/:userId"
                          component={AccountTransferForm}
                        />
                        <Route
                          exact
                          path="/password-reset/pharmacy/:token"
                          component={ResetPasswordPharmacy}
                        />
                        <Route
                          exact
                          path={`/${REACT_APP_JAAS_APP_ID}/:room`}
                          component={TestAppointmentRoom}
                        />

                        <Route exact path="/*" component={LoginRoot} />
                      </Switch>
                    ) : (
                      [
                        this.props.auth.role === 'patient' ? (
                          <div className="Site" key="1">
                            <Header />
                            <div className="Site-content">
                              <Switch>
                                <Route exact path="/" component={Dashboard} />
                                <Route
                                  exact
                                  path="/dashboard/:toolbarPage?/:editPage?"
                                  component={Dashboard}
                                />
                                <Route
                                  exact={true}
                                  path="/profileform"
                                  component={ProfileFormRoot}
                                />
                                <Route
                                  exact={true}
                                  path="/AdditionalFamilyMembers"
                                  component={AdditionalFamilyMembers}
                                />
                                <Route
                                  exact={true}
                                  path="/standard-plans"
                                  component={StandardMembership}
                                />
                                <Route
                                  exact={true}
                                  path="/checkout"
                                  component={Checkout}
                                />
                                <Route
                                  exact
                                  path="/checkout/confirmation"
                                  component={CheckoutConfirmation}
                                />
                                <Route
                                  exact={true}
                                  path="/ModifyFamilyMembers"
                                  component={ModifyFamilyMembers}
                                />
                                <Route
                                  exact
                                  path="/paymentacknowledgment/:existing?"
                                  component={PaymentAcknowledgement}
                                />
                                <Route
                                  exact={true}
                                  path="/registration/new"
                                  component={Registration}
                                />
                                <Route
                                  exact={true}
                                  path="/logged_out"
                                  component={LoggedOut}
                                />
                                <Route
                                  exact={true}
                                  path="/paymentplans"
                                  component={PaymentPlanRoot}
                                />
                                <Route
                                  exact={true}
                                  path="/PaymentPlanMembership/:selectedType/:origin"
                                  component={CheckoutForm}
                                />
                                <Route
                                  exact={true}
                                  path="/medicalprofileform/:name"
                                  component={WizardForm}
                                />
                                <Route
                                  exact={true}
                                  path="/your_symptoms"
                                  component={SeeDoctorRoot}
                                />
                                <Route
                                  exact={true}
                                  path="/your_ohipsymptoms"
                                  component={SeeDoctorRootOhip}
                                />
                                <Route
                                  exact
                                  path="/appointmentroomp/:room"
                                  component={PatientAppointmentRoom}
                                />
                                <Route
                                  exact={true}
                                  path="/completed"
                                  component={
                                    WizardProfileCompletionAcknowledgement
                                  }
                                />
                                <Route
                                  exact={true}
                                  path="/TermsAndConditions"
                                  component={TermsAndConditions}
                                />
                                <Route
                                  exact={true}
                                  path="/PrivacyPolicy"
                                  component={PrivacyPolicy}
                                />

                                <Route exact path="*" component={Dashboard} />
                              </Switch>
                            </div>

                            <Footer />
                          </div>
                        ) : this.props.auth.role === 'doctor' ? (
                          <div className="Site" key="2">
                            <Header />
                            <div className="Site-content">
                              <Switch>
                                <Route
                                  exact
                                  path="/"
                                  component={DoctorDashboard}
                                />
                                <Route
                                  exact
                                  path="/dashboard"
                                  component={DoctorDashboard}
                                />
                                <Route
                                  exact={true}
                                  path="/appointmentroomdr/:room"
                                  component={DoctorAppointmentRoom}
                                />

                                <Route
                                  exact={true}
                                  path="/logged_out"
                                  component={LoggedOut}
                                />

                                <Route
                                  exact={true}
                                  path="/TermsAndConditions"
                                  component={TermsAndConditions}
                                />
                                <Route
                                  exact={true}
                                  path="/PrivacyPolicy"
                                  component={PrivacyPolicy}
                                />

                                <Route
                                  exact
                                  path="*"
                                  component={DoctorDashboard}
                                />
                              </Switch>
                            </div>
                            <Footer />
                          </div>
                        ) : this.props.auth.role === 'pharmacy' ? (
                          <div className="Site" key="3">
                            <Header />
                            <Switch>
                              <Route
                                exact
                                path="/"
                                component={PharmacyDashboard}
                              />
                              <Route
                                exact
                                path="/pharmacy-dashboard"
                                component={PharmacyDashboard}
                              />
                              <Route
                                exact
                                path="*"
                                component={PharmacyDashboard}
                              />
                            </Switch>
                          </div>
                        ) : (
                          ''
                        ),
                      ]
                    )}
                  </div>
                </DoctorListProvider>
              </QueueProvider>
            </div>
          </BrowserRouter>
        </Fragment>
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    appointmentParams: state.appointmentParams,
    //state for getting rid of header/footer
  };
}

export default connect(mapStateToProps, actions)(App);
