import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
} from '@material-ui/core';

import DropzoneInput from '../commonform/FileInput';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
    };
  }

  componentDidUpdate = () => {
    if (this.props.open !== this.state.open) {
      this.setState({
        open: this.props.open,
      });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.onClose && typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  render() {
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={this.state.open}
        onClose={this.handleClose}
      >
        <DialogTitle>
          <Typography variant="h5" color="primary">
            Upload Files
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            <Typography color="primary">
              You may upload images, MS Word documents and PDFs.
            </Typography>
          </Grid>
          <DropzoneInput
            patientId={this.props.patientId}
            appointmentId={this.props.appointmentId}
            closeComponent={this.handleClose}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.appointmentParams.patientId,
    appointmentId: state.appointmentParams.appointmentId,
  };
}

FileUpload = connect(mapStateToProps, actions)(FileUpload);

export default FileUpload;
