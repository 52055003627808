const general_sicknote_field = [
  {
    name: 'general_sicknote_final_validation_yes',
    type: 'input'
  },
  { name: 'general_sicknote_final_validation_na', type: 'input' }
];

module.exports.general_sicknote_field = general_sicknote_field;

const school_sicknote_field = [
  { name: 'school_sicknote_final_validation_yes', type: 'input' },
  { name: 'school_sicknote_final_validation_na', type: 'input' }
];

module.exports.school_sicknote_field = school_sicknote_field;

const prescription_final_field = [
  { name: 'prescription_final_validation_yes', type: 'input' },
  { name: 'prescription_final_validation_na', type: 'input' }
];

module.exports.prescription_final_field = prescription_final_field;

const final_id_validation_field = [
  { name: 'final_id_validation_field_yes', type: 'input' },
  { name: 'final_id_validation_field_na', type: 'input' }
];

module.exports.final_id_validation_field = final_id_validation_field;
