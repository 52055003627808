import { FETCH_PRICE } from '../actions/types';

export default function(
  state = { isPromoApplied: false, promoCodeAmount: 0, priceId: false },
  action
) {
  switch (action.type) {
    case FETCH_PRICE:
      return Object.assign({}, state, {
        price: action.price,
        isPromoCodeApplied: action.isPromoApplied,
        promoCodeAmount: action.promoCodeAmount,
        priceId: action.priceId
      });

    default:
      return state;
  }
}
