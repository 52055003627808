import React, { Fragment } from 'react';
import _ from 'lodash';
import { Button, Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

const FamilyMedicalFormCompletionPrompts = (props) => {
  var medicalPromptsArray = _.map(props.medicalProfileCompletion, (member) => {
    var url = member.id;

    if (!member.completed && member.isActive) {
      //   return (
      //     <Fragment key={member}>
      //       <Button
      //         variant="contained"
      //         color="primary"
      //         style={{ marginRight: '10px', marginTop: '10px' }}
      //       >
      //         <Link
      //           style={{ color: 'white', textDecoration: 'none' }}
      //           to="/your_symptoms"
      //           onClick={(e) => {
      //             props.setPatientId(member.id);
      //           }}
      //         >
      //           See a Doctor for {member.name.first}
      //         </Link>
      //       </Button>
      //       <br />
      //     </Fragment>
      //   );
      // } else {
      url = '/medicalprofileform/' + url;
      return (
        <Grid item>
          <Fragment key={member}>
            <Typography variant="body1">
              {member.name.first} needs to complete their Medical Profile in
              order to see a doctor:
            </Typography>
            <Link
              style={{
                textDecoration: 'none',
              }}
              to={url}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  maxWidth: 220,
                }}
              >
                Complete Medical Profile - {member.name.first}
              </Button>
            </Link>
          </Fragment>
        </Grid>
      );
    }
  });

  return <Fragment>{medicalPromptsArray}</Fragment>;
};

export default FamilyMedicalFormCompletionPrompts;
