import { Backdrop, CircularProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import React, { Component } from 'react';
import toast from 'react-hot-toast';

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

class HTTPErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backdrop: false /*  */,
    };

    axios.interceptors.request.use(
      (config) => {
        if (config.data && config.data.backdrop) {
          this.handleBackdrop(true);
          delete config.data.backdrop;
        }

        return config;
      },
      (err) => {
        return err;
      }
    );

    axios.interceptors.response.use(
      (response) => {
        if (response.data.success) {
          this.handleSuccess(response);
        }
        this.handleBackdrop(false);
        return response;
      },
      (err) => {
        if (err.response) {
          this.handleError(err);
        }
        return err;
      }
    );
  }

  handleBackdrop = (value) => {
    this.setState({
      backdrop: value,
    });
  };

  handleSuccess = (response) => {
    toast.success(response.data.success);
    this.setState({
      backdrop: false,
    });
  };

  handleError = (err) => {
    toast.error(this.getErrorMessage(err));
    this.setState({
      backdrop: false,
    });
  };

  getErrorMessage(error) {
    const { data } = error.response;
    if (data) {
      if (data.error) {
        return data.error;
      } else if (data.message) {
        return data.message;
      } else if (typeof data === 'string') {
        return data;
      } else {
        // TODO: send a message to server that it got an error without a known
        //       error field
        return 'Unknown error occured: Error did not have known field';
      }
    } else {
      return 'Unknown error occured: Error lacked data';
    }
  }

  handleClose = () => {
    this.setState({
      open: false,
    });

    setTimeout(() => {
      this.setState({
        message: '',
      });
    }, 500);
  };

  render() {
    const { backdrop } = this.state;
    const { classes } = this.props;
    return (
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}

export default withStyles(styles)(HTTPErrorHandler);
