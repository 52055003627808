import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Typography, Grid } from '@material-ui/core';
import OfficialStripePaymentPrompt from './CheckoutFormYearlyFamilyPlan';
import { Elements } from 'react-stripe-elements';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { withRouter } from 'react-router-dom';
import { Button, Dialog } from '@material-ui/core';
import { Link } from 'react-router-dom';

class PayAsYouGo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: 0,
    };
  }

  async componentDidMount() {
    const res = await axios.get('/api/billing/stripe/PayAsYouGoData/');
    this.setState({ price: res.data.price });
  }

  render() {
    return (
      <Fragment>
        <Grid container spacing={1} justify="center">
          <Grid container xs={12} sm={8}>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                Pay Per Visit:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Our <b>Pay Per Visit</b> option will give you 1 appointment with
                a Canadian doctor for only <b>${this.state.price} CAD</b>.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={4}
            justify="center"
            alignItems="center"
          >
            <Button variant="contained" color="primary" size="small">
              <Link
                style={{ color: 'white', textDecoration: 'none' }}
                to={
                  this.props.origin === 'dashboard'
                    ? '/PaymentPlanMembership/PayAsYouGo/dashboard'
                    : '/PaymentPlanMembership/PayAsYouGo/appointment'
                }
              >
                Select
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default PayAsYouGo;
