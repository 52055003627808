import { PAYMENTHISTORY } from '../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case PAYMENTHISTORY:
      return Object.assign({}, state, {
        paymentHistory: action.payload
      });

    default:
      return state;
  }
}
