import React, { Component, Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import * as actions from '../../actions';
import _ from 'lodash';
import loginFormFields from './loginFormFields';
import PropTypes from 'prop-types';
import {
  Button,
  Paper,
  TextField,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress,
  Grid,
  Collapse,
  Fade,
} from '@material-ui/core';
import TextButton from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import Snackbar from '@material-ui/core/Snackbar';
import { Close } from '@material-ui/icons';
import PasswordField from 'material-ui-password-field';
import logoMain from '../SnapMedLogoMain.png';
import logoMainRetina from '../SnapMedLogoMainRetina.png';
import '../../styles/loginFormStyles.css';
import { REACT_APP_VERSION } from '../../constants';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      maxWidth: 450,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  link: {
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
    width: '100px',
  },
  eye: {
    cursor: 'pointer',
  },
  passwordCard: {
    boxShadow: '0px 5px 5px -1px gray',
    backgroundColor: 'aliceblue',
  },
});

const usernameField = ({
  input,
  label,
  name,
  type,
  meta: { touched, error },
}) => (
  <TextField
    {...input}
    //required
    fullWidth
    label={label}
    type={type}
    id={name}
    error={touched && error}
    helperText={touched && error}
    margin="normal"
    inputProps={{
      autoCapitalize: 'none',
    }}
    onChange={(event) => input.onChange(event.target.value.toLowerCase())}
  />
);

const passwordField = ({
  input,
  label,
  name,
  type,
  meta: { touched, error },
  passwordIsMasked,
  classes,
}) => (
  <FormControl
    {...input}
    fullWidth
    label={label}
    id={name}
    error={touched && error}
    margin="normal"
  >
    <InputLabel>Password</InputLabel>
    <PasswordField
      inputProps={{
        autoCapitalize: 'none',
      }}
    />
    {touched && error && <FormHelperText>field required</FormHelperText>}
  </FormControl>
);

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgotPasswordField: false,
      email: '',
      forgotEmailMessage: '',
      passwordIsMasked: true,
      loadingRegister: false,
      loadingResetPassword: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.idleLogout === true) {
        this.setState({ forgotEmailMessage: 'You have been logged out.' });
      }
    }, 500);
  }

  handleClose = () => {
    this.setState({
      forgotEmailMessage: '',
      email: '',
      loadingResetPassword: false,
    });
  };

  renderFields() {
    return _.map(loginFormFields, ({ label, name, type }) => {
      if (type === 'email') {
        return (
          <Field
            name={name}
            label={label}
            type={type}
            component={usernameField}
          />
        );
      } else if (type === 'password') {
        return (
          <Field
            name={name}
            label={label}
            type={type}
            classes={this.props.classes}
            component={passwordField}
            passwordIsMasked={this.state.passwordIsMasked}
          />
        );
      }
    });
  }

  togglePasswordMask = () => {
    this.setState((prevState) => ({
      passwordIsMasked: !prevState.passwordIsMasked,
    }));
  };

  render() {
    const { handleSubmit, classes, loginFailure } = this.props;
    const { loadingResetPassword, showForgotPasswordField } = this.state;
    return (
      <Fragment>
        <main className={classes.layout}>
          <Fade in={true} timeout={500}>
            <Paper className={classes.paper}>
              <img
                alt="SnapMed Logo"
                src={logoMain}
                srcSet={`${logoMainRetina} 2x,`}
              />
              <form className={classes.form} onSubmit={handleSubmit}>
                {this.renderFields()}

                {loginFailure === true && (
                  <Typography color="error" variant="body1">
                    Incorrect email or password
                  </Typography>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={true ? classes.submit : classes.example}
                >
                  Sign in
                </Button>
                {}
                <Link to="/registration/new" style={{ textDecoration: 'none' }}>
                  <Button //className={classes.link}
                    variant="outlined"
                    fullWidth
                    disabled={this.state.loadingRegister}
                    onClick={() => this.setState({ loadingRegister: true })}
                  >
                    Register
                  </Button>
                </Link>
              </form>
              <TextButton
                //className={classes.link}
                size="small"
                style={{ marginTop: '50px' }}
                onClick={() => {
                  this.setState({
                    showForgotPasswordField: !showForgotPasswordField,
                  });
                }}
              >
                forgot password?
              </TextButton>
              <Collapse in={showForgotPasswordField}>
                <Grid container xs={12} className={classes.passwordCard}>
                  <Grid item xs={12} container justify="flex-end">
                    <IconButton
                      onClick={() =>
                        this.setState({ showForgotPasswordField: false })
                      }
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    style={{
                      padding: '0px 20px 20px 20px',
                    }}
                  >
                    <Typography variant="body1" color="primary">
                      Email associated to your account:
                    </Typography>
                    <TextField
                      id="email"
                      type="email"
                      label="Email"
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      fullWidth
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{
                        margin: '15px 0 0 10px',
                      }}
                      onClick={(_) => {
                        this.submitForgotEmail(this.state.email);
                        this.setState({
                          showForgotPasswordField: false,
                          loadingResetPassword: true,
                        });
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Collapse>
              <Typography
                align="center"
                variant="caption"
                style={{ marginTop: 16 }}
              >
                SnapMED v{REACT_APP_VERSION}
              </Typography>
              {loadingResetPassword && (
                <CircularProgress size={25} color="primary" />
              )}
            </Paper>
          </Fade>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.forgotEmailMessage ? true : false}
            autoHideDuration={3000}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={
              <span id="message-id">{this.state.forgotEmailMessage}</span>
            }
            action={
              <Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <Close />
                </IconButton>
              </Fragment>
            }
          />
        </main>
      </Fragment>
    );
  }

  async submitForgotEmail(email) {
    const res = await this.props.LoginService.forgotPassword(email);
    this.setState({ forgotEmailMessage: res, loadingResetPassword: false });
  }
}

function validate(values) {
  const errors = {};

  _.each(loginFormFields, ({ name }) => {
    if (!values[name]) {
      errors[name] = 'field required';
    }
  });

  return errors;
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    loginFailure: state.loginFailure,
    idleLogout: state.idleLogout,
    workingHours: state.workingHours,
  };
}

LoginForm = connect(mapStateToProps, actions)(withRouter(LoginForm));

LoginForm = reduxForm({
  validate,
  form: 'loginForm',
})(LoginForm);

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(LoginForm);
