import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { CssBaseline, Paper, Typography, Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button } from '@material-ui/core';

const logo = require('../../images/SnapMed-Logo-smaller.png');

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
});

class CheckEmailPromptPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { email } = this.props.match.params;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <img src={logo} alt="SnapMed Logo" height="60" width="204" />
            <Typography variant="h5" color="primary" style={{ marginTop: 20 }}>
              Please Confirm Your Email Address
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              style={{ marginTop: 20, textAlign: 'center' }}
            >
              A confirmation link was sent to <b>{email}</b>
              <br /> and should be in your e-mail inbox{' '}
              <strong>within 5 minutes</strong>.
              <br />
              Thank you for your patience.
            </Typography>
            <Button
              className={classes.button}
              href="/login"
              variant="contained"
              color="primary"
              style={{
                margin: '15px',
              }}
            >
              Ok
            </Button>
          </Paper>
        </main>
      </Fragment>
    );
  }
}

export default withStyles(styles)(CheckEmailPromptPage);
