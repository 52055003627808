import _ from "lodash";

function checkIfAtLeastOneValueFilledIn(foundOneValue, type) {
  var errors = {};
  if (!foundOneValue) {
    errors[type + "_none"] = "You must provide at least one value";
  }

  return errors;
}

export default (values, type, fields) => {
  var errors = {};
  var regExp = new RegExp(type + "_*");
  var foundOneValue = false;
  var noneSelected = false;

  _.each(fields, ({ name, id }) => {
    if (regExp.test(name)) {
      if (values[name]) {
        if (name === type + "_none") {
          noneSelected = true;
        } else {
          foundOneValue = true;
        }
      }
    }
  });
  if (noneSelected && foundOneValue) {
    errors[type + "_none"] =
      'You cannot select "None" combined with another option, please revise your answer.';
  }

  _.merge(
    errors,
    checkIfAtLeastOneValueFilledIn(foundOneValue || noneSelected, type)
  );

  return errors;
};
