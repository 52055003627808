import React from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import Notes from '@material-ui/icons/Description';
import PharmacyIcon from '@material-ui/icons/LocalPharmacy';
import DoctorNotesIcon from '@material-ui/icons/Assignment';

import Popup from 'reactjs-popup';
import { Button } from '@material-ui/core';

class PdfDialog extends React.Component {
  renderIcon = () => {
    const { data, type } = this.props;
    if (type == 'Prescription') {
      return <PharmacyIcon style={{ color: 'primary' }} />;
    } else if (type == 'Sick Note') {
      return <Notes style={{ color: 'primary' }} />;
    } else {
      return <DoctorNotesIcon style={{ color: 'primary' }} />;
    }
  };
  render() {
    const { data, type } = this.props;

    return (
      <Popup
        contentStyle={{
          width: 'auto',
          backgroundColor: 'white',
          border: '3px solid',
          borderColor: '#192b4a',
          borderRadius: '10px',
          padding: '20px'
        }}
        trigger={
          <IconButton
            variant={'raised'}
            color="primary"
          >
            {this.renderIcon()}
          </IconButton>
        }
        modal
      >
        <span>
          {type == 'Doctor Notes' ? (
            <div style={{ textAlign: 'center' }}>
              <strong>
                {' '}
                Dr. {this.props.doctor.name.first} {this.props.doctor.name.last}
              </strong>
              {ReactHtmlParser(data)}
            </div>
          ) : (
              <div style={{ textAlign: 'center' }}>
                <strong>
                  {' '}
                  Please refer to PDF sent to your email.
              </strong>
              </div>
            )}
        </span>
      </Popup>
    );
  }
}

export default PdfDialog;
