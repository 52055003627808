import React, { Component, Fragment } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
  background: {
    backgroundColor: '#EEEDEE',
  },
  status: {
    marginTop: 3,
  },
  summary: {
    backgroundColor: '#EEEDEE',
  },
  sectionHeader: {
    borderBottom: '1px solid #192b4a',
  },
  purchaseButton: {
    marginTop: theme.spacing.unit * 2,
  },
  cancelButton: {
    marginTop: theme.spacing.unit * 2,
    backgroundColor: '#ff4a4a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#e54242',
    },
  },
  resumeButton: {
    marginTop: theme.spacing.unit * 2,
  },
  subscribed: {
    color: 'green',
  },
});

class SubscriptionAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plans: undefined,
      accountType: props.auth.familyMembers.length > 0 ? 'family' : 'personal',
    };

    props.member.standardMembershipExpire = new Date(
      props.member.standardMembershipExpire
    );

    if (
      props.member.standardMembershipStatus &&
      props.member.premiumSubscriptionStatus
    )
      this.state.plans = 'Standard & Premium';
    else if (props.member.standardMembershipStatus)
      this.state.plans = 'Standard';
    else if (props.member.premiumSubscriptionStatus)
      this.state.plans = 'Premium';
    else this.state.plans = 'None';
  }

  handleStandardOnClick = async () => {
    await this.props.selectedUserChange({
      id: this.props.member.id,
      fullName: this.props.member.name,
    });

    this.props.history.push({
      pathname: '/ohip_plan',
      state: {
        origin: '/dashboard/manageSubscriptions',
        redirect: '/dashboard/manageSubscriptions',
      },
    });
  };

  handlePremiumOnClick = async () => {
    if (this.state.accountType === 'family') {
      this.props.history.push(
        '/PaymentPlanMembership/YearlyFamilyPlan/dashboard'
      );
    } else {
      this.props.history.push('/PaymentPlanMembership/YearlyPlan/dashboard');
    }
  };

  render() {
    const { member, classes, standardDescription, premiumDescription } =
      this.props;
    const { accountType } = this.state;

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          className={classes.summary}
        >
          <Grid container direction="row" alignContent="center">
            <Grid item xs={7}>
              <Typography color="primary">{member.name}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                color="primary"
                variant="body2"
                align="left"
                className={classes.status}
              >
                Plan(s): {this.state.plans}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container direction="column" alignItems="stretch" spacing={2}>
            <Grid item>
              <Typography
                color="primary"
                variant="h6"
                fullWidth
                className={classes.sectionHeader}
              >
                Standard Subscription
              </Typography>
              <Typography variant="body2">{standardDescription}</Typography>
              <br />
              {accountType === 'family' && (
                <Fragment>
                  <Typography variant="body2">
                    <b>Note:</b> The Standard subscription applies to each
                    individual member and it cannot be shared between family
                    members.
                  </Typography>
                  <br />
                </Fragment>
              )}

              <Typography>
                <b>Status:</b>{' '}
                <span
                  className={
                    member.standardMembershipStatus ? classes.subscribed : ''
                  }
                >
                  {member.standardMembershipStatus
                    ? 'Subscribed'
                    : 'Not Subscribed'}
                </span>
              </Typography>

              {member.standardMembershipStatus ? (
                <Typography>
                  <b>Expires:</b>{' '}
                  {member.standardMembershipExpire.toLocaleString()}
                </Typography>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.purchaseButton}
                  onClick={this.props.openStandardDialog}
                >
                  Purchase
                </Button>
              )}
            </Grid>
            <Grid item>
              <Typography
                color="primary"
                variant="h6"
                fullWidth
                className={classes.sectionHeader}
              >
                Premium Subscription
              </Typography>
              <Typography variant="body2">{premiumDescription}</Typography>
              <br />

              <Typography>
                <b>Status:</b>{' '}
                <span
                  className={
                    member.premiumSubscriptionStatus ? classes.subscribed : ''
                  }
                >
                  {member.premiumSubscriptionStatus
                    ? 'Subscribed'
                    : 'Not Subscribed'}
                </span>
              </Typography>
              {member.premiumSubscriptionStatus ? (
                <Fragment>
                  <Grid container direction="row">
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        <b>Plan:</b> {member.premiumSubscriptionPlan}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>
                        <b>Expires:</b>{' '}
                        {member.premiumSubscriptionExpire.toLocaleString()}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>
                        <b>Balance:</b> {member.premiumSubscriptionBalance}{' '}
                        visits
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>
                        <b>Auto Renewal:</b>{' '}
                        {member.premiumSubscriptionRenews ? 'On' : 'Off'}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container direction="row" spacing={1}>
                    {member.premiumSubscriptionRenews ? (
                      <Grid item>
                        <Button
                          variant="contained"
                          size="small"
                          className={classes.cancelButton}
                          onClick={this.props.openCancelDialog}
                        >
                          Cancel Auto-Renewal
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          className={classes.resumeButton}
                          onClick={this.props.openRenewDialog}
                        >
                          Resume Auto-Renewal
                        </Button>
                      </Grid>
                    )}

                    {member.premiumSubscriptionStatus &&
                      this.state.accountType === 'family' &&
                      member.premiumSubscriptionPlan ===
                        'Yearly Premium Subscription' && (
                        <Grid item>
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            className={classes.resumeButton}
                            onClick={this.props.openUpgradeDialog}
                          >
                            Upgrade to Family Plan
                          </Button>
                        </Grid>
                      )}
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.purchaseButton}
                    onClick={this.props.openPremiumDialog}
                  >
                    Purchase
                  </Button>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

SubscriptionAccordion = withRouter(SubscriptionAccordion);

SubscriptionAccordion = connect(
  mapStateToProps,
  actions
)(SubscriptionAccordion);

export default withStyles(styles)(SubscriptionAccordion);
