import { EventEmitter } from '../classes/event-emitter';
import { getCookie } from '../utils/getCookie';

class AuthApi extends EventEmitter {
  #token = null;
  #authenticated = false;
  #userType = null;

  constructor() {
    super();
  }

  isAuthenticated() {
    return this.#authenticated;
  }

  setAuthenticated(state, type = '') {
    if (typeof state === 'boolean') {
      this.#authenticated = state;
      this.setToken(getCookie('connect.sid'));
      this.setUserType(type);
      if (state) this.emit('authenticated');
      else this.emit('unauthenticated');
    } else {
      console.error(
        `setAuthenticated called with incorrect param type. Expected boolean, got ${typeof state}`
      );
    }
  }

  setUserType(type) {
    this.#userType = type;
  }

  getUserType() {
    return this.#userType;
  }

  setToken(token) {
    this.#token = token;
  }

  getToken() {
    return this.#token;
  }
}

export const authApi = new AuthApi();
