import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { Grid, Typography } from '@material-ui/core';

const styles = {
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
    paddingLeft: '7px',
  },
};

function HistoryCard(props) {
  const { classes } = props;
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={6}>
        <Typography variant="body2" color="primary">
          Current Medication
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.patient.currentMedication.join(', ')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" color="primary">
          Other Medication
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.patient.currentMedicationOtherField ||
              'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" color="primary">
          Medical Procedures
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.patient.medicalProcedures.join(', ')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" color="primary">
          Other Medical Procedures
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.patient.medicalProceduresOtherField ||
              'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" color="primary">
          Current Medical Conditions
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.patient.currentMedicalCondition
              // .map((item) => item.name)
              .join(', ')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" color="primary">
          Other Medical Conditions
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{ overflow: 'hidden' }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.patient
              .currentMedicalConditionOtherField || 'N/A'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(HistoryCard);
