import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import {
  CssBaseline,
  MenuItem,
  Select,
  IconButton,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import PharmacyIcon from '@material-ui/icons/LocalPharmacy';
import Notes from '@material-ui/icons/Description';
import DoctorNotesIcon from '@material-ui/icons/Assignment';
import PdfDialog from './PdfDialog2';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Slide from '@material-ui/core/Slide';
import jsPDF from 'jspdf';
import ReactHtmlParser from 'react-html-parser';
import snapmedLogo from '../../../../SnapMedLogoRetina';
import ApptPdfRendering from '../../../dashboard/doctorPdfRendering/ApptPdfRendering';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  align: {
    marginRight: '37%',
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: (props) => ({
    backgroundColor: props.signed ? 'mediumseagreen' : theme.palette.error[500],
  }),
}))(Badge);

class PatientAppointmentHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      descendingOrder: true,
      openPdf: false,
      pdfData: {},
    };
    this.perPage = 8;
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
  }
  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  nextPage() {
    this.setState({
      page: this.state.page + 1,
    });
  }

  previousPage() {
    this.setState({
      page: this.state.page - 1,
    });
  }
  lastPage() {
    this.setState({
      page: this.numPages,
    });
  }
  firstPage() {
    this.setState({
      page: 0,
    });
  }

  closeDocument() {
    this.setState({ openPdf: false });
  }

  createPdf(patient, doctor, appt, data, type) {
    if (type === 'prescription') {
      const pdf = new jsPDF('p', 'pt', 'a3');
      const center = pdf.internal.pageSize.getWidth() / 2;
      const imgData = snapmedLogo;

      pdf.setTextColor(220);
      pdf.setFontSize(150);
      pdf.text('SnapMed.ca', 80, 300, null, 30);
      pdf.text('SnapMed.ca', 80, 600, null, 30);
      pdf.text('SnapMed.ca', 80, 900, null, 30);
      pdf.text('SnapMed.ca', 80, 1200, null, 30);
      pdf.text('SnapMed.ca', 80, 1500, null, 30);
      pdf.addImage(imgData, 'JPEG', center - 75, 10, 200, 60);
      pdf.setTextColor(10);
      pdf.fromHTML(
        ReactDOMServer.renderToString(
          ReactHtmlParser(data),
          setTimeout(function () {
            pdf.save('prescription.pdf');
          }, 500)
        ),
        40,
        90
      );
    } else if (type === 'generalSickNote') {
      const pdf = new jsPDF('p', 'pt', 'a5');
      const center = pdf.internal.pageSize.getWidth() / 2;
      const imgData = snapmedLogo;

      pdf.setTextColor(220);
      pdf.setFontSize(70);
      pdf.text('SnapMed.ca', 45, 150, null, 30);
      pdf.text('SnapMed.ca', 45, 300, null, 30);
      pdf.text('SnapMed.ca', 45, 450, null, 30);
      pdf.text('SnapMed.ca', 45, 600, null, 30);
      pdf.text('SnapMed.ca', 45, 750, null, 30);
      pdf.addImage(imgData, 'JPEG', center - 75, 10, 155, 45);
      pdf.setTextColor(10);
      pdf.fromHTML(
        ReactDOMServer.renderToString(
          ReactHtmlParser(data),
          setTimeout(function () {
            pdf.save('Sick Note.pdf');
          }, 500)
        ),
        40,
        70
      );
    } else if (type === 'schoolSickNote') {
      const pdf = new jsPDF('p', 'pt', 'a4');
      pdf.fromHTML(
        ReactDOMServer.renderToString(
          ReactHtmlParser(data),
          setTimeout(function () {
            pdf.save('School Sick Note.pdf');
          }, 500)
        ),
        40,
        10
      );
    } else if (type === 'doctorNotes') {
      const pdf = new jsPDF('p', 'pt', 'letter');
      //const pageWidth = pdf.internal.pageSize.getWidth();
      const center = pdf.internal.pageSize.getWidth() / 2;
      const imgData = snapmedLogo;
      const apptDate = new Date(appt.date).toLocaleDateString('en-CA');
      const dateOfBirth = patient.dateOfBirth;
      const timestamp = appt.finalizedNotesTimestamp
        ? new Date(appt.finalizedNotesTimestamp).toLocaleString('en-CA')
        : 'N/A';
      //const noteText = str.replace(/"?<\/?p>"?/gi, "");
      //const splitText = pdf.splitTextToSize(data, pageWidth - 20);
      pdf.addImage(imgData, 'JPEG', center - 75, 10, 150, 45);
      pdf.setFontSize(10);
      pdf.text('202-446 Concession St.', center, 75, 'center');
      pdf.text('Hamilton, ON, L9A 1C2', center, 90, 'center');
      pdf.text('1-833-SNAP-333 (1-833-762-7333)', center, 105, 'center');
      pdf.text(`Dr. ${doctor.name.first} ${doctor.name.last}`, 30, 125);
      pdf.text(`Patient: ${patient.name.first} ${patient.name.last}`, 30, 145);
      pdf.text(patient.address, 70, 160);
      pdf.text(`${patient.city}, ${patient.province}`, 70, 175);
      pdf.text(patient.zipCode, 70, 190);
      pdf.text(`Phone: ${patient.mobilePhone}`, 70, 205);
      pdf.text(`DOB: ${dateOfBirth}`, 70, 220);
      pdf.text(`Appointment Date: ${apptDate}`, 30, 250);
      pdf.text(`Reason for Visit: ${appt.shortReason}`, 30, 270);
      pdf.text(`Symptoms: ${appt.symptoms}`, 30, 290);
      pdf.text(`Final note added on: ${timestamp}`, 30, 320);
      pdf.text('Notes:', 30, 340);
      pdf.setFontSize(14);
      //pdf.text(splitText, 40, 300);
      pdf.fromHTML(
        ReactDOMServer.renderToString(
          ReactHtmlParser(data),
          setTimeout(function () {
            pdf.save('Doctor Notes.pdf');
          }, 500)
        ),
        40,
        350,
        {
          width: 500,
        }
      );
    }
  }

  renderRxButtons = (prescriptions) => {
    return prescriptions.map((rx) => (
      <IconButton
        onClick={() => {
          this.setState({
            openPdf: true,
            pdfData: rx.prescriptionNoteValues,
          });
        }}
      >
        <StyledBadge
          badgeContent=""
          variant="dot"
          invisible={rx.consentForms.length === 0}
          signed={rx.isConsentSigned}
        >
          <PharmacyIcon color="primary" />
        </StyledBadge>
      </IconButton>
    ));
  };

  returnFields = () => {
    const appointmentHistory = this.props.appointmentParams.patientHistory;
    if (!this.props.appointmentParams.patientHistory) {
      return [];
    }
    const { classes } = this.props;
    const { page, descendingOrder } = this.state;
    var history = [];
    var stepper = page * this.perPage;
    var top = stepper + this.perPage;
    var order = descendingOrder
      ? appointmentHistory.sort(function (a, b) {
          var dateA = new Date(a.date);
          var dateB = new Date(b.date);
          return dateB - dateA;
        })
      : appointmentHistory.sort(function (a, b) {
          var dateA = new Date(a.date);
          var dateB = new Date(b.date);
          return dateA - dateB;
        });
    for (var i = stepper; i < top; i++) {
      let appt = appointmentHistory[i];
      if (appt == undefined) {
        break;
      }
      var attachmentExist =
        appt.prescription || appt.schoolSicknote || appt.generalSicknote;
      history.push(
        <Accordion>
          <AccordionSummary
            style={{ backgroundColor: '#EEEDEE' }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Grid container direction="row" spacing={1}>
              <Grid item container xs={12} sm={6} alignItems="center">
                {(appt.patientLeftWaitingRoom || !appt.patientConnected) &&
                this.props.currentAppointment !== appt._id ? (
                  <Typography variant="button" color="error">
                    MISSED CALL - DATE:{' '}
                    {new Date(appt.date).toLocaleDateString('en-CA')}
                  </Typography>
                ) : (
                  <Typography variant="button" color="primary">
                    Appointment Date:{' '}
                    {new Date(appt.date).toLocaleDateString('en-CA')}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={6}
                direction="row"
                spacing={1}
                justify="flex-end"
              >
                <Grid item>
                  {this.props.currentAppointment === appt._id && (
                    <Typography variant="caption" color="error">
                      CURRENT APPOINTMENT
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="button" color="primary">
                    ID: {appt._id.substring(19, 24)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails style={{ backgroundColor: '#D3D3D3' }}>
            <Grid container>
              <Grid item xs={12}>
                {appt.ohipAppointment && (
                  <Typography variant="body1" color="error">
                    OHIP Appointment
                  </Typography>
                )}
                {appt.doctor && (
                  <Typography variant="body2">
                    <strong> Doctor:</strong> Dr. {appt.doctor.name.first}{' '}
                    {appt.doctor.name.last}
                  </Typography>
                )}
                {appt.ohipAppointment && (
                  <Typography variant="body2">
                    <strong>OHIP Number:</strong> {appt.ohipNumber}
                  </Typography>
                )}
                <Typography variant="body2">
                  <strong>Reason for Visit:</strong> {appt.shortReason}
                </Typography>

                <Typography variant="body2">
                  <strong> Symptoms:</strong> {appt.symptoms.join(', ')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  <strong> Dr. Notes:</strong>
                  {appt.doctorNotesFinalized ? (
                    <IconButton>
                      <DoctorNotesIcon
                        color="primary"
                        onClick={() =>
                          this.createPdf(
                            appt.patient,
                            appt.doctor,
                            appt,
                            appt.doctorNotes,
                            'doctorNotes'
                          )
                        }
                      />
                    </IconButton>
                  ) : (
                    'No notes available'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {attachmentExist ? <strong>Attachments:</strong> : ''}
                </Typography>

                {appt.prescriptions &&
                  appt.prescriptions.length > 0 &&
                  this.renderRxButtons(appt.prescriptions)}
                {appt.schoolSicknote != undefined && (
                  // <PdfDialog
                  //   data={appt.schoolSicknote.schoolSickNoteValues}
                  //   type={'Sick Note'}
                  // />
                  <IconButton
                    onClick={() =>
                      this.setState({
                        openPdf: true,
                        pdfData: appt.schoolSicknote.schoolSickNoteValues,
                      })
                    }
                  >
                    <Notes color="primary" />
                  </IconButton>
                )}
                {appt.generalSicknote != undefined && (
                  <IconButton
                    onClick={() =>
                      this.setState({
                        openPdf: true,
                        pdfData: appt.generalSicknote.generalSickNoteValues,
                      })
                    }
                  >
                    <Notes color="primary" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    }
    return history;
  };

  render() {
    const { classes, theme, auth, appointmentParams } = this.props;

    const appointmentHistory = this.props.appointmentParams.patientHistory;

    var numberAppts = appointmentHistory ? appointmentHistory.length : 0;
    //pages start at 0 therefore subtract one
    var pages = numberAppts / this.perPage - 1;
    var quotient = pages - Math.floor(pages);

    this.numPages = Math.floor(numberAppts / this.perPage);
    this.bottomPerPage = this.state.page * this.perPage + 1;
    this.topPerPage = this.state.page * this.perPage + this.perPage;

    return !this.state.openPdf ? (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Slide direction="right" in={true}>
            <Grid
              container
              spacing={1}
              style={{
                margin: 0,
                width: '100%',
              }}
              direction="row"
            >
              <Grid item xs={12} style={{ marginTop: '5%' }}>
                <Typography
                  variant="h5"
                  className={classes.spacing}
                  color="primary"
                >
                  Your Patient's Appointment History
                </Typography>
              </Grid>
              <br />
              <Grid item xs={12}>
                <Typography variant="body1">
                  {' '}
                  You can see all your patient's previous appointments below,
                  just click on any tab to expand it for more information:{' '}
                </Typography>
                <br />
                {/* */}
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={6} sm={4}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel htmlFor={'order'}>Order by</InputLabel>
                      <Select
                        id="order"
                        input={<OutlinedInput label="Order by" />}
                        InputProps={{
                          classes: { input: classes.resize },
                        }}
                        value={
                          this.state.descendingOrder
                            ? 'Descending'
                            : 'Ascending'
                        }
                        onChange={(event) => {
                          event.target.value == 'Ascending'
                            ? this.setState({
                                descendingOrder: false,
                              })
                            : this.setState({
                                descendingOrder: true,
                              });
                        }}
                      >
                        {['Ascending', 'Descending'].map((order) => (
                          <MenuItem value={order}>{order}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="caption">
                      {' '}
                      {this.bottomPerPage}{' '}
                      {this.state.page === this.numPages
                        ? '- ' + numberAppts
                        : '- ' + this.topPerPage}{' '}
                      of {numberAppts}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={1}>
                    <IconButton
                      onClick={this.firstPage}
                      disabled={this.state.page == 0 ? true : false}
                    >
                      <FirstPageIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={3} sm={1}>
                    <IconButton
                      onClick={this.previousPage}
                      disabled={this.state.page == 0 ? true : false}
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                  </Grid>

                  <Grid item xs={3} sm={1}>
                    <IconButton
                      onClick={this.nextPage}
                      disabled={this.state.page == this.numPages ? true : false}
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </Grid>
                  <Grid item xs={3} sm={1}>
                    <IconButton
                      onClick={this.lastPage}
                      disabled={this.state.page == this.numPages ? true : false}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {this.returnFields()}
              </Grid>
            </Grid>
          </Slide>
        </main>
      </Fragment>
    ) : (
      <Slide in={true} direction="right">
        <ApptPdfRendering
          data={this.state.pdfData}
          closeDocument={() => this.closeDocument()}
        />
      </Slide>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentAppointment: state.appointmentParams.appointmentId,
    appointmentParams: state.appointmentParams,
  };
}
PatientAppointmentHistory = connect(mapStateToProps)(PatientAppointmentHistory);

export default withStyles(styles)(PatientAppointmentHistory);
