
import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import * as actions from '../../../actions';
import axios from 'axios';
import {
  Grid,
  Typography,
  Zoom,
  Button,
  Chip,
  TextField,
  FormControl
} from '@material-ui/core';
import {
  Save,
  Cancel,
  Subject
} from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

const styles = theme => ({
  // layout: {
  //   overflow: 'hidden',
  //   marginLeft: theme.spacing.unit,
  //   marginRight: theme.spacing.unit,
  //   [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
  //     marginLeft: theme.spacing.unit * 6,
  //     marginRight: theme.spacing.unit * 6
  //   },
  //   maxWidth: 850
  // },
  infoBlock: {
    marginTop: '10px'
  }
});

const hourField = props => {
  // console.log(input.value)
  // return(
  //   <FormControl 
  //     fullWidth
  //     variant='outlined'
  //   >
  //     <TextField
  //       {...input}
  //       fullWidth
  //       type='time'
  //       variant='outlined'
  //       id={name}
  //     />
  //   </FormControl>
  // )
  const {
    label,
    meta: { submitting, error, touched },
    input: { onBlur, value, ...inputProps },
    ...others
  } = props;

  const onChange = date => {
    Date.parse(date) ? inputProps.onChange(date.toISOString()) : inputProps.onChange(null);
  };
  return (
    <FormControl 
      fullWidth
    >
      <TimePicker
        {...inputProps}
        {...others}
        clearable
        value={value ? new Date(value) : null}
        onBlur={() => onBlur(value ? new Date(value).toISOString() : null)}
        inputVariant='outlined'
        minutesStep={5}
        onChange={onChange}
        emptyLabel='- - : - -'
        label={label}
        size='small'
      />
    </FormControl>
  )
};


class EditProfileHours extends Component {
  constructor(props) {
    super(props);
  }

  async handleHourUpdate(values) {
    const result = await axios.post('/api/edit-pharmacy-hours', { hours: values });
    if (result.data) {
      this.props.fetchUser();
      this.props.closeEdit();
    } else {
      alert('fail')
    }
  }

  buildHours(authHours) {
    const hours = Object.keys(authHours).map(item => (
      <Fragment>
        <Grid item xs={1}>
          <Typography color='primary'>
            {item[0].toUpperCase() + item.substring(1, 3)}.
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Field 
            name={`${item}Open`}
            id={`${item}Open`}
            component={hourField}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography color='primary' align='center'>
            to
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Field 
            name={`${item}Close`}
            id={`${item}Close`}
            component={hourField}
          />
        </Grid>
        <Grid item xs={3}>
          <Field 
            name={`${item}DeliveryCutoff`}
            id={`${item}DeliveryCutoff`}
            component={hourField}
            label='Delivery Cutoff'
          />
        </Grid>
      </Fragment>
    ))
    return hours;
  }

  handleAutoFill(authHours) {
    const hours = authHours;
    const clonedHours = {};
    Object.keys(hours).forEach(item => {
      clonedHours[item] = {
        open: this.props.mondayOpen,
        close: this.props.mondayClose,
        deliveryCutoff: this.props.mondayDeliveryCutoff
      }
    });
    this.props.buildInitialValues(clonedHours);
  }

  render() {
    const { handleSubmit, closeEdit } = this.props;
    const authHours = this.props.auth.hours;
    return (
      <form
        onSubmit={handleSubmit(values => this.handleHourUpdate(values))}
      >
        <Grid container spacing={1}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={10} container spacing={2} alignItems='center'>
              {this.buildHours(authHours)}
            </Grid>
          </MuiPickersUtilsProvider>
          <Grid item xs={2}>
            <Button
              startIcon={<Subject />}
              variant='outlined'
              color='primary'
              onClick={() => this.handleAutoFill(authHours)}
            >
              Autofill
            </Button>
          </Grid>
          <Grid container justify='flex-end' spacing={1}>
            <Grid item>
              <Button
                variant='outlined'
                size='small'
                color='primary'
                style={{
                  color: 'crimson'
                }}
                startIcon={<Cancel />}
                onClick={closeEdit}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='outlined'
                size='small'
                color='primary'
                startIcon={<Save />}
                type='submit'
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }
}

const selector = formValueSelector('editHoursForm')

function mapStateToProps(state) {
  return {
    auth: state.auth,
    mondayOpen: selector(state, 'mondayOpen'),
    mondayClose: selector(state, 'mondayClose'),
    mondayDeliveryCutoff: selector(state, 'mondayDeliveryCutoff')
  };
}

EditProfileHours = connect(mapStateToProps, actions)(withRouter(EditProfileHours));

EditProfileHours = reduxForm({
  // validate,
  form: 'editHoursForm',
  enableReinitialize: true,
})(EditProfileHours);

export default withStyles(styles)(EditProfileHours);
