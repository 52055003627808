import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  Typography,
  Grid,
  Tabs,
  Tab,
  Fade,
  Paper,
  Button,
} from '@material-ui/core';

import PatientCard from './editNoteInfoCards/PatientCard';
import SymptomsCard from './editNoteInfoCards/SymptomsCard';
import HistoryCard from './editNoteInfoCards/HistoryCard';
import AllergiesCard from './editNoteInfoCards/AllergiesCard';
import PrescriptionsCard from './editNoteInfoCards/PrescriptionsCard';
import PhotosCard from './editNoteInfoCards/PhotosCard';

const styles = (theme) => ({
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
    paddingLeft: '7px',
  },
  infoTabs: {
    flexGrow: 1,
  },
  infoCard: {
    width: '100%',
  },
  cardPaper: {
    height: 200,
    width: '100%',
    overflow: 'auto',
    padding: '5px',
  },
});

const CustomTab = withStyles((theme) => ({
  root: {
    minWidth: 80,
    minHeight: 20,
    textTransform: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    selected: {},
  },
  selected: {
    backgroundColor: '#f2f4f5',
  },
}))((props) => <Tab {...props} />);

class EditNoteInfoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
  }

  handleTabChange(event, newValue) {
    this.setState({ selectedTab: newValue });
  }

  handleClose() {
    this.setState({ openDialog: false });
  }

  renderCard() {
    const { selectedTab } = this.state;

    switch (selectedTab) {
      case 1:
        return <SymptomsCard editNoteParams={this.props.editNoteParams} />;
      case 2:
        return <HistoryCard editNoteParams={this.props.editNoteParams} />;
      case 3:
        return <AllergiesCard editNoteParams={this.props.editNoteParams} />;
      case 4:
        return <PrescriptionsCard editNoteParams={this.props.editNoteParams} />;
      case 5:
        return <PhotosCard editNoteParams={this.props.editNoteParams} />;
      default:
        return <PatientCard editNoteParams={this.props.editNoteParams} />;
    }
  }

  render() {
    const { classes } = this.props;
    const { selectedTab } = this.state;

    const labels = [
      'Patient',
      'Symptoms',
      'History',
      'Allergies',
      'Prescriptions',
      'Photos',
    ];

    return (
      <Fragment>
        <Grid item container xs={12}>
          <Paper square className={classes.infoTabs}>
            <Tabs
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              style={{
                minHeight: '20px',
              }}
              value={selectedTab}
              onChange={this.handleTabChange.bind(this)}
            >
              {labels.map((label) => (
                <CustomTab label={label} />
              ))}
            </Tabs>
          </Paper>
        </Grid>
        <Grid container item xs={12} className={classes.infoCard}>
          <Paper className={classes.cardPaper}>{this.renderCard()}</Paper>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(EditNoteInfoCard);
