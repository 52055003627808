import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import RegistrationForm from './RegistrationForm';
import { Link, withRouter } from 'react-router-dom';
import * as actions from '../../actions';
import { connect } from 'react-redux';

class Registration extends Component {
  constructor(props) {
    super(props);
    var loggedOutFirst = false;
  }

  registrationFormCreator(newTitle) {
    return (
      <div className="flex-container">
        <div className="container pageContent">
          <div className="row separator">
            <RegistrationForm
              //title={newTitle}
              errorOne={this.props.registrationError}
              onSubmit={values => {
                this.props.submitRegistration(values, this.props.history);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
  renderContent() {
    //the following flow is complicated because of the way App.js fetches the user

    switch (this.props.auth) {
      case false:
        if (!this.loggedOutFirst) {
          return this.registrationFormCreator('Registration');
        } else {
          return this.registrationFormCreator('You have been logged out');
        }
      default:
        switch (this.props.auth._id) {
          case undefined:
            return this.registrationFormCreator('');
          default:
            this.props.logout();
            this.loggedOutFirst = true;

            return this.registrationFormCreator('You have been logged out');
        }
    }
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}
function mapStateToProps(state) {
  return {
    auth: state.auth,
    registrationError: state.registrationError
  };
}
export default connect(
  mapStateToProps,
  actions
)(withRouter(Registration));
