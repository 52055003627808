import React, { Component, Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import ReactDOMServer from "react-dom/server";


class DoctorPdfRendering extends Component {
  constructor(props) {
    super(props);
  }
  print(div) {
    const win = window.open('', 'printwindow');
    win.document.write(div);
    win.print();
    // html2canvas(div, {
    //   scale: 2.5,
    //   height: 2000
    // })
    //   .then((canvas) => {
    //     const pdf = new jsPDF("p", "pt", "a2");
    //     const imgData = canvas.toDataURL('image/png');
    //     pdf.addImage(imgData, 'PNG', 20, -1000);
    //     pdf.save('document.pdf');
    //   });
  }
  render() {
    return (
      <Fragment>
        <Grid container id='printArea' justify='center' style={{ overflowY: 'auto', height: '35rem' }}>
          {ReactHtmlParser(this.props.data)}
        </Grid>
          <Grid container justify='flex-end' style={{ margin: '10px 0'}}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                this.props.closeDocument();
                this.props.disableToolbar(false);
              }}

            >
              Back
            </Button>
          </Grid>
      </Fragment >
    )
  }
}
export default (DoctorPdfRendering);