import React, { createContext, useContext, useState, useEffect } from 'react';
import { doctorSocket } from '../api/doctor-ws';
import toast from 'react-hot-toast';

export const QueueContext = createContext();

export function QueueProvider({ children }) {
  const [queue, setQueue] = useState(0);

  function updateQueue(args) {
    const numPatients = Number(args);
    if (!isNaN(numPatients) && numPatients >= 0) {
      if (queue === 0 && numPatients === 1) toast('A patient has just arrived');
      setQueue(numPatients);
    }
  }

  useEffect(() => {
    doctorSocket.on('numPatients', updateQueue);
    doctorSocket.on('initial-connection', updateQueue);

    return () => {
      doctorSocket.off('numPatients', updateQueue);
      doctorSocket.off('initial-connection', updateQueue);
    };
  }, []);

  return (
    <QueueContext.Provider value={{ queue }}>{children}</QueueContext.Provider>
  );
}

export const useQueue = () => useContext(QueueContext);
