import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import {
  Grid,
  Typography,
  Zoom,
  Button,
  Chip,
} from '@material-ui/core';
import {
  ArrowDropUp,
  ArrowDropDown
} from '@material-ui/icons';

const styles = theme => ({
  container: {
    marginTop: '3%'
  },
  tableHeader: {
    marginTop: '10px',
    padding: '5px',
    borderBottom: 'solid 1px lightgray'
  },
  deliveryList: {
    height: '40vh',
    overflow: 'auto'
  }
});

class PendingDeliveriesCard extends Component {
  constructor(props) {
    super(props);
  }

  renderStatusChip = (status) => {
    return (
      <Chip
        label={status.name}
        style={{
          color: 'white',
          backgroundColor: status.color
        }}
        size='small'
      />
    )
  }

  renderDeliveries = (deliveries) => {
    const { classes } = this.props;
    const deliveriesArray = [];
    deliveries.forEach(delivery => {
      const date = new Date(delivery.date).toLocaleDateString('en-CA');
      const dueDate = new Date(delivery.dueDate).toLocaleDateString('en-CA');
      if (delivery.status && delivery.status.status === 6) {
        deliveriesArray.push(
          <Grid container
            style={{
              padding: '5px 0px'
            }}
          >
            <Grid item xs={2}>
              <Typography align='center'>
                {delivery.number}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>
                {delivery.customer.name.first} {delivery.customer.name.last}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                {date}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                {dueDate}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='subtitle2'>
                {this.renderStatusChip(delivery.status)}
              </Typography>
            </Grid>
          </Grid>
        )}
      }
    );
    if (deliveriesArray.length > 0) {
      return deliveriesArray;
    } else {
      return (
        <Grid
          container
          style={{ height: '100%' }}
          alignItems='center'
          justify='center'
        >
          <Typography variant='h6' align='center' style={{ color: 'lightgray' }}>
            No pending deliveries.
          </Typography>
        </Grid>
      )
    }
  }

  render() {
    const { classes, deliveries } = this.props;

    return (
      <Grid item xs={12}>
        <Grid container className={classes.tableHeader}>
          <Grid item xs={2}>
            <Typography variant='subtitle2' align='center'>
              #
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='subtitle2'>
              Customer
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='subtitle2'>
              Date
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='subtitle2'>
              Due
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='subtitle2'>
              Status
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.deliveryList}>
            {deliveries ? this.renderDeliveries(deliveries) : null}
        </Grid>
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    deliveries: state.deliveriesList,
  };
}

PendingDeliveriesCard = connect(mapStateToProps, actions)(withRouter(PendingDeliveriesCard));

export default withStyles(styles)(PendingDeliveriesCard)