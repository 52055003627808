import React, { Component, Fragment } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { withRouter, Link } from 'react-router-dom';

import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  CardElement,
} from 'react-stripe-elements';
import {
  Button,
  CssBaseline,
  Paper,
  TextField,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';

import 'typeface-roboto';

const styles = (theme) => ({
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  input: {
    width: '100%',
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  link: {
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
    width: '100px',
  },
  cardElementWrapper: {
    padding: '10px',
    borderRadius: '5px',
    boxShadow: '0px 0px 3px 3px #cecece',
  },
  cardElement: {
    width: '100%',
  },
});

class AddCreditCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      promoCode: '',
      errorMessage: '',
      promoCodeApplied: false,
      paymentIntent: '',
      disableButton: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    this.setState({
      errorMessage: '',
      disableButton: true,
    });
    const card = await this.props.elements.getElement('card');
    const paymentMethod = await this.props.stripe.createPaymentMethod({
      type: 'card',
      card: card,
      billing_details: {
        email: this.props.auth.email,
      },
    });
    if (paymentMethod.error) {
      this.setState({
        errorMessage: paymentMethod.error.message,
        disableButton: false,
      });
    } else {
      try {
        const newPaymentMethodResponse = await axios.post(
          '/api/billing/add_credit_card',
          paymentMethod
        );
        if (newPaymentMethodResponse.data.error) {
          this.setState({
            errorMessage: 'The card was declined. Please try again.',
            disableButton: false,
          });
        } else {
          this.props.openModal(false);
          this.props.getCardList();
        }
      } catch (err) {
        this.setState({
          errorMessage: 'Something went wrong! Please try again',
          disableButton: false,
        });
      }
    }
  }
  render() {
    const { classes } = this.props;

    return (
      <Grid container xs={12}>
        <form onSubmit={this.handleSubmit} style={{ width: '100%' }}>
          <Grid item xs={12} className={classes.cardElementWrapper}>
            <CardElement
              className={classes.cardElement}
              hidePostalCode={true}
              style={{
                base: {
                  fontSize: '15px',
                  color: '#424770',
                  letterSpacing: '0.025em',
                  fontFamily: 'Source Code Pro, monospace',
                  '::placeholder': {
                    color: '#95a1ad',
                  },
                  padding: '10px',
                },
                invalid: {
                  color: '#9e2146',
                },
              }}
            />
          </Grid>
          <Grid
            item
            container
            spacing={1}
            justify="flex-end"
            style={{ marginTop: '15px' }}
          >
            <Grid item>
              <Button>dismiss</Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                textPrimary="hi"
                color="primary"
                disabled={this.state.disableButton}
              >
                Save Card
                {this.state.disableButton && (
                  <CircularProgress
                    size={15}
                    color="white"
                    style={{
                      marginLeft: 5,
                    }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid container justify="flex-end" xs={12}>
          <Typography color="error">{this.state.errorMessage}</Typography>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

const StyledAddCreditCardForm = withStyles(styles)(AddCreditCardForm);

export default injectStripe(
  connect(mapStateToProps, actions)(withRouter(StyledAddCreditCardForm))
);
