import axios from 'axios';
class LoginService {
  forgotPassword(email) {
    return axios
      .post('/api/auth/forgot-password', { email })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error.response.data;
      });
  }
  check() {}

  forgotPharmacyPassword(email) {
    return axios
      .post('/api/auth/forgot-pharmacy-password', { email })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error.response.data;
      });
  }
}

export default LoginService;
