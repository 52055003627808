import React, { Component, Fragment } from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../actions';
import _ from 'lodash';
import PropTypes from 'prop-types';
import logoMain from '../../SnapMedLogoMain.png';
import logoMainRetina from '../../SnapMedLogoMainRetina.png';
import {
  Button,
  CssBaseline,
  Paper,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  FormHelperText,
  Snackbar,
  Grid,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import DoctorLoginFormFields from './DoctorLoginFormFields';
import PasswordField from 'material-ui-password-field';
import InputMask from 'react-input-mask';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import toast from 'react-hot-toast';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
  layout: {
    display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      maxWidth: 450,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
});

const usernameField = ({
  input,
  label,
  name,
  type,
  meta: { touched, error },
}) => (
  <TextField
    {...input}
    fullWidth
    label={label}
    type={type}
    id={name}
    error={touched && error}
    helperText={touched && error}
    margin="normal"
    inputProps={{
      autoCapitalize: 'none',
    }}
    onChange={(event) => input.onChange(event.target.value.toLowerCase())}
  />
);

const passwordField = ({ input, label, name, meta: { touched, error } }) => (
  <FormControl
    {...input}
    fullWidth
    label={label}
    id={name}
    error={touched && error}
    margin="normal"
  >
    <InputLabel>Password</InputLabel>
    <PasswordField
      inputProps={{
        autoCapitalize: 'none',
      }}
    />
    {touched && error && <FormHelperText>field required</FormHelperText>}
  </FormControl>
);

const authPinField = ({ input, id, label, meta: { touched, error } }) => (
  <FormControl fullWidth error={touched && error}>
    <InputMask mask="999999" {...input} onChange={input.onChange} maskChar="">
      {(inputProps) => (
        <TextField
          {...inputProps}
          autoFocus
          autoComplete="off"
          disableUnderline
          type="text"
          label={label}
          id={id}
          variant="outlined"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    </InputMask>
  </FormControl>
);

class DoctorLoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordIsMasked: true,
      openSnackbar: false,
      verified: false,
      message: false,
      recipient: '',
    };
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.idleLogout === true) {
        this.setState({ openSnackbar: true });
      }
    }, 500);
  }

  handleClose = () => {
    // clear all snackbar open states
    this.setState({
      openSnackbar: false,
      success: false,
      error: false,
    });

    // delay clearing message for smoother animation
    setTimeout(() => {
      this.setState({
        message: false,
      });
    }, 1000);
  };

  async handleVerification(values) {
    const res = await axios.post('/api/auth/doctor/two-factor-auth', values);

    if (res.data) {
      const method = res.data.method === 'sms' ? 'SMS' : 'Email';

      toast.success(`${method} with PIN was sent.`, {
        position: 'bottom-center',
        duration: 4000,
      });
      this.setState({
        verified: true,
        recipient: res.data.recipient,
      });
    }
  }

  async handleResendPin(email) {
    const res = await axios.post('/api/auth/doctor/resend-pin', {
      email: email,
    });

    if (res.data) {
      const method = res.data === 'sms' ? 'SMS' : 'Email';

      toast.success(`${method} with PIN was resent.`, {
        position: 'bottom-center',
        duration: 4000,
      });
    }
  }

  handleLogin = (values) => {
    this.props.doctorLogin(values, this.props.history);
  };

  renderFields() {
    return _.map(DoctorLoginFormFields, ({ label, name, type }) => {
      if (type === 'email') {
        return (
          <Field
            name={name}
            label={label}
            type={type}
            component={usernameField}
          />
        );
      } else if (type === 'password') {
        return (
          <Field
            name={name}
            label={label}
            type={type}
            classes={this.props.classes}
            component={passwordField}
            passwordIsMasked={this.state.passwordIsMasked}
          />
        );
      }
    });
  }

  renderError() {
    return <div>{this.props.auth}</div>;
  }

  render() {
    const { handleSubmit, classes, loginFailure, authPin } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <img
              alt="SnapMed Logo"
              src={logoMain}
              srcSet={`${logoMainRetina} 2x,`}
            />
            {!this.state.verified ? (
              <form
                className={classes.form}
                onSubmit={handleSubmit((values) =>
                  this.handleVerification(values)
                )}
              >
                <Fragment>
                  {this.renderFields()}
                  {/* {loginFailure === true ? (
                    <Typography color="error" variant="body1">
                      Incorrect email or password
                    </Typography>
                  ) : null} */}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Sign in
                  </Button>
                </Fragment>
              </form>
            ) : (
              <form
                className={classes.form}
                onSubmit={handleSubmit(this.handleLogin)}
              >
                <Fragment>
                  <Grid item xs={12} style={{ margin: '15px 0px' }}>
                    <Typography color="primary">
                      We've sent a 6 digit PIN to <b>{this.state.recipient}</b>.
                    </Typography>
                  </Grid>
                  <Field
                    name="authPin"
                    label="Enter PIN"
                    type="text"
                    component={authPinField}
                  />
                  {/* {loginFailure === true ? (
                    <Typography color="error" variant="body1">
                      Incorrect PIN
                    </Typography>
                  ) : null} */}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={!authPin || (authPin && authPin.length < 6)}
                  >
                    Submit
                  </Button>
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: '10px' }}
                    container
                    justify="center"
                    spacing={1}
                  >
                    <Grid item xs={12} container justify="center">
                      <Typography color="primary">
                        Didn't get the PIN?
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container justify="center">
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={() => this.handleResendPin(this.props.email)}
                      >
                        Resend PIN
                      </Button>
                    </Grid>
                  </Grid>
                </Fragment>
              </form>
            )}
            {!this.props.auth ? this.renderError() : <div />}
          </Paper>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.openSnackbar}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={'You have been logged out.'}
          />
        </main>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.username) {
    errors.username = 'Field required';
  }
  if (!values.password) {
    errors.password = 'Field required';
  }

  return errors;
}

DoctorLoginForm = reduxForm({
  validate,
  form: 'doctorloginForm',
  destroyOnUnmount: true,
})(DoctorLoginForm);

DoctorLoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const selector = formValueSelector('doctorloginForm');

function mapStateToProps(state) {
  return {
    auth: state.auth,
    loginFailure: state.loginFailure,
    idleLogout: state.idleLogout,
    email: selector(state, 'username'),
    authPin: selector(state, 'authPin'),
  };
}
DoctorLoginForm = connect(
  mapStateToProps,
  actions
)(withRouter(DoctorLoginForm));

export default withStyles(styles)(DoctorLoginForm);
