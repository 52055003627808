class PathBuilder {
  constructor() {}

  appointmentFilePath(appointmentId, fileName) {
    return `/media/appointments/${appointmentId}/${fileName}`;
  }

  appointmentDirPath(appointmentId) {
    return `/media/appointments/${appointmentId}`;
  }

  patientHealthCardFilePath(patientId, fileName) {
    return `/media/patients/${patientId}/health-card/${fileName}`;
  }

  patientFilePath(patientId, fileName) {
    return `/media/patients/${patientId}/${fileName}`;
  }

  doctorFilePath(doctorId, fileName) {
    return `/media/doctors/${doctorId}/${fileName}`;
  }

  doctorSignatureFilePath(doctorId, fileName) {
    return `/media/doctors/${doctorId}/signature/${fileName}`;
  }
}

export default new PathBuilder();
