import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import { Grid, Typography } from '@material-ui/core';

const styles = {
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
    paddingLeft: '7px',
  },
};

function AllergiesCard(props) {
  const { classes } = props;

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={6}>
        <Typography variant="body2" color="primary">
          Medical Allergies
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.patient.medicalAllergies.join(', ')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" color="primary">
          Other Medical Allergies
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.patient.medicalAllergiesOtherField ||
              'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" color="primary">
          Other Allergies
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.patient.otherAllergies.join(', ')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" color="primary">
          More Allergies
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.patient.otherAllergiesOtherField ||
              'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" color="primary">
          Tobacco Smoker?
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.patient.isTobaccoSmoker
              ? 'Yes'
              : 'No'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" color="primary">
          Alcohol Consumer?
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{ overflow: 'hidden' }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.patient.isAlcoholConsumer
              ? 'Yes'
              : 'No'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" color="primary">
          Substance User?
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{ overflow: 'hidden' }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.patient.isSubstanceUser
              ? 'Yes'
              : 'No'}
          </Typography>
        </Grid>
      </Grid>
      {props.editNoteParams.patient.patient.isSubstanceUser && (
        <Grid item xs={12}>
          <Typography variant="body2" color="primary">
            Substance
          </Typography>
          <Grid
            item
            xs={12}
            className={classes.textField}
            style={{ overflow: 'hidden' }}
          >
            <Typography color="primary">
              {props.editNoteParams.patient.patient.substance}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default withStyles(styles)(AllergiesCard);
