import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutFormYearlyFamilyPlan from './CheckoutFormYearlyFamilyPlan';
import CheckoutFormMonthlyFamilyPlan from './CheckoutFormMonthlyFamilyPlan';
import CheckoutFormPayAsYouGo from './CheckoutFormPayAsYouGo';
import CheckoutFormYearlyPlan from './CheckoutFormYearlyPlan';
import CheckoutFormMonthlyPlan from './CheckoutFormMonthlyPlan';
import CheckoutFormFiveCreditPackage from './CheckoutFormFiveCreditPackage';

class CheckoutForm extends Component {
  render() {
    const { selectedType, origin } = this.props.match.params;

    if (selectedType === 'YearlyFamilyPlan') {
      return (
        <Elements>
          <CheckoutFormYearlyFamilyPlan origin={origin}/>
        </Elements>
      );
    } else if (selectedType === 'MonthlyFamilyPlan') {
      return (
        <Elements>
          <CheckoutFormMonthlyFamilyPlan origin={origin}/>
        </Elements>
      );
    } else if (selectedType === 'YearlyFamilyPlanExisting') {
      return (
        <Elements>
          <CheckoutFormYearlyFamilyPlan existing="test" origin={origin}/>
        </Elements>
      );
    } else if (selectedType === 'MonthlyFamilyPlanExisting') {
      return (
        <Elements>
          <CheckoutFormMonthlyFamilyPlan existing="test" origin={origin}/>
        </Elements>
      );
    } else if (selectedType === 'PayAsYouGo') {
      return (
        <Elements>
          <CheckoutFormPayAsYouGo origin={origin}/>
        </Elements>
      );
    } else if (selectedType === 'FiveCreditPackage') {
      return (
        <Elements>
          <CheckoutFormFiveCreditPackage origin={origin}/>
        </Elements>
      );
    } else if (selectedType === 'MonthlyPlan') {
      return (
        <Elements>
          <CheckoutFormMonthlyPlan origin={origin}/>
        </Elements>
      );
    } else if (selectedType === 'YearlyPlan') {
      return (
        <Elements>
          <CheckoutFormYearlyPlan origin={origin}/>
        </Elements>
      );
    }
  }
}

export default CheckoutForm;
