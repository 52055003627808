import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, clearFields } from 'redux-form';
import LoginForm from './DoctorLoginForm';
import * as actions from '../../../actions';
import { withRouter } from 'react-router-dom';

class DoctorLoginRoot extends Component {
  constructor(props) {
    super(props);
    this.onLoginSubmit = this.onLoginSubmit.bind(this);
  }
  onLoginSubmit(values) {
    this.props.doctorLogin(values, this.props.history);
  }
  render() {
    return (
      <div>
        <LoginForm onSubmit={this.onLoginSubmit} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    form: state.form
  };
}

export default connect(
  mapStateToProps,
  actions
)(withRouter(DoctorLoginRoot));
