import React, { Component, Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Button, Grid } from '@material-ui/core';

class ApptPdfRendering extends Component {
  constructor(props) {
    super(props);
  }
  print(div) {
    const win = window.open('', 'printwindow');
    win.document.write(div);
    win.print();
  }
  render() {
    return (
      <Fragment>
        <Grid
          container
          id="printArea"
          justify="center"
          style={{ overflowY: 'auto' }}
        >
          {ReactHtmlParser(this.props.data)}
        </Grid>
        <Grid container justify="flex-end" style={{ margin: '10px 0' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.props.closeDocument();
            }}
          >
            Back
          </Button>
        </Grid>
      </Fragment>
    );
  }
}
export default ApptPdfRendering;
