import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Grid, Typography } from '@material-ui/core';

const styles = {
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
    paddingLeft: '7px',
  },
};

function PatientCard(props) {
  const { classes } = props;

  let age = new Date() - new Date(props.editNoteParams.patient.dateOfBirth);
  age = Math.floor(age / 3.156e10);

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={6}>
        <Typography variant="body2" color="primary">
          Patient
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.name.first}{' '}
            {props.editNoteParams.patient.name.last}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" color="primary">
          DOB
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.dateOfBirth || 'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" color="primary">
          Age
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">{age || 'N/A'}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" color="primary">
          Gender Identity
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.gender || 'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" color="primary">
          Sex Assigned at Birth
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.sex || 'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" color="primary">
          Location
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.city || 'N/A'},{' '}
            {props.editNoteParams.patient.province || 'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" color="primary">
          Phone Number
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.patient.mobilePhone || 'N/A'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(PatientCard);
