import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

class PaidVisitPurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
    };
  }

  componentDidUpdate = () => {
    if (this.props.open !== this.state.open) {
      this.setState({
        open: this.props.open,
      });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.onClose && typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  handleContinue = () => {
    if (this.props.onContinue && typeof this.props.onContinue === 'function') {
      this.props.onContinue();
    }
  };

  render() {
    let unitAmount;
    const { product } = this.props;
    if (product) {
      unitAmount = this.props.product.prices.data[0].unit_amount;
    }

    return (
      <Dialog open={this.state.open} onClose={this.handleClose}>
        <DialogTitle>
          <Typography variant="h5" color="primary">
            Purchase Five Credits?
          </Typography>
        </DialogTitle>
        <DialogContent>
          {product && (
            <Typography variant="body1">
              Our <b>{product.name}</b> will give you {product.metadata.credits}{' '}
              visits with a Canadian doctor for only{' '}
              <b>${unitAmount / 100} CAD</b>.
            </Typography>
          )}
        </DialogContent>
        <DialogActions
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          <Button variant="contained" onClick={this.handleClose}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleContinue}
          >
            Purchase
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default PaidVisitPurchase;
