import React from 'react';
import { Grid } from '@material-ui/core';

import PrescriptionForm from './prescriptionForm/PrescriptionForm';
import GeneralNoteForm from './GeneralNoteForm';
import SchoolNoteForm from './SchoolNoteForm';
import PatientInfoForm from './PatientInfoForm';
import DoctorAppointmentNotes from './DoctorAppointmentNotes';
import PatientAppointmentHistory from './PatientAppointmentHistory';
import Slide from '@material-ui/core/Slide';

const ToolbarPageRoot = ({ pageSelected, appointmentParams }) => {
  switch (pageSelected) {
    case 'doctor-notes':
      return (
        <Slide direction="right" in={true}>
          <Grid item id="form" style={{ overflowY: 'auto' }}>
            <DoctorAppointmentNotes />
          </Grid>
        </Slide>
      );
    case 'prescription-button':
      return (
        <Slide direction="right" in={true}>
          <Grid item id="form" style={{ overflowY: 'auto' }}>
            <PrescriptionForm />
          </Grid>
        </Slide>
      );
    // case 'general-sicknote':
    //   return (
    //     <Slide direction="right" in={true}>
    //       <Grid item id="form">
    //         <GeneralNoteForm />
    //       </Grid>
    //     </Slide>

    //   );
    // case 'school-sicknote':
    //   return (
    //     <Slide direction="right" in={true}>
    //       <Grid item id="form">
    //         <SchoolNoteForm />
    //       </Grid>
    //     </Slide>
    //   );
    case 'patient-info-button':
      return (
        <Slide direction="right" in={true}>
          <Grid item id="form-scroll" style={{ overflowY: 'auto' }}>
            <PatientInfoForm />
          </Grid>
        </Slide>
      );
    case 'patient-apt-history':
      return (
        <Slide direction="right" in={true}>
          <Grid item id="form-scroll" style={{ overflowY: 'auto' }}>
            <PatientAppointmentHistory appointmentParams={appointmentParams} />
          </Grid>
        </Slide>
      );

    default:
      return null;
  }
};

export default ToolbarPageRoot;
