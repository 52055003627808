import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import {
  CssBaseline,
  Paper,
  Typography,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import * as actions from '../../actions';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DropzoneInput from '../commonform/FileInput';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      maxWidth: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
});

class PaymentAcknowledgement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showBackBtn: false,
    };
  }

  handleSeeDoctor = async () => {};

  render() {
    const { classes, origin, history } = this.props;
    const { existing } = this.props.match.params;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography
              variant="h5"
              color="primary"
              style={{ marginBottom: 20 }}
              align="center"
            >
              {existing === 'PayAsYouGo'
                ? 'We have successfully validated your card. Thank you.'
                : 'Thank you for your payment.'}
            </Typography>
            {!this.props.appointmentParams.appointmentId ? (
              <Grid item container xs={12} justify="center" spacing={1}>
                {this.state.showBackBtn && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={this.state.loading}
                      onClick={() => history.push('/')}
                    >
                      Back
                    </Button>
                  </Grid>
                )}
                {/* if url contains 'dashboard' means user has purchased 5 credits from Settings */}
                {existing && existing === 'dashboard' ? (
                  <Button variant="contained" color="primary">
                    <Link
                      style={{ color: 'white', textDecoration: 'none' }}
                      to="/dashboard"
                    >
                      Continue
                    </Link>
                  </Button>
                ) : (
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.state.loading}
                      onClick={async () => {
                        this.setState({ loading: true });
                        const { existing } = this.props.match.params;
                        this.props.formValues.patientId =
                          this.props.appointmentParams.patientId;
                        if (existing === 'PayAsYouGo') {
                          this.props.formValues.payAsYouGoAppointment = true;
                          this.props.formValues.paymentIntentId =
                            this.props.appointmentParams.paymentIntentId;
                        }
                        const res = await this.props.useCreditForAppointment(
                          this.props.formValues,
                          this.props.history
                        );
                        if (res && res.error)
                          this.setState({ loading: false, showBackBtn: true });
                      }}
                    >
                      see doctor
                      {this.state.loading && (
                        <CircularProgress
                          size={20}
                          style={{ marginLeft: 10, color: 'primary' }}
                        />
                      )}
                    </Button>
                  </Grid>
                )}
              </Grid>
            ) : (
              this.props.appointmentParams.appointmentId && (
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  style={{ marginTop: '20px' }}
                >
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary" align="center">
                      You're now in queue for your appointment.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary" align="center">
                      You may upload files that might be useful for appointment
                      purposes.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <DropzoneInput
                      patientId={this.props.selectedUser._id}
                      appointmentId={this.props.appointmentParams.appointmentId}
                      closeComponent={() =>
                        this.props.history.push('/dashboard')
                      }
                      disableFileFecth={true}
                    />
                  </Grid>
                </Grid>
              )
            )}
          </Paper>
        </main>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    appt: state.appt,
    formValues: state.symptomsData,
    appointmentParams: state.appointmentParams,
    selectedUser: state.selectedUser,
  };
}

PaymentAcknowledgement = connect(
  mapStateToProps,
  actions
)(withRouter(PaymentAcknowledgement));

export default withStyles(styles)(PaymentAcknowledgement);
