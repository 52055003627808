import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Button,
  CssBaseline,
  FormControl,
  MenuItem,
  Select,
  Paper,
  Typography,
  TextField,
  Grid,
  FormControlLabel,
  InputLabel
} from '@material-ui/core';

const styles = theme => ({
  layout: {
    //  width: 'auto',
    //  height: '100%',
    //  display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600
    }
  },

  typography: {
    marginTop: 15
  }
});

class SignUpFailure extends Component {
  render() {
    const { classes } = this.props;
    return (
      <main className={classes.layout}>
        <Typography className={classes.typography} variant="body1">
          Your sign up attempt failed. Please register with your email again to
          make a new account
        </Typography>
      </main>
    );
  }
}

export default withStyles(styles)(SignUpFailure);
