import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { reduxForm, Field, FormSection, formValueSelector } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import * as actions from '../../../../../actions';
import {
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Slide,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import blurb from '../../../NoteConsentParagraph';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'indent',
];

const Quill = ({ input }) => (
  <ReactQuill
    formats={formats}
    modules={modules}
    style={{
      backgroundColor: 'white',
      overflow: 'hidden',
      minHeight: 200,
      height: '425px',
      width: '100%',
      borderBottom: '1px solid #B0B0B0',
    }}
    {...input}
    onBlur={(range, source, quill) => {
      input.onBlur(quill.getHTML());
    }}
  />
);

const NoteLaterCheck = ({
  input,
  name,
  type,
  label,
  meta: { touched, error },
}) => (
  <FormControl>
    <FormControlLabel
      control={
        <Checkbox
          {...input}
          id={name}
          checked={input.value ? true : false}
          value="finish later"
          color="primary"
        />
      }
    />
    {}
  </FormControl>
);

class DoctorAppointmentNotes extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (
      this.props.chatNote === '' ||
      this.props.chatNote === null ||
      this.props.chatNote === undefined
    ) {
      this.props.change('chatNote', blurb);
    }
  }

  renderFields(name, type, label, component) {
    if (component == 'quill') {
      return (
        <Field
          name={name}
          id={name}
          type={type}
          label={label}
          component={Quill}
        />
      );
    }
    if (component == 'later') {
      return (
        <Field
          name={name}
          type={type}
          label={label}
          component={NoteLaterCheck}
        />
      );
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Slide direction="right" in={true}>
        <main className={classes.layout}>
          <Grid
            container
            id="actualNote"
            direction="row"
            justify="center"
            alignItems="center"
            style={{
              padding: '1rem',
            }}
          >
            <Typography variant="h5" color="primary">
              Appointment Notes
            </Typography>
            <Typography
              variant="caption"
              style={{ marginLeft: '5%', marginBottom: '2%' }}
            >
              Please use the space below to complete your notes. If you would
              like to finish them at a more convenient time, please ensure you{' '}
              <strong>DO NOT</strong> check off the checkbox located at the
              bottom
            </Typography>
            <Grid item xs={12} id="doctorNotes">
              {this.renderFields('chatNote', 'text', '', 'quill')}
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={11}>
                <Typography variant="subtitle2" style={{ textAlign: 'right' }}>
                  Finalize Appointment Notes
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  textAlign: 'center',
                }}
              >
                {this.renderFields(
                  'doctor_finalized_notes',
                  'input',
                  'label',
                  'later',
                  this.props.noteState
                )}
              </Grid>
            </Grid>
          </Grid>
        </main>
      </Slide>
    );
  }
}
const selector = formValueSelector('noteForm');

function mapStateToProps(state) {
  return {
    chatNote: selector(state, 'chatNote'),
  };
}

DoctorAppointmentNotes = reduxForm({
  //validate,
  // initialValues: { chatNote: blurb },
  form: 'noteForm',
  destroyOnUnmount: false,
})(DoctorAppointmentNotes);

DoctorAppointmentNotes = connect(mapStateToProps)(DoctorAppointmentNotes);

export default withStyles(styles)(DoctorAppointmentNotes);
