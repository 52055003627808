import React, { Component, Fragment } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
} from '@material-ui/core';
import * as actions from '../../actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class ParallelLoginNotification extends Component {
  constructor(props) {
    super(props);
    this.socket = props.socket;
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    this.socket.on('session-terminate', () => this.setState({ open: true }));
  }

  handleClose = () => {
    this.setState({ open: false });

    switch (this.props.auth.role) {
      case 'patient':
        this.props.history.push('/login');
        break;
      case 'doctor':
        this.props.history.push('/doctors');
        break;
      case 'pharmacy':
        this.props.history.push('/pharmacy');
        break;
    }
    window.location.reload();
  };

  render() {
    return (
      <Fragment>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          disableBackdropClick="true"
          maxWidth="xs"
        >
          <DialogContent>
            <DialogContentText>
              You have logged in from another device and have been logged out of
              this session.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

ParallelLoginNotification = withRouter(ParallelLoginNotification);

ParallelLoginNotification = connect(
  mapStateToProps,
  actions
)(ParallelLoginNotification);

export default ParallelLoginNotification;
