import React, { Component } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import * as actions from '../../actions';
import { connect } from 'react-redux';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class IdleTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timers: [],
      showAlert: false,
      message: '',
      portal: props.portal || 'Patient',
      component: props.component || 'Dashboard',
      role: props.role || 'All',
    };
  }

  // specify events to reset timers on
  events = ['mousemove', 'scroll', 'keydown', 'touchstart', 'mousedown'];
  testFactor = 1; // shortens timer durations, set to 1 before deploying

  async componentDidMount() {
    // start event listeners
    this.events.forEach((event) => {
      // restart timers every time event occures
      window.addEventListener(event, this.resetTimers, false);
    });

    // start timers
    if (this.props.timers.length > 0) {
      this.props.timers.forEach((timer) => {
        if (
          timer.portal === this.state.portal &&
          timer.component === this.state.component &&
          (timer.role === this.state.roll || timer.role === 'All') &&
          (timer.type === 'Warning' || timer.type === 'Logout')
        ) {
          // add new timer obj
          this.state.timers.push({
            name: timer.name,
            duration: timer.durationInMinutes,
            message: timer.message,
            timer: setTimeout(
              this.timerCallback,
              (timer.durationInMinutes * 60 * 1000) / this.testFactor,
              timer
            ),
          });
        }
      });
    }
  }

  // fn to run when timer ends
  timerCallback = async (timer) => {
    // show alert
    await this.setState({
      showAlert: true,
      message: timer.message,
    });

    // Logout if logout timer
    if (timer.type === 'Logout') {
      // cleanup event listeners
      this.events.forEach((event) => {
        window.removeEventListener(event, this.resetTimers, false);
      });

      // cleanup timers
      this.state.timers.forEach((timer) => clearTimeout(timer.timer));

      // logout action
      this.props.logout(
        this.state.portal.toLowerCase(),
        null,
        this.props.history
      );
    }
  };

  resetTimers = async () => {
    // clean up existing timers and state
    this.state.timers.forEach((timer) => clearTimeout(timer.timer));
    await this.setState({ timers: [], showAlert: false });

    // start new timers
    this.props.timers.forEach((timer) => {
      if (
        timer.portal === this.state.portal &&
        timer.component === this.state.component &&
        (timer.role === this.state.roll || timer.role === 'All') &&
        (timer.type === 'Warning' || timer.type === 'Logout')
      ) {
        this.state.timers.push({
          name: timer.name,
          duration: timer.duration,
          message: timer.message,
          timer: setTimeout(
            this.timerCallback,
            (timer.durationInMinutes * 60 * 1000) / this.testFactor,
            timer
          ),
        });
      }
    });
  };

  async componentWillUnmount() {
    // clear all timers and event listeners
    this.state.timers.forEach((timer) => clearTimeout(timer.timer));
    await this.setState({ timers: [], showAlert: false });

    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimers, false);
    });
  }

  // hide alert
  handleClose = () => {
    this.setState({
      showAlert: false,
    });
  };

  render() {
    return (
      <Snackbar
        open={this.state.showAlert}
        onClose={this.handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="warning">{this.state.message}</Alert>
      </Snackbar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timers: state.timerValues,
  };
};

IdleTimer = connect(mapStateToProps, actions)(IdleTimer);

export default IdleTimer;
