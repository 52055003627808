export default function validationHelperFunction(
  values,
  medicalArray,
  stringPlaceholder,
  noneName,
  errors
) {
  var medicalFormNames = medicalArray.map(({ name }) =>
    stringPlaceholder.concat(name)
  );

  var valuesKeys = [];

  for (var key in values) {
    if (values[key]) {
      valuesKeys.push(key);
    }
  }

  if (valuesKeys.length === 0) {
    errors[noneName] = 'Please Select an Option';
  }

  var medicalContainer = [];

  for (var i = 0; i < valuesKeys.length; i++) {
    if (medicalFormNames.includes(valuesKeys[i])) {
      medicalContainer.push(valuesKeys[i]);
    } else if (i < valuesKeys.length - 1) {
      continue;
    } else if (i === valuesKeys.length - 1 && medicalContainer.length === 0) {
      errors[noneName] = 'Please Select an Option';
    } else {
    }
  }

  if (medicalContainer.includes(noneName) && medicalContainer.length > 1) {
    errors[noneName] = 'You cannot select this option with another option';
  }

  medicalContainer = [];

  return errors;
}
