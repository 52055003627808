import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  CssBaseline,
  Paper,
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import { Link } from 'react-router-dom';

import PackagePlan from './PackagePlan';
import PayAsYouGo from './PayAsYouGo';
import FiveCreditPackage from './FiveCreditPackage';
import YearlyFamilyPlan from './YearlyFamilyPlan';
import MonthlyFamilyPlan from './MonthlyFamilyPlan';
import YearlyPlan from './YearlyPlan';
import MonthlyPlan from './MonthlyPlan';
import PromoCode from './PromoCode';
import * as actions from '../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  card: {
    borderRadius: '5px',
    boxShadow: '0px 0px 4px -2px grey',
    marginBottom: '10px',
  },
});

class PaymentPlans extends React.Component {
  async componenDidMount() {
    const bundlePricing = {}; /*await axios.get('/api/fetch_plans_pricing');*/
    const userInfo = await axios.get('/api/auth/current_user');

    if (bundlePricing.data && userInfo.data) {
      const { data } = bundlePricing;
    }
  }

  render() {
    const { classes, origin, promoCode } = this.props;

    const {
      isPromoCodeApplied,
      errorMessage,
      errorType,
      loading,
      newPrice,
      promoCodeAmount,
      promoCodeId,
      promoType,
    } = this.props.promoCode;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography variant="h5" color="primary">
                  Payment Plans
                </Typography>
                <br />
              </Grid>
              {(() => {
                if (origin === 'registration') {
                  return (
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                      <Typography variant="subtitle1" color="primary">
                        Continue to dashboard
                        <Button
                          style={{ marginLeft: '30px' }}
                          href="/dashboard"
                        >
                          Continue
                        </Button>
                      </Typography>
                    </Grid>
                  );
                } else {
                  return (
                    <Fragment>
                      <Grid item xs={12} className={classes.card}>
                        <Typography variant="subtitle1" color="primary">
                          <PayAsYouGo
                            promoCodeId={promoCodeId}
                            onPlanSubmit={this.props.onPlanSubmit}
                            handleStripeToken={this.props.buyTokens}
                            origin={origin}
                          />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.card}>
                        <Typography variant="subtitle1" color="primary">
                          <FiveCreditPackage
                            promoCodeId={promoCodeId}
                            onPlanSubmit={this.props.onPlanSubmit}
                            handleStripeToken={this.props.buyTokens}
                            origin={origin}
                          />
                        </Typography>
                      </Grid>
                    </Fragment>
                  );
                }
              })()}
              <Grid>
                <Button
                  variant="contained"
                  style={{ backgroundColor: '#ff4a4a', color: '#ffffff' }}
                >
                  <Link
                    style={{ color: 'white', textDecoration: 'none' }}
                    to="/dashboard"
                  >
                    Cancel
                  </Link>
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </main>
      </Fragment>
    );
  }
}

function mapStateToProps({ auth, promoCode }) {
  return {
    auth,
    promoCode,
  };
}
PaymentPlans = connect(mapStateToProps, actions)(PaymentPlans);

export default withStyles(styles)(PaymentPlans);
