import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import SymptomsFormOhip from './SymptomsFormOhip';
import PaymentPrompt from './PaymentPrompt';
import IdleTimer from '../commonform/IdleTimer';
// import '../../styles/main-style.css';

class SeeDoctorRootOhip extends Component {
  constructor(props) {
    super(props);
  }

  renderContent() {
    return (
      <SymptomsFormOhip
        patientId={this.props.appointmentParams.patientId}
        initialValues={{
          ohip_card_error: this.props.selectedUserMedical.ohipCardImage
            ? false
            : true,
          ohipNumber: this.props.selectedUserMedical.ohipNumber,
          ohipExpiration: this.props.selectedUserMedical.ohipExpiration,
        }}
      />
    );
  }

  render() {
    return (
      <div>
        <IdleTimer
          portal="Patient"
          component="Symptoms"
          history={this.props.history}
        />
        {this.renderContent()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    appointmentParams: state.appointmentParams,
    selectedUserMedical: state.selectedUserMedical,
    ohipAppointment: state.ohipAppointment,
  };
}

export default connect(mapStateToProps, actions)(SeeDoctorRootOhip);
