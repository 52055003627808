import React, { Component, Fragment } from 'react';
import axios from 'axios';
import {
  CssBaseline,
  Paper,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Button } from '@material-ui/core';

const logo = require('../../images/SnapMed-Logo-smaller.png');

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
});

class EmailConfirmationMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  resendLinkEmail = async () => {
    this.setState({ loading: true });
    const { userId } = this.props.match.params;
    const response = await axios.post('/api/email-link', { id: userId });
    if (response.status === 200) {
      this.props.history.push(`/email-confirmation/resent`);
    } else {
      this.setState({ loading: false });
    }
  };

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    const { status } = this.props.match.params;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <img src={logo} alt="SnapMed Logo" height="60" width="204" />
            {status && status === 'expired' && (
              <>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ marginTop: 20 }}
                  align="center"
                >
                  Your confirmation link has expired.
                </Typography>
                <Typography
                  variant="body1"
                  color="primary"
                  style={{ marginTop: 20, textAlign: 'center' }}
                >
                  Click below to receive another confirmation link.
                </Typography>
                <Button
                  onClick={this.resendLinkEmail}
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  disabled={this.state.loading}
                  style={{
                    margin: '15px',
                  }}
                >
                  {loading ? (
                    <CircularProgress size={20} color="white" />
                  ) : (
                    'Resend Link'
                  )}
                </Button>
              </>
            )}
            {status && status === 'error' && (
              <>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ marginTop: 20 }}
                  align="center"
                >
                  There was an issue confirming your email address.
                </Typography>
                <Typography
                  variant="body1"
                  color="primary"
                  style={{ marginTop: 20, textAlign: 'center' }}
                >
                  Please try registering again.
                </Typography>
                <Button
                  className={classes.button}
                  href="/registration/new"
                  variant="contained"
                  color="primary"
                  style={{
                    margin: '15px',
                  }}
                >
                  Go to Registration
                </Button>
              </>
            )}
            {status && status === 'verified' && (
              <>
                <Typography
                  variant="h5"
                  color="primary"
                  align="center"
                  style={{ marginTop: 20 }}
                >
                  Thank you for verifying your email address!
                </Typography>
                <Typography
                  variant="body1"
                  color="primary"
                  style={{ marginTop: 20, textAlign: 'center' }}
                >
                  You may now login with your email and password.
                </Typography>
                <Button
                  className={classes.button}
                  href="/login"
                  variant="contained"
                  color="primary"
                  style={{
                    margin: '15px',
                  }}
                >
                  Go to Login
                </Button>
              </>
            )}
            {status && status === 'confirmed-verified' && (
              <>
                <Typography
                  variant="h5"
                  color="primary"
                  align="center"
                  style={{ marginTop: 20 }}
                >
                  Your email address has already been confirmed!
                </Typography>
                <Typography
                  variant="body1"
                  color="primary"
                  style={{ marginTop: 20, textAlign: 'center' }}
                >
                  You may now login with your email and password.
                </Typography>
                <Button
                  className={classes.button}
                  href="/login"
                  variant="contained"
                  color="primary"
                  style={{
                    margin: '15px',
                  }}
                >
                  Go to Login
                </Button>
              </>
            )}
            {status && status === 'resent' && (
              <>
                <Typography
                  variant="h5"
                  color="primary"
                  align="center"
                  style={{ marginTop: 20 }}
                >
                  Another confirmation link is on its way!
                </Typography>
                <Button
                  className={classes.button}
                  href="/login"
                  variant="contained"
                  color="primary"
                  style={{
                    margin: '15px',
                  }}
                >
                  Ok
                </Button>
              </>
            )}
          </Paper>
        </main>
      </Fragment>
    );
  }
}

export default withStyles(styles)(EmailConfirmationMessage);
