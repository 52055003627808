import { APPOINTMENTHISTORY } from '../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case APPOINTMENTHISTORY:
      return Object.assign({}, state, {
        appointmentHistory: action.payload
      });

    default:
      return state;
  }
}
