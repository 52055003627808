import React, { Component, Fragment } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { withRouter, Link } from 'react-router-dom';

import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from 'react-stripe-elements';
import {
  Button,
  CssBaseline,
  CircularProgress,
  Grid,
  InputBase,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import {
  Lock,
  HelpOutline,
  Save,
  Cancel
} from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import Typography from '@material-ui/core/Typography';
import trustBadge from '../../../images/Stripe-trust-badge.png';
import cardLogosTrio from '../../../images/credit-card-logos.png';
import cvcCardInfo from '../../../images/cvc-card.png';
import 'typeface-roboto';

const styles = theme => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 500,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  input: {
    width: '100%'
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3
  },
  link: {
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
    width: '100px'
  },
  cardFields: {
    border: 'solid 1px lightgray',
    borderRadius: '5px',
    padding: '10px',
  },
});

class PharmacyChangeCardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: false,
      errorMessage: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({
      errorMessage: '',
      disableButton: true,
    });
    const cardNumber = await this.props.elements.getElement('cardNumber');
    const paymentMethod = await this.props.stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: {
        email: this.props.auth.email
      }
    });
    if (paymentMethod.error) {
      this.setState({
        errorMessage: paymentMethod.error.message,
        disableButton: false,
      });
    } else {
      try {
        const newPaymentMethodResponse = await axios.post(
          '/api/replace-pharmacy-credit-card',
          {
            oldCardId: this.props.oldCardId,
            ...paymentMethod,
          }
        );
        if (newPaymentMethodResponse.data.error) {
          this.setState({
            errorMessage: 'The card was declined. Please try again.',
            disableButton: false,
          });
        } else {
          this.setState({
            disableButton: false,
          });
          this.props.closeForm();
          this.props.getPharmacyCreditCardList({ customerId: this.props.auth.stripeCustomerId });
        }
      } catch (err) {
        console.log(err)
        this.setState({
          errorMessage: 'Something went wrong! Please try again.',
          disableButton: false
        });
      };
    }
  }

  render() {
    const { classes, auth, pristine } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <main>
          <Grid container justify='center' style={{ padding: '30px 0px' }}>
            <img src={cardLogosTrio} alt='Credit card logos' width='200px'/>
          </Grid>
          <form onSubmit={this.handleSubmit} className={classes.form}>
            <Typography variant='body1' color='primary' style={{ marginBottom: '10px', borderBottom: '1px solid lightgray'}}>
              <b>Change Credit Card Details</b>
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={8} >
                <Typography variant='overline' color='primary'>
                  card holder
                </Typography>
                <InputBase
                  className={classes.cardFields}
                  fullWidth
                  placeholder="Name"
                  inputProps={{
                    style: {
                      padding: '0px',
                      fontSize: '0.9rem'
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant='overline' color='primary'>
                  exp. date
                </Typography>
                <CardExpiryElement
                  className={classes.cardFields}
                  style={{
                    base: {
                      textAlign: 'center',
                      '::placeholder': {
                        color: '#a2a4a6',
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <Typography variant='overline' color='primary'>
                  card number
                </Typography>
                <CardNumberElement
                  className={classes.cardFields}
                  // placeholder='Card Number'
                  style={{
                    base: {
                      '::placeholder': {
                        color: '#a2a4a6',
                      },
                    }
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Grid item xs={12}>
                  <Typography variant='overline' color='primary'>
                    CVC/CVV
                  </Typography>
                  <CardCvcElement
                    className={classes.cardFields}
                    placeholder='CVC/CVV'
                    style={{
                      base: {
                        textAlign: 'center',
                        '::placeholder': {
                          color: '#a2a4a6',
                        },
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item container alignItems='flex-end' xs={1}>
                <Tooltip
                  title="This is the 3 or 4 digit number found on the back or front of your card"
                  placement="top"
                >
                  <IconButton
                    style={{
                      padding: '6px 0px'
                      }}
                    >
                      <HelpOutline
                        style={{
                          color: '#9c9ea1',
                          fontSize: '1.5rem',
                        }}
                        onClick={() => this.setState({ openCvcInfo: true })}
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container justify='center' alignItems='center' style={{ marginTop: '15px' }}>
              <Lock color='primary' style={{ fontSize: 20 }}/>
              <Typography color="primary" style={{ margin: '5px'}}>
                Guaranteed <b>safe & secure</b> checkout.
              </Typography>
            </Grid>
            <Grid container justify='center'>
              <img src={trustBadge} alt='Stripe Trust Badge Image' width='150px'/>
            </Grid>
            <Grid container justify='center' spacing={2} style={{ padding: '30px 0px' }}>
              <Grid item>
                <Button
                  variant='outlined'
                  size='small'
                  color='primary'
                  style={{
                    color: 'crimson'
                  }}
                  startIcon={<Cancel />}
                  onClick={() => this.props.closeForm()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  startIcon={<Save />}
                  variant="outlined"
                  color="primary"
                  size='small'
                  disabled={this.state.disableButton}
                >
                  Update
                  {this.state.disableButton && (
                    <CircularProgress
                      size={15}
                      color="white"
                      style={{
                        marginLeft: 5
                      }}
                    />
                  )}
                </Button>
              </Grid>
              <Grid xs={12}>
                <Typography align='center' color='error'>{this.state.errorMessage}</Typography>
              </Grid>
            </Grid>
          </form>
          <Typography color='error' style={{marginBottom: '10px'}}>{this.state.promoCodeMessage}</Typography>
          <Dialog
            open={this.state.openCvcInfo}
            onClose={() => {
              this.setState(() => ({ openCvcInfo: false }));
            }}
            fullWidth
          >
            <DialogTitle>What is the CVC?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                The Card Verification Code is the 3 or 4 digit number found on the back or front of your card.
              </DialogContentText>
                <Grid container justify='center'>
                  <img src={cvcCardInfo} alt='Card CVC info' width='200px' />
                </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  this.setState(() => ({ openCvcInfo: false }));
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </main>
      </Fragment>
    )
  }
};

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
};

const StyledPharmacyChangeCardForm = withStyles(styles)(
  PharmacyChangeCardForm
);

export default injectStripe(
  connect(
    mapStateToProps,
    actions
  )(withRouter(StyledPharmacyChangeCardForm))
);