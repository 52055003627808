import React, { Component, Fragment } from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import axios from 'axios';
import { reduxForm, Field, FormSection } from 'redux-form';
import {
  Button,
  CssBaseline,
  FormControl,
  MenuItem,
  Select,
  Paper,
  Typography,
  TextField,
  Grid,
  InputLabel,
  FormControlLabel,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Slide,
  Badge,
  Grow,
  OutlinedInput,
  Tooltip,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PharmacyIcon from '@material-ui/icons/LocalPharmacy';
import Notes from '@material-ui/icons/Description';
import DoctorNotesIcon from '@material-ui/icons/Assignment';
import Chat from '@material-ui/icons/Chat';
import { ArrowBackIos, InsertDriveFile } from '@material-ui/icons';
import ReactHtmlParser from 'react-html-parser';
import PdfDialog from './PdfDialog';
import Popup from 'reactjs-popup';
import jsPDF from 'jspdf';
import snapmedLogo from '../../SnapMedLogoRetina';
import PdfRendering from '../pdfRendering/PdfRendering';
import ConsentFormDialog from '../../dialogs/ConsentFormDialog';
import AppointmentFiles from '../../dialogs/AppointmentFiles';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  layoutPdf: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 700,
    },
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
    float: 'right',
  },
  spacing: {
    marginBottom: 15,
  },
  center: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
  },
  resize: {
    fontSize: 50,
  },
  textBottomSpacing: {
    marginBottom: 20,
  },
});

class AppointmentHistory extends Component {
  constructor(props) {
    super(props);

    this.perPage = 8;

    this.state = {
      page: 0,
      descendingOrder: true,
      openPdf: false,
      pdfData: {},
      rxData: {},
      expandedPanel: false,
      openConsentDialog: false,
      openFilesDialog: false,
      selectedFiles: [],
      rxInfo: '',
    };

    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
    this.createPdf = this.createPdf.bind(this);
  }

  async componentDidMount() {
    if (this.props.selectedUser && this.props.selectedUser._id) {
      this.props.getAppointmentHistory(this.props.selectedUser._id);
    }
  }
  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedUser !== this.props.selectedUser) {
      this.props.getAppointmentHistory(this.props.selectedUser._id);
    }
  }

  nextPage() {
    this.setState({
      page: this.state.page + 1,
    });
  }

  previousPage() {
    this.setState({
      page: this.state.page - 1,
    });
  }
  lastPage() {
    this.setState({
      page: this.numPages,
    });
  }
  firstPage() {
    this.setState({
      page: 0,
    });
  }

  async createPdf(patient, doctor, appt, data, type) {
    if (type === 'prescription') {
      const pdf = new jsPDF('p', 'pt', 'a3');
      const center = pdf.internal.pageSize.getWidth() / 2;
      const imgData = snapmedLogo;

      pdf.setTextColor(220);
      pdf.setFontSize(150);
      pdf.text('SnapMed.ca', 80, 300, null, 30);
      pdf.text('SnapMed.ca', 80, 600, null, 30);
      pdf.text('SnapMed.ca', 80, 900, null, 30);
      pdf.text('SnapMed.ca', 80, 1200, null, 30);
      pdf.text('SnapMed.ca', 80, 1500, null, 30);
      pdf.addImage(imgData, 'JPEG', center - 75, 10, 200, 60);
      pdf.setTextColor(10);
      pdf.fromHTML(
        ReactDOMServer.renderToString(
          ReactHtmlParser(data),
          setTimeout(function () {
            pdf.save('prescription.pdf');
          }, 500)
        ),
        40,
        90
      );
    } else if (type === 'generalSickNote') {
      const pdf = new jsPDF('p', 'pt', 'a5');
      const center = pdf.internal.pageSize.getWidth() / 2;
      const imgData = snapmedLogo;

      pdf.setTextColor(220);
      pdf.setFontSize(70);
      pdf.text('SnapMed.ca', 45, 150, null, 30);
      pdf.text('SnapMed.ca', 45, 300, null, 30);
      pdf.text('SnapMed.ca', 45, 450, null, 30);
      pdf.text('SnapMed.ca', 45, 600, null, 30);
      pdf.text('SnapMed.ca', 45, 750, null, 30);
      pdf.addImage(imgData, 'JPEG', center - 75, 10, 155, 45);
      pdf.setTextColor(10);
      pdf.fromHTML(
        ReactDOMServer.renderToString(
          ReactHtmlParser(data),
          setTimeout(function () {
            pdf.save('Sick Note.pdf');
          }, 500)
        ),
        40,
        70
      );
    } else if (type === 'schoolSickNote') {
      const pdf = new jsPDF('p', 'pt', 'a4');
      pdf.fromHTML(
        ReactDOMServer.renderToString(
          ReactHtmlParser(data),
          setTimeout(function () {
            pdf.save('School Sick Note.pdf');
          }, 500)
        ),
        50,
        10
      );
    } else if (type === 'doctorNotes') {
      const pdf = new jsPDF('p', 'pt', 'letter');
      // const pageWidth = pdf.internal.pageSize.getWidth();
      const center = pdf.internal.pageSize.getWidth() / 2;
      const imgData = snapmedLogo;
      const apptDate = new Date(appt.date).toLocaleDateString('en-CA');
      const startTime = new Date(appt.startTimestamp).toLocaleTimeString(
        'en-CA'
      );
      const endTime = new Date(appt.endTimestamp).toLocaleTimeString('en-CA');
      const dateOfBirth = appt.patientInfoNote.dateOfBirth;
      const timestamp = appt.finalizedNotesTimestamp
        ? new Date(appt.finalizedNotesTimestamp).toLocaleString('en-CA')
        : 'N/A';
      //const noteText = str.replace(/"?<\/?p>"?/gi, "");
      //const splitText = pdf.splitTextToSize(data, pageWidth - 20);
      pdf.addImage(imgData, 'JPEG', center - 75, 10, 150, 45);
      pdf.setFontSize(8);
      pdf.text('202-446 Concession St.', center, 75, 'center');
      pdf.text('Hamilton, ON, L9A 1C2', center, 85, 'center');
      pdf.text('1-833-SNAP-333 (1-833-762-7333)', center, 95, 'center');
      pdf.setFontSize(10);
      pdf.text(`Dr. ${doctor.name.first} ${doctor.name.last}`, 30, 125);
      pdf.text(`Patient: ${appt.patientInfoNote.name}`, 30, 145);
      pdf.text(appt.patientInfoNote.address, 70, 160);
      pdf.text(
        `${appt.patientInfoNote.city}, ${appt.patientInfoNote.province}`,
        70,
        175
      );
      pdf.text(appt.patientInfoNote.postalCode, 70, 190);
      pdf.text(`Phone: ${appt.patientInfoNote.mobilePhone}`, 70, 205);
      pdf.text(`DOB: ${dateOfBirth}`, 70, 220);
      pdf.setFontType('bold');
      pdf.text(`Appointment Date: ${apptDate}`, 360, 125);
      pdf.text(`Appointment ID: ${appt._id}`, 360, 145);
      pdf.setFontType('normal');
      pdf.text(`Reason for Visit: ${appt.shortReason}`, 30, 240);
      pdf.text(`Symptoms: ${appt.symptoms}`, 30, 260);
      pdf.text(`Final note added on: ${timestamp}`, 30, 300);
      pdf.text(`Start time: ${startTime}`, 30, 320);
      pdf.text(`End time: ${endTime}`, 160, 320);
      pdf.text('Notes:', 30, 340);
      pdf.setFontSize(14);
      //pdf.text(splitText, 40, 300);
      pdf.fromHTML(
        ReactDOMServer.renderToString(
          ReactHtmlParser(data),
          setTimeout(function () {
            pdf.save('Doctor Notes.pdf');
          }, 500)
        ),
        40,
        350,
        {
          width: 500,
        }
      );
    } else if (type === 'textChat') {
      let chat;
      // taking into account older text chat transcript as an array
      if (typeof data === 'object') {
        chat = data;
      } else if (typeof data === 'string') {
        const res = await axios.get(`/api/chat-history/${data}`);
        if (res.data) {
          chat = res.data.messages.map((message) => {
            const timestamp = new Date(message.timestamp).toLocaleString(
              'en-CA'
            );
            return `${timestamp}\n[from]: ${message.name}\n[msg]: ${message.message}\n`;
          });
        }
      }
      const pdf = new jsPDF('p', 'pt', 'letter');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const center = pdf.internal.pageSize.getWidth() / 2;
      const imgData = snapmedLogo;
      const apptDate = new Date(appt.date).toLocaleDateString('en-CA');
      const dateOfBirth = patient.dateOfBirth;
      const str = chat.join('\n');
      let page = 1;
      //const noteText = str.replace(/"?<\/?p>"?/gi, "");
      const splitText = pdf.splitTextToSize(str, pageWidth - 20);
      pdf.addImage(imgData, 'JPEG', center - 75, 10, 150, 45);
      pdf.setFontSize(14);
      pdf.text('202-446 Concession St.', center, 80, 'center');
      pdf.text('Hamilton, ON, L9A 1C2', center, 100, 'center');
      pdf.text('1-833-SNAP-333 (1-833-762-7333)', center, 120, 'center');
      pdf.setFontSize(12);
      pdf.text(`Dr. ${doctor.name.first} ${doctor.name.last}`, 30, 150);
      pdf.text(`Patient: ${patient.name.first} ${patient.name.last}`, 30, 170);
      pdf.text(patient.address, 70, 190);
      pdf.text(`${patient.city}, ${patient.province}`, 70, 210);
      pdf.text(patient.zipCode, 70, 230);
      pdf.text(`Phone: ${patient.mobilePhone}`, 70, 250);
      pdf.text(`DOB: ${dateOfBirth}`, 70, 270);
      pdf.text(`Appointment Date: ${apptDate}`, 30, 290);
      pdf.text(`Transcript (page ${page}):`, 30, 310);
      let y = 330;
      for (let i = 0; i < splitText.length; i++) {
        if (y > pageHeight - 50) {
          y = 330;
          page++;
          pdf.addPage();
          pdf.addImage(imgData, 'JPEG', center - 75, 10, 150, 45);
          pdf.setFontSize(14);
          pdf.text('202-446 Concession St.', center, 80, 'center');
          pdf.text('Hamilton, ON, L9A 1C2', center, 100, 'center');
          pdf.text('1-833-SNAP-333 (1-833-762-7333)', center, 120, 'center');
          pdf.setFontSize(12);
          pdf.text(`Dr. ${doctor.name.first} ${doctor.name.last}`, 30, 150);
          pdf.text(
            `Patient: ${patient.name.first} ${patient.name.last}`,
            30,
            170
          );
          pdf.text(patient.address, 70, 190);
          pdf.text(`${patient.city}, ${patient.province}`, 70, 210);
          pdf.text(patient.zipCode, 70, 230);
          pdf.text(`Phone: ${patient.mobilePhone}`, 70, 250);
          pdf.text(`DOB: ${dateOfBirth}`, 70, 270);
          pdf.text(`Appointment Date: ${apptDate}`, 30, 290);
          pdf.text(`Transcript (page ${page}):`, 30, 310);
        }
        pdf.setFontSize(10);
        pdf.text(50, y, splitText[i]);
        y = y + 15;
      }
      pdf.save('SnapMed Chat Transcript.pdf');
    }
  }

  handleRx = (rx) => {
    if (rx.consentForms && rx.consentForms.length > 0) {
      if (!rx.isConsentSigned) {
        this.setState({
          rxInfo: { form: rx.consentForms[0], id: rx._id },
          openConsentDialog: true,
        });
      } else {
        this.setState({
          openPdf: true,
          rxData: rx,
        });
      }
    } else {
      this.setState({
        openPdf: true,
        rxData: rx,
      });
    }
  };

  handleOpenFilesDialog = (files) => {
    this.setState({
      openFilesDialog: true,
      selectedFiles: files,
    });
  };

  renderRxButtons = (rxArray) => {
    return rxArray.map((rx) => (
      <IconButton onClick={() => this.handleRx(rx)}>
        <Badge
          color="error"
          badgeContent=""
          variant="dot"
          invisible={
            (rx.consentForms.length > 0 && rx.isConsentSigned) ||
            rx.consentForms.length === 0
          }
        >
          <PharmacyIcon color="primary" />
        </Badge>
      </IconButton>
    ));
  };

  returnFields = () => {
    const { appointments, classes } = this.props;
    const { expandedPanel } = this.state;

    if (appointments.appointmentHistory.length === 0) {
      return (
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{
            marginTop: 20,
          }}
        >
          <Typography textAlign="center" variant="h5" color="primary">
            No appointment records.
          </Typography>
        </Grid>
      );
    } else {
      const { page, descendingOrder } = this.state;
      var history = [];
      var stepper = page * this.perPage;
      var top = stepper + this.perPage;
      var order = descendingOrder
        ? appointments.appointmentHistory.sort(function (a, b) {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return dateB - dateA;
          })
        : appointments.appointmentHistory.sort(function (a, b) {
            var dateA = new Date(a.date);
            var dateB = new Date(b.date);
            return dateA - dateB;
          });
      for (var i = stepper; i < top; i++) {
        let appt = appointments.appointmentHistory[i];
        let rxConsent = false;
        if (appt && Array.isArray(appt.prescriptions)) {
          appt.prescriptions.forEach((rx) => {
            if (rx.consentForms.length > 0 && !rx.isConsentSigned) {
              rxConsent = true;
            }
          });
        }
        if (appt === undefined) {
          break;
        }
        var attachmentExist =
          (appt.fileList && appt.fileList.length > 0) ||
          (appt.prescriptions && appt.prescriptions.length > 0) ||
          appt.schoolSicknote ||
          appt.generalSicknote;

        history.push(
          <Grow in={true} timeout={500}>
            <Accordion
              expanded={expandedPanel === appt._id}
              onChange={(event, isExpanded) =>
                this.setState({ expandedPanel: isExpanded ? appt._id : false })
              }
            >
              <AccordionSummary
                style={{ backgroundColor: '#EEEDEE' }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Grid container direction="row" spacing={1}>
                  <Grid item container xs={12} sm={6} alignItems="center">
                    {(appt.patientLeftWaitingRoom || !appt.patientConnected) &&
                    this.props.appointmentInQueueId !== appt._id ? (
                      <Typography variant="button" color="error">
                        MISSED CALL - DATE:{' '}
                        {new Date(appt.date).toLocaleDateString('en-CA')}
                      </Typography>
                    ) : (
                      <Typography variant="button" color="primary">
                        Appointment Date:{' '}
                        {new Date(appt.date).toLocaleDateString('en-CA')}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={6}
                    direction="row"
                    spacing={1}
                    justify="flex-end"
                  >
                    <Grid item>
                      {this.props.appointmentInQueueId === appt._id && (
                        <Typography variant="caption" color="error">
                          CURRENT APPOINTMENT
                        </Typography>
                      )}
                    </Grid>
                    <Grid item>
                      <Badge
                        color="error"
                        badgeContent=""
                        variant="dot"
                        invisible={!rxConsent}
                      >
                        <Typography variant="button" color="primary">
                          ID: {appt._id.substring(19, 24)}
                        </Typography>
                      </Badge>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails style={{ backgroundColor: '#D3D3D3' }}>
                <Grid container>
                  <Grid item xs={12}>
                    {appt.ohipAppointment && (
                      <Typography variant="h6" color="primary">
                        OHIP Appointment
                      </Typography>
                    )}

                    {appt.doctor && (
                      <Typography variant="body2">
                        <strong> Doctor:</strong> Dr. {appt.doctor.name.first}{' '}
                        {appt.doctor.name.last}
                      </Typography>
                    )}

                    {appt.ohipAppointment && (
                      <Typography variant="body2">
                        <strong>OHIP Number:</strong> {appt.ohipNumber}
                      </Typography>
                    )}

                    <Typography variant="body2">
                      <strong>Reason for Visit:</strong> {appt.shortReason}
                    </Typography>

                    <Typography variant="body2">
                      <strong> Symptoms:</strong> {appt.symptoms.join(', ')}
                    </Typography>
                    {appt.temperature && (
                      <Typography variant="body2">
                        <strong> Temperature:</strong> {appt.temperature}&#176;C
                      </Typography>
                    )}
                    {appt.heartRate && (
                      <Typography variant="body2">
                        <strong> Heart Rate:</strong> {appt.heartRate}
                      </Typography>
                    )}
                    {appt.bloodPressure && (
                      <Typography variant="body2">
                        <strong> Blood Pressure:</strong> {appt.bloodPressure}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {appt.doctor && (
                      <Typography variant="body2">
                        <strong> Dr. Notes: </strong>
                        {appt.doctorNotesFinalized && appt.patientConnected ? (
                          <IconButton>
                            <DoctorNotesIcon
                              color="primary"
                              onClick={() =>
                                this.createPdf(
                                  appt.patient,
                                  appt.doctor,
                                  appt,
                                  appt.doctorNotes,
                                  'doctorNotes'
                                )
                              }
                            />
                          </IconButton>
                        ) : !appt.doctorNotesFinalized &&
                          appt.patientConnected ? (
                          'Dr. notes will be available within 24 hrs.'
                        ) : (
                          'No notes available.'
                        )}
                      </Typography>
                    )}
                  </Grid>
                  {appt.savedTextChat && (
                    <Grid item xs="12">
                      <Typography variant="body2">
                        <strong>Text Chat:</strong>
                        <IconButton>
                          <Chat
                            color="primary"
                            onClick={() =>
                              this.createPdf(
                                appt.patient,
                                appt.doctor,
                                appt,
                                appt.savedTextChat,
                                'textChat'
                              )
                            }
                          />
                        </IconButton>
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {attachmentExist ? <strong>Attachments:</strong> : ''}
                    </Typography>

                    {appt.prescriptions &&
                      appt.prescriptions.length > 0 &&
                      this.renderRxButtons(appt.prescriptions)}
                    {appt.fileList && appt.fileList.length > 0 && (
                      <Tooltip title="Files">
                        <IconButton
                          onClick={() =>
                            this.handleOpenFilesDialog(appt.fileList)
                          }
                        >
                          <InsertDriveFile color="primary" />
                        </IconButton>
                      </Tooltip>
                    )}
                    {appt.schoolSicknote != undefined && (
                      // <PdfDialog
                      //   data={appt.schoolSicknote.schoolSickNoteValues}
                      //   type={'Sick Note'}
                      // />
                      <IconButton
                        onClick={() =>
                          this.setState({
                            openPdf: true,
                            pdfData: appt.schoolSicknote.schoolSickNoteValues,
                          })
                        }
                      >
                        <Notes color="primary" />
                      </IconButton>
                    )}
                    {appt.generalSicknote != undefined && (
                      <IconButton
                        onClick={() =>
                          this.setState({
                            openPdf: true,
                            pdfData: appt.generalSicknote.generalSickNoteValues,
                          })
                        }
                      >
                        <Notes color="primary" />
                      </IconButton>
                    )}
                  </Grid>
                  {appt.patientLeftWaitingRoom && appt.patientWasPicked ? (
                    <Typography variant="caption" color="error">
                      NOTE: Patient left appointment.
                    </Typography>
                  ) : appt.patientLeftWaitingRoom && !appt.patientWasPicked ? (
                    <Typography variant="caption" color="error">
                      NOTE: Patient left waiting room.
                    </Typography>
                  ) : (
                    ''
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grow>
        );
      }
      return history;
    }
  };

  print() {
    var win = window.open('', 'printwindow');
    win.document.write(this.props.auth.name.last);
    win.print();
  }

  render() {
    const { classes, auth, appointments, selectedUser } = this.props;

    var numberAppts = appointments.appointmentHistory
      ? appointments.appointmentHistory.length
      : 0;
    this.numPages = Math.floor(numberAppts / this.perPage);
    this.bottomPerPage = this.state.page * this.perPage + 1;
    this.topPerPage = this.state.page * this.perPage + this.perPage;

    return !this.state.openPdf ? (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid
              container
              spacing={1}
              style={{
                margin: 0,
                width: '100%',
              }}
              direction="row"
            >
              <Grid item xs={12} style={{ marginTop: '5%' }}>
                <Typography
                  variant="h5"
                  className={classes.spacing}
                  color="primary"
                >
                  Your Appointment History
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  className={classes.spacing}
                  color="primary"
                >
                  Patient:{' '}
                  <span style={{ fontSize: '22px' }}>
                    {selectedUser.fullName}
                  </span>
                </Typography>
              </Grid>
              <br />
              <Grid item xs={12} className={classes.textBottomSpacing}>
                <Typography variant="body1">
                  You can see all information from your previous appointments
                  below, including your prescriptions and the doctor's notes.
                  Just click to expand the tab.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={6} sm={4}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel htmlFor={'order'}>Order by</InputLabel>
                      <Select
                        id="order"
                        input={<OutlinedInput label="Order by" />}
                        InputProps={{ classes: { input: classes.resize } }}
                        value={this.state.descendingOrder ? 'Latest' : 'Oldest'}
                        onChange={(event) => {
                          event.target.value == 'Oldest'
                            ? this.setState({
                                descendingOrder: false,
                              })
                            : this.setState({
                                descendingOrder: true,
                              });
                        }}
                      >
                        {['Oldest', 'Latest'].map((order) => (
                          <MenuItem value={order}>{order}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="caption" className={classes.center}>
                      {' '}
                      {this.bottomPerPage}{' '}
                      {this.state.page === this.numPages
                        ? '- ' + numberAppts
                        : '- ' + this.topPerPage}{' '}
                      of {numberAppts}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sm={1}>
                    <IconButton
                      onClick={this.firstPage}
                      disabled={this.state.page == 0 ? true : false}
                    >
                      <FirstPageIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={3} sm={1}>
                    <IconButton
                      onClick={this.previousPage}
                      disabled={this.state.page == 0 ? true : false}
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                  </Grid>

                  <Grid item xs={3} sm={1}>
                    <IconButton
                      onClick={this.nextPage}
                      disabled={this.state.page == this.numPages ? true : false}
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </Grid>
                  <Grid item xs={3} sm={1}>
                    <IconButton
                      onClick={this.lastPage}
                      disabled={this.state.page == this.numPages ? true : false}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {!appointments.appointmentHistory ? (
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    alignContent="center"
                    style={{
                      height: '200px',
                    }}
                  >
                    <CircularProgress size={40} color="primary" />
                  </Grid>
                ) : (
                  this.returnFields()
                )}
              </Grid>
            </Grid>
          </main>
        </Slide>
        {this.state.openConsentDialog && (
          <ConsentFormDialog
            open={this.state.openConsentDialog}
            close={() => this.setState({ openConsentDialog: false })}
            rxInfo={this.state.rxInfo}
          />
        )}
        {this.state.openFilesDialog && (
          <AppointmentFiles
            open={this.state.openFilesDialog}
            onClose={() =>
              this.setState({ openFilesDialog: false, selectedFiles: [] })
            }
            files={this.state.selectedFiles}
          />
        )}
      </Fragment>
    ) : (
      <Fragment>
        <Slide in={true} direction="right">
          <main className={classes.layoutPdf}>
            <Grid item container justify="flex-end">
              <Button
                className={classes.spacing}
                variant="outlined"
                size="small"
                startIcon={<ArrowBackIos />}
                onClick={() => this.setState({ openPdf: false })}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <PdfRendering
                rxData={this.state.rxData}
                closeDocument={() => this.setState({ openPdf: false })}
                addPharmacy={this.props.pharmacyPage}
              />
            </Grid>
          </main>
        </Slide>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    form: state.form,
    auth: state.auth,
    appointments: state.appointments,
    selectedUser: state.selectedUser,
  };
}

AppointmentHistory = connect(mapStateToProps, actions)(AppointmentHistory);

export default withStyles(styles)(AppointmentHistory);
