import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

class ResumeRenewal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
    };
  }

  componentDidUpdate = () => {
    if (this.props.open !== this.state.open) {
      this.setState({
        open: this.props.open,
      });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.onClose && typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  handleContinue = () => {
    if (this.props.onContinue && typeof this.props.onContinue === 'function') {
      this.props.onContinue();
    }
  };

  render() {
    return (
      <Dialog open={this.state.open} onClose={this.handleClose}>
        <DialogTitle>
          <Typography variant="h5" color="primary">
            Resume Auto Renewal?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Once you resume, your credits <b>will</b> renew at the end of the
            current billing period.
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          <Button variant="contained" onClick={this.handleClose}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleContinue}
          >
            Resume Renewal
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ResumeRenewal;
