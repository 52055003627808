import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { withRouter } from 'react-router-dom';
import {
  Button,
  CssBaseline,
  Paper,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import PaymentPlanRoot from '../payment/PaymentPlanRoot';
import DropzoneInput from '../../components/commonform/FileInput';

const styles = (theme) => ({
  layout: {
    width: 'auto',
    height: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    float: 'right',
  },
  back: {
    marginTop: '20%',
    width: 125,
  },
});

class PaymentPrompt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  handlePayment = (paymentType) => {
    this.setState({ loading: true });
    this.props.formValues.patientId = this.props.patientId;
    this.props.formValues[paymentType] = true;
    this.props.useCreditForAppointment(
      this.props.formValues,
      this.props.history
    );
  };

  renderCreditPrompt() {
    const { classes, onCancel } = this.props;

    return (
      <Paper className={classes.paper}>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary">
            Please confirm the use of 1 of your visits:
          </Typography>
          <br />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={this.state.loading}
            onClick={() => this.handlePayment('regularCreditAppointment')}
          >
            Use one visit
            {this.state.loading && (
              <CircularProgress
                size={15}
                color="white"
                style={{
                  marginLeft: 5,
                }}
              />
            )}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            style={{ backgroundColor: '#ff4a4a', color: '#ffffff' }}
            variant="contained"
            className={classes.back}
            onClick={onCancel}
          >
            Back
          </Button>
        </Grid>
      </Paper>
    );
  }

  renderSubscriptionPrompt() {
    const { classes, onCancel } = this.props;

    return (
      <Paper className={classes.paper}>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary">
            Please confirm you will use your subscription coverage:
          </Typography>
          <br />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={this.state.loading}
            onClick={() => this.handlePayment('subscriptionAppointment')}
          >
            Use one visit
            {this.state.loading && (
              <CircularProgress
                size={15}
                color="white"
                style={{
                  marginLeft: 5,
                }}
              />
            )}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            style={{ backgroundColor: '#ff4a4a', color: '#ffffff' }}
            variant="contained"
            className={classes.back}
            onClick={onCancel}
          >
            Back
          </Button>
        </Grid>
      </Paper>
    );
  }

  renderPaymentPlansPrompt() {
    return (
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="primary">
          <PaymentPlanRoot
            completedPayment={() =>
              this.handlePayment('regularCreditAppointment')
            }
            origin="appointment"
          />
        </Typography>
      </Grid>
    );
  }

  renderPrompts() {
    if (!this.props.auth.subscribedToPlan) {
      if (this.props.auth.credits > 0) {
        return this.renderCreditPrompt();
      }
    } else {
      if (this.props.auth.credits > 0) {
        return this.renderCreditPrompt();
      } else if (this.props.auth.subscriptionVisitBalance > 0) {
        return this.renderSubscriptionPrompt();
      }
    }

    return this.renderPaymentPlansPrompt();
  }

  render() {
    const { classes, onCancel } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Grid
            container
            spacing={1}
            justify="center"
            alignItems="center"
            direction="row"
            style={{
              width: '100%',
            }}
          >
            {!this.props.appointmentParams.appointmentId
              ? this.renderPrompts()
              : this.props.appointmentParams.appointmentId && (
                  <Paper className={classes.paper}>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      style={{ marginTop: '20px' }}
                    >
                      <Grid item xs={12}>
                        <Typography variant="h5" color="primary" align="center">
                          You're now in queue for your appointment.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="primary" align="center">
                          You may upload files that might be useful for
                          appointment purposes.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <DropzoneInput
                          patientId={this.props.selectedUser._id}
                          appointmentId={
                            this.props.appointmentParams.appointmentId
                          }
                          closeComponent={() =>
                            this.props.history.push('/dashboard')
                          }
                          disableFileFecth={true}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                )}
          </Grid>
        </main>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    appt: state.appt,
    formValues: state.symptomsData,
    selectedUser: state.selectedUser,
    appointmentParams: state.appointmentParams,
  };
}

const PaymentPromptPage = connect(
  mapStateToProps,
  actions
)(withRouter(PaymentPrompt));

export default withStyles(styles)(PaymentPromptPage);
