import { WIZARD_PROFILE } from '../actions/types';

export default function(state = [], action) {
	switch (action.type) {
		case WIZARD_PROFILE:
			return action.payload || false;
		case 'add_form_item':
			return action.payload;
		case 'delete_form_item':
			return action.payload;
		case 'trim_about_you_form':
			return action.payload;
		default:
			return state;
	}
}
