import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import { Button, Grid, Typography } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';

class PdfRendering extends Component {
  constructor(props) {
    super(props);
  }
  print(div) {
    const win = window.open('', 'printwindow');
    win.document.write(div);
    win.print();
    // html2canvas(div, {
    //   scale: 2.5,
    //   height: 2000
    // })
    //   .then((canvas) => {
    //     const pdf = new jsPDF("p", "pt", "a2");
    //     const imgData = canvas.toDataURL('image/png');
    //     pdf.addImage(imgData, 'PNG', 20, -1000);
    //     pdf.save('document.pdf');
    //   });
  }

  handleFax = (prescription) => {
    axios
      .post('/api/resend_prescription_fax', { ...prescription, backdrop: true })
      .then((res) => {
        this.props.getAppointmentHistory(this.props.selectedUser._id);
        this.props.closeDocument();
      })
      .catch((err) => console.log(err));
  };

  render() {
    const prescription = this.props.rxData;
    return (
      <Fragment>
        <Grid
          container
          id="printArea"
          justify="center"
          style={{ overflowY: 'auto', height: '35rem' }}
        >
          {ReactHtmlParser(prescription.prescriptionNoteValues)}
        </Grid>
        <Grid container style={{ margin: '10px 0' }}>
          <Grid item xs={2}>
            <Button
              startIcon={<ArrowBackIos />}
              variant="outlined"
              onClick={this.props.closeDocument}
            >
              Back
            </Button>
          </Grid>
          <Grid item container xs={10} justify="flex-end" spacing={1}>
            <Grid item>
              {!this.props.auth.patient.pharmacy.premiumPharmacy &&
                !this.props.auth.patient.pharmacy.faxNumber && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => this.props.addPharmacy()}
                  >
                    Add Pharmacy
                  </Button>
                )}
              {(this.props.auth.patient.pharmacy.faxNumber ||
                (this.props.auth.patient.pharmacy.premiumPharmacy &&
                  this.props.auth.patient.pharmacy.premiumPharmacy
                    .faxNumber)) && (
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  justify="flex-end"
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      variant="outlined"
                      disabled={
                        prescription.prescriptionWasFaxedDashboard ||
                        (prescription.consentForms &&
                          prescription.consentForms.length > 0 &&
                          !prescription.isConsentSigned)
                      }
                      onClick={() => {
                        this.handleFax(prescription);
                      }}
                    >
                      {prescription.prescriptionWasFaxedDashboard
                        ? 'prescription faxed'
                        : 'fax prescription'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      {prescription.consentForms &&
                        prescription.consentForms.length > 0 &&
                        !prescription.isConsentSigned &&
                        'Please sign consent to fax prescription'}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  this.print(document.getElementById('printArea').innerHTML)
                }
              >
                Print
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {prescription.destinationPharmacy &&
          (prescription.prescriptionWasFaxed ||
            prescription.prescriptionWasFaxedDashboard) && (
            <Grid item container xs={12} justify="flex-end">
              <Grid item>
                <Typography variant="body2">
                  Prescription faxed to: {prescription.destinationPharmacy.name}
                </Typography>
                <Typography variant="body2">
                  at: {prescription.destinationPharmacy.faxNumber}
                </Typography>
                <Typography paragraph variant="body2">
                  on:{' '}
                  {new Date(prescription.faxDate).toLocaleString('en-CA', {
                    dateStyle: 'short',
                    timeStyle: 'short',
                  })}
                </Typography>
              </Grid>
            </Grid>
          )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedUser: state.selectedUser,
  };
}

PdfRendering = connect(mapStateToProps, actions)(PdfRendering);

export default PdfRendering;
