import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  reduxForm,
  Field,
  formValueSelector,
  change,
  //startAsyncValidation
} from 'redux-form';
//import PropTypes from 'prop-types';
import {
  Button,
  CssBaseline,
  FormControl,
  Radio,
  RadioGroup,
  Paper,
  Typography,
  TextField,
  Grid,
  FormHelperText,
  FormControlLabel,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from '@material-ui/core/styles/withStyles';
// import MedicalAllergyFields from './MedicalAllergyFields';
// import MedicalAllergyFields1 from './MedicalAllergyFields1';
// import OtherAllergyFields from './OtherAllergyFields';
// import OtherAllergyFields1 from './OtherAllergyFields1';
// import MedicalAllergyFieldsValidate from './MedicalAllergyFieldsValidate';
// import OtherAllergyFieldsValidate from './OtherAllergyFieldsValidate';
import * as actions from '../../../actions';
// import formAddField from '../../commonform/formAddField';
// import formAddButton from '../../commonform/formAddButton';
// import formRemoveButton from '../../commonform/formRemoveButton';
import validationHelperFunction from '../validationHelperFunction';
import snapmedLogo from '../../SnapMedLogoRetina';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: '1.5rem',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    float: 'right',
  },
  alignButton: {
    float: 'right',
    marginTop: 40,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

function validate(values, props) {
  var errors = {};

  const medicalAllergiesArray = props.medicalAllergiesArray;
  const otherAllergiesArray = props.otherAllergiesArray;

  errors = validationHelperFunction(
    values,
    medicalAllergiesArray,
    'medical_allergy_',
    'medical_allergy_No Medical Allergies',
    errors
  );
  errors = validationHelperFunction(
    values,
    otherAllergiesArray,
    'other_allergy_',
    'other_allergy_No Other Allergies',
    errors
  );

  if (!values.isTobaccoSmoker) {
    errors.isTobaccoSmoker = 'Please Select an Option';
  }
  if (!values.isAlcoholConsumer) {
    errors.isAlcoholConsumer = 'Please Select an Option';
  }
  if (!values.isAlcoholConsumer) {
    errors.isAlcoholConsumer = 'Please Select an Option';
  }
  if (!values.isSubstanceUser) {
    errors.isSubstanceUser = 'Please Select an Option';
  }

  if (
    values.isSubstanceUser &&
    !values.substance_input_field &&
    values.isSubstanceUser === 'isSubstanceUser_yes'
  ) {
    errors.substance_input_field = 'This field cannot be blank';
  }

  if (
    !('medical_allergies_other' in values) &&
    !values.medical_allergies_other &&
    !values.medical_allergies_other_field
  ) {
    errors.medical_allergies_other_field = 'This field cannot be blank';
  }

  if (
    !('other_allergies_other' in values) &&
    !values.other_allergies_other &&
    !values.other_allergies_other_field
  ) {
    errors.other_allergies_other_field = 'This field cannot be blank';
  }

  return errors;
}

const AllergyField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <div>
    <FormControl error={touched && error}>
      <FormControlLabel
        error={touched && error ? error : undefined}
        style={{ display: 'flex' }}
        control={
          <Checkbox
            {...input}
            id={name}
            checked={input.value ? label : false}
            error={touched && error ? error : undefined}
            //value="checkedB"
            color="primary"
          />
        }
        label={label}
      />
      {touched && error ? (
        <FormHelperText styles>*{error}</FormHelperText>
      ) : null}
    </FormControl>
  </div>
);

const SmokingField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <FormControl error={touched && error} component="fieldset">
    <RadioGroup name={name} {...input} row>
      <FormControlLabel
        value="isTobaccoSmoker_yes"
        control={<Radio color="primary" />}
        label="Yes"
      />
      <FormControlLabel
        value="isTobaccoSmoker_no"
        control={<Radio color="primary" />}
        label="No"
      />
    </RadioGroup>
    {touched && error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const DrinkingField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <FormControl error={touched && error} component="fieldset">
    <RadioGroup name={name} {...input} row>
      <FormControlLabel
        value="isAlcoholConsumer_yes"
        control={<Radio color="primary" />}
        label="Yes"
      />
      <FormControlLabel
        value="isAlcoholConsumer_no"
        control={<Radio color="primary" />}
        label="No"
      />
    </RadioGroup>
    {touched && error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const SubstanceField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <FormControl error={touched && error} component="fieldset">
    <RadioGroup name={name} {...input} row>
      <FormControlLabel
        value="isSubstanceUser_yes"
        control={<Radio color="primary" />}
        label="Yes"
      />
      <FormControlLabel
        value="isSubstanceUser_no"
        control={<Radio color="primary" />}
        label="No"
      />
    </RadioGroup>
    {touched && error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const OtherField = ({ name, label, input, meta: { error, touched } }) => (
  <TextField
    {...input}
    id={name}
    label={label}
    type="input"
    variant="outlined"
    fullWidth
    error={touched && error}
    helperText={touched && error}
  />
);

const OtherFieldCheckbox = ({ name, id, label, input }) => {
  const dispatch = useDispatch();
  return (
    <FormControl>
      <FormControlLabel
        id={name}
        control={<Checkbox {...input} color="primary" />}
        label={label}
        labelPlacement="end"
        onClick={dispatch(actions.activateAllergiesOtherField(input.value, id))}
      />
    </FormControl>
  );
};

class AboutYouForm extends Component {
  constructor(props) {
    super(props);
    // const {
    //   previousPage,
    //   handleSubmit,
    //   addField,
    //   focus,
    //   isAlcoholDrinker,
    //   isTobaccoSmoker,
    //   isSubstanceUser,
    // } = this.props;
  }

  renderAllAllergiesCheckBoxesFirstHalf(AllergiesArray, naming) {
    return _.map(AllergiesArray, ({ name, _id }, index) => {
      if (index % 2 === 0) {
        if (name === 'Other') {
          return (
            <Field
              name={naming.concat(name)}
              id={naming.concat(name)}
              label={name}
              type={'checkbox'}
              component={OtherFieldCheckbox}
              onChange={(_e, newValue) => {
                if (!newValue) {
                  if (naming === 'medical_allergy_') {
                    this.props.change('medical_allergies_other_field', '');
                  } else {
                    this.props.change('other_allergies_other_field', '');
                  }
                }
              }}
            />
          );
        } else {
          return (
            <Field
              name={naming.concat(name)}
              label={name}
              type={'checkbox'}
              parse={(value) => (value ? { name, _id } : false)}
              component={AllergyField}
            />
          );
        }
      }
    });
  }

  renderAllAllergiesCheckBoxesSecondHalf(AllergiesArray, naming) {
    return _.map(AllergiesArray, ({ name, _id }, index) => {
      if (index % 2 !== 0) {
        if (name === 'Other') {
          return (
            <Field
              name={naming.concat(name)}
              id={naming.concat(name)}
              label={name}
              type={'checkbox'}
              component={OtherFieldCheckbox}
              onChange={(_e, newValue) => {
                if (!newValue) {
                  if (naming === 'medical_allergy_') {
                    this.props.change('medical_allergies_other_field', '');
                  } else {
                    this.props.change('other_allergies_other_field', '');
                  }
                }
              }}
            />
          );
        } else {
          return (
            <Field
              name={naming.concat(name)}
              label={name}
              type={'checkbox'}
              parse={(value) => (value ? { name, _id } : false)}
              component={AllergyField}
            />
          );
        }
      }
    });
  }

  renderSmokingField() {
    return (
      <Field
        name="isTobaccoSmoker"
        type="text"
        //onBlur={null}
        component={SmokingField}
      />
    );
  }

  renderDrinkingField() {
    return (
      <Field name="isAlcoholConsumer" type="text" component={DrinkingField} />
    );
  }

  renderSubstanceField() {
    return (
      <Field name="isSubstanceUser" type="text" component={SubstanceField} />
    );
  }

  renderOtherField(name, label) {
    return <Field name={name} label={label} component={OtherField} />;
  }

  render() {
    const { handleSubmit, previousPage, focus, classes } = this.props;

    const { medicalAllergiesArray, otherAllergiesArray } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Grid container direction="row">
              <Grid xs={12}>
                <img
                  src={snapmedLogo}
                  style={{ width: 100, marginBottom: 10 }}
                />
              </Grid>
              <form className={classes.form} onSubmit={handleSubmit}>
                <Typography
                  variant="h4"
                  color="primary"
                  style={{
                    textAlign: 'center',
                  }}
                >
                  About You
                </Typography>
                <br />
                <Grid container direction="row">
                  <Grid item container xs={6} alignItems="center">
                    <Typography variant="body1">
                      Do you smoke tobacco?
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {this.renderSmokingField()}
                    {/*{this.props.isTobaccoSmoker==='isTobaccoSmoker_yes' && (  )} */}
                  </Grid>
                  <Grid item container xs={6} alignItems="center">
                    <Typography variant="body1">
                      Do you consume alcohol?
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {this.renderDrinkingField()}
                    {/*{this.props.isAlcoholDrinker==='isTobaccoSmoker_yes' && (  )} */}
                  </Grid>
                  <Grid item container xs={6} alignItems="center">
                    <Typography variant="body1">
                      Do you use any substances?
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {this.renderSubstanceField()}
                  </Grid>
                  <Grid xs={12}>
                    {this.props.isSubstanceUser === 'isSubstanceUser_yes' &&
                      this.renderOtherField(
                        'substance_input_field',
                        'Substance'
                      )}
                  </Grid>
                </Grid>
                <br />
                <Typography variant="h6" color="primary">
                  Please indicate if you have any medical allergies:{' '}
                </Typography>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <div>
                      {this.renderAllAllergiesCheckBoxesFirstHalf(
                        this.props.medicalAllergiesArray,
                        'medical_allergy_'
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      {this.renderAllAllergiesCheckBoxesSecondHalf(
                        this.props.medicalAllergiesArray,
                        'medical_allergy_'
                      )}
                    </div>
                  </Grid>
                  {this.props.medicalAllergiesOtherField === true && (
                    <Grid item xs={12}>
                      {this.renderOtherField(
                        'medical_allergies_other_field',
                        'Other medical allergies'
                      )}
                    </Grid>
                  )}
                </Grid>
                <br />

                <Typography variant="h6" color="primary">
                  Please indicate if you have any other allergies:{' '}
                </Typography>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <div>
                      {this.renderAllAllergiesCheckBoxesFirstHalf(
                        this.props.otherAllergiesArray,
                        'other_allergy_'
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      {this.renderAllAllergiesCheckBoxesSecondHalf(
                        this.props.otherAllergiesArray,
                        'other_allergy_'
                      )}
                    </div>
                  </Grid>
                  {this.props.otherAllergiesOtherField === true && (
                    <Grid item xs={12}>
                      {this.renderOtherField(
                        'other_allergies_other_field',
                        'Other allergies'
                      )}
                    </Grid>
                  )}
                </Grid>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  type="submit"
                >
                  Next
                </Button>
              </form>
            </Grid>
          </Paper>
        </main>
      </Fragment>
    );
  }
}

function validateTobaccoAndAlcohol(values) {
  var errors = {};

  if (values.isTobaccoSmoker === 'isTobaccoSmoker_yes') {
    if (!values.smoke_how_often) {
      errors.smoke_how_often = 'You must provide at least one value';
    }
    if (!values.smoke_amount) {
      errors.smoke_amount = 'You must provide at least one value';
    }
  }

  if (values.isAlcoholConsumer === 'isAlcoholConsumer_yes') {
    if (!values.drink_how_often) {
      errors.drink_how_often = 'You must provide at least one value';
    }
    if (!values.alcohol_amount) {
      errors.alcohol_amount = 'You must provide at least one value';
    }
  }

  return errors;
}

function mapStateToProps(state) {
  return {
    wizard: state.wizard,
    formData: state.form,
    medicalAllergiesArray: state.medicalData.medicalAllergiesArray,
    otherAllergiesArray: state.medicalData.otherAllergiesArray,
    otherAllergiesOtherField:
      state.medicalFormOtherField.otherAllergiesOtherField,
  };
}

AboutYouForm = reduxForm({
  validate: validate,
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(AboutYouForm);

const selector = formValueSelector('wizard'); // <-- same as form name

AboutYouForm = connect((state) => {
  const isAlcoholDrinker = selector(state, 'isAlcoholConsumer');
  const isTobaccoSmoker = selector(state, 'isTobaccoSmoker');
  const isSubstanceUser = selector(state, 'isSubstanceUser');
  const medicalAllergiesOtherField = selector(state, 'medical_allergy_Other');

  return {
    isAlcoholDrinker,
    isTobaccoSmoker,
    isSubstanceUser,
    medicalAllergiesOtherField,
  };
})(AboutYouForm);

export default withStyles(styles)(
  connect(mapStateToProps, actions)(AboutYouForm)
);
