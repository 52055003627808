import {
  CssBaseline,
  Grid,
  Paper,
  Select,
  Typography,
  withStyles,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Button,
  Collapse,
  CircularProgress,
} from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import axios from 'axios';
import { connect } from 'react-redux';

const styles = (theme) => ({
  layout: {
    width: 'auto',
    height: '100%',
    //  display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down(350 + theme.spacing.unit * 3 * 2)]: {
      width: 350,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    flexGrow: 1,
    flexDirection: 'row',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  form: {
    marginTop: theme.spacing.unit * 2,
  },
});

const selectField = ({
  input: { name },
  input,
  label,
  children,
  meta: { error, touched },
}) => {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id={`${name}-label`} error={touched && error}>
        {label}
      </InputLabel>
      <Select
        {...input}
        label={label}
        labelId={`${name}-label`}
        id={`${name}-select`}
        error={touched && error}
        helperText={touched && error}
      >
        {children}
      </Select>
      {touched && error && <FormHelperText error>*{error}</FormHelperText>}
    </FormControl>
  );
};

class OhipMembership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      open: false,
      product: undefined,
    };
    this.products = [];
  }

  componentDidMount = async () => {
    try {
      const res = await axios.get('/api/billing/stripe/ohip-plans');
      this.products = res.data;
      this.products.prices.sort(
        (a, b) => a.metadata.duration_in_hours - b.metadata.duration_in_hours
      );
    } catch (err) {
      console.log(err);
    }
    this.setState({
      loading: false,
    });
  };

  handleChange = async (_e, val) => {
    const price = this.products.prices.find((price) => price.id === val);
    let product;

    if (price) {
      product = {
        id: this.products.id,
        name: this.products.name,
        description: this.products.description,
        duration_in_hours: price.metadata.duration_in_hours,
        currency: price.currency,
        unit_amount: price.unit_amount,
        interval: price.recurring.interval,
        price_id: price.id,
        plan_type: 'OHIP',
        product_type: 'Subscription',
      };
    } else {
      product = undefined;
    }

    await this.setState({
      open: true,
      product: product,
    });
  };

  handleBack = () => this.props.history.push(this.props.location.state.origin);

  renderEndDate = () => {
    const date = new Date();
    const dateval =
      date.getHours() + parseInt(this.state.product.duration_in_hours);
    date.setHours(dateval);
    return date.toDateString();
  };

  onSubmit = () => {
    this.props.history.push({
      pathname: '/checkout',
      state: {
        product: this.state.product,
        origin: this.props.location.state.origin,
        redirect: this.props.location.state.redirect,
      },
    });
  };

  render() {
    const { classes, handleSubmit, selectedUser } = this.props;
    const { product } = this.state;
    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography variant="h5">Subscription</Typography>
            <Typography>
              Ontario patients are required to have a standard subscription to
              access the convenient platform. The standard subscription applies
              to each individual patient and it is effective until the selected
              timeline. Subscription is non-refundable.
            </Typography>
            <br />
            <Typography>
              You are purchasing this subscription for:{' '}
              <b>{selectedUser.fullName}</b>
            </Typography>
            {this.state.loading ? (
              <Fragment>
                <br />
                <Grid container justify="center">
                  <Grid item>
                    <CircularProgress size={25} color="primary" />
                  </Grid>
                </Grid>
              </Fragment>
            ) : (
              <form
                id="membership-select-form"
                className={classes.form}
                onSubmit={handleSubmit(this.onSubmit)}
              >
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Field
                      name="membership"
                      label="Subscription"
                      component={selectField}
                      onChange={this.handleChange}
                    >
                      {this.products.prices.map((price, i) => {
                        return (
                          <MenuItem value={price.id} key={i}>
                            {this.products.name} - {price.nickname}
                          </MenuItem>
                        );
                      })}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    {product && (
                      <Collapse in={this.state.open}>
                        <Typography>
                          <b>Product:</b> {product.name}
                        </Typography>
                        <Typography>
                          <b>Cost:</b> ${product.unit_amount / 100}{' '}
                          {product.currency.toUpperCase()} /{' '}
                          {product.interval === 'day'
                            ? '24 hours'
                            : product.interval}
                        </Typography>
                        <Typography>
                          <b>Ends:</b> {this.renderEndDate()}
                        </Typography>
                      </Collapse>
                    )}
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={this.handleBack}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={!product}
                    >
                      Purchase
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Paper>
        </main>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedUser: state.selectedUser,
  };
};

OhipMembership = connect(mapStateToProps)(OhipMembership);

OhipMembership = reduxForm({
  form: 'membership-select-form',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(OhipMembership);

export default withStyles(styles)(OhipMembership);
