import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Typography, Grid } from '@material-ui/core';
import OfficialStripePaymentPrompt from './CheckoutFormYearlyFamilyPlan';
import { Elements } from 'react-stripe-elements';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { withRouter } from 'react-router-dom';
import { Button, Dialog } from '@material-ui/core';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = (theme) => ({
  disclaimer: {
    fontSize: '10px',
  },
});
class YearlyFamilyPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: 0,
      credits: 0,
    };
  }

  async componentDidMount() {
    const res = await axios.get('/api/billing/stripe/GetYearlyPlanData');
    this.setState({ price: res.data.price, credits: res.data.credits });
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Grid container spacing={1} justify="center">
          <Grid container xs={12} sm={8}>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                Yearly Subscription:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Register for the <b>Yearly Subscription</b>, unlimited* doctor
                appointments for the whole family at{' '}
                <b>${this.state.price} CAD/year</b>.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary" className={classes.disclaimer}>
                * A maximum of {this.state.credits} doctor appointments are
                allowed per family yearly. A family plan consists 2 adults and 4
                children under 18. 13% tax rates will apply on all payments.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={4}
            justify="center"
            alignItems="center"
          >
            <Button variant="contained" color="primary" size="small">
              <Link
                style={{ color: 'white', textDecoration: 'none' }}
                to="PaymentPlanMembership/YearlyPlan"
              >
                Select
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(YearlyFamilyPlan);
