export default [
  {
    label: 'Minor injury',
    name: 'symptoms_1',
    type: 'checkbox',
    id: 'symptoms_1',
    component: 'input'
  },
  {
    label: 'Neck/Back pain',
    name: 'symptoms_2',
    type: 'checkbox',
    id: 'symptoms_2',
    component: 'input'
  },
  {
    label: 'COVID-19 symptoms',
    name: 'symptoms_3',
    type: 'checkbox',
    id: 'symptoms_3',
    component: 'input'
  },
  {
    label: 'Vomiting/Diarrhea/Constipation',
    name: 'symptoms_4',
    type: 'checkbox',
    id: 'symptoms_4',
    component: 'input'
  },
  {
    label: 'Headache',
    name: 'symptoms_5',
    type: 'checkbox',
    id: 'symptoms_5',
    component: 'input'
  },
  {
    label: 'Fever',
    name: 'symptoms_6',
    type: 'checkbox',
    id: 'symptoms_6',
    component: 'input'
  },
  {
    label: 'Cough/Cold/Flu',
    name: 'symptoms_7',
    type: 'checkbox',
    id: 'symptoms_7',
    component: 'input'
  }
];
