import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import ProfileForm from './ProfileForm';
import ProfileFormReview from './ProfileFormReview';
import { Prompt } from 'react-router';
import axios from 'axios';

class ProfileFormRoot extends Component {
  state = { showFormReview: false, ohipInfo: {} };

  renderContent() {
    if (this.state.showFormReview) {
      return (
        <ProfileFormReview
          ohipInfo={this.state.ohipInfo}
          onCancel={() => this.setState({ showFormReview: false })}
        />
      );
    }
    return (
      <ProfileForm
        onProfileSubmit={async (values) => {
          if (values.ohipNumber) {
            await axios
              .post('/api/profile/patient/ohip-card-validation', {
                userId: this.props.auth._id,
                softCheckOhip: true,
                healthCardNumber: values.ohipNumber,
                backdrop: true,
              })
              .then((res) => {
                return res.data;
              })
              .then((response) =>
                this.setState({
                  ohipInfo: response,
                  showFormReview: true,
                })
              );
          } else {
            this.setState({
              showFormReview: true,
            });
          }
        }}
        initialValues={{
          country_code: '+1',
          first_name: this.props.auth.name.first,
          last_name: this.props.auth.name.last,
        }}
      />
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

ProfileFormRoot = connect(mapStateToProps)(ProfileFormRoot);

export default reduxForm({
  form: 'profileForm',
})(ProfileFormRoot);
