import React, { Fragment, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withStyles } from '@material-ui/styles';

import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  Badge,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';

import { LocalPharmacy, ArrowForwardIos } from '@material-ui/icons';

const styles = {
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
    paddingLeft: '7px',
  },
  listContainer: {
    width: '100%',
    minWidth: 380,
  },
};

function PrescriptionCard(props) {
  const { classes } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [rx, setRx] = useState();

  const StyledBadge = withStyles((theme) => ({
    badge: (props) => ({
      backgroundColor: props.signed
        ? 'mediumseagreen'
        : theme.palette.error[500],
    }),
  }))(Badge);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const renderPrescriptions = (rxArray) => {
    if (rxArray.length > 0) {
      return rxArray.map((rx) => (
        <ListItem
          key={rx._id}
          button
          onClick={() => {
            setOpenDialog(true);
            setRx(rx.prescriptionNoteValues);
          }}
        >
          <ListItemIcon>
            <StyledBadge
              badgeContent=""
              variant="dot"
              invisible={rx.consentForms.length === 0}
              signed={rx.isConsentSigned}
            >
              <LocalPharmacy color="primary" />
            </StyledBadge>
          </ListItemIcon>
          <ListItemText
            primary={`Medications: ${rx.medications.length}`}
            secondary={
              rx.consentForms.length > 0
                ? 'Requires consent'
                : 'No consent required'
            }
          />
          <ListItemSecondaryAction>
            <ArrowForwardIos color="primary" fontSize="small" />
          </ListItemSecondaryAction>
        </ListItem>
      ));
    }
  };

  return (
    <Grid container item xs={12} style={{ height: '100%' }}>
      {props.editNoteParams.prescriptions.length > 0 ? (
        <Fragment>
          {/* <Grid container item xs={12} spacing={1}>
            <Grid item xs={6}>
              <Typography variant="subtitle" color="primary">
                Pharmacy
              </Typography>
              <Grid
                item
                xs={12}
                className={classes.textField}
                style={{
                  overflow: 'hidden',
                }}
              >
                <Typography color="primary">
                  {props.editNoteParams.prescription.destinationPharmacy.name ||
                    'N/A'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle" color="primary">
                Pharmacy Fax Number
              </Typography>
              <Grid
                item
                xs={12}
                className={classes.textField}
                style={{
                  overflow: 'hidden',
                }}
              >
                <Typography color="primary">
                  {props.editNoteParams.prescription.destinationPharmacy
                    .faxNumber || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid container item xs={12} justify="center">
            <Grid item>
              <List className={classes.listContainer}>
                {renderPrescriptions(props.editNoteParams.prescriptions)}
              </List>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        <Grid container item xs={12} justify="center" alignContent="center">
          <Typography> No prescriptions</Typography>
        </Grid>
      )}
      <Dialog
        maxWidth={false}
        open={openDialog}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title">Prescription</DialogTitle>
        <DialogContent dividers>
          {rx && (
            <Grid item xs={12}>
              {ReactHtmlParser(rx)}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default withStyles(styles)(PrescriptionCard);
