import {
  FETCH_APPOINTMENT,
  APPOINTMENT,
  FETCH_APPOINTMENT_ID,
  SET_PATIENT_ID,
  REFRESH_SESSION,
  SET_PAYMENT_INTENT_ID,
  RESET_APPT_PARAMS,
} from '../actions/types';

const initialState = {
  appointment: null,
  appointmentImages: null,
  patient: null,
  patientId: null,
  sessionId: null,
  room: null,
  token: null,
  appointmentId: null,
  paymentIntentId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_PATIENT_ID:
      return Object.assign({}, state, {
        patientId: action.payload,
      });
    case SET_PAYMENT_INTENT_ID:
      return Object.assign({}, state, {
        paymentIntentId: action.payload,
      });
    case FETCH_APPOINTMENT:
      return Object.assign({}, state, {
        appointment: action.payload.appointment,
        appointmentImages: action.payload.appointmentImages,
        patient: action.payload.patient,
        patientId: action.payload.patient._id,
        appointmentId: action.payload.appointment._id,
        patientHistory: action.payload.patientHistory,
      });
    case APPOINTMENT:
      return Object.assign({}, state, {
        room: action.payload.room,
        token: action.payload.token,
        sessionId: action.payload.sessionId,
      });
    case FETCH_APPOINTMENT_ID:
      return Object.assign({}, state, {
        appointmentId: action.payload,
      });

    case REFRESH_SESSION:
      return Object.assign(initialState);

    case RESET_APPT_PARAMS:
      return initialState;

    case 'logout':
      return {};

    default:
      return state;
  }
}
