import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, clearFields } from 'redux-form';
import LoginForm from './LoginForm';
import * as actions from '../../actions';
import { withRouter } from 'react-router-dom';

import LoginService from './LoginService';

class LoginRoot extends Component {
  constructor(props) {
    super(props);
    this.onLoginSubmit = this.onLoginSubmit.bind(this);
    this.loginService = new LoginService();
  }
  onLoginSubmit(values) {
    this.props.login(values, this.props.history);
  }
  render() {
    return (
      <LoginForm
        onSubmit={this.onLoginSubmit}
        LoginService={this.loginService}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    form: state.form,
  };
}

export default connect(mapStateToProps, actions)(withRouter(LoginRoot));
