import {
  ALLERGIES_OTHER_FIELD,
  MEDICAL_CONDITIONS_OTHER_FIELD,
  MEDICAL_PROCEDURES_OTHER_FIELD,
  CURRENT_MEDICATION_OTHER_FIELD
} from '../actions/types';

const initialState = {
  otherAllergiesOtherField: false,
  medicalConditionsOtherField: false,
  medicalProceduresOtherField: false,
  currentMedicationOtherField: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ALLERGIES_OTHER_FIELD:
      return Object.assign({}, state, {
        otherAllergiesOtherField: action.payload
      });
    case MEDICAL_CONDITIONS_OTHER_FIELD:
      return Object.assign({}, state, {
        medicalConditionsOtherField: action.payload
      });
    case MEDICAL_PROCEDURES_OTHER_FIELD:
      return Object.assign({}, state, {
        medicalProceduresOtherField: action.payload
      });
    case CURRENT_MEDICATION_OTHER_FIELD:
      return Object.assign({}, state, {
        currentMedicationOtherField: action.payload
      });

    default:
      return state;
  }
}