import React, { Component, Fragment } from 'react';
import { reduxForm, Field, reset, clearFields, change } from 'redux-form';
import * as actions from '../../../actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import socketIOClient from 'socket.io-client';
import PropTypes from 'prop-types';
import DoctorVideoChat from './videoChat/DoctorVideoChat';
import blurb from '../NoteConsentParagraph';
import IdleTimer from '../../commonform/IdleTimer';
import toast from 'react-hot-toast';
import {
  general_sicknote_field,
  school_sicknote_field,
  prescription_final_field,
  final_id_validation_field,
} from './renderFinalValidationFields';
import {
  Button,
  CssBaseline,
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  FormControlLabel,
  Dialog,
  Checkbox,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  OutlinedInput,
  FormHelperText,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import withStyles from '@material-ui/core/styles/withStyles';
import '../../../styles/doctorRoomStyles.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FETCH_APPOINTMENT, DOCTORJAAS } from '../../../actions/types';

const constants = require('../../../../src/constants');

const styles = (theme) => ({
  root: {
    flexGrow: 1, //specifies grow factor of a flex item
    padding: 15,
  },
  popUpFirstItem: {
    margin: 5,
  },
  popUpSecondItem: {
    margin: 5,
    marginBottom: 25,
    marginTop: 50,
  },
  popUpSubmittingButtons: {
    float: 'right',
    margin: 5,
  },
  popUpBorderRadius: {
    borderRadius: 10,
  },
  submitAppointmentButton: {
    position: 'absolute',
    right: '2%',
    marginTop: '2.5%',
    marginBottom: '2.5%',
  },
  noteReminder: {
    position: 'relative',
    float: 'right',
  },
  note: {
    position: 'relative',
    marginTop: '7%',
    float: 'right',
  },
  paper: {
    borderRadius: '10px',
    marginTop: '10%',
    marginLeft: '15%',
    width: 500,
    height: '80%',
  },
  video: {
    borderRadius: '10px',
    border: '1px solid black',
    width: '90%',
    height: 500,
    maxWidth: 700,
    minWidth: 260,
    maxHeight: 500,
    marginLeft: '5%',
  },
});

const renderRefundField = ({ input, name, type, meta: { touched, error } }) => (
  <TextField
    {...input}
    multiline
    fullWidth
    id={name}
    style={{ marginBottom: 25 }}
  />
);

const FinalCheckbox = ({ input, label, disableCheckbox }) => (
  <FormControl>
    {label}
    <FormControlLabel
      control={
        <Checkbox
          {...input}
          color="primary"
          checked={input.value ? true : false}
          disabled={disableCheckbox}
        />
      }
    />
  </FormControl>
);

const FinalCheckboxPrescription = ({ input, label, finalizePrescription }) => (
  <FormControl>
    {label}
    <FormControlLabel
      control={
        <Checkbox
          {...input}
          color="primary"
          checked={finalizePrescription}
          inputProps={{
            checked: finalizePrescription,
          }}
        />
      }
    />
  </FormControl>
);

const FinalCheckboxSickNote = ({ input, label, finalizeSickNote }) => (
  <FormControl>
    <FormControlLabel
      control={
        <Checkbox
          {...input}
          color="primary"
          checked={finalizeSickNote}
          inputProps={{
            checked: finalizeSickNote,
          }}
        />
      }
    />
  </FormControl>
);

const FinalCheckboxSchoolSickNote = ({
  input,
  label,
  finalizeSchoolSickNote,
}) => (
  <FormControl>
    {label}
    <FormControlLabel
      control={
        <Checkbox
          {...input}
          color="primary"
          checked={finalizeSchoolSickNote}
          inputProps={{
            checked: finalizeSchoolSickNote,
          }}
        />
      }
    />
  </FormControl>
);

const DiagnosticCode = ({ name, input, options, meta: { touched, error } }) => {
  const { onChange, ...rest } = input;
  return (
    <FormControl variant="outlined" fullWidth>
      <Autocomplete
        //{...input}
        id={name}
        size="small"
        value={input.value || []}
        options={options}
        onChange={(e, newValue) => {
          onChange(newValue);
        }}
        getOptionLabel={(option) => option.diagnosis}
        renderInput={(params) => (
          <TextField {...params} label="Diagnostic Code" variant="outlined" />
        )}
      />
      {touched && error ? (
        <FormHelperText>
          <span style={{ color: '#f44336' }}>{error}</span>
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

class DoctorAppointmentRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: socketIOClient(
        constants.REACT_APP_HOST_NAME + '/snapmed_doctors',
        { transports: ['websocket'] }
      ),
      submitAppointment: false,
      renderRefund: false,
      finalizeRefund: false,
      disablePatientAppointmentButton: true,
      disconnectCall: false,
      callDuration: '',
      textChat: [],
    };
  }

  handleRefundOpen = () => {
    this.setState({ renderRefund: true });
  };

  handleRefundClose = () => {
    this.setState({ renderRefund: false });
  };

  finalizeRefund = () => {
    this.setState({ renderRefund: false, finalizeRefund: true });
  };

  updateTextChat = (message) => {
    this.setState((prevState) => ({
      textChat: [...prevState.textChat, message],
    }));
  };

  async componentDidMount() {
    if (!this.props.appointmentParams.appointmentId) {
      // TODO use this to create an action
      await axios
        .get('/api/get_current_session_doctor')
        .then((res) => {
          const jaasData = {
            sessionId: res.data.jaasCredentials.sessionId,
            docToken: res.data.jaasCredentials.docToken,
          };
          this.props.dispatch({
            type: FETCH_APPOINTMENT,
            payload: res.data,
          });
          this.props.dispatch({
            type: DOCTORJAAS,
            payload: jaasData,
          });
        })
        .catch((err) => {
          window.location.replace('/doctors');
        });
    }

    toast(
      (t) => (
        <Grid container item xs={12} alignItems="center">
          <Grid item xs={11}>
            <Typography color="primary">
              If you believe this is an emergency, please indicate to the
              patient to call 911 or go to their nearest Medical Facility.
            </Typography>
          </Grid>
          <Grid item xs={1} container justify="flex-end">
            <IconButton
              variant="outlined"
              color="primary"
              onClick={() => toast.dismiss(t.id)}
              size="small"
            >
              <Close color="primary" />
            </IconButton>
          </Grid>
        </Grid>
      ),
      {
        position: 'bottom-center',
        duration: Infinity,
        style: { maxWidth: 'fit-content' },
      }
    );
  }

  componentDidUpdate = () => {
    if (!this.state.submitAppointment) {
    } else {
      window.pagehide = undefined;
      window.popstate = undefined;
    }
  };

  componentWillUnmount() {
    this.props.LeaveRoom();
    this.setState({ socket: '' });
  }

  renderFinalValidation(
    name,
    type,
    label,
    finalizePrescription,
    finalizeSickNote,
    finalizeSchoolSickNote,
    disableCheckbox
  ) {
    return (
      <Field
        name={name}
        type={type}
        label={label}
        finalizePrescription={finalizePrescription}
        finalizeSickNote={finalizeSickNote}
        finalizeSchoolSickNote={finalizeSchoolSickNote}
        disableCheckbox={disableCheckbox}
        component={
          name === 'prescription_final_validation_yes'
            ? FinalCheckboxPrescription
            : name === 'general_sicknote_final_validation_yes'
            ? FinalCheckboxSickNote
            : name === 'school_sicknote_final_validation_yes'
            ? FinalCheckboxSchoolSickNote
            : FinalCheckbox
        }
      />
    );
  }

  checkGeneralError() {
    const { form } = this.props;
    if (
      form.noteForm != null &&
      form.noteForm.syncErrors != null &&
      form.noteForm.syncErrors.general_note === true
    ) {
      return true;
    } else {
      return null;
    }
  }

  checkIncompleteGeneralError() {
    const { form } = this.props;
    if (
      form.noteForm != null &&
      form.noteForm.values != null &&
      form.noteForm.values.general_sicknote_final_validation_no === true
    ) {
      return true;
    } else {
      return null;
    }
  }

  checkSchoolError() {
    const { form } = this.props;
    if (
      form.noteForm != null &&
      form.noteForm.syncErrors != null &&
      form.noteForm.syncErrors.school_note === true
    ) {
      return true;
    } else {
      return null;
    }
  }

  checkIncompleteSchoolError() {
    const { form } = this.props;
    if (
      form.noteForm != null &&
      form.noteForm.values != null &&
      form.noteForm.values.school_sicknote_final_validation_no === true
    ) {
      return true;
    } else {
      return null;
    }
  }
  checkPrescriptionError() {
    const { form } = this.props;
    if (
      form.noteForm != null &&
      form.noteForm.syncErrors != null &&
      form.noteForm.syncErrors.prescription === true
    ) {
      return true;
    } else {
      return null;
    }
  }

  checkIncompletePrescriptionError() {
    const { form } = this.props;
    if (
      form.noteForm != null &&
      form.noteForm.values != null &&
      form.noteForm.values.prescription_final_validation_no === true
    ) {
      return true;
    } else {
      return null;
    }
  }
  checkOne(type) {
    const { form } = this.props;
    if (type === 'gen') {
      if (
        (form.noteForm != null &&
          form.noteForm.values != null &&
          !form.noteForm.values.general_sicknote_final_validation_yes) ||
        (form.noteForm != null &&
          form.noteForm.values != null &&
          !form.noteForm.values.general_sicknote_final_validation_na)
      ) {
        return true;
      }
    }

    if (type === 'pres') {
      if (
        (form.noteForm != null &&
          form.noteForm.values != null &&
          !form.noteForm.values.prescription_final_validation_yes) ||
        !form.noteForm.values.prescription_final_validation_na
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (type === 'school') {
      if (
        (form.noteForm != null &&
          form.noteForm.values != null &&
          !form.noteForm.values.school_sicknote_final_validation_yes) ||
        !form.noteForm.values.school_sicknote_final_validation_na
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  disableSubmitButton() {
    const { form } = this.props;

    if (form.noteForm != null && form.noteForm.values != null) {
      if (!this.props.appointmentParams.appointment.ohipAppointment) {
        // if prescription, school sick note and general sick note are ALL finalized
        if (
          this.props.finalizePrescription &&
          this.props.finalizeSchoolSickNote &&
          this.props.finalizeSickNote &&
          form.noteForm.values.diagnosticCode != null &&
          form.noteForm.values.appointmentCode != null
        ) {
          return false;
        }
        // if prescription and general sick note are finalized AND school sick note is N/A
        else if (
          this.props.finalizePrescription &&
          this.props.finalizeSickNote &&
          form.noteForm.values.school_sicknote_final_validation_na &&
          form.noteForm.values.diagnosticCode != null &&
          form.noteForm.values.appointmentCode != null
        ) {
          return false;
        }
        // if prescription is finalized AND both school sick note and general sick note are N/A
        else if (
          this.props.finalizePrescription &&
          form.noteForm.values.school_sicknote_final_validation_na &&
          form.noteForm.values.general_sicknote_final_validation_na &&
          form.noteForm.values.diagnosticCode != null &&
          form.noteForm.values.appointmentCode != null
        ) {
          return false;
        }
        // if prescription and school sick note are finalized AND general sick note is N/A
        else if (
          this.props.finalizePrescription &&
          this.props.finalizeSchoolSickNote &&
          form.noteForm.values.general_sicknote_final_validation_na &&
          form.noteForm.values.diagnosticCode != null &&
          form.noteForm.values.appointmentCode != null
        ) {
          return false;
        }
        // if prescription, school sick note and general sick note are ALL N/A
        else if (
          form.noteForm.values.prescription_final_validation_na &&
          form.noteForm.values.school_sicknote_final_validation_na &&
          form.noteForm.values.general_sicknote_final_validation_na &&
          form.noteForm.values.diagnosticCode != null &&
          form.noteForm.values.appointmentCode != null
        ) {
          return false;
        }
        // if school sick note and general sick note are both finalized AND prescription is N/A
        else if (
          this.props.finalizeSchoolSickNote &&
          this.props.finalizeSickNote &&
          form.noteForm.values.prescription_final_validation_na &&
          form.noteForm.values.diagnosticCode != null &&
          form.noteForm.values.appointmentCode != null
        ) {
          return false;
        }
        //if general sick note is finalized AND prescription and school sick note are both N/A
        else if (
          this.props.finalizeSickNote &&
          form.noteForm.values.prescription_final_validation_na &&
          form.noteForm.values.school_sicknote_final_validation_na &&
          form.noteForm.values.diagnosticCode != null &&
          form.noteForm.values.appointmentCode != null
        ) {
          return false;
        }
        // if prescription and general sick note are both N/A AND school sick note is finalized.
        else if (
          form.noteForm.values.prescription_final_validation_na &&
          form.noteForm.values.general_sicknote_final_validation_na &&
          form.noteForm.values.appointmentCode != null &&
          form.noteForm.values.diagnosticCode != null &&
          this.props.finalizeSchoolSickNote
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        // same validation as above but within OHIP appointment with ID verification.
        if (
          this.props.finalizePrescription &&
          this.props.finalizeSchoolSickNote &&
          this.props.finalizeSickNote &&
          form.noteForm.values.diagnosticCode != null &&
          form.noteForm.values.appointmentCode != null &&
          (form.noteForm.values.final_id_validation_field_na ||
            form.noteForm.values.final_id_validation_field_yes)
        ) {
          return false;
        } else if (
          this.props.finalizePrescription &&
          this.props.finalizeSickNote &&
          form.noteForm.values.school_sicknote_final_validation_na &&
          form.noteForm.values.diagnosticCode != null &&
          form.noteForm.values.appointmentCode != null &&
          (form.noteForm.values.final_id_validation_field_na ||
            form.noteForm.values.final_id_validation_field_yes)
        ) {
          return false;
        } else if (
          this.props.finalizePrescription &&
          form.noteForm.values.school_sicknote_final_validation_na &&
          form.noteForm.values.general_sicknote_final_validation_na &&
          form.noteForm.values.diagnosticCode != null &&
          form.noteForm.values.appointmentCode != null &&
          (form.noteForm.values.final_id_validation_field_na ||
            form.noteForm.values.final_id_validation_field_yes)
        ) {
          return false;
        } else if (
          this.props.finalizePrescription &&
          this.props.finalizeSchoolSickNote &&
          form.noteForm.values.general_sicknote_final_validation_na &&
          form.noteForm.values.diagnosticCode != null &&
          form.noteForm.values.appointmentCode != null &&
          (form.noteForm.values.final_id_validation_field_na ||
            form.noteForm.values.final_id_validation_field_yes)
        ) {
          return false;
        } else if (
          form.noteForm.values.prescription_final_validation_na &&
          form.noteForm.values.school_sicknote_final_validation_na &&
          form.noteForm.values.general_sicknote_final_validation_na &&
          form.noteForm.values.diagnosticCode != null &&
          form.noteForm.values.appointmentCode != null &&
          (form.noteForm.values.final_id_validation_field_na ||
            form.noteForm.values.final_id_validation_field_yes)
        ) {
          return false;
        } else if (
          this.props.finalizeSchoolSickNote &&
          this.props.finalizeSickNote &&
          form.noteForm.values.prescription_final_validation_na &&
          form.noteForm.values.appointmentCode != null &&
          form.noteForm.values.diagnosticCode != null &&
          (form.noteForm.values.final_id_validation_field_na ||
            form.noteForm.values.final_id_validation_field_yes)
        ) {
          return false;
        } else if (
          this.props.finalizeSickNote &&
          form.noteForm.values.prescription_final_validation_na &&
          form.noteForm.values.school_sicknote_final_validation_na &&
          form.noteForm.values.appointmentCode != null &&
          form.noteForm.values.diagnosticCode != null &&
          (form.noteForm.values.final_id_validation_field_na ||
            form.noteForm.values.final_id_validation_field_yes)
        ) {
          return false;
        } else if (
          form.noteForm.values.prescription_final_validation_na &&
          form.noteForm.values.general_sicknote_final_validation_na &&
          form.noteForm.values.appointmentCode != null &&
          form.noteForm.values.diagnosticCode != null &&
          this.props.finalizeSchoolSickNote &&
          (form.noteForm.values.final_id_validation_field_na ||
            form.noteForm.values.final_id_validation_field_yes)
        ) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  disableCheckbox(name) {
    const { form } = this.props;
    if (!form.noteForm) return true;
    if (
      name === 'final_id_validation_field_yes' &&
      form.noteForm.values != null &&
      form.noteForm.values.final_id_validation_field_na
    ) {
      return true;
    }
    if (
      name === 'final_id_validation_field_na' &&
      form.noteForm.values != null &&
      form.noteForm.values.final_id_validation_field_yes
    ) {
      return true;
    }
    if (
      name === 'prescription_final_validation_na' &&
      this.props.finalizePrescription
    ) {
      return true;
    }

    if (
      name === 'school_sicknote_final_validation_na' &&
      this.props.finalizeSchoolSickNote
    ) {
      return true;
    }

    if (
      name === 'general_sicknote_final_validation_na' &&
      this.props.finalizeSickNote
    ) {
      return true;
    }
  }

  handleFinalizeAppointment() {
    this.setState({ submitAppointment: true }, () => {
      // const fullChat = document.getElementById('history');
      // const texts = [].map.call(fullChat.querySelectorAll('p'), function (p) {
      //   return p.textContent;
      // });
      // this.props.dispatch(actions.savedTextChatAction(texts));
    });
  }

  formDataRender = ({
    name,
    input,
    label,
    placeholder,
    type,
    formArray,
    meta: { touched, error },
  }) => {
    {
      return (
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id={name}> {placeholder} </InputLabel>
          <Select {...input} input={<OutlinedInput label={placeholder} />}>
            {formArray.map((formData) => (
              <MenuItem key={formData.id} value={formData}>
                {formData.name}
              </MenuItem>
            ))}
          </Select>
          <br />
          {error && <span style={{ color: 'red' }}>{error}</span>}
        </FormControl>
      );
    }
  };

  renderFields = (placeholder, name, formArray) => {
    return (
      <Field
        name={name}
        type={'text'}
        placeholder={placeholder}
        format={(value) => (value ? value : '')}
        onBlur={null}
        formArray={formArray}
        component={this.formDataRender}
      />
    );
  };

  renderDiagnosticCodeField(placeholder, name, type, codes) {
    return (
      <Field
        name={name}
        type={type}
        placeholder={placeholder}
        format={(value) => (value ? value : '')}
        onBlur={null}
        component={DiagnosticCode}
        options={codes}
      />
    );
  }

  render() {
    const {
      classes,
      auth,
      form,
      finalizePrescription,
      finalizeSickNote,
      finalizeSchoolSickNote,
      diagnosticCodes,
    } = this.props;
    const { docToken } = this.props.appointmentDoctorJaas;
    const { appointment, patient } = this.props.appointmentParams;

    // var pristine =
    //   form.noteForm != null &&
    //   form.noteForm.syncErrors != null &&
    //   form.noteForm.syncErrors.pristinePres;
    // (form.noteForm != null &&
    //   form.noteForm.syncErrors != null &&
    //   form.noteForm.syncErrors.pristineSchool) ||
    //   (form.noteForm != null &&
    //     form.noteForm.syncErrors != null &&
    //     form.noteForm.syncErrors.pristineGen);

    var genError = this.checkGeneralError();
    var schoolError = this.checkSchoolError();
    var prescriptionError = this.checkPrescriptionError();
    var incompleteGenError = this.checkIncompleteGeneralError();
    var incompleteSchoolError = this.checkIncompleteSchoolError();
    var incompletePrescriptionError = this.checkIncompletePrescriptionError();
    var genCheckOne = this.checkOne('gen');

    return (
      <Fragment>
        <CssBaseline />
        <IdleTimer
          portal="Doctor"
          component="Appointment"
          history={this.props.history}
        />
        <div className={classes.root}>
          <Grid container id="main-container-doctor">
            <Grid item xs={12}>
              {this.props.appointmentParams &&
                this.props.appointmentParams.appointmentId &&
                this.props.appointmentDoctorJaas &&
                this.props.appointmentDoctorJaas.docToken && (
                  <DoctorVideoChat
                    docToken={docToken}
                    room={this.props.appointmentParams.appointmentId}
                    getCallDuration={(value) =>
                      this.setState({ callDuration: value })
                    }
                    disconnectCall={this.state.disconnectCall}
                    updateTextChat={(message) => this.updateTextChat(message)}
                    finalizeAppointment={() => {
                      this.props.appointmentRoomRemovePatient({
                        appointment: this.props.appointmentParams.appointment,
                      });
                      this.handleFinalizeAppointment();
                      this.setState({
                        disconnectCall: true,
                      });
                    }}
                  />
                )}
            </Grid>
            <form
              id="doctor_appointment"
              onSubmit={this.props.handleSubmit(async (values) => {
                if (!values.chatNote) {
                  values.chatNote = blurb;
                }
                values.patientObject = this.props.appointmentParams.patient;
                values.appointment = this.props.appointmentParams.appointment;
                values.room = this.props.appointmentParams.appointment._id;
                values.submitRefund = this.state.finalizeRefund;
                values.textChatTranscript = this.state.textChat;
                values.status = this.props.issue;
                values.patientWasPicked = true;
                values.doctorAppointmentTime = this.state.callDuration;
                this.props.appointmentRoomHelper(values, this.props.history);
                this.props.reset(this.props.form.noteForm);
              })}
            >
              {appointment && appointment.patientRequireSickNote && (
                <Typography
                  variant="subtitle1"
                  color="error"
                  className={classes.noteReminder}
                >
                  *This patient requires a work or school sicknote
                </Typography>
              )}

              <Dialog
                fullscreen
                fullWidth
                open={this.state.submitAppointment}
                onClose={() => {
                  this.setState({ submitAppointment: false });
                }}
              >
                <DialogTitle style={{ color: 'primary' }}>
                  {' '}
                  Would you like to finalize the appointment?
                </DialogTitle>
                <DialogContent classes={classes.dialogPaper}>
                  <DialogContentText>
                    <Grid container direction="row" alignItems="center">
                      <Grid item xs={10}>
                        <Typography>
                          <strong>Please confirm the below:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          style={{
                            fontSize: '0.7em',
                          }}
                        >
                          YES
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography
                          style={{
                            fontSize: '0.7em',
                          }}
                        >
                          N/A
                        </Typography>
                      </Grid>

                      <Grid item xs={genError ? 9 : 10}>
                        {"Has the patient's sick note been completed?"}
                      </Grid>
                      {genError && (
                        <Grid item xs={1}>
                          <Typography color="error" variant="caption">
                            ***
                          </Typography>
                        </Grid>
                      )}
                      {general_sicknote_field.map(({ name, type, label }) => (
                        <Grid
                          item
                          xs={1}
                          key={name}
                          style={{ justifyContent: 'center' }}
                        >
                          {this.renderFinalValidation(
                            name,
                            type,
                            label,
                            null,
                            finalizeSickNote,
                            null,
                            this.disableCheckbox(name)
                          )}
                        </Grid>
                      ))}
                      {incompleteGenError && (
                        <Typography color="error" variant="caption">
                          Please complete the patients sick note
                        </Typography>
                      )}
                      <Grid item xs={schoolError ? 9 : 10}>
                        {"Has the patient's school sick note been completed?"}
                      </Grid>
                      {schoolError && (
                        <Grid item xs={1}>
                          <Typography color="error" variant="caption">
                            ***
                          </Typography>
                        </Grid>
                      )}

                      {school_sicknote_field.map(({ name, type }) => (
                        <Grid item xs={1} key={name}>
                          {this.renderFinalValidation(
                            name,
                            type,
                            null,
                            null,
                            null,
                            finalizeSchoolSickNote,
                            this.disableCheckbox(name)
                          )}
                        </Grid>
                      ))}
                      {incompleteSchoolError && (
                        <Typography color="error" variant="caption">
                          Please complete the patients school sick note
                        </Typography>
                      )}
                      <Grid item xs={prescriptionError ? 9 : 10}>
                        {'Has the prescription been completed and sent?'}
                      </Grid>
                      {prescriptionError && (
                        <Grid item xs={1}>
                          <Typography color="error" variant="caption">
                            ***
                          </Typography>
                        </Grid>
                      )}
                      {prescription_final_field.map(({ name, type }) => (
                        <Grid item xs={1} key={name}>
                          {this.renderFinalValidation(
                            name,
                            type,
                            null,
                            finalizePrescription,
                            null,
                            null,
                            this.disableCheckbox(name)
                          )}
                        </Grid>
                      ))}
                      {incompletePrescriptionError && (
                        <Typography color="error" variant="caption">
                          Please complete the patients prescription
                        </Typography>
                      )}
                      {this.props.appointmentParams.appointment &&
                        this.props.appointmentParams.appointment
                          .ohipAppointment && (
                          <Grid item container alignItems="center">
                            <Grid item xs={10}>
                              {"Has the patient's ID been verified?"}
                            </Grid>
                            {final_id_validation_field.map(({ name, type }) => (
                              <Grid item xs={1} key={name}>
                                {this.renderFinalValidation(
                                  name,
                                  type,
                                  null,
                                  null,
                                  null,
                                  null,
                                  this.disableCheckbox(name)
                                )}
                              </Grid>
                            ))}
                            {/* <Grid item xs={12}>
                            <img
                              src={'data:image/png;base64,' +
                                this.props.appointmentParams.appointmentImages[0].picture
                              }
                              style={{
                                width: '150px'
                              }}
                            />
                          </Grid> */}
                          </Grid>
                        )}
                      <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <Typography>
                          <strong>
                            Please select the billing codes for this
                            appointment:
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item container spacing={1}>
                        <Grid item xs={12} sm={6} style={{ marginTop: 10 }}>
                          <Typography>
                            {this.renderFields(
                              'Fee Code',
                              'appointmentCode',
                              this.props.appointmentCodes
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ marginTop: 10 }}>
                          <Typography>
                            {this.renderDiagnosticCodeField(
                              'Diagnosis Code',
                              'diagnosticCode',
                              'input',
                              diagnosticCodes
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  {/* <Button onClick={this.handleRefundOpen}>
                    Refund Patient Credit
                  </Button> */}
                  <Button
                    disabled={this.disableSubmitButton()}
                    //disabled={
                    //pristine
                    // incompleteGenError ||
                    // incompleteSchoolError ||submitAppointment
                    //true
                    // : false
                    //}
                    variant="contained"
                    color="primary"
                    form="doctor_appointment"
                    type="submit"
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={this.state.renderRefund}
                onClose={this.handleRefundClose}
              >
                <DialogContent>
                  <DialogContentText>
                    Please give a reason for refund the patient
                    <Field
                      type="text"
                      name="refundReasonField"
                      component={renderRefundField}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleRefundClose}>Cancel</Button>
                  <Button
                    onClick={this.finalizeRefund}
                    form="doctor_appointment"
                    type="submit"
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </form>
          </Grid>
        </div>
      </Fragment>
    );
  }
}

function validate(values) {
  var errors = {};
  var arrayGen = [];
  var arraySchool = [];
  var arrayPres = [];

  general_sicknote_field.filter((v) => {
    if (values[v.name]) {
      arrayGen.push(1);
      if (arrayGen.length != 1) {
        errors['general_note'] = true;
      }
    }
  });

  school_sicknote_field.filter((v) => {
    if (values[v.name]) {
      arraySchool.push(1);
      if (arraySchool.length != 1) {
        errors['school_note'] = true;
      }
    }
  });

  prescription_final_field.filter((v) => {
    if (values[v.name]) {
      arrayPres.push(1);
      if (arrayPres.length != 1) {
        errors['prescription'] = true;
      }
    }
  });

  if (
    !values.prescription_final_validation_yes &&
    !values.prescription_final_validation_na
  ) {
    errors['pristinePres'] = true;
  } else if (
    values.prescription_final_validation_yes ||
    values.prescription_final_validation_na
  ) {
    errors['pristinePres'] = false;
  }
  if (
    !values.school_sicknote_final_validation_yes &&
    !values.school_sicknote_final_validation_na
  ) {
    errors['pristineSchool'] = true;
  } else if (
    values.school_sicknote_final_validation_yes ||
    values.school_sicknote_final_validation_na
  ) {
    errors['pristineSchool'] = false;
  }
  if (
    !values.general_sicknote_final_validation_yes ||
    !values.general_sicknote_final_validation_na
  ) {
    errors['pristineGen'] = true;
  } else if (
    values.general_sicknote_final_validation_yes ||
    values.general_sicknote_final_validation_na
  ) {
    errors['pristineGen'] = false;
  }

  return errors;
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    issue: state.issue,
    appointmentDoctorJaas: state.appointmentDoctorJaas,
    appointmentParams: state.appointmentParams,
    form: state.form,
    tokPersist: state.tokPersist,
    finalizePrescription: state.finalizePrescription,
    finalizeSickNote: state.finalizeSickNote,
    finalizeSchoolSickNote: state.finalizeSchoolSickNote,
    savedTextChat: state.savedTextChat,
    appointmentCodes: state.smartAppointmentCode,
    issue: state.issue,
    diagnosticCodes: state.diagnosticCodes,
  };
}

DoctorAppointmentRoom = connect(
  mapStateToProps,
  actions
)(DoctorAppointmentRoom);

DoctorAppointmentRoom = reduxForm({
  validate,
  form: 'noteForm',
  destroyOnUnmount: false,
})(withRouter(DoctorAppointmentRoom));

DoctorAppointmentRoom.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(DoctorAppointmentRoom));
