import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import axios from 'axios';
import {
  CssBaseline,
  Paper,
  Grid,
  Typography,
  Tabs,
  Tab,
  Zoom,
  Button,
} from '@material-ui/core';
import {
  ArrowDropUp,
  Refresh
} from '@material-ui/icons';
import DailyDeliveriesCard from './DailyDeliveriesCard';
import MonthlyDeliveriesCard from './MonthlyDeliveriesCard';
import AvgDeliveryTimeCard from './AvgDeliveryTimeCard';
import ActiveDeliveriesCard from './ActiveDeliveriesCard';
import PendingDeliveriesCard from './PendingDeliveriesCard';

const styles = theme => ({
  // layout: {
  //   overflow: 'hidden',
  //   marginLeft: theme.spacing.unit,
  //   marginRight: theme.spacing.unit,
  //   [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
  //     marginLeft: theme.spacing.unit * 6,
  //     marginRight: theme.spacing.unit * 6
  //   },
  //   maxWidth: 850
  // },
  paper : {
    padding: '10px',
    height: '100%'
  },
  section: {
    marginLeft: '200px',
    marginRight: '20px'
  },
  sectionTab: { 
    margin: 'auto 10px',
    borderRight: 'solid lightgray 1px'
  },
});

class DashboardSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 'Profile',
      deliveries: [],
    }
  }
  
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue })
  }

  handleDeliveriesRefresh = async () => {
    const result = await axios.get('/api/pharmacy-deliveries');
    if (result.data) {
      this.setState({ deliveries: result.data})
    }
  }

  render() {
    const { tabValue, deliveries } = this.state;
    const { classes } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={4}>
          <Paper className={classes.paper}>
            <Typography color='primary' variant='h6'>
              Daily Deliveries
            </Typography>
            <DailyDeliveriesCard />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper className={classes.paper}>
            <Typography color='primary' variant='h6'>
              Prescriptions Received Monthly
            </Typography>
            <MonthlyDeliveriesCard />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper className={classes.paper}>
            <Typography color='primary' variant='h6'>
              Avg. Delivery Time
            </Typography>
            <AvgDeliveryTimeCard />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={6}>
                <Typography color='primary' variant='h6'>
                  Active Deliveries
                </Typography>
              </Grid>
              <Grid item xs={6} container justify='flex-end'>
                <Button
                  size='small'
                  variant='outlined'
                  color='primary'
                  startIcon={<Refresh />}
                  onClick={() => this.props.getDeliveriesList()}
                >
                  Refresh
                </Button>
              </Grid>
            </Grid>
            <ActiveDeliveriesCard />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant='h6' color='error'>
                  Pending Deliveries
                </Typography>
              </Grid>
              <Grid item xs={6} container justify='flex-end'>
                <Button
                  size='small'
                  variant='outlined'
                  color='primary'
                  startIcon={<Refresh />}
                  onClick={() => this.props.getDeliveriesList()}
                >
                  Refresh
                </Button>
              </Grid>
            </Grid>
            <PendingDeliveriesCard />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

DashboardSection = connect(mapStateToProps, actions)(withRouter(DashboardSection));

export default withStyles(styles)(DashboardSection)