import _ from 'lodash';
import React, { Component, Fragment, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  reduxForm,
  getFormMeta,
  Field,
  FormSection,
  formValueSelector,
  startAsyncValidation,
  FieldArray,
  reset,
} from 'redux-form';
import PropTypes from 'prop-types';
import {
  Button,
  CssBaseline,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Grid,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Slide,
} from '@material-ui/core';
import { createTextMask } from 'redux-form-input-masks';
import DatePicker from 'react-datepicker';
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from '@material-ui/core/styles/withStyles';
import * as actions from '../../actions';
import MuiAlert from '@material-ui/lab/Alert';
import renderDropzoneInputOhip from '../commonform/FileInputOhip';
import cardPlaceholder from '../../images/ohip_card_placeholder.png';

import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/safariDatePicker.css';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: '1.5rem',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    float: 'right',
  },
  choices: {
    marginTop: 30,
  },
  title: {
    margin: '30px 0',
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  otherField: {
    marginTop: 40,
  },
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
  },
});

const customOhipNumberMask = {
  9: {
    regExp: /[0-9]/,
  },
  A: {
    regExp: /[A-Za-z]/,
    transform: (char) => char.toUpperCase(),
  },
};

const ohipNumberMask = createTextMask({
  pattern: '9999-999-999-AA',
  guide: true,
  allowEmpty: false,
  stripMask: false,
  maskDefinitions: customOhipNumberMask,
});

const isDateSupported = () => {
  let i = document.createElement('input');
  i.setAttribute('type', 'date');
  return i.type !== 'text';
};

const SafariDatePicker = ({ id, input, meta: { touched, error } }) => {
  const [startDate, setStartDate] = useState();
  const CustomInput = ({ value, onClick }) => {
    return (
      <TextField
        value={value}
        onClick={onClick}
        //{...input}
        fullWidth
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="yyyy-mm-dd"
        error={touched && error}
        helperText={touched && error}
      />
    );
  };
  return (
    <div>
      <DatePicker
        {...input}
        name={id}
        customInput={<CustomInput />}
        dateFormat="yyyy-MM-dd"
        selected={startDate}
        showYearDropdown
        showMonthDropdown
        onChange={(date) => {
          setStartDate(date);
          const newDate = date.toLocaleDateString('en-CA');
          input.onChange(newDate);
        }}
      />
    </div>
  );
};

const OhipNumberField = ({ input, name, type, meta: { touched, error } }) => (
  <TextField
    {...input}
    fullWidth
    variant="outlined"
    type={type}
    id={name}
    error={touched && error}
    helperText={(touched && error) || '####-###-###-AZ'}
  />
);

const OhipExpirationDate = ({ input, name, meta: { touched, error } }) => (
  <TextField
    {...input}
    fullWidth
    type="date"
    variant="outlined"
    error={touched && error}
    helperText={(touched && error) || 'YYYY-MM-DD'}
  />
);

const ImageError = ({ name, meta: { touched, error } }) => (
  <Typography variant="caption" color="error">
    {error}
  </Typography>
);

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

class EditOhipCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteThumbnail: false,
      redOhipCardDialog: false,
      ohipReminder: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      document.querySelector('#toolbar-section').scrollIntoView({
        behavior: 'smooth',
      });
    }, 500);
  }

  renderOhipNumber = () => (
    <Field name="ohipNumber" component={OhipNumberField} {...ohipNumberMask} />
  );

  renderOhipExpirationDate = (isDateSupported) => {
    return (
      <Field
        name="ohipExpiration"
        id="ohipExpiration"
        component={
          isDateSupported ? OhipExpirationDate : SafariDatePicker
        } /*{...ohipExpirationMask}*/
      />
    );
  };

  render() {
    const { classes, selectedUserMedical, selectedUser, pristine, invalid } =
      this.props;
    const { deleteThumbnail } = this.state;

    return (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <FormSection name="medicalProfile">
              <Grid container direction="row">
                <Grid item className={classes.title}>
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Edit OHIP Card Information
                  </Typography>
                </Grid>
                <Grid item container spacing={1}>
                  <Grid item xs={12} sm={5}>
                    <img
                      src={
                        selectedUserMedical.ohipCardImage
                          ? `data:image/png;base64,${this.props.selectedUserMedical.ohipCardImage}`
                          : cardPlaceholder
                      }
                      width="100%"
                    />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Button
                      variant="outlined"
                      onClick={() => this.setState({ redOhipCardDialog: true })}
                      style={{
                        textTransform: 'none',
                        color: '#ef4242',
                        borderColor: '#ef4242',
                      }}
                    >
                      Click here if you have a Red & White Card
                    </Button>
                  </Grid>
                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        className={classes.typography}
                        variant="body1"
                      >
                        OHIP Number{' '}
                        <span
                          style={{
                            color: 'green',
                            fontSize: '13px',
                            margin: '4px',
                          }}
                        >
                          (Green Health card)
                        </span>
                      </Typography>
                      {this.renderOhipNumber()}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        className={classes.typography}
                        variant="body1"
                      >
                        OHIP Exp. Date
                      </Typography>
                      {this.renderOhipExpirationDate(isDateSupported())}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.typography}>
                      New picture of your OHIP card (front).
                    </Typography>
                    <br />
                    <div>
                      <FieldArray
                        name="files"
                        component={renderDropzoneInputOhip}
                        props={{ ondrop: this.onDrop, files: this.props.files }}
                        deleteThumbnail={deleteThumbnail}
                      />
                    </div>
                  </Grid>
                  {this.props.formMeta &&
                    this.props.formMeta.medicalProfile &&
                    ((this.props.formMeta.medicalProfile.ohipNumber &&
                      this.props.formMeta.medicalProfile.ohipNumber.touched) ||
                      (this.props.formMeta.medicalProfile.ohipExpiration &&
                        this.props.formMeta.medicalProfile.ohipExpiration
                          .touched)) && (
                      <Field name="image_error" component={ImageError} />
                    )}
                </Grid>
                <Grid
                  container
                  direction="row"
                  className={classes.bottomSpacing}
                >
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      className={classes.alignButton}
                      onClick={() => {
                        this.props.closeEditPage();
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="submit"
                      disabled={pristine || invalid}
                      variant="contained"
                      color="primary"
                      className={classes.alignButton}
                      style={{
                        float: 'right',
                      }}
                      onClick={() => {
                        setTimeout(() => {
                          this.setState({ deleteThumbnail: true });
                        }, 500);

                        setTimeout(() => {
                          this.setState({ deleteThumbnail: false });
                        }, 1000);
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </FormSection>
            <Dialog
              open={this.state.redOhipCardDialog}
              onClose={() => this.setState({ redOhipCardDialog: false })}
            >
              <DialogTitle>Red and White OHIP Card?</DialogTitle>
              <DialogContent>
                <Typography>
                  Please call us at <b>1-833-762-7333</b> for help adding the
                  card to your profile.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ redOhipCardDialog: false })}
                  variant="contained"
                  color="primary"
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={this.state.ohipReminder}
              onClose={() => {
                document.querySelector('#dashboard-section').scrollIntoView({
                  behavior: 'smooth',
                });
                this.setState({ ohipReminder: false });
                this.props.closeEditPage();
              }}
            >
              <DialogTitle>OHIP Card Information</DialogTitle>
              <DialogContent>
                <Typography>
                  Now that you have uploaded your OHIP card, you can start an
                  OHIP appointment by selecting the SEE DOCTOR button at the top
                  of your dashboard.
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    document
                      .querySelector('#dashboard-section')
                      .scrollIntoView({
                        behavior: 'smooth',
                      });
                    this.props.closeEditPage();
                    this.setState({ ohipReminder: false });
                  }}
                  variant="contained"
                  color="primary"
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </main>
        </Slide>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = { medicalProfile: {} };
  const today = new Date();

  if (values.medicalProfile) {
    if (
      !values.medicalProfile.files ||
      (values.medicalProfile.files && values.medicalProfile.files.length === 0)
    ) {
      errors.medicalProfile.image_error =
        'Please upload a picture of your health card';
    }

    if (!values.medicalProfile.ohipNumber) {
      errors.medicalProfile.ohipNumber = 'Please enter a valid ohip number';
    }

    if (!values.medicalProfile.ohipExpiration) {
      errors.medicalProfile.ohipExpiration = 'Please enter a valid date';
    }

    if (
      values.medicalProfile.ohipNumber &&
      values.medicalProfile.ohipNumber.replace(/[^a-zA-Z0-9]/g, '').length !==
        12
    ) {
      errors.medicalProfile.ohipNumber = 'Please enter a valid ohip number';
    }

    if (
      values.medicalProfile.ohipExpiration &&
      values.medicalProfile.ohipExpiration.replace(/\D+/g, '').length !== 8
    ) {
      const expirationError = () => {
        return (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="caption">
                Please enter a valid date.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">YYYY-MM-DD</Typography>
            </Grid>
          </Grid>
        );
      };
      errors.medicalProfile.ohipExpiration = expirationError();
    }
    // if (values.medicalProfile.ohipExpiration) {
    //   const expirationDate = new Date(
    //     values.medicalProfile.ohipExpiration.replace(/-/g, '/')
    //   );
    //   const day = today.getDate();
    //   const month =
    //     today.getMonth() + 1 < 10
    //       ? `0${today.getMonth() + 1}`
    //       : today.getMonth() + 1;
    //   const year = today.getFullYear();
    //   const dateString = `${year}-${month}-${day}`;

    //   if (
    //     expirationDate < today &&
    //     values.medicalProfile.ohipExpiration !== dateString
    //   ) {
    //     errors.medicalProfile.ohipExpiration = 'Card expired';
    //   }
    // }
  }
  return errors;
}

function mapStateToProps(state) {
  return {
    form: state.form.dashboardUpdateForm,
    auth: state.auth,
    selectedUser: state.selectedUser,
    selectedUserMedical: state.selectedUserMedical,
    formMeta: getFormMeta('dashboardUpdateForm')(state),
  };
}

EditOhipCard = connect(mapStateToProps, actions)(EditOhipCard);

EditOhipCard = reduxForm({
  validate,
  form: 'dashboardUpdateForm',
  destroyOnUnmount: false,
  // forceUnregisterOnUnmount: true // <------ unregister fields on unmount
  destroyOnUnmount: true,
})(EditOhipCard);

export default withStyles(styles)(EditOhipCard);
