import { SELECTED_USER_MEDICAL } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case SELECTED_USER_MEDICAL:
      return action.payload || state;
    default:
      return state;
  }
}
