import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import * as actions from '../../../actions';
import { Elements, StripeProvider } from 'react-stripe-elements';
import {
  Grid,
  Typography,
  Button,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { Edit, ExpandMore, CreditCard } from '@material-ui/icons';
import PharmacyCreditCardForm from '../payment/PharmacyCreditCardForm';
import PharmacyChangeCardForm from '../payment/PharmacyChangeCardForm';
import visaLogo from '../../../images/visa_logo.png';
import mastercardLogo from '../../../images/mc_logo.png';
import amexLogo from '../../../images/amex_logo.png';
import discoverLogo from '../../../images/discover_logo.png';

const styles = (theme) => ({
  // layout: {
  //   overflow: 'hidden',
  //   marginLeft: theme.spacing.unit,
  //   marginRight: theme.spacing.unit,
  //   [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
  //     marginLeft: theme.spacing.unit * 6,
  //     marginRight: theme.spacing.unit * 6
  //   },
  //   maxWidth: 850
  // },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  labelText: {
    color: theme.palette.text.secondary,
  },
  cardInfo: {
    marginTop: '20px',
  },
});

class PharmacyPaymentHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: true,
      showCardForm: false,
      showChangeForm: false,
    };
  }

  async componentDidMount() {
    if (!this.props.auth.stripeCustomerId) {
      this.setState({ showCardForm: true, showInfo: false });
    }
  }

  renderPayments() {
    const { classes } = this.props;
    const payments = this.props.pharmacyPayments;
    if (payments.length > 0) {
      return payments.map((payment) => {
        const paymentDate = new Date(payment.created * 1000).toLocaleDateString(
          'en-CA'
        );
        // Something has changed within the stripe response;
        // we are not able to expand the 'invoice' data so we don't get the right values to populate.
        // const invoiceDate = new Date(payment.invoice.created * 1000).toLocaleDateString('en-CA');
        const currency = payment.currency.toUpperCase();
        const amount = payment.amount / 100;
        // const periodStart = new Date(payment.invoice.lines.data[0].period.start * 1000).toLocaleDateString('en-CA', { month: 'short', day: 'numeric' });
        // const periodEnd = new Date(payment.invoice.lines.data[0].period.end * 1000).toLocaleDateString('en-CA', { month: 'short', day: 'numeric' });
        const card = payment.charges.data[0].payment_method_details.card;
        return (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Grid item xs={4}>
                <Typography className={classes.accordionHeading}>
                  {paymentDate}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.accordionHeading}>
                  Card ending in •••• {card.last4}
                </Typography>
              </Grid>
              <Grid item xs={4} container justify="flex-end">
                <Typography className={classes.accordionHeading}>
                  {currency} {amount}
                </Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="body" className={classes.labelText}>
                    Invoice No
                  </Typography>
                  <Typography color="primary">
                    {/* {payment.invoice.number} */}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body" className={classes.labelText}>
                    Invoice Date
                  </Typography>
                  {/* <Typography color="primary">{invoiceDate}</Typography> */}
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body" className={classes.labelText}>
                    Subscription Period
                  </Typography>
                  {/* <Typography color="primary">
                     {periodStart} to {periodEnd} 
                  </Typography> */}
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body" className={classes.labelText}>
                    Description
                  </Typography>
                  <Typography color="primary">{payment.description}</Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      });
    } else {
      return (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{
            color: '#bcbcbc',
            height: '30vh',
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              No payments yet.
            </Typography>
          </Grid>
        </Grid>
      );
    }
  }

  render() {
    const { classes, auth } = this.props;
    const { showCardForm, showChangeForm, showInfo } = this.state;
    const card = this.props.creditCardList
      ? this.props.creditCardList[0].card
      : null;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary">
            Payment
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {showCardForm && (
            <Grid item xs={12}>
              <Elements>
                <PharmacyCreditCardForm
                  closeForm={() =>
                    this.setState({ showCardForm: false, showInfo: true })
                  }
                />
              </Elements>
            </Grid>
          )}
          {showChangeForm && (
            <Grid item xs={12}>
              <Elements>
                <PharmacyChangeCardForm
                  closeForm={() =>
                    this.setState({ showChangeForm: false, showInfo: true })
                  }
                  oldCardId={this.props.creditCardList[0].id}
                />
              </Elements>
            </Grid>
          )}
          {card && showInfo && (
            <Grid
              item
              xs={12}
              container
              alignItems="center"
              className={classes.cardInfo}
            >
              <Grid item xs={12} style={{ marginBottom: '15px' }}>
                <Typography align="center" variant="h6" color="primary">
                  Credit Card
                </Typography>
              </Grid>
              <Grid item container alignItems="center" justify="center" xs={2}>
                {card.brand === 'discover' && (
                  <img src={discoverLogo} alt="Amex Logo" width="30px" />
                )}
                {card.brand === 'amex' && (
                  <img src={amexLogo} alt="Amex Logo" width="30px" />
                )}
                {card.brand === 'visa' && (
                  <img src={visaLogo} alt="Visa Logo" width="30px" />
                )}
                {card.brand === 'mastercard' && (
                  <img
                    src={mastercardLogo}
                    alt="Mastercard Logo"
                    width="30px"
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography>Card ending in •••• {card.last4}</Typography>
              </Grid>
              <Grid item xs={4} container justify="center">
                <Button
                  startIcon={<Edit />}
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    this.setState({ showChangeForm: true, showInfo: false })
                  }
                >
                  Change
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={6} className={classes.cardInfo}>
          <Grid item xs={12}>
            <Typography align="center" variant="h6" color="primary">
              Payment History
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {this.renderPayments()}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    creditCardList: state.creditCardList,
    pharmacyPayments: state.pharmacyPayments,
  };
}

PharmacyPaymentHistory = connect(
  mapStateToProps,
  actions
)(withRouter(PharmacyPaymentHistory));

export default withStyles(styles)(PharmacyPaymentHistory);
