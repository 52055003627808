import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
  clearFields,
  Field,
  formValues,
  FieldArray,
  change,
} from 'redux-form';
import {
  Button,
  CssBaseline,
  FormControl,
  MenuItem,
  Select,
  Paper,
  Typography,
  TextField,
  Grid,
  FormControlLabel,
  InputLabel,
  Divider,
  OutlinedInput,
  FormHelperText,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from '@material-ui/core/styles/withStyles';
import { createTextMask } from 'redux-form-input-masks';
import countryRegionFields from '../dashboard/CountryRegionFields';
import { Prompt } from 'react-router';
import { BorderBottom } from '@material-ui/icons';
import DataUseConsentText from './DataUseConsentText';
import ReactFlagsSelect from 'react-flags-select';
import snapmedLogo from '../SnapMedLogoRetina';
import DatePicker from 'react-datepicker';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputMask from 'react-input-mask';

import renderDropzoneInputOhip from '../commonform/FileInputOhip';
import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/safariDatePicker.css';

const customPhoneMask = {
  9: {
    regExp: /[0-9]/,
  },
};

const phoneMask = createTextMask({
  pattern: '(999) 999-9999',
  guide: false,
  allowEmpty: true,
  stripMask: false,
  maskDefinitions: customPhoneMask,
});

const customPostalCodeMaskDefinitions = {
  9: {
    regExp: /[0-9]/,
  },
  A: {
    regExp: /[A-Za-z]/,
    transform: (char) => char.toUpperCase(),
  },
};

const postalCodeMask = createTextMask({
  pattern: 'A9A 9A9',
  guide: false,
  stripMask: false,
  maskDefinitions: customPostalCodeMaskDefinitions,
});

const styles = (theme) => ({
  layout: {
    //  width: 'auto',
    //  height: '100%',
    //  display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 700,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${
      theme.spacing.unit * 5
    }px`,
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    float: 'right',
    marginTop: 40,
  },

  typography: {
    marginTop: 15,
  },
  menuFlagsContainer: {
    maxWidth: '96px',
    height: '100%',
    paddingBottom: 0,
  },
  menuFlagsButton: {
    height: '56px',
  },
  menuPaper: { maxHeight: '400px' },
});

const postalCodeRegExp = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
const zipCodeRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;

const customDateMask = {
  9: {
    regExp: /[0-9]/,
  },
};

const dateMask = createTextMask({
  pattern: '9999-99-99',
  guide: true,
  allowEmpty: false,
  stripMask: false,
  maskDefinitions: customDateMask,
});

const ProfileField = ({
  input,
  name,
  type,
  meta: { touched, error },
  placeholder,
  disabled,
}) => (
  <TextField
    {...input}
    //required
    type={type}
    id={name}
    fullWidth
    placeholder={placeholder}
    disabled={disabled}
    error={touched && error}
    helperText={touched && error}
    variant="outlined"
  />
);

const DateField = ({ input, name, type, meta: { touched, error } }) => (
  <TextField
    {...input}
    //required
    type={type}
    id={name}
    fullWidth
    error={touched && error}
    helperText={touched && error}
    variant="outlined"
  />
);

const isDateSupported = () => {
  let i = document.createElement('input');
  i.setAttribute('type', 'date');
  return i.type !== 'text';
};

const SafariDatePicker = ({ id, input, meta: { touched, error } }) => {
  const [startDate, setStartDate] = useState();
  const CustomInput = ({ value, onClick }) => {
    return (
      <TextField
        value={value}
        onClick={onClick}
        //{...input}
        fullWidth
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="yyyy-mm-dd"
        error={touched && error}
        helperText={touched && error}
      />
    );
  };
  return (
    <div>
      <DatePicker
        {...input}
        name={id}
        customInput={<CustomInput />}
        dateFormat="yyyy-MM-dd"
        selected={startDate}
        showYearDropdown
        showMonthDropdown
        onChange={(date) => {
          setStartDate(date);
          const newDate = date.toLocaleDateString('en-CA');
          input.onChange(newDate);
        }}
      />
    </div>
  );
};

const CheckboxField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <div>
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            {...input}
            id={name}
            color="primary"
            checked={input.value ? label : false}
          />
        }
        label={label}
      />
    </FormControl>
  </div>
);

const FamilyAccountSelectField = ({
  name,
  input,
  label,
  type,
  familyMembers,
  meta: { touched, error },
}) => {
  let memberArray = [];
  for (let i = 1; i <= familyMembers; i++) {
    memberArray[i] = i;
  }
  return (
    <FormControl fullWidth variant="outlined" margin="normal">
      <InputLabel htmlFor={name}>How many family members?</InputLabel>
      <Select
        {...input}
        id={name}
        input={<OutlinedInput id={name} label={'How many family members?'} />}
      >
        {' '}
        <MenuItem value="">None</MenuItem>
        {memberArray.map((member) => (
          <MenuItem value={member}>{member}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const userGender = [
  'Male',
  'Female',
  'Transgender Male / FTM',
  'Transgender Female / MTF',
  'Non-binary / Other',
  'Rather not say',
];
const userSex = ['Male', 'Female'];

const phoneRegex = /1[0-9]+/g;

// const GenderField = ({
//   name,
//   input,
//   label,
//   type,
//   meta: { touched, error },
// }) => (
//   <FormControl variant="outlined" fullWidth>
//     <Select {...input} error={touched && error} helperText={touched && error}>
//       {gender.map((gend) => (
//         <MenuItem key={gend} value={gend}>
//           {gend}
//         </MenuItem>
//       ))}
//     </Select>
//     {touched && error ? (
//       <FormHelperText>
//         <span style={{ color: '#f44336' }}>{error}</span>
//       </FormHelperText>
//     ) : null}
//   </FormControl>
// );

const GenderField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <FormControl variant="outlined" fullWidth>
    <Autocomplete
      {...input}
      id="genderFieldAutocomplete"
      options={userGender}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" error={touched && error} />
      )}
    />
    {touched && error ? (
      <FormHelperText>
        <span style={{ color: '#f44336' }}>{error}</span>
      </FormHelperText>
    ) : null}
  </FormControl>
);

const SexField = ({ name, input, label, type, meta: { touched, error } }) => (
  <FormControl variant="outlined" fullWidth>
    <Select {...input} error={touched && error} helperText={touched && error}>
      {userSex.map((sex) => (
        <MenuItem key={sex} value={sex}>
          {sex}
        </MenuItem>
      ))}
    </Select>
    {touched && error ? (
      <FormHelperText>
        <span style={{ color: '#f44336' }}>{error}</span>
      </FormHelperText>
    ) : null}
  </FormControl>
);

const language = [
  'English',
  // ' Mandarin',
  // ' Spanish',
  // ' Italian',
  // ' Hindi',
  // ' Arabic',
  // ' Portuguese',
  // ' Bengali',
  // ' Russian',
  // ' Japanese',
  // ' Punjabi',
  // ' German',
  // ' French',
  // ' Korean' //uncomment for future use
];

const OhipNumberField = ({ input, name, type, meta: { touched, error } }) => (
  <TextField
    {...input}
    fullWidth
    variant="outlined"
    type={type}
    id={name}
    error={touched && error}
    helperText={(touched && error) || '####-###-###-AZ'}
  />
);

const customOhipNumberMask = {
  9: {
    regExp: /[0-9]/,
  },
  A: {
    regExp: /[A-Za-z]/,
    transform: (char) => char.toUpperCase(),
  },
};

const ohipNumberMask = createTextMask({
  pattern: '9999-999-999-AA',
  guide: false,
  allowEmpty: false,
  stripMask: false,
  maskDefinitions: customOhipNumberMask,
});

const OhipExpirationDate = ({ input, name, meta: { touched, error } }) => (
  <TextField
    {...input}
    fullWidth
    type="date"
    variant="outlined"
    error={touched && error}
    helperText={(touched && error) || 'YYYY-MM-DD'}
  />
);

const LanguageField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <FormControl variant="outlined" fullWidth>
    <Select
      {...input}
      //multiple //uncomment for future use
      error={touched && error}
      helperText={touched && error}
    >
      {language.map((lang) => (
        <MenuItem value={lang}>{lang}</MenuItem>
      ))}
    </Select>
    {touched && error ? (
      <FormHelperText>
        <span style={{ color: '#f44336' }}>{error}</span>
      </FormHelperText>
    ) : null}
  </FormControl>
);

const phoneLabels = {
  GB: {
    primary: 'GB',
    secondary: '+44',
  },
  US: {
    primary: 'US',
    secondary: '+1',
  },
  CA: {
    primary: 'CA',
    secondary: '+1',
  },
  AU: {
    primary: 'AU',
    secondary: '+61',
  },
  LK: {
    primary: 'LK',
    secondary: '+94',
  },
  IN: {
    primary: 'IN',
    secondary: '+91',
  },
  FR: {
    primary: 'FR',
    secondary: '+33',
  },
};

const PhoneNumberField = ({
  input,
  name,
  placeholder,
  meta: { touched, error },
}) => {
  return (
    <InputMask mask="(999) 999-9999" {...input} onChange={input.onChange}>
      {(inputProps) => (
        <TextField
          {...inputProps}
          fullWidth
          name={name}
          variant="outlined"
          placeholder={placeholder}
          error={touched && error}
          helperText={touched && error}
        />
      )}
    </InputMask>
  );
};

const PhoneNumberFieldNoMask = ({
  input,
  name,
  placeholder,
  meta: { touched, error },
}) => (
  <TextField
    {...input}
    fullWidth
    name={name}
    variant="outlined"
    placeholder={placeholder}
    error={touched && error}
    helperText={touched && error}
    // inputProps={{
    //   placeholder: placeholder
    // }}
  />
);

const provinces = [
  'Ontario',
  'Quebec',
  'British Columbia',
  'AlbertmaxNumba',
  'Saskatchewan',
  'Nova Scotia',
  'New Brunswick',
  'Manitoba',
  'Prince Edward Island',
  'Newfoundland And Labrador',
  'Yukon',
  'Nunavut',
  'Northwest Territories',
];

const DataUseConsent = ({ input, disabled }) => (
  <FormControl>
    <FormControlLabel
      label="I Have Read and I Agree"
      labelPlacement="end"
      disabled={disabled}
      control={
        <Checkbox
          {...input}
          name="data_use_consent"
          color="primary"
          checked={input.value}
        />
      }
    />
  </FormControl>
);

class ProfileForm extends Component {
  constructor(props) {
    super(props);

    const { isFamilyAccount, numberOfFamilyMembersSelected } = this.props;
    this.state = {
      country: false,
      smartFamilyPlan: this.props.smartFamilyPlan,
      minimumMembers: 1,
      familyMembers: ['false'],
      maxNumberOfFamilyMembers: 0,
      dataUseConsent: false,
      mailingListConsent: false,
      disableDataUseConsent: true,
      regionsForCountry: [],
      redOhipCardDialog: false,
    };
    var previousNumberOfFamilyMembers = 0;
  }

  componentDidMount() {
    if (this.props.dataUseConsent === true) {
      this.setState({ disableDataUseConsent: false });
    }
    if (this.props.country) {
      countryRegionFields.map((obj) => {
        if (obj['countryName'] === this.props.country) {
          this.setState({
            regionsForCountry: obj['regions'],
          });
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.smartFamilyPlan != this.props.smartFamilyPlan &&
      this.props.smartFamilyPlan
    ) {
      this.setState({ maxNumberOfFamilyMembers: this.props.smartFamilyPlan });
    }
    if (
      this.props !== prevProps &&
      this.props.form &&
      this.props.form.profileForm &&
      this.props.form.profileForm.values &&
      this.props.form.profileForm.values.data_use_consent === true
    ) {
      this.setState({
        dataUseConsent: this.props.form.profileForm.values.data_use_consent,
      });
    } else if (
      this.props !== prevProps &&
      this.props.form &&
      this.props.form.profileForm &&
      this.props.form.profileForm.values &&
      this.props.form.profileForm.values.data_use_consent === false
    ) {
      this.setState({
        dataUseConsent: this.props.form.profileForm.values.data_use_consent,
      });
    }
  }

  addFamilyMemberFields() {
    var familyMemberInputFields = [];

    //gets rid of values
    for (var i = 0; i < this.previousNumberOfFamilyMembers; i++) {
      if (i >= this.props.numberOfFamilyMembersSelected) {
        this.props.dispatch(
          clearFields('profileForm', false, false, 'first_name_' + i)
        );
        this.props.dispatch(
          clearFields('profileForm', false, false, 'last_name_' + i)
        );
        this.props.dispatch(
          clearFields('profileForm', false, false, 'date_of_birth_' + i)
        );
        this.props.dispatch(
          clearFields('profileForm', false, false, 'gender_' + i)
        );
      }
    }

    this.previousNumberOfFamilyMembers =
      this.props.numberOfFamilyMembersSelected;

    for (var i = 1; i <= this.props.numberOfFamilyMembersSelected; i++) {
      familyMemberInputFields.push(this.addOneFamilyMember(i));
    }

    return familyMemberInputFields;
  }

  addOneFamilyMember(i) {
    const { classes } = this.props;
    return (
      <Fragment>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary">
              Family Member {i}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.typography} variant="body1">
              First Name
            </Typography>
            {this.renderFields('family_member_first_name_' + i, 'text')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.typography} variant="body1">
              Last Name
            </Typography>
            {this.renderFields('family_member_last_name_' + i, 'text')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.typography} variant="body1">
              Date of Birth
            </Typography>
            {this.renderDateField(
              'family_member_date_of_birth_' + i,
              'date',
              isDateSupported()
            )}
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginBottom: '10px' }}>
            <Typography className={classes.typography} variant="body1">
              Sex Assigned at Birth
            </Typography>
            {this.renderSexField('family_member_sex_' + i, 'input')}
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginBottom: '10px' }}>
            <Typography className={classes.typography} variant="body1">
              Gender Identity
            </Typography>
            {this.renderGenderField('family_member_gender_' + i, 'input')}
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  clearFamilyFields() {
    for (var i = 0; i < 8; i++) {
      this.props.dispatch(
        clearFields(
          'profileForm',
          false,
          false,
          'family_member_first_name_' + i
        )
      );

      this.props.dispatch(
        clearFields('profileForm', false, false, 'family_member_last_name_' + i)
      );
      this.props.dispatch(
        clearFields(
          'profileForm',
          false,
          false,
          'family_member_date_of_birth_' + i
        )
      );
      this.props.dispatch(
        clearFields('profileForm', false, false, 'family_member_gender_' + i)
      );
    }
    this.props.dispatch(
      clearFields('profileForm', false, false, 'number_of_family_members')
    );
    this.props.dispatch(
      clearFields(
        'profileForm',
        false,
        false,
        'is_this_family_account_checkbox'
      )
    );
  }

  renderLanguageField() {
    return (
      <Field
        name="spoken_languages"
        type="text"
        multiple
        format={
          (value) =>
            value ? value : '' /*(Array.isArray(value) ? value : [])*/
        }
        onBlur={null}
        component={LanguageField}
      />
    );
  }

  renderGenderField(name, type) {
    return (
      <Field
        name={name}
        type={type}
        // placeholder={placeholder}
        multiple
        format={(value) => (value ? value : '')}
        onBlur={null}
        component={GenderField}
      />
    );
  }

  renderSexField(name, type) {
    return (
      <Field
        name={name}
        type={type}
        // placeholder={placeholder}
        multiple
        format={(value) => (value ? value : '')}
        onBlur={null}
        component={SexField}
      />
    );
  }

  renderOhipNumber = () => (
    <Field name="ohipNumber" component={OhipNumberField} {...ohipNumberMask} />
  );

  renderOhipExpirationDate = (isDateSupported) => {
    return (
      <Field
        name="ohipExpiration"
        id="ohipExpiration"
        component={
          isDateSupported ? OhipExpirationDate : SafariDatePicker
        } /*{...ohipExpirationMask}*/
      />
    );
  };

  renderFields(name, type) {
    return <Field name={name} type={type} component={ProfileField} />;
  }

  renderPostalCodeFields(name, type) {
    if (this.state.country === 'Canada') {
      return (
        <Field
          name={name}
          type={type}
          component={ProfileField}
          {...postalCodeMask}
        />
      );
    } else {
      return <Field name={name} type={type} component={ProfileField} />;
    }
  }

  menuFlags = () => {
    const [selected, setSelected] = useState('CA');
    const { classes, form } = this.props;
    return (
      <ReactFlagsSelect
        selected={selected}
        onSelect={(code) => {
          setSelected(code);
          switch (code) {
            case 'US':
              this.props.dispatch(
                change(
                  'profileForm',
                  'country_code',
                  `${phoneLabels.US.secondary}`
                )
              );
              break;
            case 'CA':
              this.props.dispatch(
                change(
                  'profileForm',
                  'country_code',
                  `${phoneLabels.CA.secondary}`
                )
              );
              break;
            case 'AU':
              this.props.dispatch(
                change(
                  'profileForm',
                  'country_code',
                  `${phoneLabels.AU.secondary}`
                )
              );
              break;
            case 'LK':
              this.props.dispatch(
                change(
                  'profileForm',
                  'country_code',
                  `${phoneLabels.LK.secondary}`
                )
              );
              break;
            case 'IN':
              this.props.dispatch(
                change(
                  'profileForm',
                  'country_code',
                  `${phoneLabels.IN.secondary}`
                )
              );
              break;
            case 'FR':
              this.props.dispatch(
                change(
                  'profileForm',
                  'country_code',
                  `${phoneLabels.FR.secondary}`
                )
              );
              break;
          }
        }}
        showSelectedLabel={false}
        showOptionLabel={false}
        selectedSize={14}
        optionsSize={14}
        className={classes.menuFlagsContainer}
        selectButtonClassName={classes.menuFlagsButton}
        countries={['CA', 'US', 'AU', 'LK', 'IN', 'FR']}
        customLabels={phoneLabels}
      />
    );
  };

  renderPhoneFields(name, type) {
    return (
      <Grid item container>
        <Grid item xs={4}>
          <Field name="country_code" component={this.menuFlags} />
        </Grid>
        {this.props.form.profileForm.values &&
        this.props.form.profileForm.values.country_code === '+1' ? (
          <Grid item xs={8}>
            <Field name={name} type={type} component={PhoneNumberField} />
          </Grid>
        ) : (
          <Grid item xs={8}>
            <Field name={name} type={type} component={PhoneNumberFieldNoMask} />
          </Grid>
        )}
      </Grid>
    );
  }

  ProvinceField = ({
    name,
    regionsForCountry,
    input,
    label,
    type,
    meta: { touched, error },
  }) => (
    <FormControl variant="outlined" fullWidth>
      <Select {...input} error={touched && error} helperText={touched && error}>
        {regionsForCountry.length === 0 && (
          <MenuItem value="">Please choose a country</MenuItem>
        )}
        {regionsForCountry.map((obj) => (
          <MenuItem value={obj['name']}>{obj['name']}</MenuItem>
        ))}
      </Select>
      {touched && error ? (
        <FormHelperText>
          <span style={{ color: '#f44336' }}>{error}</span>
        </FormHelperText>
      ) : null}
    </FormControl>
  );

  renderProvinceFields = (name, type) => {
    return (
      <Field
        name={name}
        type={type}
        format={(value) => (value ? value : [])}
        regionsForCountry={this.state.regionsForCountry}
        component={this.ProvinceField}
      />
    );
  };

  CountryField = ({ name, input, label, type, meta: { touched, error } }) => {
    const { classes } = this.props;
    return (
      <FormControl variant="outlined" fullWidth>
        <Select
          {...input}
          error={touched && error}
          helperText={touched && error}
          MenuProps={{
            classes: { paper: classes.menuPaper },
          }}
        >
          {countryRegionFields.map((lang) => (
            <MenuItem value={lang['countryName']}>
              {lang['countryName']}
            </MenuItem>
          ))}
        </Select>
        {touched && error ? (
          <FormHelperText>
            <span style={{ color: '#f44336' }}>{error}</span>
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  };
  renderCountryFields(name, type) {
    return (
      <Field
        name={name}
        type={type}
        format={(value) => (value ? value : [])}
        component={this.CountryField}
        onChange={(event) => {
          this.setState({
            country: event.target.value,
          });
          countryRegionFields.map((obj) => {
            if (obj['countryName'] === event.target.value) {
              this.setState({
                regionsForCountry: obj['regions'],
              });
            }
          });
        }}
      />
    );
  }

  renderDateField(name, type, isDateSupported) {
    return (
      <Field
        name={name}
        type={type}
        component={
          isDateSupported ? DateField : SafariDatePicker
        } /*{...dateMask}*/
      />
    );
  }

  renderCheckbox(id, name, type, label) {
    return (
      <Field
        id={id}
        name={name}
        type={type}
        label={label}
        parse={(value) => (value ? label : false)}
        component={CheckboxField}
      />
    );
  }

  renderFamilySelect(id, name, type, familyMembers) {
    return (
      <Field
        id={id}
        name={name}
        type={type}
        familyMembers={familyMembers}
        component={FamilyAccountSelectField}
      />
    );
  }

  MailingListConsent = ({ input }) => (
    <FormControl>
      <FormControlLabel
        label={
          <Typography variant="body2">
            I wish to receive customized messages from SnapMed including
            announcements and promotions.
          </Typography>
        }
        labelPlacement="end"
        control={
          <Checkbox
            {...input}
            //id={name}
            color="primary"
            checked={input.value ? true : false}
            //onChange={() => this.setState({ mailingListConsent: !this.state.mailingListConsent })}
          />
        }
      />
    </FormControl>
  );

  scrollCheck = (event) => {
    const bottom =
      Math.floor(event.target.scrollHeight - event.target.scrollTop - 300) <=
      event.target.clientHeight;
    if (bottom) {
      this.setState({ disableDataUseConsent: false });
    }
  };

  renderDataUseConsent() {
    return (
      <Grid item xs={12} container onScroll={this.scrollCheck}>
        <DataUseConsentText />

        <Field
          name="data_use_consent"
          disabled={this.state.disableDataUseConsent}
          component={DataUseConsent}
        />
        <Field
          name="mailing_list_consent"
          component={this.MailingListConsent}
        />
      </Grid>
    );
  }

  render() {
    const { handleSubmit, isFamilyAccount, wizard, classes, smartFamilyPlan } =
      this.props;

    if (this.props.isFamilyAccount === false) {
      this.clearFamilyFields();
    }

    // let arrayLength = this.state.maxNumberOfFamilyMembers + 1;
    // let familyArray = this.state.maxNumberOfFamilyMembers
    //   ? Array(arrayLength)
    //   : this.state.familyMembers;

    // let i;
    // if (this.state.maxNumberOfFamilyMembers) {
    //   for (i = 0; i < familyArray.length; i++) {
    //     familyArray[i] = i;
    //   }
    // }

    // if (this.state.maxNumberOfFamilyMembers) {
    //   familyArray[0] = 'false';
    // } else {
    //   familyArray = this.state.familyMembers;
    // }

    return (
      <Fragment>
        {/* <Prompt
          when={true}
          message="This will bring you to the previous page and you will have to redo making this form. Are you sure you want to do this?"
        /> */}
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <form
              className={classes.form}
              onSubmit={handleSubmit(this.props.onProfileSubmit)}
            >
              <Grid container direction="row" spacing={2}>
                <Grid xs={12}>
                  <img
                    src={snapmedLogo}
                    style={{ width: 100, marginBottom: 10 }}
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '10px' }}>
                  <Typography variant="h5" color="primary">
                    Complete Your Profile
                  </Typography>
                </Grid>
                <Grid item xs={12} container direction="row" spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.typography} variant="body1">
                      First Name
                    </Typography>
                    {this.renderFields('first_name', 'text')}
                    <Typography variant="caption">
                      * Name should match government ID
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.typography} variant="body1">
                      Last Name
                    </Typography>
                    {this.renderFields('last_name', 'text')}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.typography} variant="body1">
                      Date of Birth
                    </Typography>
                    {this.renderDateField(
                      'date_of_birth',
                      'date',
                      isDateSupported()
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.typography} variant="body1">
                      Sex Assigned at Birth
                    </Typography>
                    {this.renderSexField('sex', 'text')}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.typography} variant="body1">
                      Gender Identity
                    </Typography>
                    {this.renderGenderField('gender', 'text')}
                  </Grid>
                  <Grid item container xs={12} alignItems="center">
                    <Grid item xs={10} sm={6}>
                      <Typography
                        className={classes.typography}
                        variant="button"
                        color="primary"
                      >
                        Do you have OHIP coverage?
                      </Typography>
                    </Grid>
                    <Grid item xs={2} sm={6}>
                      {this.renderCheckbox(
                        'ohip_coverage_checkbox_yes',
                        'ohip_coverage_checkbox',
                        'checkbox',
                        'Yes'
                      )}
                    </Grid>
                  </Grid>
                  <Collapse in={this.props.ohipCoverage}>
                    <Grid
                      item
                      container
                      spacing={1}
                      style={{
                        backgroundColor: 'aliceblue',
                        borderRadius: '10px',
                      }}
                    >
                      <Grid item xs={12} container justify="center">
                        <Button
                          variant="outlined"
                          onClick={() =>
                            this.setState({ redOhipCardDialog: true })
                          }
                          style={{
                            textTransform: 'none',
                            color: '#ef4242',
                            borderColor: '#ef4242',
                            backgroundColor: 'white',
                          }}
                        >
                          Click here if you have a Red & White Card
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          className={classes.typography}
                          variant="body1"
                        >
                          OHIP Number{' '}
                          <span
                            style={{
                              color: 'green',
                              fontSize: '13px',
                              margin: '4px',
                            }}
                          >
                            (Green Health card)
                          </span>
                        </Typography>
                        {this.renderOhipNumber()}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography
                          className={classes.typography}
                          variant="body1"
                        >
                          OHIP Exp. Date
                        </Typography>
                        {this.renderOhipExpirationDate(isDateSupported())}
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.typography}>
                          Picture of your OHIP card (front).
                        </Typography>
                        <div>
                          <FieldArray
                            name="files"
                            component={renderDropzoneInputOhip}
                            props={{
                              ondrop: this.onDrop,
                              files: this.props.files,
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Collapse>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.typography} variant="body1">
                      Spoken Languages
                    </Typography>
                    {this.renderLanguageField()}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.typography} variant="body1">
                      Mobile Phone Number
                    </Typography>
                    {this.renderPhoneFields('mobile_phone_number', 'text')}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.typography} variant="body1">
                      Street Address
                    </Typography>
                    {this.renderFields('address', 'text')}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.typography} variant="body1">
                      Country
                    </Typography>
                    {this.renderCountryFields('country', 'text')}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.typography} variant="body1">
                      City
                    </Typography>
                    {this.renderFields('city', 'text')}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.typography} variant="body1">
                      Province/Region
                    </Typography>
                    {this.renderProvinceFields('province', 'text')}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography className={classes.typography} variant="body1">
                      Postal Code
                    </Typography>
                    {this.renderPostalCodeFields('postal_zip_code', 'text')}
                  </Grid>
                  <Grid item xs={12} container direction="row">
                    <Grid item xs={12} container direction="row">
                      <Grid item container xs={12} alignItems="center">
                        <Grid item xs={10} sm={6}>
                          <Typography
                            className={classes.typography}
                            variant="button"
                            color="primary"
                          >
                            Is this a Family Account?
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sm={6}>
                          {this.renderCheckbox(
                            'is_this_family_account_checkbox_yes',
                            'is_this_family_account_checkbox',
                            'checkbox',
                            'Yes'
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          backgroundColor: 'aliceblue',
                          borderRadius: '10px',
                        }}
                      >
                        <Grid item xs={12} sm={6}>
                          {this.props.isFamilyAccount && (
                            <div>
                              {this.renderFamilySelect(
                                'number_of_family_members',
                                'number_of_family_members',
                                'select',
                                smartFamilyPlan
                              )}
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          {this.props.numberOfFamilyMembersSelected &&
                          this.props.isFamilyAccount ? (
                            <div>{this.addFamilyMemberFields()}</div>
                          ) : (
                            ''
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: '20px' }}>
                        {this.renderDataUseConsent()}
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.alignButton}
                          type="submit"
                          disabled={!this.state.dataUseConsent}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
          <Dialog
            open={this.state.redOhipCardDialog}
            onClose={() => this.setState({ redOhipCardDialog: false })}
          >
            <DialogTitle>Red and White OHIP Card?</DialogTitle>
            <DialogContent>
              <Typography>
                Please call us at <b>1-833-762-7333</b> for help adding the card
                to your profile.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.setState({ redOhipCardDialog: false })}
                variant="contained"
                color="primary"
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </main>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = {};

  const today = new Date();

  if (!values.first_name) {
    errors.first_name = 'Field required';
  }
  if (!values.last_name) {
    errors.last_name = 'Field required';
  }
  if (!values.date_of_birth) {
    errors.date_of_birth = 'Field required';
  }
  if (values.date_of_birth) {
    const dateValue = new Date(values.date_of_birth);
    const currentAge = Math.floor((today - dateValue.getTime()) / 3.15576e10);

    if (currentAge < 18) {
      errors.date_of_birth = 'You must be 18+ to register';
    }
    if (dateValue > today) {
      errors.date_of_birth = 'Invalid date';
    }
  }
  if (!values.gender) {
    errors.gender = 'Field required';
  }
  if (!values.sex) {
    errors.sex = 'Field required';
  }
  if (!values.spoken_languages) {
    errors.spoken_languages = 'Field required';
  }
  if (!values.address) {
    errors.address = 'Field required';
  }
  if (!values.city) {
    errors.city = 'Field required';
  }
  if (!values.province) {
    errors.province = 'Field required';
  }
  if (!values.country) {
    errors.country = 'Field required';
  }
  if (!values.postal_zip_code) {
    errors.postal_zip_code = 'Field required';
  }
  if (!values.mobile_phone_number) {
    errors.mobile_phone_number = 'Field required';
  }

  if (
    values.mobile_phone_number &&
    values.country_code === '+1' &&
    values.mobile_phone_number.replace(/\D+/g, '').length !== 10
  ) {
    errors.mobile_phone_number =
      'Please enter a valid North-American phone number';
  }

  if (values.is_this_family_account_checkbox) {
    if (values.number_of_family_members === 'false') {
      errors.number_of_family_members =
        'Please indicate the number of family members';
    } else {
      var numberAbove18 = 1;

      for (var i = 1; i <= values.number_of_family_members; i++) {
        if (!values['family_member_first_name_' + i]) {
          errors['family_member_first_name_' + i] = 'Field required';
        }
        if (!values['family_member_last_name_' + i]) {
          errors['family_member_last_name_' + i] = 'Field required';
        }
        if (!values['family_member_date_of_birth_' + i]) {
          errors['family_member_date_of_birth_' + i] = 'Field required';
        }
        if (
          values['family_member_gender_' + i] === 'false' ||
          !values['family_member_gender_' + i]
        ) {
          errors['family_member_gender_' + i] = 'Field required';
        }
        if (
          values['family_member_sex_' + i] === 'false' ||
          !values['family_member_sex_' + i]
        ) {
          errors['family_member_sex_' + i] = 'Field required';
        }

        var dateOfBirth = new Date(values['family_member_date_of_birth_' + i]);
        dateOfBirth = new Date(
          dateOfBirth.getTime() + dateOfBirth.getTimezoneOffset() * 60 * 1000
        );
        if (dateOfBirth > today) {
          errors['family_member_date_of_birth_' + i] = 'Invalid date';
        }
        if (today.getFullYear() - dateOfBirth.getFullYear() > 18) {
          numberAbove18++;
        } else if (today.getFullYear() - dateOfBirth.getFullYear() === 18) {
          if (today.getMonth() > dateOfBirth.getMonth()) {
            numberAbove18++;
          } else if (
            today.getMonth() === dateOfBirth.getMonth() &&
            today.getDate() >= dateOfBirth.getDate()
          ) {
            numberAbove18++;
          }
        }

        if (numberAbove18 > 2) {
          errors['family_member_date_of_birth_' + i] =
            'Only 2 adults per account are allowed.';
        }
      }
    }
  }

  if (
    values.ohip_coverage_checkbox &&
    values.ohipNumber &&
    values.ohipNumber.replace(/[^a-zA-Z0-9]/g, '').length !== 12
  ) {
    errors.ohipNumber = 'Please enter a valid ohip code';
  }

  if (
    values.ohip_coverage_checkbox &&
    values.ohipNumber &&
    (!values.files || values.files.length === 0)
  ) {
    errors.ohipNumber = 'Please upload a picture of your health card';
  }

  if (
    values.ohipExpiration &&
    values.ohipExpiration.replace(/\D+/g, '').length !== 8
  ) {
    const expirationError = () => {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="caption">
              Please enter a valid date.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">YYYY-MM-DD</Typography>
          </Grid>
        </Grid>
      );
    };
    errors.ohipExpiration = expirationError();
  }

  // if (values.ohipExpiration) {
  //   const expirationDate = new Date(values.ohipExpiration.replace(/-/g, '/'));
  //   const day = today.getDate();
  //   const month =
  //     today.getMonth() + 1 < 10
  //       ? `0${today.getMonth() + 1}`
  //       : today.getMonth() + 1;
  //   const year = today.getFullYear();
  //   const dateString = `${year}-${month}-${day}`;

  //   if (expirationDate < today && values.ohipExpiration !== dateString) {
  //     errors.ohipExpiration = 'Card expired';
  //   }
  // }
  /*  if (
  values.postal_zip_code &&
  (!/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(values.postal_zip_code) ||
        !/^[0-9]{5}(?:-[0-9]{4})?$/.test(values.postal_zip_code))
    ) {
            errors.postal_zip_code = 'Invalid Zip or Postal Code';
        } */

  return errors;
}
const selector = formValueSelector('profileForm');

function mapStateToProps(state) {
  return {
    form: state.form,
    auth: state.auth,
    smartFamilyPlan: state.smartFamilyPlan,
    ohipCoverage: selector(state, 'ohip_coverage_checkbox'),
    isFamilyAccount: selector(state, 'is_this_family_account_checkbox'),
    numberOfFamilyMembersSelected: selector(state, 'number_of_family_members'),
    country: selector(state, 'country'),
    dataUseConsent: selector(state, 'data_use_consent'),
  };
}

const mapDispatchToProps = (dispatch) => ({
  change,
  dispatch,
});

const ProfileFormRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileForm);

ProfileForm = reduxForm({
  validate,
  form: 'profileForm',
  destroyOnUnmount: false,
})(ProfileFormRedux);

export default withStyles(styles)(ProfileForm);
