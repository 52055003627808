// package imports
import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';

// component imports
import {
  CssBaseline,
  FormControl,
  InputLabel,
  Select,
  withStyles,
  Button,
  Grid,
  MenuItem,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Snackbar,
  CircularProgress,
  Backdrop,
  IconButton,
} from '@material-ui/core';

import { ArrowBackIos, Done, Delete } from '@material-ui/icons';

import { Autocomplete, Alert, AlertTitle } from '@material-ui/lab';
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from 'redux-form';

// src imports
import * as actions from '../../../actions';

// styling
const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 700,
    },
  },
  title: {
    marginTop: 15,
    marginBottom: 15,
  },
  codeSection: {
    marginTop: 15,
  },
});

// local components
const renderSelectField = ({
  input: { name },
  input,
  label,
  options,
  children,
  meta: { error, touched },
}) => {
  return (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel id={`${name}-label`} error={touched && error}>
        {label}
      </InputLabel>
      <Select
        {...input}
        label={label}
        labelId={`${name}-label`}
        id={`${name}-select`}
        error={touched && error}
        helperText={touched && error}
      >
        {options
          ? options.map((option) => (
              <MenuItem value={option}>{option}</MenuItem>
            ))
          : children}
      </Select>
      {touched && error && <FormHelperText error>*{error}</FormHelperText>}
    </FormControl>
  );
};

const renderCheckbox = ({
  input,
  label,
  disabled,
  meta: { error, touched },
}) => {
  return (
    <FormControl fullWidth={true}>
      <FormControlLabel
        label={<Typography variant="body2">{label}</Typography>}
        control={
          <Checkbox
            disabled={disabled}
            checked={input.value ? true : false}
            onChange={input.onChange}
            color="primary"
          />
        }
      />
      {touched && error && <FormHelperText error>*{error}</FormHelperText>}
    </FormControl>
  );
};

const renderAutocomplete = ({
  input,
  input: { onChange },
  label,
  options,
  meta: { error, touched },
}) => {
  return (
    <FormControl fullWidth>
      <Autocomplete
        freeSolo
        autoSelect
        id={input.name}
        value={input.value || []}
        options={options}
        onChange={(e, newValue) => {
          onChange(newValue);
        }}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            //{...input}
            size="small"
            label={label}
            error={touched && error}
            variant="outlined"
          />
        )}
      />
      {touched && error && <FormHelperText error>*{error}</FormHelperText>}
    </FormControl>
  );
};

// main component
class FinishAppointmentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      openBackdrop: false,
    };
  }

  handleClose = (_e, reason) => {
    // supress closing by clicking anywhere but close button
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ showError: false });
    setTimeout(this.props.clearSubmitErrors, 500);
  };

  removeMedication = (index) => {
    // update medication selection checking for consent form presence
    let hiddenFieldArray = this.props.medicationSelection;
    hiddenFieldArray.splice(index, 1);
    this.props.updateMedicationList(hiddenFieldArray);
    this.props.change('medications', hiddenFieldArray);
  };

  updatePrescription = async (values) => {
    const { selectedAppointment } = this.props;

    let body = {
      patientObject: selectedAppointment.patient,
      medications: values.medications,
      room: selectedAppointment._id,
      prescription_number: values.medications.length || 0,
      drug_interaction_existing_med_check: values.interaction,
      no_controlled_substance_check: values.narcotic,
      photo_id_validation_check: values.photoId,
      fax_prescription_to_pharmacy_check: values.faxRx,
      finalize_prescription: true,
    };

    if (values.medications.length > 0) {
      return await axios.post('/api/add_prescription', body);
    }
    return Promise.resolve('ok');
  };

  updateAppointment = async (values) => {
    const { selectedAppointment } = this.props;

    let body = {
      _id: selectedAppointment._id,
      appointmentCode: values.appointmentCode,
      diagnosticCode: values.diagnosticCode,
    };

    return await axios.post('/api/update_appointment', {
      ...body,
      backdrop: true, // enable backdrop in HTTP request
    });
  };

  finishAppointment = async (values) => {
    try {
      // wrapper for parallel async requests
      await Promise.all([
        this.updateAppointment(values),
        this.updatePrescription(values),
      ]);

      this.props.getDoctorAppointmentHistory();
      this.props.closePage();
    } catch (err) {
      // catch any errors from submitting data
      console.log('Error:', err);

      // re-format into error that redux-form expects
      throw new SubmissionError({
        _error: `${err.message || err}. Please try again.`,
      });
    }
  };

  render() {
    const {
      classes,
      error,
      showRx,
      medWithConsent,
      diagnosticCodeList,
      medicationSelection,
      handleSubmit,
      selectedAppointment,
    } = this.props;
    const { showError, openBackdrop } = this.state;

    return (
      <Fragment>
        <CssBaseline />
        <Snackbar
          open={showError && error}
          autoHideDuration={10000}
          onClose={this.handleClose}
        >
          <Alert severity="error" onClose={this.handleClose}>
            <AlertTitle>Submission Error</AlertTitle>
            {error}
          </Alert>
        </Snackbar>
        <Grid item xs={12} style={{ marginBottom: '10px' }}>
          {medicationSelection &&
            Array.isArray(medicationSelection) &&
            medicationSelection.length > 0 && (
              <Typography color="primary">Medications:</Typography>
            )}
          {medicationSelection &&
            Array.isArray(medicationSelection) &&
            medicationSelection.map((item, index) => (
              <Fragment key={item.medicationId}>
                <Grid
                  item
                  container
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  xs={12}
                  style={{
                    border: '1px solid grey',
                    borderRadius: '5px',
                    marginBottom: '5px',
                  }}
                >
                  <Grid item>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => this.removeMedication(index)}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="primary">
                      {index + 1}.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="primary">
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="primary">
                      {item.dosage} {item.dosageUnit}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="primary">
                      <b>Freq.:</b> {item.frequency}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="primary">
                      <b>Duration:</b> {item.duration} {item.durationUnit}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="primary">
                      <b>Refills:</b> {item.rxRefills}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="primary">
                      <b>Discontinue:</b> {item.discontinue ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="primary">
                      <b>PRN:</b> {item.rxPrn ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" color="primary">
                      <b>Directions:</b> {item.bottleLabel}
                    </Typography>
                  </Grid>
                </Grid>
              </Fragment>
            ))}
        </Grid>
        <form
          id="finish-appointment-form"
          onSubmit={handleSubmit(this.finishAppointment)}
        >
          <Field name="medications" component="input" type="hidden" />
          <Grid
            container
            direction="row"
            spacing={1}
            className={classes.codeSection}
          >
            <Grid item xs={12} sm={6}>
              <Field
                name="appointmentCode"
                label="Appointment Code"
                component={renderSelectField}
              >
                {_.map(this.props.apptCodes, (item) => {
                  return (
                    <MenuItem value={item.name} key={item._id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="diagnosticCode"
                label="Diagnostic Code"
                component={renderAutocomplete}
                options={_.map(diagnosticCodeList, (item) => {
                  return item.diagnosis;
                })}
              />
            </Grid>
            {showRx && (
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={12}>
                    <Field
                      name={'interaction'}
                      label="I confirm that the prescribing medication does not have any drug to drug interactions with their current medications. I have reviewed the patient's history of allergies."
                      component={renderCheckbox}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={'narcotic'}
                      label="Prescription does not contain any narcotic substance."
                      component={renderCheckbox}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={'photoId'}
                      label="I have verified government issued photo ID: I confirm that the patient on the prescription is the same person as on the photo ID as well as the same on the video call."
                      component={renderCheckbox}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={'faxRx'}
                      disabled={
                        (!selectedAppointment.patient.patient.pharmacy
                          .faxNumber &&
                          !selectedAppointment.patient.patient.pharmacy
                            .premiumPharmacy) ||
                        medWithConsent
                      }
                      label="The patient has requested to have the prescription faxed to their preferred pharmacy."
                      component={renderCheckbox}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              container
              spacing={1}
              justify="flex-end"
              xs={12}
              style={{ margin: 0 }}
            >
              <Grid item>
                <Button
                  startIcon={<ArrowBackIos />}
                  className={classes.button}
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => this.props.closePage()}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<Done />}
                  disabled={
                    showRx &&
                    this.props.medicationSelection &&
                    this.props.medicationSelection.length === 0
                  }
                  type="submit"
                  className={classes.button}
                  size="small"
                  variant="contained"
                  color="primary"
                >
                  Done
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Fragment>
    );
  }
}

const selector = formValueSelector('finish-appointment-form'); // <-- same as form name

const mapStateToProps = (state, props) => {
  // const diagnosticCode = _.find(diagnosticCodeNames, (val) => {
  //   return val.code === props.appointment.diagnosticCode;
  // });

  const medicationSelection = selector(state, 'medications');

  const initialValues = {
    appointmentCode:
      (props.selectedAppointment.appointmentCode &&
        props.selectedAppointment.appointmentCode.name) ||
      '',
    diagnosticCode:
      (props.selectedAppointment.diagnosticCode &&
        props.selectedAppointment.diagnosticCode.diagnosis) ||
      '',
    medications: [],
  };

  return {
    apptCodes: state.smartAppointmentCode,
    medication: state.medicalData.medicationArray,
    diagnosticCodeList: state.diagnosticCodes,
    initialValues,
    medicationSelection,
  };
};

// fn to handle form validation
const validate = (values) => {
  let errors = {};
  const { appointmentCode, diagnosticCode, medications } = values;

  // general form validation
  if (!appointmentCode) errors.appointmentCode = 'Required';
  if (!diagnosticCode) errors.diagnosticCode = 'Required';

  // if there are no medications in the submission, we can return early
  if (!medications || medications.length === 0) return errors;

  if (!values.interaction) errors.interaction = 'Required';
  if (!values.narcotic) errors.narcotic = 'Required';
  if (!values.photoId) errors.photoId = 'Required';

  return errors;
};

FinishAppointmentForm = withStyles(styles)(FinishAppointmentForm);

FinishAppointmentForm = reduxForm({
  form: 'finish-appointment-form',
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate,
})(FinishAppointmentForm);

FinishAppointmentForm = connect(
  mapStateToProps,
  actions
)(FinishAppointmentForm);

export default FinishAppointmentForm;
