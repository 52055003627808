import {
  PROMO_CODE_ERROR,
  PROMO_CODE_SUCCESS,
  PROMO_CODE_LOADING
} from '../actions/types';

const initialState = {
  isPromoCodeApplied: false,
  loading: false,
  errorType: false,
  errorMessage: false,
  newPrice: false,
  promoCodeAmount: false,
  promoType: false,
  promoCodeId: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PROMO_CODE_LOADING:
      return Object.assign({}, state, {
        loading: true
      });
    case PROMO_CODE_ERROR:
      return Object.assign({}, state, {
        errorType: action.errorType,
        errorMessage: action.errorMessage,
        isPromoCodeApplied: false
      });
    case PROMO_CODE_SUCCESS:
      return Object.assign({}, state, {
        errorType: false,
        errorMessage: false,
        loading: false,
        isPromoCodeApplied: true,
        newPrice: action.newPrice,
        promoCodeAmount: action.promoCodeAmount,
        promoType: action.promoType,
        promoCodeId: action.key
      });
    default:
      return state;
  }
}
