import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Typography,
  Grid,
  Button,
  Slide,
  TextField
} from '@material-ui/core';
import DrPasswordEditForm from './DrPasswordEditForm';

const styles = theme => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600
    }
  },
  titleMargin: {
    marginBottom: '30px'
  },
});

class DrSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Slide in={true} direction='right'>
          <main className={classes.layout}>
            <Grid container direction="row" className={classes.pageMargins}>
              <Grid
                item
                container
                style={{ marginTop: '5%' }}
                alignItems="center"
              >
                <Grid item xs={9} sm={10} className={classes.titleMargin}>
                  <Typography variant="h5" color="primary">
                    Settings
                  </Typography>
                </Grid>
              </Grid>
              <DrPasswordEditForm />
            </Grid>
          </main>
        </Slide>
      </Fragment>
    );
  }
}

export default withStyles(styles)(DrSettings);
