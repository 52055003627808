import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import {
  Grid,
  Typography,
  Zoom,
  Button,
} from '@material-ui/core';
import {
  ArrowDropUp,
  ArrowDropDown
} from '@material-ui/icons';

const styles = theme => ({
  // layout: {
  //   overflow: 'hidden',
  //   marginLeft: theme.spacing.unit,
  //   marginRight: theme.spacing.unit,
  //   [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
  //     marginLeft: theme.spacing.unit * 6,
  //     marginRight: theme.spacing.unit * 6
  //   },
  //   maxWidth: 850
  // },
  container: {
    marginTop: '3%'
  },
  reportButton : {
    margin: '10px 0px'
  },
});

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const previousMonthName = () => {
  const date = new Date();
  const monthName = date.getMonth() === 0 ? (
    month[11]
  ) : (
    month[date.getMonth() - 1]
  );
  return monthName;
}
class MonthlyDeliveriesCard extends Component {
  constructor(props) {
    super(props);
  }

  renderDeliveriesAnimation() {
    let delay = 0;
    const today = new Date();
    const previousMonth = () => {
      return today.getMonth() === 0 ? 11 : (today.getMonth() - 1);
    }
    const deliveries = this.props.deliveries ? this.props.deliveries : [];
    const previousMonthDeliveries = deliveries.filter(item => {
      const month = new Date(item.date).getMonth();
      return month === previousMonth();
    });
    let numbers = `${previousMonthDeliveries.length }`;
    numbers = numbers.split('');
    const numberAnimation = numbers.map(number => {
      delay += 100;
      return (
        <Zoom in={true} timeout={500} style={{ transitionDelay: `${delay}ms`}}>
          <Grid item>
            <Typography color='primary' variant='h2' align='center'>
              {number}
            </Typography>
          </Grid>
        </Zoom>
      )
    });
    return numberAnimation;
  }

  renderDeliveryPercentge() {
    const today = new Date();
    const previousMonth = () => {
      return today.getMonth() === 0 ? 11 : (today.getMonth() - 1);
    }
    const controlMonth = previousMonth() - 1;
    const totalDeliveries = this.props.deliveries ? this.props.deliveries : [];
    const previousMonthDeliveries = totalDeliveries.filter(item => {
      const month = new Date(item.date).getMonth();
      return month === previousMonth();
    });
    const controlMonthDeliveries = totalDeliveries.filter(item => {
      const month = new Date(item.date).getMonth();
      return month === controlMonth;
    });
    const difference = previousMonthDeliveries.length - controlMonthDeliveries.length;
    const percentage = () => {
      if (difference !== previousMonthDeliveries.length) {
        const result = (previousMonthDeliveries.length - controlMonthDeliveries.length) / controlMonthDeliveries.length * 100
        return result > 0 ? `${result.toFixed(1)}%` : `${(result * -1).toFixed(1)}%`;
      } else {
        return 'Absolute growth.'
      }
    }
    return (
      <Grid item container alignItems='center' justify='center'>
        {difference > 0 ? (
          <ArrowDropUp style={{ fontSize: 40, color: 'green' }} />
        ) : (
          <ArrowDropDown style={{ fontSize: 40, color: 'red' }} />
        )}
        <Typography align='center' style={difference > 0 ? {color: 'green'} : {color: 'red'}}> 
          {this.props.deliveries ? percentage() : '0%'}
        </Typography>
      </Grid>
    )
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.container} spacing={1}>
        <Grid item container justify='center'>
          <Grid item xs={12} container alignItems='center' justify='center'>
            <Typography variant='button' color='primary' align='center'>
              {previousMonthName()}
            </Typography>
          </Grid>
          {this.renderDeliveriesAnimation()}
        </Grid>
        <Grid item container alignItems='center' justify='center'>
          {this.renderDeliveryPercentge()}
        </Grid>
        {/* <Grid item xs={12} container justify='center'>
          <Button
            className={classes.reportButton}
            variant='outlined'
            color='primary'
            size='small'
          >
            View Report
          </Button>
        </Grid> */}
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    deliveries: state.deliveriesList,
  };
}

MonthlyDeliveriesCard = connect(mapStateToProps, actions)(withRouter(MonthlyDeliveriesCard));

export default withStyles(styles)(MonthlyDeliveriesCard)