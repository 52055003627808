import React from 'react';
import { withStyles } from '@material-ui/styles';

import { Grid, Typography } from '@material-ui/core';

const styles = {
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
    paddingLeft: '7px',
  },
};

function SymptomsCard(props) {
  const { classes } = props;

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body2" color="primary">
          Reason for Visit
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.shortReason}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color="primary">
          Symptoms
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.symptoms.join(', ')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" color="primary">
          Temperature
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{ overflow: 'hidden' }}
        >
          <Typography color="primary">
            {props.editNoteParams.temperature || 'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" color="primary">
          Heart Rate
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.heartRate || 'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2" color="primary">
          Blood Pressure
        </Typography>
        <Grid
          item
          xs={12}
          className={classes.textField}
          style={{
            overflow: 'hidden',
          }}
        >
          <Typography color="primary">
            {props.editNoteParams.bloodPressure || 'N/A'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(SymptomsCard);
