import React from 'react';
import {
  Assignment as DoctorNotesIcon,
  ChatBubble as ChatIcon,
  LocalPharmacy as PrescriptionIcon,
  Description as GeneralNoteIcon,
  Person as PersonIcon,
  CastForEducation as SchoolNoteIcon,
  Videocam as VideoChatIcon,
  MeetingRoom as AppointmentHistoryIcon
} from '@material-ui/icons';

const ToolbarIcons = props => {
  const { name } = props;
  switch (name) {
    case 'videochat':
      return <VideoChatIcon {...props} />;
    case 'doctor-notes':
      return <DoctorNotesIcon {...props} />;
    case 'prescription-button':
      return <PrescriptionIcon {...props} />;
    case 'general-sicknote':
      return <GeneralNoteIcon {...props} />;
    case 'school-sicknote':
      return <SchoolNoteIcon {...props} />;
    case 'patient-info-button':
      return <PersonIcon {...props} />;
    case 'patient-apt-history':
      return <AppointmentHistoryIcon {...props} />;
  }
};

export default ToolbarIcons;
