import React, { Component } from 'react';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff
} from '@material-ui/icons';

class PasswordField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordButton: false,
    };
  }

  handlePasswordButton = () => {
    this.setState(prevState => ({ passwordButton: !prevState.passwordButton }));
  }

  render() {
    return(
      <FormControl
        fullWidth
        variant="outlined"
        size='small'
        error={this.props.meta.touched && this.props.meta.error}
      >
        <InputLabel>{this.props.label}</InputLabel>
        <OutlinedInput
          {...this.props.input}
          id={this.props.id}
          type={this.state.passwordButton ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={this.handlePasswordButton}
                edge="end"
              >
                {this.state.passwordButton ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          label={this.props.label}
        />
        <FormHelperText>{this.props.meta.touched && this.props.meta.error}</FormHelperText>
      </FormControl>
    )
  }
}

export default PasswordField;

