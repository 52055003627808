import React, { Component } from 'react';
import { connect } from 'react-redux';
import SignaturePad from 'react-signature-canvas';
import * as actions from '../../actions';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  Button,
  Typography,
  Grid,
  Fab,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Slide,
} from '@material-ui/core';

import { Delete, ArrowBack } from '@material-ui/icons';

import withStyles from '@material-ui/core/styles/withStyles';
import '../../styles/signaturePad.css';

const styles = (theme) => ({
  layout: {
    maxWidth: 800,
  },
  bottomSpacing: {
    marginBottom: 15,
  },
});

class DoctorSignature extends Component {
  state = {
    trimmedDataURL: null,
    disableSubmit: true,
  };
  sigPad = {};

  clear = () => {
    this.sigPad.clear();
    this.setState({ disableSubmit: true });
  };

  render() {
    const { classes } = this.props;
    let { trimmedDataURL } = this.state;

    return (
      <Slide in={true} direction="right">
        <main className={classes.layout}>
          <Card>
            <CardContent>
              {this.props.auth.signature && (
                <div className={classes.bottomSpacing}>
                  <Typography
                    color="primary"
                    variant="body1"
                    className={classes.bottomSpacing}
                  >
                    Previous Signature
                  </Typography>
                  <Grid container justify="center">
                    <img
                      id="previous-signature"
                      src={this.props.auth.signature}
                    />
                  </Grid>
                </div>
              )}
              <Grid container className={classes.bottomSpacing}>
                <Typography color="primary" variant="h5">
                  Please sign below{' '}
                  {this.props.auth.signature && 'to change your signature'}:
                </Typography>
              </Grid>
              <div id="sigContainer">
                <SignaturePad
                  onEnd={() => this.setState({ disableSubmit: false })}
                  canvasProps={{
                    className: 'canvas',
                  }}
                  ref={(ref) => {
                    this.sigPad = ref;
                  }}
                />
              </div>
              <CardActions>
                <Grid container spacing={1}>
                  <Grid item xs={2} sm={1}>
                    <Fab size="small" onClick={() => this.props.exitPage()}>
                      <ArrowBack />
                    </Fab>
                  </Grid>
                  <Grid item xs={2} sm={1}>
                    <Fab size="small" onClick={this.clear}>
                      <Delete />
                    </Fab>
                  </Grid>
                  <Grid item xs={8} sm={10}>
                    <Button
                      style={{ float: 'right' }}
                      color="primary"
                      variant="contained"
                      disabled={this.state.disableSubmit}
                      onClick={async () => {
                        const res = await this.props.submitDoctorSignature({
                          signature: this.sigPad
                            .getTrimmedCanvas()
                            .toDataURL('image/png'),
                        });
                        if (res.data) {
                          this.props.fetchUser();
                          this.props.exitPage();
                        }
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </CardContent>
          </Card>
        </main>
      </Slide>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

DoctorSignature = connect(
  mapStateToProps,
  actions
)(withRouter(DoctorSignature));

export default withStyles(styles)(DoctorSignature);
