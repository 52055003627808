import React from 'react';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import ToolbarIcons from './ToolbarIcons';
import iconList from './iconList';

const Toolbar = ({
  classes,
  handleClick,
  iconClassName,
  enableVideoWindow,
}) => {
  return (
    <Grid item id="toolbar">
      {iconList.map((name) => {
        if (name !== 'videochat') {
          return (
            <Tooltip title={name} placement="right">
              <IconButton
                id={name}
                key={name}
                className={iconClassName(name)}
                onClick={() => {
                  handleClick(name);
                }}
              >
                <ToolbarIcons className={classes.actualIcon} name={name} />
              </IconButton>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title="Video Chat" placement="right">
              <IconButton
                id={name}
                key={name}
                className={iconClassName(name)}
                onClick={() => {
                  handleClick(name);
                }}
              >
                <ToolbarIcons className={classes.actualIcon} name={name} />
              </IconButton>
            </Tooltip>
          );
        }
      })}
    </Grid>
  );
};

export default Toolbar;
