import React, { Component, Fragment, useState } from 'react';
import axios from 'axios';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { reduxForm, Field, FormSection } from 'redux-form';
import {
  Button,
  CssBaseline,
  FormControl,
  MenuItem,
  Select,
  Paper,
  Typography,
  TextField,
  Grid,
  InputLabel,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Dialog,
  CircularProgress,
  OutlinedInput,
  Slide,
  Badge,
  Tooltip,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PharmacyIcon from '@material-ui/icons/LocalPharmacy';
import Notes from '@material-ui/icons/Description';
import DoctorNotesIcon from '@material-ui/icons/Assignment';
import Chat from '@material-ui/icons/Chat';
import { InsertDriveFile } from '@material-ui/icons';
import snapmedLogo from '../../SnapMedLogoRetina';
import ReactHtmlParser from 'react-html-parser';
import DoctorPdfDialog from './DoctorPdfDialog';
import Popup from 'reactjs-popup';
import jsPDF from 'jspdf';
import ReactQuill from 'react-quill';
import DoctorPdfRendering from '../dashboard/doctorPdfRendering/DoctorPdfRendering';
import AppointmentFiles from '../../dialogs/AppointmentFiles';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  layoutPdf: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 700,
    },
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
    float: 'right',
  },
  spacing: {
    marginBottom: 15,
  },
  align: {
    marginRight: '37%',
  },
  center: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
  },
});

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'indent',
];

const Quill = ({ name, editor, savedText, appointment }) => {
  //const [disableButton, setDisable] = useState(false);
  return (
    <Grid item container xs={12} direction="row" spacing={1}>
      <Grid item xs={12}>
        <ReactQuill
          id={editor}
          formats={formats}
          modules={modules}
          theme={'snow'}
          placeholder="Write something"
          value={savedText ? savedText : null}
          style={{
            backgroundColor: 'white',
            overflow: 'hidden',
            height: 250,
            width: '100%',
            borderBottom: '1px solid #B0B0B0',
          }}
        />
      </Grid>
      <Grid item container xs={12} justify="flex-end">
        <Button
          form={name}
          size="small"
          variant="outlined"
          type="submit"
          //onClick={() => setDisable(true)}
        >
          save
          {/* {disableButton && (
            <CircularProgress
              size={15}
              color='primary'
            />
          )} */}
        </Button>
      </Grid>
    </Grid>
  );
};

const StyledBadge = withStyles((theme) => ({
  badge: (props) => ({
    backgroundColor: props.signed ? 'mediumseagreen' : theme.palette.error[500],
  }),
}))(Badge);

class DoctorAppointmentHistory extends Component {
  constructor(props) {
    super(props);
    this.perPage = 8;

    this.state = {
      page: 0,
      appointmentOrderValue: 'Latest',
      openPdf: false,
      pdfData: {},
      expandedPanel: false,
      openFilesDialog: false,
      selectedFiles: [],
    };
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
  }

  componentDidMount() {
    this.props.getDoctorAppointmentHistory();
  }

  nextPage() {
    this.setState({
      page: this.state.page + 1,
    });
  }

  previousPage() {
    this.setState({
      page: this.state.page - 1,
    });
  }
  lastPage() {
    this.setState({
      page: this.numPages,
    });
  }
  firstPage() {
    this.setState({
      page: 0,
    });
  }

  closeDocument() {
    this.setState({ openPdf: false });
  }

  createPdf(patient, doctor, appt, data, type) {
    if (type === 'prescription') {
      const pdf = new jsPDF('p', 'pt', 'a3');
      const center = pdf.internal.pageSize.getWidth() / 2;
      const imgData = snapmedLogo;

      pdf.setTextColor(220);
      pdf.setFontSize(150);
      pdf.text('SnapMed.ca', 80, 300, null, 30);
      pdf.text('SnapMed.ca', 80, 600, null, 30);
      pdf.text('SnapMed.ca', 80, 900, null, 30);
      pdf.text('SnapMed.ca', 80, 1200, null, 30);
      pdf.text('SnapMed.ca', 80, 1500, null, 30);
      pdf.addImage(imgData, 'JPEG', center - 75, 10, 200, 60);
      pdf.setTextColor(10);
      pdf.fromHTML(
        ReactDOMServer.renderToString(
          ReactHtmlParser(data),
          setTimeout(function () {
            pdf.save('prescription.pdf');
          }, 500)
        ),
        40,
        90
      );
    } else if (type === 'generalSickNote') {
      const pdf = new jsPDF('p', 'pt', 'a5');
      const center = pdf.internal.pageSize.getWidth() / 2;
      const imgData = snapmedLogo;

      pdf.setTextColor(220);
      pdf.setFontSize(70);
      pdf.text('SnapMed.ca', 45, 150, null, 30);
      pdf.text('SnapMed.ca', 45, 300, null, 30);
      pdf.text('SnapMed.ca', 45, 450, null, 30);
      pdf.text('SnapMed.ca', 45, 600, null, 30);
      pdf.text('SnapMed.ca', 45, 750, null, 30);
      pdf.addImage(imgData, 'JPEG', center - 75, 10, 155, 45);
      pdf.setTextColor(10);
      pdf.fromHTML(
        ReactDOMServer.renderToString(
          ReactHtmlParser(data),
          setTimeout(function () {
            pdf.save('Sick Note.pdf');
          }, 500)
        ),
        40,
        70
      );
    } else if (type === 'doctorNotes') {
      const pdf = new jsPDF('p', 'pt', 'letter');
      // const pageWidth = pdf.internal.pageSize.getWidth();
      const center = pdf.internal.pageSize.getWidth() / 2;
      const imgData = snapmedLogo;
      const apptDate = new Date(appt.date).toLocaleDateString('en-CA');
      const startTime = new Date(appt.startTimestamp).toLocaleTimeString(
        'en-CA'
      );
      const endTime = new Date(appt.endTimestamp).toLocaleTimeString('en-CA');
      const dateOfBirth = appt.patientInfoNote.dateOfBirth;
      const timestamp = appt.finalizedNotesTimestamp
        ? new Date(appt.finalizedNotesTimestamp).toLocaleString('en-CA')
        : 'N/A';
      //const noteText = str.replace(/"?<\/?p>"?/gi, "");
      //const splitText = pdf.splitTextToSize(data, pageWidth - 20);
      pdf.addImage(imgData, 'JPEG', center - 75, 10, 150, 45);
      pdf.setFontSize(8);
      pdf.text('202-446 Concession St.', center, 75, 'center');
      pdf.text('Hamilton, ON, L9A 1C2', center, 85, 'center');
      pdf.text('1-833-SNAP-333 (1-833-762-7333)', center, 95, 'center');
      pdf.setFontSize(10);
      pdf.text(`Dr. ${doctor.name.first} ${doctor.name.last}`, 30, 125);
      pdf.text(`Patient: ${appt.patientInfoNote.name}`, 30, 145);
      pdf.text(appt.patientInfoNote.address, 70, 160);
      pdf.text(
        `${appt.patientInfoNote.city}, ${appt.patientInfoNote.province}`,
        70,
        175
      );
      pdf.text(appt.patientInfoNote.postalCode, 70, 190);
      pdf.text(`Phone: ${appt.patientInfoNote.mobilePhone}`, 70, 205);
      pdf.text(`DOB: ${dateOfBirth}`, 70, 220);
      pdf.setFontType('bold');
      pdf.text(`Appointment Date: ${apptDate}`, 360, 125);
      pdf.text(`Appointment ID: ${appt._id}`, 360, 145);
      pdf.setFontType('normal');
      pdf.text(`Reason for Visit: ${appt.shortReason}`, 30, 240);
      pdf.text(`Symptoms: ${appt.symptoms}`, 30, 260);
      pdf.text(`Final note added on: ${timestamp}`, 30, 300);
      pdf.text(`Start time: ${startTime}`, 30, 320);
      pdf.text(`End time: ${endTime}`, 160, 320);
      pdf.text('Notes:', 30, 340);
      pdf.setFontSize(14);
      //pdf.text(splitText, 40, 300);
      pdf.fromHTML(
        ReactDOMServer.renderToString(
          ReactHtmlParser(data),
          setTimeout(function () {
            pdf.save('Doctor Notes.pdf');
          }, 500)
        ),
        40,
        350,
        {
          width: 500,
        }
      );
    } else if (type === 'schoolSickNote') {
      const pdf = new jsPDF('p', 'pt', 'a2');
      const center = pdf.internal.pageSize.getWidth() / 2;
      const imgData = snapmedLogo;

      pdf.addImage(imgData, 'JPEG', center - 75, 10, 150, 45);
      pdf.fromHTML(
        ReactDOMServer.renderToString(
          ReactHtmlParser(data),
          setTimeout(function () {
            pdf.save('School Sick Note.pdf');
          }, 1000)
        ),
        50,
        10
      );
    } else if (type === 'textChat') {
      const pdf = new jsPDF('p', 'pt', 'letter');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const center = pdf.internal.pageSize.getWidth() / 2;
      const imgData = snapmedLogo;
      const apptDate = new Date(appt.date).toLocaleDateString('en-CA');
      const dateOfBirth = patient.dateOfBirth;
      const str = data.join('\n');
      let page = 1;
      //const noteText = str.replace(/"?<\/?p>"?/gi, "");
      const splitText = pdf.splitTextToSize(str, pageWidth - 20);
      pdf.addImage(imgData, 'JPEG', center - 75, 10, 150, 45);
      pdf.setFontSize(14);
      pdf.text('202-446 Concession St.', center, 80, 'center');
      pdf.text('Hamilton, ON, L9A 1C2', center, 100, 'center');
      pdf.text('1-833-SNAP-333 (1-833-762-7333)', center, 120, 'center');
      pdf.setFontSize(12);
      pdf.text(`Dr. ${doctor.name.first} ${doctor.name.last}`, 30, 150);
      pdf.text(`Patient: ${patient.name.first} ${patient.name.last}`, 30, 170);
      pdf.text(patient.address, 70, 190);
      pdf.text(`${patient.city}, ${patient.province}`, 70, 210);
      pdf.text(patient.zipCode, 70, 230);
      pdf.text(`Phone: ${patient.mobilePhone}`, 70, 250);
      pdf.text(`DOB: ${dateOfBirth}`, 70, 270);
      pdf.text(`Appointment Date: ${apptDate}`, 30, 290);
      pdf.text(`Transcript (page ${page}):`, 30, 310);
      let y = 330;
      for (let i = 0; i < splitText.length; i++) {
        if (y > pageHeight - 35) {
          y = 330;
          page++;
          pdf.addPage();
          pdf.addImage(imgData, 'JPEG', center - 75, 10, 150, 45);
          pdf.setFontSize(14);
          pdf.text('202-446 Concession St.', center, 80, 'center');
          pdf.text('Hamilton, ON, L9A 1C2', center, 100, 'center');
          pdf.text('1-833-SNAP-333 (1-833-762-7333)', center, 120, 'center');
          pdf.setFontSize(12);
          pdf.text(`Dr. ${doctor.name.first} ${doctor.name.last}`, 30, 150);
          pdf.text(
            `Patient: ${patient.name.first} ${patient.name.last}`,
            30,
            170
          );
          pdf.text(patient.address, 70, 190);
          pdf.text(`${patient.city}, ${patient.province}`, 70, 210);
          pdf.text(patient.zipCode, 70, 230);
          pdf.text(`Phone: ${patient.mobilePhone}`, 70, 250);
          pdf.text(`DOB: ${dateOfBirth}`, 70, 270);
          pdf.text(`Appointment Date: ${apptDate}`, 30, 290);
          pdf.text(`Transcript (page ${page}):`, 30, 310);
        }
        pdf.text(50, y, splitText[i]);
        y = y + 15;
      }
      pdf.save('SnapMed Chat Transcript.pdf');
    }
  }

  renderQuill(name, editor, savedText, apptID, handleSubmit) {
    //const str = savedText || '';
    //const printText = str.replace(/"?<\/?p>"?/gi, "");
    return (
      <form
        id={name}
        onSubmit={handleSubmit((values) => {
          values.room = apptID;
          values.chatNote = document.getElementById(
            `${editor}`
          ).children[1].children[0].innerHTML;
          values.doctorNotesEditable = false;
          this.props.updateUnfinishedNotes(values);
          this.props.getDoctorAppointmentHistory();
        })}
        style={{
          width: '100%',
        }}
      >
        <Field
          name={name}
          savedText={savedText}
          component={Quill}
          appointment={apptID}
          editor={editor}
        />
      </form>
    );
  }

  closeNotes(appt) {
    const values = {};
    values.room = appt._id;
    values.doctor_finalized_notes = true;
    if (appt.patient) {
      values.patientEmail = appt.patient.email;
      values.patientFirstName = appt.patient.name.first;
      values.patientLastName = appt.patient.name.last;
    }
    this.props.updateUnfinishedNotes(values);
    setTimeout(() => this.props.getDoctorAppointmentHistory(), 500);
  }

  renderRxButtons = (prescriptions) => {
    return prescriptions.map((rx) => (
      <IconButton
        onClick={() => {
          this.setState({
            openPdf: true,
            pdfData: rx.prescriptionNoteValues,
          });
          this.props.disableToolbar(true);
        }}
      >
        <StyledBadge
          badgeContent=""
          variant="dot"
          invisible={rx.consentForms.length === 0}
          signed={rx.isConsentSigned}
        >
          <PharmacyIcon color="primary" />
        </StyledBadge>
      </IconButton>
    ));
  };

  handleOpenFilesDialog = (files) => {
    this.setState({
      openFilesDialog: true,
      selectedFiles: files,
    });
  };

  returnFields = () => {
    const { appointments, classes, auth, handleSubmit } = this.props;
    const { page, appointmentOrderValue, expandedPanel } = this.state;
    var history = [];
    var stepper = page * this.perPage;
    var top = stepper + this.perPage;
    let sortedAppointments = [];

    if (!appointments.appointmentHistory) {
      return [];
    }

    if (appointments.appointmentHistory.length === 0) {
      return (
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{
            marginTop: 20,
          }}
        >
          <Typography textAlign="center" variant="h5" color="primary">
            No appointment records.
          </Typography>
        </Grid>
      );
    }

    switch (appointmentOrderValue) {
      // Sort by most recent appointments first.
      case 'Latest':
        sortedAppointments = appointments.appointmentHistory.sort(function (
          a,
          b
        ) {
          var dateA = new Date(a.date);
          var dateB = new Date(b.date);
          return dateB - dateA;
        });
        break;
      // Sort by oldest appointments first.
      case 'Oldest':
        sortedAppointments = appointments.appointmentHistory.sort(function (
          a,
          b
        ) {
          var dateA = new Date(a.date);
          var dateB = new Date(b.date);
          return dateA - dateB;
        });
        break;
      // Sort by most recent appointments with unfinished notes first,
      // followed by most recent appointments with finalized notes.
      case 'Unfinished Notes':
        sortedAppointments = appointments.appointmentHistory.sort(function (
          a,
          b
        ) {
          var dateA = new Date(a.date);
          var dateB = new Date(b.date);
          return dateB - dateA;
        });
        let noNotes = sortedAppointments.filter(
          (appt) =>
            !appt.doctorNotesFinalized || appt.doctorNotesFinalized === null
        );
        let withNotes = sortedAppointments.filter(
          (appt) => appt.doctorNotesFinalized
        );
        sortedAppointments = [...noNotes, ...withNotes];
        break;
      case 'Unfinished Appointment':
        sortedAppointments = appointments.appointmentHistory.sort(function (
          a,
          b
        ) {
          var dateA = new Date(a.date);
          var dateB = new Date(b.date);
          return dateB - dateA;
        });
        let unfinished = sortedAppointments.filter(
          (appt) => appt.appointmentFinalized
        );
        let finished = sortedAppointments.filter(
          (appt) => !appt.appointmentFinalized
        );
        sortedAppointments = [...finished, ...unfinished];
        break;
    }

    for (var i = stepper; i < top; i++) {
      let appt = sortedAppointments[i];
      if (appt === undefined) {
        break;
      }
      let rxConsent = false;
      let consentSigned = true;
      if (appt && Array.isArray(appt.prescriptions)) {
        appt.prescriptions.forEach((rx) => {
          if (rx.consentForms.length > 0) {
            rxConsent = true;
            if (!rx.isConsentSigned) {
              consentSigned = false;
            }
          }
        });
      }
      var attachmentExist =
        //if prescription, sick note or school sick note exists
        appt.prescription ||
        (appt.fileList && appt.fileList.length > 0) ||
        appt.schoolSicknote ||
        appt.generalSicknote;

      history.push(
        <Accordion
          expanded={expandedPanel === appt._id}
          onChange={(event, isExpanded) =>
            this.setState({ expandedPanel: isExpanded ? appt._id : false })
          }
        >
          <AccordionSummary
            style={{ backgroundColor: '#EEEDEE' }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Grid container direction="row" spacing={1}>
              <Grid item container xs={12} sm={6} alignItems="center">
                {appt.patientLeftWaitingRoom || !appt.patientConnected ? (
                  <Typography variant="button" color="error">
                    MISSED CALL - DATE:{' '}
                    {new Date(appt.date).toLocaleDateString('en-CA')}
                  </Typography>
                ) : (
                  <Typography variant="button" color="primary">
                    Appointment Date:{' '}
                    {new Date(appt.date).toLocaleDateString('en-CA')}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={6}
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  sm={8}
                  style={{
                    padding: 0,
                  }}
                >
                  {!appt.doctorNotesFinalized &&
                    !appt.patientLeftWaitingRoom && (
                      <Grid item>
                        <Typography variant="caption" color="error">
                          Unfinished Notes
                        </Typography>
                      </Grid>
                    )}
                  {!appt.appointmentFinalized && (
                    <Grid item>
                      <Typography variant="caption" color="error">
                        Unfinished Appointment
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid item sm={4}>
                  <StyledBadge
                    badgeContent=""
                    variant="dot"
                    invisible={!rxConsent}
                    signed={consentSigned}
                  >
                    <Typography
                      variant="button"
                      color="primary"
                      style={{ float: 'right' }}
                    >
                      ID: {appt._id.substring(19, 24)}
                    </Typography>
                  </StyledBadge>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails style={{ backgroundColor: '#D3D3D3' }}>
            <Grid container>
              <Grid item xs={12}>
                {appt.ohipAppointment && (
                  <Typography variant="body1" color="error">
                    OHIP Appointment
                  </Typography>
                )}
                {appt.patient ? (
                  <div>
                    <Typography variant="body2">
                      <strong> Patient:</strong> {appt.patient.name.first}{' '}
                      {appt.patient.name.last}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Phone Number:</strong> {appt.patient.mobilePhone}
                    </Typography>
                  </div>
                ) : (
                  <Typography variant="body2">
                    <strong> Patient:</strong> No Patient
                  </Typography>
                )}

                {appt.ohipAppointment && (
                  <Typography variant="body2">
                    <strong>OHIP Number:</strong> {appt.ohipNumber}
                  </Typography>
                )}
                <Typography variant="body2">
                  <strong>Reason for Visit:</strong> {appt.shortReason}
                </Typography>

                <Typography variant="body2">
                  <strong> Symptoms:</strong> {appt.symptoms.join(', ')}
                </Typography>
              </Grid>
              {!appt.patientLeftWaitingRoom && (
                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={1}>
                    <Typography variant="body2">
                      <strong>Notes:</strong>
                    </Typography>
                  </Grid>
                  <Grid item container direction="row" xs={12} sm={11}>
                    {!appt.doctorNotesEditable ? (
                      <Grid item container xs={12} alignItems="center">
                        <Grid item xs={12} sm={2}>
                          <IconButton
                            onClick={() =>
                              this.createPdf(
                                appt.patient,
                                appt.doctor,
                                appt,
                                appt.doctorNotes,
                                'doctorNotes'
                              )
                            }
                          >
                            <DoctorNotesIcon color="primary" />
                          </IconButton>
                        </Grid>
                        {!appt.doctorNotesFinalized ? (
                          <Grid container xs={10} spacing={1}>
                            <Grid item>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => {
                                  this.props.openEditNote(appt);
                                  this.props.disableToolbar(true);
                                }}
                              >
                                <Typography variant="subtitle2" color="error">
                                  Edit
                                </Typography>
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={() => this.closeNotes(appt)}
                              >
                                <Typography variant="subtitle2">
                                  Close
                                </Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        ) : (
                          <Typography variant="caption">
                            Note is closed.
                          </Typography>
                        )}
                      </Grid>
                    ) : (
                      <Grid container alignItems="center">
                        <Grid item xs={12} sm={6}>
                          <Typography variant="caption" color="error">
                            Please finish your notes Dr. {auth.name.last}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              this.props.disableToolbar(true);
                              this.props.openEditNote(appt);
                            }}
                          >
                            <Typography variant="subtitle2" color="error">
                              Edit Notes
                            </Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {appt.savedTextChat && (
                <Grid item xs={12}>
                  <Typography variant="body2">
                    <strong>Text Chat:</strong>
                    <IconButton>
                      <Chat
                        color="primary"
                        onClick={() =>
                          // taking into account older text chat transcript format as an array of strings
                          typeof appt.savedTextChat === 'string'
                            ? this.props.openChatHistory(appt.savedTextChat)
                            : this.createPdf(
                                appt.patient,
                                appt.doctor,
                                appt,
                                appt.savedTextChat,
                                'textChat'
                              )
                        }
                      />
                    </IconButton>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="body2">
                  {attachmentExist ? <strong>Attachments:</strong> : ''}
                </Typography>
              </Grid>
              <Grid>
                {appt.prescriptions &&
                  appt.prescriptions.length > 0 &&
                  this.renderRxButtons(appt.prescriptions)}
                {appt.fileList && appt.fileList.length > 0 && (
                  <Tooltip title="Files">
                    <IconButton
                      onClick={() => this.handleOpenFilesDialog(appt.fileList)}
                    >
                      <InsertDriveFile color="primary" />
                    </IconButton>
                  </Tooltip>
                )}
                {appt.schoolSicknote != undefined && (
                  // <DoctorPdfDialog
                  //   data={appt.schoolSicknote.schoolSickNoteValues}
                  //   type={'Sick Note'}
                  // />
                  <IconButton
                    onClick={() =>
                      this.setState({
                        openPdf: true,
                        pdfData: appt.schoolSicknote.schoolSickNoteValues,
                      })
                    }
                  >
                    <Notes color="primary" />
                  </IconButton>
                )}
                {appt.generalSicknote != undefined && (
                  <IconButton
                    onClick={() =>
                      this.setState({
                        openPdf: true,
                        pdfData: appt.generalSicknote.generalSickNoteValues,
                      })
                    }
                  >
                    <Notes color="primary" />
                  </IconButton>
                )}
              </Grid>
              {!appt.appointmentFinalized && (
                <Grid container alignItems="center" style={{ paddingTop: 10 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="caption" color="error">
                      This appointment is unfinished.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      size="small"
                      style={{}}
                      onClick={() => this.props.openFinishAppointment(appt)}
                    >
                      <Typography variant="subtitle2" color="error">
                        Edit Appointment
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    }
    return history;
  };

  render() {
    const { classes, auth, appointments, handleSubmit, returnFields } =
      this.props;

    var numberAppts = appointments.appointmentHistory
      ? appointments.appointmentHistory.length
      : 0;
    this.numPages = Math.floor(numberAppts / this.perPage);
    this.bottomPerPage = this.state.page * this.perPage + 1;
    this.topPerPage = this.state.page * this.perPage + this.perPage;

    return !this.state.openPdf ? (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid
              container
              spacing={1}
              style={{
                margin: 0,
                width: '100%',
              }}
              direction="row"
            >
              <Grid item xs={12} style={{ marginTop: '5%' }}>
                <Typography
                  variant="h5"
                  className={classes.spacing}
                  color="primary"
                >
                  Your Appointment History
                </Typography>
              </Grid>
              <br />
              <Grid item xs={12}>
                <Typography variant="body1">
                  {' '}
                  You can see all your previous appointments below, just click
                  on any tab to expand it for more information:{' '}
                </Typography>
                <br />
                {/* */}
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={6} sm={4}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel htmlFor={'order'}>Order by</InputLabel>
                      <Select
                        id="order"
                        input={<OutlinedInput label="Order by" />}
                        value={this.state.appointmentOrderValue}
                        onChange={(event) =>
                          this.setState({
                            appointmentOrderValue: event.target.value,
                          })
                        }
                      >
                        {[
                          'Latest',
                          'Oldest',
                          'Unfinished Notes',
                          'Unfinished Appointment',
                        ].map((order) => (
                          <MenuItem value={order}>{order}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="caption" className={classes.center}>
                      {' '}
                      {this.bottomPerPage}{' '}
                      {this.state.page === this.numPages
                        ? '- ' + numberAppts
                        : '- ' + this.topPerPage}{' '}
                      of {numberAppts}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Grid container direction={'row'}>
                      <Grid item xs={3}>
                        <IconButton
                          onClick={this.firstPage}
                          disabled={this.state.page === 0 ? true : false}
                        >
                          <FirstPageIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={3}>
                        <IconButton
                          onClick={this.previousPage}
                          disabled={this.state.page === 0 ? true : false}
                        >
                          <KeyboardArrowLeft />
                        </IconButton>
                      </Grid>

                      <Grid item xs={3}>
                        <IconButton
                          onClick={this.nextPage}
                          disabled={
                            this.state.page === this.numPages ? true : false
                          }
                        >
                          <KeyboardArrowRight />
                        </IconButton>
                      </Grid>
                      <Grid item xs={3}>
                        <IconButton
                          onClick={this.lastPage}
                          disabled={
                            this.state.page === this.numPages ? true : false
                          }
                        >
                          <LastPageIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {!appointments.appointmentHistory && (
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    alignContent="center"
                    style={{
                      height: '200px',
                    }}
                  >
                    <CircularProgress size={40} color="primary" />
                  </Grid>
                )}
                {this.returnFields()}
              </Grid>
            </Grid>
          </main>
        </Slide>
        {this.state.openFilesDialog && (
          <AppointmentFiles
            open={this.state.openFilesDialog}
            onClose={() =>
              this.setState({ openFilesDialog: false, selectedFiles: [] })
            }
            files={this.state.selectedFiles}
          />
        )}
      </Fragment>
    ) : (
      <Fragment>
        <Slide in={true} direction="right">
          <main className={classes.layoutPdf}>
            <Grid item container justify="flex-end">
              <Button
                className={classes.spacing}
                variant="contained"
                color="primary"
                onClick={() => {
                  this.setState({ openPdf: false });
                  this.props.disableToolbar(false);
                }}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <DoctorPdfRendering
                data={this.state.pdfData}
                closeDocument={() => this.closeDocument()}
                disableToolbar={this.props.disableToolbar}
              />
            </Grid>
          </main>
        </Slide>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    form: state.form,
    auth: state.auth,
    appointments: state.appointments,
  };
}

DoctorAppointmentHistory = reduxForm({
  //  validate,
  form: 'unfinishedNotesForm',
  destroyOnUnmount: false,
})(DoctorAppointmentHistory);

export default withStyles(styles)(
  connect(mapStateToProps, actions)(DoctorAppointmentHistory)
);
