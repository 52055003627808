import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import * as actions from '../../../actions';
import PropTypes from 'prop-types';
import {
  Button,
  CssBaseline,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Chip,
  Paper,
  Typography,
  TextField,
  Grid,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TextButton from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import WizardProfileField from '../../commonform/wizardProfileField';
import currentMedicationFormFields from './currentMedicationFormFields';
import currentMedicationFormFields1 from './currentMedicationFormFields1';
import currentMedicationFormFieldsValidate from './currentMedicationFormFieldsValidate';
import formAddField from '../../commonform/formAddField';
import formAddButton from '../../commonform/formAddButton';
import formRemoveButton from '../../commonform/formRemoveButton';
import validateSubformWithNoneOption from '../../commonform/validateSubformWithNoneOption';
import validationHelperFunction from '../validationHelperFunction';
import snapmedLogo from '../../SnapMedLogoRetina';

const styles = (theme) => ({
  layout: {
    //  width: 'auto',
    //  height: '100%',
    //  display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: '1.5rem',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: 40,
    marginBottom: theme.spacing.unit * 3,
    float: 'right',
  },
  previous: {
    marginTop: 40,
    marginBottom: theme.spacing.unit * 3,
    float: 'left',
  },
});

const MedicationField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <div>
    <FormControl error={touched && error}>
      <FormControlLabel
        control={
          <Checkbox
            {...input}
            id={name}
            checked={input.value ? label : false}
            onChange={input.onChange}
            //value="checkedB"
            color="primary"
          />
        }
        label={label}
      />
      {touched && error ? (
        <FormHelperText styles>*{error}</FormHelperText>
      ) : null}
    </FormControl>
  </div>
);

const OtherField = ({ name, input, meta: { touched, error } }) => (
  <TextField
    {...input}
    id={name}
    type="input"
    variant="outlined"
    label="Other"
    fullWidth
    error={touched && error}
    helperText={touched && error}
  />
);

const OtherFieldCheckbox = ({ name, id, label, input }) => {
  const dispatch = useDispatch();
  return (
    <FormControl>
      <FormControlLabel
        id={name}
        value="end"
        control={<Checkbox {...input} color="primary" />}
        label={label}
        labelPlacement="end"
        onClick={dispatch(actions.activateAllergiesOtherField(input.value, id))}
      />
    </FormControl>
  );
};

class CurrentMedicationForm extends Component {
  constructor(props) {
    super(props);

    const { previousPage, handleSubmit } = this.props;
  }

  renderCurrentMedicationCheckBoxesFirstHalf(currentMedicationArray) {
    return _.map(currentMedicationArray, ({ name, _id }, index) => {
      if (index % 2 === 0) {
        if (name === 'Other') {
          return (
            <Field
              name={'current_medication_'.concat(name)}
              id={'current_medication_'.concat(name)}
              label={name}
              type={'checkbox'}
              component={OtherFieldCheckbox}
              onChange={(_e, newValue) => {
                if (!newValue) {
                  this.props.change('current_medication_other_field', '');
                }
              }}
            />
          );
        } else {
          return (
            <Field
              name={'current_medication_'.concat(name)}
              label={name}
              type={'checkbox'}
              parse={(value) => (value ? { name, _id } : false)}
              component={MedicationField}
            />
          );
        }
      }
    });
  }

  renderCurrentMedicationCheckBoxesSecondHalf(currentMedicationArray) {
    return _.map(currentMedicationArray, ({ name, _id }, index) => {
      if (index % 2 !== 0) {
        if (name === 'Other') {
          return (
            <Field
              name={'current_medication_'.concat(name)}
              id={'current_medication_'.concat(name)}
              label={name}
              type={'checkbox'}
              component={OtherFieldCheckbox}
              onChange={(_e, newValue) => {
                if (!newValue) {
                  this.props.change('current_medication_other_field', '');
                }
              }}
            />
          );
        } else {
          return (
            <Field
              name={'current_medication_'.concat(name)}
              label={name}
              type={'checkbox'}
              parse={(value) => (value ? { name, _id } : false)}
              component={MedicationField}
            />
          );
        }
      }
    });
  }

  renderOtherField() {
    return (
      <Field name="current_medication_other_field" component={OtherField} />
    );
  }

  render() {
    const { handleSubmit, previousPage, onSubmit, classes } = this.props;
    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Grid container spacing={1} direction="row">
              <Grid xs={12}>
                <img
                  src={snapmedLogo}
                  style={{ width: 100, marginBottom: 10 }}
                />
              </Grid>
              <form className={classes.form} onSubmit={handleSubmit}>
                <div>
                  <Typography
                    variant="h4"
                    color="primary"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Current Medication
                  </Typography>
                  <br />
                  <Typography variant="h6" color="primary">
                    Please check any current medication you are currently
                    taking:
                  </Typography>

                  <Grid container direction="row">
                    <Grid item xs={6}>
                      {this.renderCurrentMedicationCheckBoxesFirstHalf(
                        this.props.currentMedicationArray
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {this.renderCurrentMedicationCheckBoxesSecondHalf(
                        this.props.currentMedicationArray
                      )}
                    </Grid>
                    {this.props.currentMedicationOtherField === true && (
                      <Grid item xs={12}>
                        {this.renderOtherField()}
                      </Grid>
                    )}
                  </Grid>
                </div>

                <Button
                  variant="contained"
                  style={{ backgroundColor: '#ff4a4a', color: '#ffffff' }}
                  type="submit"
                  className={classes.previous}
                  onClick={previousPage}
                >
                  Previous
                </Button>
                <Button
                  type="submit"
                  className={classes.submit}
                  variant="contained"
                  color="primary"
                >
                  {' '}
                  next
                </Button>
              </form>
            </Grid>
          </Paper>
        </main>
      </Fragment>
    );
  }
}

function validate(values, props) {
  var errors = {};

  const currentMedicationArray = props.currentMedicationArray;
  errors = validationHelperFunction(
    values,
    currentMedicationArray,
    'current_medication_',
    'current_medication_No Medications',
    errors
  );

  if (
    !('current_medication_other' in values) &&
    !values.current_medication_other &&
    !values.current_medication_other_field
  ) {
    errors.current_medication_other_field = 'This field cannot be blank';
  }

  return errors;
}

function mapStateToProps(state) {
  return {
    wizard: state.wizard,
    currentMedicationArray: state.medicalData.currentMedicationArray,
    currentMedicationOtherField:
      state.medicalFormOtherField.currentMedicationOtherField,
  };
}

CurrentMedicationForm = reduxForm({
  validate: validate,
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(CurrentMedicationForm);

export default withStyles(styles)(
  connect(mapStateToProps, actions)(CurrentMedicationForm)
);
