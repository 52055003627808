import React, { Component, Fragment, useState } from 'react';
import {
  reduxForm,
  formValueSelector,
  clearFields,
  Field,
  FormSection,
} from 'redux-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Button,
  CssBaseline,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Chip,
  Paper,
  Typography,
  TextField,
  Grid,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  Divider,
  FormLabel,
  OutlinedInput,
  Slide,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TextButton from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../../actions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../../styles/safariDatePicker.css';

const styles = (theme) => ({
  dateContainer: {
    marginLeft: 20,
  },
  dateField: {
    padding: 0,
  },
  resize: {
    fontSize: 12,
  },
});

const AdditionalCommentField = ({
  input,
  name,
  label,
  type,
  meta: { touched, error },
}) => (
    <TextField
      style={{ width: '100%' }}
      multiline
      {...input}
      //required
      type={type}
      variant="outlined"
      label={label}
      id={name}
    />
  );

const EstimatedTextField = ({
  input,
  name,
  label,
  type,
  meta: { touched, error },
}) => (
    <TextField
      variant="outlined"
      {...input}
      type={type}
      label={label}
      id={name}
    />
  );

const EstimatedSelectField = ({
  input,
  name,
  label,
  type,
  meta: { touched, error },
}) => (
    <FormControl>
      <InputLabel
        htmlFor={name}
        style={{ fontSize: '0.9rem', paddingLeft: '5px' }}
      >
        Unit
    </InputLabel>
      <Select {...input} input={<OutlinedInput id={name} />}>
        <MenuItem value="">
          <em />
        </MenuItem>
        <MenuItem value="day(s)">day(s)</MenuItem>
        <MenuItem value="week(s)">week(s)</MenuItem>
        <MenuItem value="month(s)">month(s)</MenuItem>
        <MenuItem value="indefinite">indefinite</MenuItem>
      </Select>
    </FormControl>
  );

const DateField = ({
  input,
  name,
  label,
  font_size,
  type,
  meta: { touched, error },
}) => (
    <TextField
      {...input}
      variant="outlined"
      //required
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        classes: {
          input: font_size,
        },
      }}
      type={type}
      error={error}
      helperText={error}
      id={name}
    />
  );

const isDateSupported = () => {
  let i = document.createElement("input");
  i.setAttribute("type", "date");
  return i.type !== 'text';
}

const SafariDatePicker = ({ id, input, meta: { touched, error } }) => {
  const [startDate, setStartDate] = useState();
  const CustomInput = ({value, onClick}) => {
    return (
      <TextField
        value={value}
        onClick={onClick}
        //{...input}
        fullWidth
        variant="outlined"
        size="small"
        label="Date"
        InputLabelProps={{
          shrink: true
        }}
        placeholder="yyyy-mm-dd"
        error={touched && error}
        helperText={touched && error}
      />
    )
  };
  return (
    <div>
      <DatePicker
        {...input}
        name={id}
        customInput={<CustomInput/>}
        dateFormat='yyyy-MM-dd'
        selected={startDate}
        showYearDropdown
        showMonthDropdown
        onChange={date => {
          setStartDate(date)
          const newDate = date.toLocaleDateString('en-CA');
          input.onChange(newDate);
        }}
      />
    </div>
  );
};

const CheckboxFieldEndate = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
    <div>
      <FormControl style={{ width: '100%' }}>
        <FormControlLabel
          control={
            <Checkbox
              style={{ padding: 2 }}
              {...input}
              id={name}
              color="primary"
              checked={input.value ? label : false}
            />
          }
          label="unknown"
        />
      </FormControl>
    </div>
  );

const CheckboxField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
    <div>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              {...input}
              id={name}
              color="primary"
              checked={input.value ? label : false}
            />
          }
          label={<Typography style={{ fontSize: '0.875rem' }}>{label}</Typography>}
          labelPlacement='end'
        />
      </FormControl>
    </div>
  );

let FinalCheck = ({ name, input, label, type }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              {...input}
              name={name}
              //disabled={this.props.state.drugInteractionCheck && this.props.state.controlledSubstanceCheck}
              checked={input.value ? true : false}
              onClick={dispatch(actions.finalizeSickNoteAction(input.value))}
              color="primary"
            />
          }
        />
      </FormControl>
    </div>
  );
};

class GeneralNoteForm extends Component {
  constructor(props) {
    super(props);
  }

  renderAdditionalComment(name, type, label) {
    return (
      <Field
        name={name}
        type={type}
        label={label}
        component={AdditionalCommentField}
      />
    );
  }

  renderCheckBox(name, type, label) {
    return (
      <Field
        name={name}
        type={type}
        label={label}
        component={
          name === 'finalize_general_note' ? FinalCheck : CheckboxField
        }
      />
    );
  }

  renderText(name, type, label) {
    return (
      <Field
        name={name}
        type={type}
        label={label}
        component={EstimatedTextField}
      />
    );
  }

  renderSelect(name, type, label) {
    return (
      <Field
        name={name}
        type={type}
        label={label}
        component={EstimatedSelectField}
      />
    );
  }

  renderCheckBoxEndate(name, type, label) {
    return (
      <Field
        name={name}
        type={type}
        label={label}
        component={CheckboxFieldEndate}
      />
    );
  }

  renderDateField(name, type, isDateSupported) {
    return (
      <Field
        name={name}
        type={type}
        component={isDateSupported ? DateField : SafariDatePicker}
        font_size={this.props.classes.resize}
      />
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Slide direction="right" in={true}>
        <div
          style={{
            margin: 'auto',
            width: '90%',
          }}
        >
          <FormSection name="generalNoteForm">
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              style={{
                padding: '1rem',
                height: '36rem',
                overflowY: 'auto',
                margin: 0,
                borderBottom: '1px solid gray',
              }}
            >
              <Typography
                variant="h5"
                color="primary"
                style={{ textAlign: 'center' }}
              >
                Sick Note
              </Typography>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                spacing={1}
              >
                <Grid item xs={12} sm={6}>
                  <Typography
                    className={classes.dateTypography}
                    variant="body2"
                  >
                    Patient was seen on:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.dateField}>
                  {this.renderDateField('seen_on_date', 'date', isDateSupported())}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    className={classes.dateTypography}
                    variant="body2"
                  >
                    Start date of total disability:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {this.renderDateField('start_date_date', 'date', isDateSupported())}
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography
                    className={classes.dateTypography}
                    variant="body2"
                  >
                    End date (Optional):
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  {this.renderCheckBoxEndate(
                    'end_date_unknown_check',
                    'input',
                    'SAMPLE'
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {this.renderDateField('end_disability_date', 'date', isDateSupported())}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    className={classes.dateTypography}
                    variant="body2"
                  >
                    Return to regular work on:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {this.renderDateField('return_to_regular_date', 'date', isDateSupported())}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    className={classes.dateTypography}
                    variant="body2"
                  >
                    Patient fit for light duty on:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {this.renderDateField('patient_light_duty_date', 'date', isDateSupported())}
                </Grid>

                <Grid item xs={12}>
                  {this.renderCheckBox(
                    'condition_confirmed_exam_check',
                    'input',
                    'Confirmed by virtual medical exam.'
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Fragment>
                  {this.renderAdditionalComment(
                    'general_sicknote_comment',
                    'input',
                    'Additional Comments'
                  )}
                </Fragment>
              </Grid>
            </Grid>

            {/*  <Button style={{ bottom: 0, marginTop: '5%', marginLeft: '40%' }}>
            Sign
          </Button>*/}
          </FormSection>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={1}
            style={{
              padding: '1rem',
            }}
          >
            <Grid item xs={11}>
              <Typography
                variant="subtitle2"
                style={{
                  textAlign: 'right',
                }}
              >
                Finalize Sick Note
              </Typography>
            </Grid>

            <Grid item xs={1}>
              {this.renderCheckBox('finalize_general_note', 'input', 'a')}
            </Grid>
          </Grid>
        </div>
      </Slide>
    );
  }
}

const date_fields = [
  'seen_on_date',
  'start_date_date',
  'end_disability_date',
  'return_to_regular_date',
  'estimated_return_date',
  'patient_light_duty_date',
];

function validate(values) {
  var errors = {};
  var now = new Date().toISOString().slice(0, 10);
  //values[date] != 'start_date_date' &&
  _.each(date_fields, (date) => {
    if (values[date] < now) {
      errors[date] = 'Date must be in future';
    }
  });

  return errors;
}

GeneralNoteForm = reduxForm({
  validate,
  form: 'noteForm',
  destroyOnUnmount: false,
})(GeneralNoteForm);

GeneralNoteForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GeneralNoteForm);
