import _ from 'lodash';
import React, { Component, Fragment, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  reduxForm,
  Field,
  FormSection,
  formValueSelector,
  reset,
} from 'redux-form';
import PropTypes from 'prop-types';
import {
  Button,
  CssBaseline,
  FormControl,
  Radio,
  RadioGroup,
  Paper,
  Typography,
  TextField,
  Grid,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Slide,
  Switch,
  Fade,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import * as actions from '../../actions';
import MuiAlert from '@material-ui/lab/Alert';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: '1.5rem',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    float: 'right',
  },
  choices: {
    marginTop: 30,
  },
  title: {
    margin: '30px 0',
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  otherField: {
    marginTop: 40,
  },
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
  },
});

const SmokingField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <FormControl error={touched && error} component="fieldset">
    <RadioGroup name={name} {...input} row>
      <FormControlLabel
        value="isTobaccoSmoker_yes"
        control={<Radio color="primary" />}
        label="Yes"
      />
      <FormControlLabel
        value="isTobaccoSmoker_no"
        control={<Radio color="primary" />}
        label="No"
      />
    </RadioGroup>
    {touched && error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const DrinkingField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <FormControl error={touched && error} component="fieldset">
    <RadioGroup name={name} {...input} row>
      <FormControlLabel
        value="isAlcoholConsumer_yes"
        control={<Radio color="primary" />}
        label="Yes"
      />
      <FormControlLabel
        value="isAlcoholConsumer_no"
        control={<Radio color="primary" />}
        label="No"
      />
    </RadioGroup>
    {touched && error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const SubstanceField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error },
}) => (
  <FormControl error={touched && error} component="fieldset">
    <RadioGroup name={name} {...input} row>
      <FormControlLabel
        value="isSubstanceUser_yes"
        control={<Radio color="primary" />}
        label="Yes"
      />
      <FormControlLabel
        value="isSubstanceUser_no"
        control={<Radio color="primary" />}
        label="No"
      />
    </RadioGroup>
    {touched && error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const SubstancesField = ({ name, label, input, meta: { touched, error } }) => (
  <TextField
    {...input}
    id={name}
    label={label}
    type="input"
    variant="outlined"
    fullWidth
    error={touched && error}
    helperText={touched && error}
  />
);

const checkForSubstances = (values) => {
  if (
    values &&
    values.medicalProfile &&
    values.medicalProfile.isSubstanceUser === 'isSubstanceUser_yes'
  ) {
    return true;
  }
};

class EditSubstances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openOtherField: this.props.selectedUserMedical.isSubstanceUser,
    };
  }

  renderSmokingField() {
    return (
      <Field
        name="isTobaccoSmoker"
        type="text"
        //onBlur={null}
        component={SmokingField}
      />
    );
  }

  renderDrinkingField() {
    return (
      <Field name="isAlcoholConsumer" type="text" component={DrinkingField} />
    );
  }

  renderSubstanceField() {
    return (
      <Field
        name="isSubstanceUser"
        type="text"
        component={SubstanceField}
        onChange={(_event, newValue) => {
          this.setState({
            openOtherField: newValue === 'isSubstanceUser_yes',
          });
        }}
      />
    );
  }

  renderSubstancesInputField(name, label) {
    return <Field name={name} label={label} component={SubstancesField} />;
  }

  render() {
    const {
      classes,
      selectedUser,
      dashboardUpdateForm,
      selectedUserMedical,
      reset,
      handleSubmit,
    } = this.props;

    const onSubmit = (values) => {
      const {
        isTobaccoSmoker,
        isAlcoholConsumer,
        isSubstanceUser,
        substance_input_field,
      } = values.medicalProfile;

      values.medicalProfile = {
        isTobaccoSmoker: isTobaccoSmoker === 'isTobaccoSmoker_yes',
        isAlcoholConsumer: isAlcoholConsumer === 'isAlcoholConsumer_yes',
        isSubstanceUser: isSubstanceUser === 'isSubstanceUser_yes',
        substance:
          isSubstanceUser === 'isSubstanceUser_yes'
            ? substance_input_field
            : '',
      };
      values.id = selectedUser._id;

      this.props.dispatch(actions.editProfile(values));

      setTimeout(() => {
        this.props.closeEditPage();
      }, 1000);
    };

    return (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <FormSection name="medicalProfile">
              <Grid container direction="row">
                <Grid item className={classes.title}>
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Edit Substances
                  </Typography>
                </Grid>
                <Grid container direction="row">
                  <Grid item container xs={6} alignItems="center">
                    <Typography variant="body1">
                      Do you smoke tobacco?
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {this.renderSmokingField()}
                    {/*{this.props.isTobaccoSmoker==='isTobaccoSmoker_yes' && (  )} */}
                  </Grid>
                  <Grid item container xs={6} alignItems="center">
                    <Typography variant="body1">
                      Do you consume alcohol?
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {this.renderDrinkingField()}
                    {/*{this.props.isAlcoholDrinker==='isTobaccoSmoker_yes' && (  )} */}
                  </Grid>
                  <Grid item container xs={6} alignItems="center">
                    <Typography variant="body1">
                      Do you use any substance?
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {this.renderSubstanceField()}
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  {this.state.openOtherField && (
                    <Grid item xs={12}>
                      {this.renderSubstancesInputField(
                        'substance_input_field',
                        'Substance'
                      )}
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  direction="row"
                  className={classes.bottomSpacing}
                >
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      className={classes.alignButton}
                      onClick={() => {
                        reset();
                        this.props.closeEditPage();
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.alignButton}
                      style={{
                        float: 'right',
                      }}
                      onClick={handleSubmit((data) => onSubmit(data))}
                      disabled={!this.props.valid}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </FormSection>
          </main>
        </Slide>
      </Fragment>
    );
  }
}

const validate = (values, props) => {
  let errors = { medicalProfile: {} };

  if (values.medicalProfile) {
    if (
      values.medicalProfile.substance_input_field === '' &&
      values.medicalProfile.isSubstanceUser === 'isSubstanceUser_yes'
    ) {
      errors.medicalProfile['substance_input_field'] =
        'This field cannot be blank';
    }
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedUser: state.selectedUser,
    selectedUserMedical: state.selectedUserMedical,
    dashboardUpdateForm: state.form.dashboardUpdateForm,
    initialValues: {
      medicalProfile: {
        isTobaccoSmoker: state.selectedUserMedical.isTobaccoSmoker
          ? 'isTobaccoSmoker_yes'
          : 'isTobaccoSmoker_no',
        isAlcoholConsumer: state.selectedUserMedical.isAlcoholConsumer
          ? 'isAlcoholConsumer_yes'
          : 'isAlcoholConsumer_no',
        isSubstanceUser: state.selectedUserMedical.isSubstanceUser
          ? 'isSubstanceUser_yes'
          : 'isSubstanceUser_no',
        substance_input_field: state.selectedUserMedical.substance,
      },
    },
  };
}

EditSubstances = reduxForm({
  validate,
  form: 'dashboardUpdateForm',
  destroyOnUnmount: false,
  //onSubmit: handleSave,
  enableReinitialize: true,
  //forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(EditSubstances);

EditSubstances = connect(mapStateToProps, actions)(EditSubstances);

export default withStyles(styles)(EditSubstances);
