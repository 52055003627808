import React, { Component, Fragment } from 'react';
import { Elements } from 'react-stripe-elements';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';
import { Lock } from '@material-ui/icons';
import {
  CssBaseline,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import trustBadge from '../../images/Stripe-trust-badge.png';
import cardLogosTrio from '../../images/credit-card-logos.png';

import CheckoutPaymentForm from './CheckoutPaymentForm';

const styles = (theme) => ({
  layout: {
    width: 'auto',
    height: '100%',
    //  display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down(350 + theme.spacing.unit * 3 * 2)]: {
      width: 350,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    flexGrow: 1,
    flexDirection: 'row',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  cardField: {
    border: 'solid 1px lightgray',
    borderRadius: '5px',
    padding: '10px',
    base: {
      '::placeholder': {
        color: '#a2a4a6',
      },
    },
    '&:hover': {
      borderColor: 'primary',
    },
  },
  trust: {
    paddingTop: theme.spacing.unit * 3,
  },
  ohipDisclaimer: {
    paddingTop: theme.spacing.unit * 3,
  },
});

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      product: undefined,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
      product: this.props.location.state.product,
    });
  }

  render() {
    const { classes } = this.props;
    const { product } = this.state;
    return (
      <Fragment>
        <CssBaseline />
        <Elements>
          <main className={classes.layout}>
            <Paper className={classes.paper}>
              {!this.state.loading ? (
                // loaded state
                <Fragment>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h5" color="primary">
                        {product.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body1"
                        color="primary"
                        align="center"
                      >
                        {product.description || 'Some info about the product.'}
                      </Typography>
                    </Grid>
                    {product.plan_type && product.plan_type === 'OHIP' && (
                      <Typography
                        variant="body1"
                        color="primary"
                        className={classes.ohipDisclaimer}
                      >
                        <b>
                          This plan requires that you have an Ontario health
                          card.
                        </b>
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    container
                    justify="center"
                    style={{ padding: '30px 0px' }}
                  >
                    <img
                      src={cardLogosTrio}
                      alt="Credit card logos"
                      width="200px"
                    />
                  </Grid>
                  <CheckoutPaymentForm
                    product={product}
                    origin={this.props.location.state.origin}
                    redirect={this.props.location.state.redirect}
                  />
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.trust}
                  >
                    <Grid item container justify="center" xs={12} spacing={1}>
                      <Grid item>
                        <Lock color="primary" style={{ fontSize: 20 }} />
                      </Grid>
                      <Grid item>
                        <Typography color="primary">
                          Guaranteed <b>safe & secure</b> checkout.
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <img
                        src={trustBadge}
                        alt="Stripe Trust Badge Image"
                        width="150px"
                      />
                    </Grid>
                  </Grid>
                </Fragment>
              ) : (
                // loading state
                <CircularProgress size={25} color="primary" />
              )}
            </Paper>
          </main>
        </Elements>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

Checkout = withStyles(styles)(Checkout);

export default connect(mapStateToProps)(Checkout);
