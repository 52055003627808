import React, { Component } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';

const StripePaymentPrompt = ({
  description,
  amount,
  actionTaken,
  handleStripeToken,
  history,
  paymentTypeId,
  promoCodeId
}) => {
  //promoId = promoId ? promoId : false;

  return (
    <StripeCheckout
      name="SnapMed"
      description={description}
      currency="CAD"
      amount={amount}
      token={token => {
        actionTaken(amount); // onplan submit -> takes you to acknowledgement
        handleStripeToken(
          token,
          amount,
          history,
          description,
          promoCodeId,
          paymentTypeId
        );
      }}
      stripeKey="pk_test_sI1gCB87sxyKUO3QVa3bMukO"
    >
      <Button
        style={{ width: 102, height: 38 }}
        variant="contained"
        color="primary"
      >
        Pay Now
      </Button>
    </StripeCheckout>
  );
};

export default connect(null, actions)(withRouter(StripePaymentPrompt));
