const pharmacy64 = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAIACAYAAAD0eNT6AAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAABf8SURB
VHic7d17sG53Xd/xz/dwJBdCGECxWO6QhotkqoSiFguiFpSOVBEBLd4QHKeKWC+o006ljpcWpw5i
U0CkDtXSqlwUalAUUEAZCoUAEVHUyKUpIhJiSFCSfPvHPiKUS87J2c/+7b2+r9fM/iOZc87zmT17
7/Xeaz3PsypwQnffLclXJPnCJJ+d5DOS3GLpKOB0dJIrkrw7yVuSvCzJi6rq/y5dxaFQqwewXnd/
cZIfTPJF8TUBW/fhJC9M8sNV9ebVY1jHD/vBuvszkzwze7/1A7Ncl+TpSb6vqq5ePYaDJwCG6u7P
S/IrSW6zeguw1KVJ/llVXbZ6CAdLAAzU3Q9K8qIkZ6/eAhwKlyd5QFX90eohHBwBMEx33zvJq5Kc
u3oLcKj8SZL7VdVfrB7CwTi2egAHp7vPTPILcfAHPt5dkjynu/1iOIQAmOUHktx79Qjg0PqyJA9f
PYKDofSG6O7PSPKnSW62egtwqP1RkrtX1fWrh7BbzgDM8bg4+AM37LwkX756BLsnAOb42tUDgCPj
61YPYPdcAhigu2+X5J2rdwBHxl8m+fSq6tVD2B1nAGa4z+oBwJFyqyR3Wj2C3RIAM9xt9QDgyDlv
9QB2SwDM4I5+wKnyc2PjBMAMZ6weABw5Z64ewG4JAAAYSAAAwEACAAAGEgAAMJAAAICBBAAADCQA
AGAgAQAAAwkAABhIAADAQAIAAAYSAAAwkAAAgIEEAAAMdHz1ADbtSVX1H1aPgKOsu5+T5DGrd7A9
zgAAwEACAAAGEgAAMJAAAICBBAAADCQAAGAgAQAAAwkAABhIAADAQAIAAAYSAAAwkAAAgIEEAAAM
JAAAYCABAAADCQAAGEgAAMBAAgAABhIAADCQAACAgQQAAAwkAABgIAEAAAMJAAAYSAAAwEACAAAG
EgAAMJAAAICBBAAADHR89QBYrbtvluS2SW6xegs7dV2SK5K8q6quXT0GVhMAjNPdx5I8JMk/T/Kg
JHdJUktHcZD+prvfkuQ3kvz3qrpk9SBYwSUAxujuY939TUnenuR/JnlckrvGwX+amyb53CTfn+SN
3f073X3/xZvgwAkARujuuyV5dZJnJ7nz4jkcLl+Y5JXd/bPdfc7qMXBQBACb191fnOT1ST5v9RYO
tW9O8pruvv3qIXAQBACb1t3/NMmvJTl39RaOhHsleVV33271ENg1AcBmdfe9kvxy9q75wsm6Q5Jf
6+6zVw+BXRIAbFJ3n5HkvyW5+eotHEn3TvKU1SNglwQAW/WEJBesHsGR9m3dfeHqEbArAoDNOXHq
9vtX7+DIqyRPXj0CdkUAsEWPTnKr1SPYhId0911Wj4BdEABs0SNWD2AzjiV5+OoRsAsCgE3p7psm
ecDqHWzKl64eALsgANia85OcuXoEm+LJpGySAGBr7rh6AJvzmd191uoRsN8EAFvjHf/YBbeKZnME
AFvzaasHsElunc7mCAAAGEgAAMBAAgAABhIAADCQAACAgQQAAAwkAABgIAEAAAMJAAAYSAAAwEAC
AAAGEgAAMJAAAICB3OEK9sf7kly2esQA905y09UjYAsEAOyPF1fVN64esXXd/c4kt1u9A7bAJQAA
GEgAAMBAAgAABhIAADCQAACAgQQAAAwkAABgIAEAAAMJAAAYSAAAwEACAAAGEgAAMJAAAICBBAAA
DCQAAGAgAQAAAwkAABhIAADAQAIAAAYSAAAwkAAAgIEEAAAMJAAAYCABAAADCQAAGEgAAMBAAgAA
BhIAADCQAACAgY6f7B/s7rOTPCDJ5yQ5P8m5Sc7Z0S7213mrBwBHzpO6++tXj+AGXZXkyiRvS/KG
JL9dVVefzF+8wQDo7s9L8h1JvirJmacxEoCj414nPjhaPtTdz0/ytKp6zaf6g5/0EkB33767n5fk
95J8bRz8AeCwOzN7x+zf6+7ndfftP9kf/IQB0N1fkeSS7P3WDwAcPV+V5JITx/SP83EB0N3fmuT5
SW6542EAwG7dMsnzTxzbP8bHBEB3PyrJRUluckDDAIDdukmSi04c4z/iIwHQ3fdI8ux4aSAAbM2x
JP+lu+/90f8j3X0syXOSnLVoGACwW2cmefaJY/5Hftt/dJILl00CAA7Chdk75n8kAL573RYA4AB9
X5Ic6+77ZO/d/QCA7bugu+9zLMlDVy8BAA7UQ48l+YLVKwCAA/X5x5LcffUKAOBA3eNYkk9fvQIA
OFC3Ppbk7NUr2KxrVw+ADbhu9QA26WbHktTqFWzWlasHwAb4PmIXytv+skvvWD0ANuCdqwewTQKA
XXrz6gGwAW9aPYBtEgDsyqVVdfnqEbABr0xyzeoRbI8AYFeev3oAbEFVXZPk11fvYHsEALtwbZKf
WT0CNuSi1QPYHgHALjyjqjxxCfZJVb00yctW72BbBAD77T1Jnrx6BGzQdyf5m9Uj2A4BwH66Lsk3
VtV7Vw+BramqNyb5gdU72A4BwH56QlW9ZPUI2Kqq+o9J/vPqHWyDAGA/XJfk26rKE5Vg9/5lXGZj
HwgATtc7knxxVT199RCYoKq6qn4oydckef/iORxhAoAb68ok/y7Jvarqt1ePgWmq6peS3Ct7L7n1
5EBOmQDgVFyV5NeSPD7J7arq31bVVYs3wVhVdXlVPT7JHZJ8X5LfiRjgJB1f+NhfuvCxOTVXJ7k8
yWVV1avHAB+rqt6T5ClJntLdx5PcNcltkpyxdBgn66UrHnRZAFTVb656bICtqqprk7ztxAdHQPea
36tcAgCAgQQAAAwkAABgIAEAAAMJAAAYSAAAwEACAAAGEgAAMJAAAICBBAAADCQAAGAgAQAAAwkA
ABhIAADAQAIAAAYSAAAwkAAAgIEEAAAMJAAAYCABAAADCQAAGEgAAMBAAgAABhIAADCQAACAgQQA
AAwkAABgoOOrBxxW3f1ZSb4syT9Kcs8kt0py5tJRnIybrx7AJr26u69dPYIb9NdJ3p/krUlel+Ti
qvqztZMOLwHw/+nuByX5niQPjjMkwJ47rB7AKfmCJI9N0t398iQ/WVUvXrzp0HGAO6G7b9fdL07y
W9n7zd/nBuBoqyQPSvKi7n55d99t9aDDxEEuSXc/OMklSR66egsAO/HAJG/o7q9ePeSwGB8A3f3I
JC/K3jV+ALbrnCT/o7u/dfWQw2B0AHT3lyR5TpJPW70FgANxLMlF3f3w1UNWGxsA3X2bJD+f5Kar
twBwoI4l+bnuPm/1kJXGBkCSn0rymatHALDEOUkuWj1ipZEB0N33TfI1q3cAsNSXdPdDVo9YZWQA
JPmu7L08BIDZnrh6wCrjAqC7z0nyVat3AHAofGl333b1iBXGBUD23hTijNUjADgUjiUZeRlgYgDc
d/UAAA6VC1cPWGFiAPyD1QMAOFTOXz1ghYkB4B3/APhot1w9YIWJAeCWvuzCh1cPGMLnmV04a/WA
FSYGAOzCB1YPGMLnGfaJAID98SerBwzxp6sHwFYIANgfl6weMMQbVw+ArRAAcPo+kOS1q0cM8Rur
B8BWCAA4fc+rKk9OOxivjcstsC8EAJyeTvLTq0dMUVXXZ/gd3GC/CAA4Pb9YVW9YPWKYi5K8Y/UI
OOoEANx470/yvatHTFNV1yR5wuodcNQJALhxrk/yLVX1ztVDJqqqX4lLL3BaBACcuk7yr6rq+auH
DPfEJC9YPQKOKgEAp+bDSR5XVU9dPWS6qrouydckefbqLXAUCQA4eX+Y5P5V9bOrh7Cnqq6tqscm
+aYkV67eA0eJAIAb9q4k35Xk3lXlDX8Ooar6uSTnJXlakqvWroGjobq7lzxwVa143O5+ZZL7r3hs
jowPJ3lbklcneXGSl1TVtWsncbK6+xZJHp7kIUkuTHKnJEt+3nBkvLWq7rnqwVcdh4+veNChHpnk
datHcIOuSPJX3tnv6KqqD2TveQHPTpLuPivJuUlutnIXJ+Wu8XbPB0YAHJz/U1XewhQO2In3Dbhm
9Q5uWHefsXrDJJ4DAAADCQAAGEgAAMBAAgAABhIAADCQAACAgQQAAAwkAABgIAEAAAMJAAAYSAAA
wEACAAAGEgAAMJAAAICBBAAADCQAAGAgAQAAAwkAABhIAADAQAIAAAYSAAAwkAAAgIEEAAAMJAAA
YCABAAADCQAAGEgAAMBAAgAABhIAADCQAACAgQQAAAwkAABgIAEAAAMJAAAYSAAAwEACAAAGEgAA
MJAAAICBBAAADCQAAGAgAQAAAwkAABhIAADAQAIAAAYSAAAwkAAAgIEEAAAMJAAAYCABAAADCQAA
GEgAAMBAAgAABhIAADCQAACAgQQAAAwkAABgIAEAAAMJAAAYSAAAwEACAAAGEgAAMJAAAICBBAAA
DCQAAGAgAQAAAwkAABhIAADAQAIAAAYSAAAwkAAAgIEEAAAMJAAAYCABAAADCQAAGEgAAMBAAgAA
BhIAADCQAACAgQQAAAwkAABgIAEAAAMJAAAYSAAAwEACAAAGEgAAMJAAAICBBAAADCQAAGAgAQAA
AwkAABhIAADAQMdXDxjkWd191eoRfErvT/KXSf4gyWuSvKKqrlk7iVPV3ceS3DfJA5LcM8lnJTk3
ft4dBWeuHjCJb4iDc/7qAZyyq7v7uUl+sqouXT2GT627PyPJdyT5liS3XTwHDj2XAOCTOzvJY5Nc
0t3P7O5brB7Ex+vuY939PUnenuTfxMEfTooAgBt2kySPS/LG7r7P6jH8nRO/9b8syVOyd5ofOEkC
AE7enZK8orsftHoISXffIcmrs3etHzhFAgBOzTlJXtjdn7t6yGTdfcskFyc5b/UWOKoEAJy6myf5
5e52ynmdZ2TvGf7AjSQA4Ma5c5IfXz1iou5+dJJHrN4BR50AgBvvcd1999UjJunumyb50dU7YAsE
ANx4x5N85+oRwzwie0/GBE6TAIDT83XdfcbqEYN8w+oBsBUCAE7PzZPcf/WICbr7nCT/ZPUO2AoB
AKfvfqsHDPE5SZxtgX0iAOD0eSLgwfB5hn0kAOD03Wr1gCFuvXoAbMnEALh29QA252arBwxx1uoB
bNbI48LEALhy9QAADpWRx4WJAfBnqwcAcKhctnrAChMD4JLVAwA4VN60esAKEwPgN1YPAOBQeenq
ASuMC4CqemeS167eAcCh8PYkb1w9YoVxAXDCf1o9AIBD4aKq6tUjVpgaAL+Q5NLVIwBY6t1JnrF6
xCojA6CqrkvyHUmuX70FgGWeUFVXrx6xysgASJKqenmSf796BwBLPLOqnr96xEpjA+CEf53kuatH
AHCgLk7y7atHrDY6AKrq+iSPSfLM1VsAOBC/mOQrq+rDq4esNjoAkr3nA1TVtyZ5bJK/Wr0HgJ34
UJInJnlUVf316jGHwfgA+FtV9ewk5yf5mSS+OAC24drsvfLrHlX11Kkv+ftEBMBHqarLq+rxSW6f
vVJ8aYbeJALgCLsqySuSPCnJHavqX1TVZUsXHULHVw84jKrqvUmeeuIj3f33s3cv8k9buYsbdJ8M
fk0vO/P0JM9aPYKTcm2S9yV5t9/0b5gAOAlV9e7svWEEh1h333z1Bjbp8qp6/eoRsN9cAgCAgQQA
AAwkAABgIAEAAAMJAAAYSAAAwEACAAAGEgAAMJAAAICBBAAADCQAAGAgAQAAAwkAABhIAADAQAIA
AAYSAAAwkAAAgIEEAAAMJAAAYCABAAADCQAAGEgAAMBAAgAABhIAADCQAACAgY6veuDuvsuqx+ak
XZPkyqr64OohwMnr7nOTnJvkpqu3cHgtC4Akf7zwsTkF3f2+JG9K8ptJXlBVb108Cfgo3f35SR6W
5IFJ7pnk5ksHcSS4BMDJuHWSL0ryI0ku7e7f6u5/vHgTjNbd1d1f3d2XJPndJE9Kcr84+HOSBACn
qpI8KMmruvs53X2L1YNgmu6+Y5KXJfmlJBcsnsMRJQA4HY9J8vruPm/1EJiiux+Y5A3ZO90PN5oA
4HTdNXtnA85fPQS2rrsfnOQlSW65egtHnwBgP9wmyUu6+9NXD4Gt6u4LkvxykjNWb2EbBAD75U5J
nrV6BGxRd5+V5LlJzlm9he0QAOynh3X3w1aPgA363uy9vA/2jQBgv/1Id9fqEbAV3X3LJN+zegfb
IwDYb/fK3nsGAPvjG+K1/eyAAGAXHrl6AGzIo1YPYJsEALvw4NUDYAtOnP6/7+odbJMAYBfu2N23
Wj0CNuCC+DnNjvjCYlfuvHoAbIC7prIzAoBdcY8AOH2+j9iZY0k+tHoEm+Q+5HD6fB+xK9ccS/L+
1SsAgAN1xbEkb1u9AgA4UH9wLMnrVq8AAA7U648luXj1CgDgQF18LMkrkvzp4iEAwMF4R5LfPlZV
1yf56dVrAIAD8dSquu5v3wfgoiSXLRwDAOzeZdk75u+9EVBVfSh7d5y6bt0mAGCHrk/yLSeO+X/3
ToBV9TtJnrxqFQCwUz9UVb/1t//xMW8FXFU/nORpBz4JANilp504xn/Ex90LoKqekOQH43IAABx1
1yX5wRPH9o/xCW8GVFU/luSBSd66210AwI68NckDTxzTP84nvRtgVb0qe/eiflySN+9mGwCwz96c
vWP3BSeO5Z/Q8U/1L1TVtUmeleRZ3X1BkgcnuU/27lF96/3byg79vSRnrx4BHClXJPnL1SM4ae9L
8idJXp/k16vqTSfzlz5lAHy0E//gSf2jHB7dfXGSh6zeARwpz6yqJ60ewW590ksAAMB2CQAAGEgA
AMBAAgAABhIAADCQAACAgQQAAAwkAABgIAEAAAMJAAAYSAAAwEACAAAGEgAAMJAAAICBBAAADCQA
AGAgAQAAAwkAABhIAADAQAIAAAYSAAAwkAAAgIEEAAAMJAAAYCABAAADCQAAGEgAAMBAAgAABhIA
ADDQ8dUDYAPO7+5nrB4xwIWrB8CWCAA4fbdN8vjVIwBOhUsAADCQAACAgQQAAAwkAABgIAEAAAMJ
AAAYSAAAwEACAAAGEgAAMJAAAICBBAAADCQAAGAgAQAAAwkAtuS61QPYJF9XbJIAYEuuWj2ATfqr
1QNgFwQAW/Ku1QPYpHevHgC7IADYjKp6b5L3rN7B5rxl9QDYBQHA1rx89QA25fIkf7h6BOyCAGBr
Xrh6AJvygqrq1SNgFwQAW/OCuAzA/nn66gGwKwKATamqv0ny46t3sAnPq6o3rx4BuyIA2KKLkly6
egRH2geTPGn1CNglAcDmnDgL8LVJrlm9hSPriVX1x6tHwC4JADapqt6U5JFJrl29hSPnJ6rqWatH
wK4JADarql6U5BFxJoCT91Nx6p8hBACbVlUvTPLAJG9fPIXD7cok31BV31lV168eAwdBALB5VfXa
JP8wyZPjfgF8rOuT/Nckn11Vz1k9Bg6SAGCEqvpgVf1QktsneWKSV8bzAya7NMmPJrlbVX19Vb1z
9SA4aMdXD4CDVFVXJHlqkqd2982S3DPJHZPcIslNVm5j565I8udJ3lJVf7F6DKwmABirqj6Y5H+d
+AAYxSUAABhIAADAQAIAAAYSAAAwkAAAgIEEAAAMJAAAYCABAAADCQAAGEgAAMBAAgAABhIAADCQ
AACAgQQAAAwkAABgIAEAAAMJAAAYSAAAwEACAAAGEgAAMJAAAICBBAAADCQAAGAgAQAAAwkAABhI
AADAQAIAAAYSAAAwkAAAgIGOrx7AZl3c3as3APBJOAMAAAMJAAAYSAAAwEACAAAGEgAAMJAAAICB
BAAADCQAAGAgAQAAAwkAABhIAADAQAIAAAYSAAAwkAAAgIEEwPb99eoBwJHj58YAAmD7rlg9ADhy
PrB6ALsnALbvj1cPAI6cP1o9gN0TANv3+tUDgCPnf68ewO7V6gHsVnefneR9Sc5cvQU4Ei6tqs9e
PYLdcwZg46rq6iQvWL0DODJ+fvUADoYzAAN09+cn+d3VO4BD74NJ7lxV7109hN1zBmCAqvq9OAsA
3LCfcPCfwxmAIbr7dkkuSXKr1VuAQ+n3k1xYVdesHsLBcAZgiKp6V5JvTnLd6i3AoXNlkkc5+M8i
AAapql9J8u1JevUW4NC4OslXVtWbVw/hYLkEMFB3PzrJzyY5a/UWYKk/T/KwqnrN6iEcPGcABqqq
5ya5MMnrVm8BlvnVJBc4+M8lAIaqqt9Pcr8kj8nekwOB7eskL0vyJVX1sKp6z+pBrOMSAEmS7r4w
yZcn+YIk98jeqwXOWToKOF0fSPLeJG9J8sokv1pVb187icPi/wEQb7DPctXLWAAAAABJRU5ErkJg
gg==`;
export default pharmacy64;
