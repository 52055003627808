import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import {
  Typography,
  Grid,
  Button,
  Slide,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Checkbox,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 700,
      minHeight: '80%',
    },
  },
  titleMargin: {
    marginBottom: '30px',
  },
  table: {
    height: '100%',
  },
});

class TextChatTranscript extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatHistory: '',
      selected: [],
      page: 0,
      rowsPerPage: 8,
    };
  }

  async componentDidMount() {
    await axios
      .get(`/api/chat-history/${this.props.textChatId}`)
      .then((res) => {
        const ids = [];
        if (res.data && res.data.messages) {
          const importantMessages = res.data.messages.filter(
            (message) => message.isImportant
          );
          importantMessages.forEach((message) => {
            ids.push(message._id);
          });
        }
        this.setState({
          chatHistory: res.data,
          selected: ids,
        });
      });
  }

  handleSelect = (event, id) => {
    this.setState((prevState) => ({
      selected: !prevState.selected.includes(id)
        ? [...prevState.selected, id]
        : prevState.selected.filter((item) => item !== id),
    }));
    this.state.chatHistory.messages.forEach((message) => {
      if (message._id === id) {
        message.isImportant = !message.isImportant;
      }
    });
  };

  handleSave = async () => {
    const { chatHistory, selected } = this.state;
    chatHistory.hasFlaggedMsg = selected.length > 0;
    const response = await axios.post('/api/save-important-messages', {
      chat: chatHistory,
      backdrop: true,
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  render() {
    const { classes } = this.props;
    const { chatHistory, selected, page, rowsPerPage } = this.state;

    return (
      <Fragment>
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid
              item
              container
              style={{ marginTop: '5%' }}
              alignItems="center"
            >
              <Grid item xs={9} sm={10} className={classes.titleMargin}>
                <Typography variant="h5" color="primary">
                  Text Chat Transcript
                </Typography>
              </Grid>
              <Grid item xs={3} sm={2} className={classes.titleMargin}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => this.handleSave()}
                  startIcon={<Save />}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            <Paper>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-label="text chat table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Important</TableCell>
                      <TableCell>From</TableCell>
                      <TableCell>Message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {chatHistory &&
                      chatHistory.messages
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = selected.includes(row._id);
                          return (
                            <TableRow
                              hover
                              onClick={(event) =>
                                this.handleSelect(event, row._id)
                              }
                              role="checkbox"
                              key={row._id}
                              selected={isItemSelected}
                            >
                              <TableCell>
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell>{row.message}</TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                count={chatHistory && chatHistory.messages.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                page={page}
                onChangePage={this.handleChangePage}
              />
            </Paper>
          </main>
        </Slide>
      </Fragment>
    );
  }
}

export default withStyles(styles)(TextChatTranscript);
