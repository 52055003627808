import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import { REACT_APP_ENV } from '../constants';

const backgroundColourMap = {
  development: process.env.REACT_APP_BACKGROUND_COLOUR,
  qa: '#87ddb0',
  production: '#caebf2',
};

export default createMuiTheme({
  palette: {
    secondary: { light: '#fdffff', main: '#caebf2', dark: '#99b9bf' },
    primary: { light: '#455376', main: '#192b4a', dark: '#000023' },
    error: red,
    background: {
      default: backgroundColourMap[REACT_APP_ENV] || '#05b08e',
      opacity: 0.5,
    },
  },

  typography: {
    useNextVariants: true,
  },

  overrides: {
    // Name of the component ⚛️ / style sheet
    MuiDialogTitle: {
      root: {
        color: '#192b4a',
      },
    },
    MuiFormControlLabel: {},
  },
});
