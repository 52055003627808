import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import {
  Grid,
} from '@material-ui/core';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

const styles = theme => ({
  // layout: {
  //   overflow: 'hidden',
  //   marginLeft: theme.spacing.unit,
  //   marginRight: theme.spacing.unit,
  //   [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
  //     marginLeft: theme.spacing.unit * 6,
  //     marginRight: theme.spacing.unit * 6
  //   },
  //   maxWidth: 850
  // },
  reportButton : {
    margin: '10px 0px'
  }
});

Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
  },
  };
const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
let currentDate = new Date();
currentDate = new Date(currentDate.getTime() + currentDate.getTimezoneOffset() * 60 * 1000);
const currentMonth =  month[currentDate.getMonth()];
const previousMonthName = () => {
  const monthName = currentDate.getMonth() === 0 ? (
    month[11]
  ) : (
    month[currentDate.getMonth() - 1]
  );
  return monthName;
}

const currentMonthValues = (deliveryList) => {
  let range = 7;
  const values = []
  while (range > 0) {
    let d = new Date();
    const day = d.getDate();
    d.setDate(d.getDate() - range);
    // in case the resulting day is one of the last days of the previous month
    if (d.getDate() > day) {
      values.push(0);
      range--
    } else {
      const value = deliveryList.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate.getDate() === d.getDate() &&
        itemDate.getMonth() === d.getMonth();
      })
      values.push(value.length);
      range--
    }
  }
  return values;
};

const previousMonthValues = (deliveryList) => {
  let range = 7;
  const values = []
  while (range > 0) {
    let previousDate = new Date();
    const month = previousDate.getMonth() - 1;
    previousDate.setDate(previousDate.getDate() - range);
    previousDate.setMonth(month); // making sure the month is always the same
    const value = deliveryList.filter(item => {
      const itemDate = new Date(item.date);
      return itemDate.getDate() === previousDate.getDate() &&
      itemDate.getMonth() === previousDate.getMonth();
    })
    values.push(value.length);
    range--
  }
  return values;
};

const labels = () => {
  let range = 7;
  const days = [];
  while (range > 0) {
    let d = new Date();
    d.setDate(d.getDate() - range);
    days.push(`${d.getDate()}`);
    range--
  }
  return days;
};


class DailyDeliveriesCard extends Component {
  constructor(props) {
    super(props);
  }

  data() {
    return {
      labels: labels(),
      datasets: [
        {
          label: previousMonthName(),
          data: previousMonthValues(this.props.deliveries ? this.props.deliveries : []),
          backgroundColor: 'rgba(125, 125, 176, 0.5)',
          borderRadius: 3,
        },
        {
          label: currentMonth,
          data: currentMonthValues(this.props.deliveries ? this.props.deliveries : []),
          backgroundColor: 'rgba(0, 0, 255, 0.6)',
          borderRadius: 3,
        },
      ],
    }
  };

  render() {
    return (
      <Grid container>
        <Bar options={options} data={this.data()} />
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    deliveries: state.deliveriesList,
  };
}

DailyDeliveriesCard = connect(mapStateToProps, actions)(withRouter(DailyDeliveriesCard));

export default withStyles(styles)(DailyDeliveriesCard)