import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { reduxForm, Field, FormSection } from 'redux-form';
import eventsApi from '../../api/events-api';
import pathBuilder from '../../api/media-path-builder';
import { RESET_APPT_PARAMS } from './../../actions/types';
import {
  Paper,
  CssBaseline,
  FormControl,
  MenuItem,
  Select,
  Typography,
  TextField,
  Grid,
  InputLabel,
  IconButton,
  Button,
  OutlinedInput,
  Snackbar,
  Collapse,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Badge,
  Chip,
} from '@material-ui/core';
import {
  AccessAlarm,
  ExpandMore,
  Close,
  LibraryBooks,
} from '@material-ui/icons';
import socketIOClient from 'socket.io-client';
import AboutYouIcon from '@material-ui/icons/AccountBox';
import PatientSettingsIcon from '@material-ui/icons/Settings';
import MedicalInfoIcon from '@material-ui/icons/LocalHospital';
import PharmaciesIcon from '@material-ui/icons/LocalPharmacy';
import PaymentHistoryIcon from '@material-ui/icons/Payment';
import AppointmentsIcon from '@material-ui/icons/MeetingRoom';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import * as actions from '../../actions';
import FamilyMedicalFormCompletionPrompts from './FamilyMedicalFormCompletionPrompts';
import PersonalMedicalCompletionPrompt from './PersonalMedicalCompletionPrompt';
import ProfileDashboard from './ProfileDashboard';
import PharmacyInformation from './PharmacyInformation';
import PaymentHistory from './PaymentHistory';
import AppointmentHistory from './appointmentHistory/AppointmentHistory';
import PatientSettings from './PatientSettings';
import PatientProfile from './PatientProfile';
import MedicalInformation from './MedicalInformation';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import loadingLogo from '../../images/loading.gif';
import InputMask from 'react-input-mask';
import IdleTimer from '../commonform/IdleTimer';
import ManageSubscriptions from './ManageSubscriptions';
import { patientSocket } from '../../api/patient-ws';

const constants = require('../../constants');

const styles = (theme) => ({
  layout: {
    overflow: 'hidden',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: theme.spacing.unit * 6,
      marginRight: theme.spacing.unit * 6,
    },
    maxWidth: 850,
  },

  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },

  toolbarPaper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '7px',
    overflow: 'auto',
  },

  toolbar: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },

  toolBarMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#fcfcfcff',
    borderRadius: '5px 5px 0px 0px ',
    borderTop: '1px solid lightgray',
    height: '4rem',
    zIndex: 10,
  },

  welcome: {
    marginTop: 10,
  },

  iconButton: {
    borderRadius: 0,
    width: '100%',
  },

  iconButtonMobile: {
    width: '14%',
  },

  renderArea: {
    height: '720px',
    overflowY: 'auto',
    padding: 10,
  },

  dashboardButton: {
    minWidth: '183px',
  },

  patientInQueue: {
    border: 'solid 1px lightgray',
    borderRadius: '5px',
    boxShadow: '0px 5px 5px -1px gray',
    marginBottom: '10px',
    padding: '20px',
  },
  queueButtons: {
    width: '185px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: '15px',
    },
  },
});

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: patientSocket,
      ohipValidationAttempts: 0,
      ipCountry: '',
      notification: '',
      appointmentInQueueId: '',
      openBirthdayDialog: false,
      openDismissDialog: false,
      openQueueDialog: false,
      birthdayMember: '',
      accountTransferSubmitButton: false,
      accountTransferDismissButton: false,
      renderVideo: false,
      isPatientInQueue: false,
      isPatientPicked: false,
      patientInQueueInfo: {},
      renderMissedCallMessage: false,
      disableSeeDoctorButton: true,
      snapmedIsClosed: false,
      openingHour: '',
      closingHour: '',
      scheduleInterval: 0,
      queueSubscription: '',
      toolbarPage: 'appointmentHistory',
      disableToolbar: false,
      mainUser: props.auth.name.first + ' ' + props.auth.name.last,
      mainId: props.auth._id,
      familyMembers: props.auth.familyMembers
        ? props.auth.familyMembers.map(({ name }) => {
            if (name) {
              return name.first + ' ' + name.last;
            } else {
            }
          })
        : false,
      allUsers: [
        {
          fullName: props.auth.name.first + ' ' + props.auth.name.last,
          id: props.auth._id,
          completed: props.auth.finishedProfile,
          isActive: props.auth.isActive,
        },
      ].concat(
        props.auth.familyMembers
          ? props.auth.familyMembers.map(
              ({ name, _id, finishedProfile, isActive }) => {
                if (name) {
                  return {
                    fullName: name.first + ' ' + name.last,
                    id: _id,
                    completed: finishedProfile,
                    isActive,
                  };
                }
              }
            )
          : false
      ),
      openDismissAlert: false,
      eventTopic: '',
      shouldRenderOhipButton: false,
    };
    this.state.socket.on(this.props.auth._id, async (response) => {
      if (response.logout) {
        this.props.history.push('/logged_out');
      }
    });
    this.setPatientId = this.setPatientId.bind(this);

    props.fetchUser();

    if (!this.props.selectedUser._id) {
      this.props.selectedUserChange({
        id: this.props.auth._id,
      });
    }

    if (!this.props.auth.isActive) {
      this.props.history.push('/profileForm');
    }
  }

  async componentDidMount() {
    if (!this.props.auth.isActive) {
      this.props.logout('patient', null, this.props.history);
    }
    const { toolbarPage } = this.props.match.params;
    const abstractApiKey = await axios.get('/api/auth/abstractapi-key');
    const geoLocationData = await axios.get(
      `https://ipgeolocation.abstractapi.com/v1/?api_key=${abstractApiKey.data}`
    );
    this.setState({ ipCountry: geoLocationData.data.country_code });

    if (window.location.href.includes('dashboard/origin_login')) {
      setTimeout(
        () => this.state.socket.emit('socket-logout', this.props.auth._id),
        500
      );
    }

    const pages = [
      'appointmentHistory',
      'AboutMe',
      'AboutMeMedical',
      'PharmacyInformation',
      'paymentHistory',
      'manageSubscriptions',
      'patientSettings',
    ];

    if (toolbarPage && pages.includes(toolbarPage)) {
      this.setState({ toolbarPage: toolbarPage }); // set the page to the URL paramaters if any
    }

    window.scrollTo(0, 0); // locking the scroll to the top when mounting.

    if (this.props.auth.familyMembers.length > 0) {
      this.props.getMedicalProfileState();
    }

    setTimeout(
      () => this.setState({ snapmedIsClosed: this.checkScheduleHours() }),
      500
    );
    this.setState({
      scheduleInterval: setInterval(() => this.checkClosingTime(), 10000),
    });

    const queueResponse = await axios.get('/api/check_for_patient_in_queue', {
      patient: this.props.auth._id,
    });
    // const notificationResponse = await axios.get(
    //   '/api/check_for_notifications',
    //   { patient: this.props.auth._id }
    // );

    // if (notificationResponse.data !== false) {
    //   this.setState({ notification: notificationResponse.data.message });
    // }

    if (queueResponse.data !== false) {
      this.subscribeToQueue(queueResponse.data.appointmentId);
      this.setState({
        isPatientInQueue: true,
        patientInQueueInfo: queueResponse.data,
        appointmentInQueueId: queueResponse.data.appointmentId,
        isPatientPicked: queueResponse.data.isPatientPicked,
      });
      if (
        queueResponse.data.jaasCredentials &&
        queueResponse.data.jaasCredentials.sessionId
      ) {
        this.setState({ disableSeeDoctorButton: false });
      }
    }

    this.setState({
      shouldRenderOhipButton: !!(
        this.props.selectedUserMedical &&
        this.props.selectedUserMedical.ohipNumber &&
        this.props.selectedUserMedical.ohipExpiration &&
        this.props.selectedUserMedical.ohipCardImage
      ),
    });
  }

  componentWillUnmount() {
    // clear all timers and event listeners
    clearInterval(this.state.scheduleInterval);
  }

  componentDidUpdate() {
    const shouldRenderOhipButton = !!(
      this.props.selectedUserMedical &&
      this.props.selectedUserMedical.ohipNumber &&
      this.props.selectedUserMedical.ohipExpiration &&
      this.props.selectedUserMedical.ohipCardImage
    );

    if (shouldRenderOhipButton !== this.state.shouldRenderOhipButton) {
      this.setState({
        shouldRenderOhipButton,
      });
    }
  }

  manageQueue = (data) => {
    switch (data) {
      case 'patient_picked':
        this.setState({ isPatientPicked: true });
        break;
      case 'doctor_ready':
        this.setState({ disableSeeDoctorButton: false });
        break;
      case 'doctor_left_appointment':
        this.setState({
          renderMissedCallMessage: true,
          isPatientInQueue: false,
        });
        // If token exist, it means the user is in the appointment room, so Redux state should not be reset.
        // Even if the Dashboard component is unmounted, the event listener will still be active
        // and will trigger this function in the AppointmentRoom component, which will unmount the PatientVideoChat component,
        // which in turn is condiotional to the presence of token and appointment ID.
        // This mitigates the PatientVideoChat component not rendering when Redux state is reset.
        if (!this.props.appointmentParams.token) {
          this.props.dispatch({
            type: RESET_APPT_PARAMS,
            payload: {},
          });
        }
        break;
      default:
        break;
    }
  };

  subscribeToQueue = (appointmentId) => {
    eventsApi.on(`appt-updates:${appointmentId}`, (message) => {
      this.setState({ eventTopic: `appt-updates:${appointmentId}` });
      this.manageQueue(message);
    });
  };

  checkScheduleHours = () => {
    let openingHourString = '';
    let closingHoursString = '';

    this.props.workingHours.forEach((value) => {
      // Headquarters date string
      const toronto = new Date().toLocaleString('en-US', {
        timeZone: 'America/Toronto',
      });

      // Calculating time offset between HQ and client time
      let cityOffset = new Date(toronto).getTime() - new Date().getTime();
      cityOffset = Math.round(cityOffset / 60 / 60 / 1000);

      // Calculating display hour for client
      const array = value.time.split(':');
      let displayHour = +array[0];
      if (array[2].includes('PM') && array[0] !== '12') {
        displayHour += 12;
      }
      displayHour = displayHour - cityOffset;

      const meridiem =
        (displayHour >= 12 && displayHour < 24) || displayHour < 0
          ? 'PM'
          : 'AM';
      displayHour =
        displayHour > 24
          ? displayHour - 24
          : displayHour > 12
          ? displayHour - 12
          : displayHour < 0
          ? displayHour + 12
          : displayHour;

      const minutes = array[1];

      if (value.name === 'Opening Time') {
        openingHourString = value.time;
        this.setState({
          openingHour: `${displayHour}:${minutes} ${meridiem}`,
        });
      } else if (value.name === 'Closing Time') {
        closingHoursString = value.time;
        this.setState({
          closingHour: `${displayHour}:${minutes} ${meridiem}`,
        });
      }
    });

    const openingHourInSeconds = () => {
      const array = openingHourString.split(':');
      let militaryHour = +array[0];
      // convert to military hours if it's PM
      if (array[2].includes('PM') && array[0] !== '12') {
        militaryHour = +array[0] + 12;
      }
      let postMeridiem = array[2].split(' ');
      postMeridiem = postMeridiem[1];
      return militaryHour * 60 * 60 + +array[1] * 60;
    };

    const closingHourInSeconds = () => {
      const array = closingHoursString.split(':');
      let militaryHour = +array[0];
      // convert to military hours if it's PM
      if (array[2].includes('PM') && array[0] !== '12') {
        militaryHour = +array[0] + 12;
      }
      let postMeridiem = array[2].split(' ');
      postMeridiem = postMeridiem[1];
      return militaryHour * 60 * 60 + +array[1] * 60;
    };

    const currentTime = () => {
      const hqHourString = new Date().toLocaleTimeString('en-US', {
        timeZone: 'America/Toronto',
      });
      const array = hqHourString.split(':'); // split it at the colons
      // convert to military hours
      if (array[2].includes('PM') && array[0] !== '12') {
        array[0] = +array[0] + 12;
      }
      const currentTimeInSeconds = +array[0] * 60 * 60 + +array[1] * 60;
      return currentTimeInSeconds;
    };

    if (
      currentTime() > openingHourInSeconds() &&
      currentTime() < closingHourInSeconds()
    ) {
      return false;
    } else {
      return true;
    }
  };

  checkClosingTime = () => {
    let closingHoursString = '';
    this.props.workingHours.forEach((value) => {
      if (value.name === 'Closing Time') {
        closingHoursString = value.time;
      }
    });
    const closingHourInSeconds = () => {
      const testString = '3:38:00 PM';
      const array = closingHoursString.split(':');
      let militaryHour = +array[0];
      // convert to military hours if it's PM
      if (array[2].includes('PM') && array[0] !== '12') {
        militaryHour = +array[0] + 12;
      }
      let postMeridiem = array[2].split(' ');
      postMeridiem = postMeridiem[1];
      return militaryHour * 60 * 60 + +array[1] * 60;
    };
    const currentTime = () => {
      const hqHourString = new Date().toLocaleTimeString('en-US', {
        timeZone: 'America/Toronto',
      });

      const array = hqHourString.split(':');
      // convert to military hours
      if (array[2].includes('PM') && array[0] !== '12') {
        array[0] = +array[0] + 12;
      }
      const currentTimeInSeconds = +array[0] * 60 * 60 + +array[1] * 60;
      return currentTimeInSeconds;
    };
    if (currentTime() >= closingHourInSeconds()) {
      this.setState({ snapmedIsClosed: true });
    }
  };

  setPatientId(patientId) {
    this.props.setPatientId(patientId);
  }

  async validateOhipCard() {
    return await axios.post('/api/profile/patient/ohip-card-validation', {
      userId: this.props.selectedUser._id,
      backdrop: true,
    });
  }

  async checkStandardSub() {
    this.setPatientId(this.props.selectedUser._id);
    const expiryDate = new Date(this.props.selectedUser.ohipMembershipEnd);
    if (this.props.selectedUser.isOhipMember && expiryDate > new Date()) {
      this.props.history.push('/your_ohipsymptoms');
    } else {
      this.props.history.push({
        pathname: '/standard-plans',
        state: {
          origin: `/dashboard/${this.state.toolbarPage}`,
          redirect: '/your_ohipsymptoms',
        },
      });
    }
  }

  seeDoctor() {
    this.setPatientId(this.state.patientInQueueInfo.patientId);
    this.props.proceedToSeeDoctor(
      this.state.patientInQueueInfo.appointmentId,
      this.props.history
    );
  }

  UserField = ({ name, input, label, placeholder, type }) => {
    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel id={`${name}-label`} style={{ fontSize: '0.9rem' }}>
          {placeholder}
        </InputLabel>
        <Select
          {...input}
          label={placeholder}
          labelId={`${name}-label`}
          id={name}
          input={<OutlinedInput id={name} label={placeholder} />}
        >
          {this.state.allUsers.map((obj) => {
            return (
              <MenuItem key={obj.id} value={obj} disabled={!obj.completed}>
                {obj.fullName}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  renderUserField() {
    return (
      <Field
        name={'selected_user'}
        type={'text'}
        label="Selected User"
        placeholder={this.props.selectedUser.fullName}
        component={this.UserField}
        //format={value => (value ? value : [])}
        onChange={(event) => {
          this.props.selectedUserChange(event.target.value);
        }}
      />
    );
  }

  AccountTransferField = ({
    input,
    name,
    type,
    label,
    meta: { touched, error },
  }) => {
    return (
      <FormControl fullWidth variant="outlined">
        <TextField
          {...input}
          size="small"
          variant="outlined"
          id={name}
          type={type}
          label={label}
          error={touched && error}
          helperText={touched && error}
        />
      </FormControl>
    );
  };

  PhoneNumberField = ({
    input,
    name,
    type,
    label,
    meta: { touched, error },
  }) => {
    return (
      <FormControl fullWidth variant="outlined">
        <InputMask mask="(999) 999-9999" {...input} onChange={input.onChange}>
          {(inputProps) => (
            <TextField
              {...inputProps}
              fullWidth
              size="small"
              id={name}
              type={type}
              label={label}
              variant="outlined"
              error={touched && error}
              helperText={touched && error}
            />
          )}
        </InputMask>
      </FormControl>
    );
  };

  mainPaper() {
    const { classes } = this.props;
    const {
      snapmedIsClosed,
      openingHour,
      closingHour,
      isPatientInQueue,
      patientInQueueInfo,
      disableSeeDoctorButton,
      renderMissedCallMessage,
      notification,
      ipCountry,
      ohipValidationAttempts,
      isPatientPicked,
      openQueueDialog,
    } = this.state;

    return (
      <Paper className={classes.paper}>
        <Grid container direction="row" spacing={1}>
          <Grid
            item
            xs={12}
            style={{ marginBottom: '20px' }}
            id="dashboard-section"
          >
            <Typography variant="h6" color="primary">
              Dashboard
            </Typography>
            <Grid item xs={12} className={classes.welcome}>
              <Typography variant="h5" color="primary">
                Welcome {this.props.auth.name.first}.
              </Typography>
            </Grid>
            {snapmedIsClosed && (
              <Grid
                item
                container
                xs={12}
                direction="row"
                style={{
                  marginTop: '10px',
                  borderLeft: '10px solid red',
                  padding: '10px',
                  backgroundColor: '#ffefec',
                }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  sm={1}
                  justify="center"
                  alignItems="center"
                >
                  <AccessAlarm style={{ fontSize: '30px' }} />
                </Grid>
                <Grid item xs={12} sm={11}>
                  <Typography variant="body1">
                    You are visiting us outside working hours.
                    <br />
                    Medical appointments are available between{' '}
                    <b>{openingHour}</b> and <b>{closingHour}</b>.
                  </Typography>
                </Grid>
              </Grid>
            )}
            {renderMissedCallMessage && (
              <Grid
                item
                container
                xs={12}
                direction="row"
                style={{
                  marginTop: '10px',
                  borderLeft: '10px solid #ffbf00',
                  padding: '10px',
                  backgroundColor: '#fdf3b4',
                  borderRadius: '5px',
                }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  sm={1}
                  justify="center"
                  alignItems="center"
                >
                  <AppointmentsIcon style={{ fontSize: '25px' }} />
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography variant="body1">
                    {patientInQueueInfo.name.first}{' '}
                    {patientInQueueInfo.name.last} just missed a call.
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={1}
                  justify="flex-end"
                  alignItems="center"
                >
                  <IconButton
                    size="small"
                    edge="end"
                    onClick={() =>
                      this.setState({ renderMissedCallMessage: false })
                    }
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            )}
            {notification && (
              <Grid
                item
                container
                xs={12}
                direction="row"
                spacing={1}
                style={{
                  marginTop: '10px',
                  borderRadius: '5px',
                  borderLeft: '10px solid #ffbf00',
                  padding: '10px',
                  backgroundColor: '#fdf3b4',
                }}
              >
                <Grid item container xs={11}>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={1}
                    justify="center"
                    alignItems="center"
                  >
                    <AppointmentsIcon style={{ fontSize: '30px' }} />
                  </Grid>
                  <Grid item xs={12} sm={11}>
                    <Typography variant="body1">{notification}</Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={1} justify="flex-end">
                  <IconButton
                    size="small"
                    onClick={() => this.setState({ notification: false })}
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            )}
            {this.props.newAdultFamilyMembers &&
              this.props.newAdultFamilyMembers.length > 0 && (
                <Grid item xs={12}>
                  {this.props.newAdultFamilyMembers.map((member) => (
                    <Grid
                      item
                      xs={12}
                      container
                      style={{
                        marginTop: '10px',
                        borderRadius: '5px',
                        borderLeft: '10px solid #ffbf00',
                        padding: '10px',
                        backgroundColor: '#fdf3b4',
                      }}
                    >
                      <Grid item xs={8} container alignItems="center">
                        <Typography color="primary">
                          {member ? member.name.first : null} is turning 18!
                          Time for {member ? member.name.first : null} to have
                          an individual account?
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        container
                        spacing={1}
                        justify="flex-end"
                      >
                        <Grid item>
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={() =>
                              this.setState({
                                openDismissDialog: true,
                                birthdayMember: member,
                              })
                            }
                          >
                            Dismiss
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() =>
                              this.setState({
                                openBirthdayDialog: true,
                                birthdayMember: member,
                              })
                            }
                          >
                            Learn more
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
          </Grid>
          {!isPatientInQueue ? (
            <Grid item container xs={12} spacing={1} justify="center">
              <Grid
                item
                container
                xs={12}
                direction="row"
                spacing={1}
                justify="center"
              >
                {this.props.auth.finishedProfile &&
                  this.props.auth.familyMembers.length > 0 &&
                  ipCountry !== '' && (
                    <Grid item xs={12} sm={4}>
                      <Grid item>
                        <Typography variant="subtitle1" color="primary">
                          Select Patient:
                        </Typography>
                      </Grid>
                      <Grid item container justify="center">
                        {this.renderUserField(
                          this.props.selectedUser
                            ? this.props.selectedUser.fullName
                            : this.state.mainUser
                        )}
                      </Grid>
                    </Grid>
                  )}
                <Grid
                  item
                  container
                  xs={8}
                  direction="row"
                  spacing={1}
                  justify="center"
                  alignItems="flex-end"
                >
                  {this.props.auth.finishedProfile &&
                    !snapmedIsClosed &&
                    ipCountry !== '' &&
                    this.state.shouldRenderOhipButton && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        container
                        justify="center"
                        alignItems="center"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.dashboardButton}
                          onClick={() => {
                            // this is where we check sub status
                            this.validateOhipCard().then((response) => {
                              if (response.data && response.data.isOhipValid) {
                                this.checkStandardSub();
                              } else {
                                this.setState((prev) => ({
                                  ohipValidationAttempts:
                                    prev.ohipValidationAttempts + 1,
                                }));
                              }
                            });
                          }}
                          disabled={
                            (this.props.selectedUser.completed &&
                              !this.props.selectedUser.isActive) ||
                            this.state.ipCountry !== 'CA'
                          }
                        >
                          {this.state.ipCountry === 'CA'
                            ? 'I have valid OHIP.'
                            : 'OHIP is not available'}
                          <br />
                          {this.state.ipCountry === 'CA'
                            ? 'See Doctor'
                            : 'outside Canada'}
                        </Button>
                      </Grid>
                    )}
                  {!this.props.auth.finishedProfile && snapmedIsClosed && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      container
                      justify="center"
                      alignItems="center"
                    >
                      <PersonalMedicalCompletionPrompt
                        auth={this.props.auth}
                        selectedUser={this.props.selectedUser}
                        setPatientId={this.setPatientId}
                        appointmentParams={this.props.appointmentParams}
                        history={this.props.history}
                      />
                    </Grid>
                  )}
                  {!snapmedIsClosed && ipCountry !== '' && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      container
                      justify="center"
                      alignItems="center"
                    >
                      <PersonalMedicalCompletionPrompt
                        auth={this.props.auth}
                        selectedUser={this.props.selectedUser}
                        setPatientId={this.setPatientId}
                        appointmentParams={this.props.appointmentParams}
                        history={this.props.history}
                      />
                    </Grid>
                  )}

                  {ipCountry === '' && (
                    <Grid item container xs={12} justify="center">
                      <CircularProgress size={25} color="primary" />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={1}>
                <FamilyMedicalFormCompletionPrompts
                  medicalProfileCompletion={this.props.medicalProfileCompletion}
                  setPatientId={this.setPatientId}
                  appointmentParams={this.props.appointmentParams}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              xs={12}
              spacing={1}
              className={classes.patientInQueue}
            >
              <Grid container style={{ margin: '15px 0px' }}>
                <Grid item xs={12} style={{ marginBottom: '15px' }}>
                  {disableSeeDoctorButton ? (
                    <Typography variant="h5" color="primary" align="center">
                      {patientInQueueInfo.name.first}{' '}
                      {patientInQueueInfo.name.last} is waiting to see a doctor.
                    </Typography>
                  ) : (
                    <Typography variant="h5" color="primary" align="center">
                      The doctor is ready to see you,{' '}
                      {patientInQueueInfo.name.first}{' '}
                      {patientInQueueInfo.name.last}.
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  justify="center"
                  style={{ marginBottom: '15px' }}
                >
                  <Collapse in={disableSeeDoctorButton}>
                    <Grid item container xs={12} justify="center">
                      <Typography variant="h6" color="primary">
                        {isPatientPicked
                          ? 'A doctor is reviewing your information.'
                          : 'In queue...'}
                      </Typography>
                    </Grid>
                    <Grid item container xs={12} justify="center">
                      <img src={loadingLogo} alt="loading..." />
                    </Grid>
                    <Grid item>
                      <Typography
                        color="primary"
                        style={{ textAlign: 'center' }}
                      >
                        If you log out your position in the queue will{' '}
                        <b>NOT</b> be affected.
                      </Typography>
                      <Typography
                        style={{ textAlign: 'center' }}
                        color="primary"
                      >
                        The doctor will be reviewing your medical information
                        and a <b>text message</b> will be sent when the doctor
                        is ready to connect with you.
                        <br />
                        {/* By proceeding with your medical virtual appointment, you are
                  agreeing to the inherent risk associated with electronic video
                  conferencing. Please ensure that you are in a private area as
                  your private health information will be discussed over the
                  video conference.{' '}*/}
                      </Typography>
                    </Grid>
                    {!patientInQueueInfo.ohipAppointment && (
                      <Grid
                        item
                        container
                        xs={12}
                        justify="center"
                        style={{
                          backgroundColor: '#fdf3b4',
                          padding: '0px 5px',
                          borderLeft: '5px solid #ffbf00',
                        }}
                      >
                        <Typography color="primary" align="center">
                          If you choose to Leave Queue, NO credit card charges
                          will be made and NO visits will be deducted.
                        </Typography>
                      </Grid>
                    )}
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  {disableSeeDoctorButton && (
                    <>
                      <Grid item xs={12} container justify="center">
                        <Button
                          variant="outlined"
                          className={classes.queueButtons}
                          color="primary"
                          size="small"
                          disabled={!disableSeeDoctorButton}
                          onClick={() =>
                            this.setState({ openQueueDialog: true })
                          }
                        >
                          Leave Queue
                        </Button>
                      </Grid>
                    </>
                  )}
                  {!disableSeeDoctorButton && (
                    <Grid item xs={12} container justify="center">
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={disableSeeDoctorButton}
                        className={classes.queueButtons}
                        onClick={() => {
                          this.props.dispatch({
                            type: 'FETCH_APPOINTMENT_ID',
                            payload: patientInQueueInfo.appointmentId,
                          });
                          this.seeDoctor();
                        }}
                      >
                        Enter Exam Room
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item style={{ marginBottom: '20px' }}>
                <Typography variant="body1" color="error" align="center">
                  If you believe that your medical issue is an emergency and
                  requires immediate attention, please terminate your call and
                  proceed to call 911 and/or proceed to the nearest emergency
                  room.
                </Typography>
              </Grid>
            </Grid>
          )}

          <Dialog
            open={this.state.openBirthdayDialog}
            onClose={() => {
              this.setState({ openBirthdayDialog: false });
              this.props.reset();
            }}
          >
            <DialogTitle>Account Transfer</DialogTitle>
            <form
              id="account-transfer-form"
              onSubmit={this.props.handleSubmit((values) =>
                this.handleAccountTransferSubmit(values)
              )}
            >
              <DialogContent>
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                  <Typography color="primary" variant="h6" align="center">
                    Once a family member turns 18, it is recommended that they
                    maintain their own individual SnapMED account.
                  </Typography>
                </Grid>
                <Grid item xs={12} container spacing={1}>
                  <Typography color="primary" variant="body2">
                    Please provide an email address so that we can email{' '}
                    {this.state.birthdayMember
                      ? this.state.birthdayMember.name.first
                      : null}{' '}
                    instructions to transfer their existing medical records to
                    their own individual account:
                  </Typography>
                  <Grid item xs={6}>
                    <Field
                      name="familyMemberEmail"
                      type="email"
                      label="Email"
                      component={this.AccountTransferField}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginBottom: '10px' }}>
                    <Field
                      name="confirmFamilyMemberEmail"
                      type="email"
                      label="Confirm Email"
                      component={this.AccountTransferField}
                    />
                  </Grid>
                  <Typography color="primary" variant="body2">
                    Please provide a phone number so that we can send{' '}
                    {this.state.birthdayMember
                      ? this.state.birthdayMember.name.first
                      : null}{' '}
                    a 6-digit verification code:
                  </Typography>
                  <Grid item xs={6}>
                    <Field
                      name="familyMemberPhoneNumber"
                      type="text"
                      label="Phone Number"
                      component={this.PhoneNumberField}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    this.props.reset();
                    this.setState({ openBirthdayDialog: false });
                  }}
                >
                  Dismiss
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  form="account-transfer-form"
                  disabled={this.state.accountTransferSubmitButton}
                >
                  Submit
                  {this.state.accountTransferSubmitButton && (
                    <CircularProgress
                      size={15}
                      color="primary"
                      style={{
                        marginLeft: 5,
                      }}
                    />
                  )}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          <Dialog
            open={this.state.openDismissDialog}
            onClose={() => {
              this.setState({ openDismissDialog: false });
            }}
          >
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                This will <b>cancel</b> the account transfer process and dismiss
                the notification. Are you sure you want to proceed?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  this.setState({ openDismissDialog: false });
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={this.state.accountTransferDismissButton}
                onClick={this.handleAccountTransferDismiss}
              >
                Cancel
                {this.state.accountTransferDismissButton && (
                  <CircularProgress
                    size={15}
                    color="primary"
                    style={{
                      marginLeft: 5,
                    }}
                  />
                )}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={ohipValidationAttempts > 2}
            onClose={this.closeValidationDialog}
          >
            <DialogTitle>Too many attempts</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please consider updating your Medical Information with valid
                health card number, version code and card photo.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={this.closeValidationDialog}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openQueueDialog} onClose={this.closeQueueDialog}>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogContent>
              <Typography color="primary">
                Please consider waiting a few more minutes.
              </Typography>
              <Typography color="primary">
                A doctor will be with you shortly.
              </Typography>
              <Typography color="primary">
                If you log out your position in the queue will not be affected.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  const values = {};
                  const path = pathBuilder.appointmentDirPath(
                    patientInQueueInfo.appointmentId
                  );
                  values.appointmentId = patientInQueueInfo.appointmentId;
                  values.patientId = patientInQueueInfo.patientId;
                  axios
                    .delete(path)
                    .then((res) =>
                      axios.post('/api/patient_left_waiting_room', values)
                    )
                    .then((response) => {
                      this.props.dispatch({
                        type: RESET_APPT_PARAMS,
                        payload: { appointmentId: null, patientId: null },
                      });
                    });
                  eventsApi.off(
                    `appt-updates:${patientInQueueInfo.appointmentId}`
                  );
                  setTimeout(() => {
                    this.setState({
                      isPatientInQueue: false,
                      appointmentInQueueId: '',
                      renderMissedCallMessage: false,
                      openQueueDialog: false,
                    });
                  }, 500);
                }}
              >
                Leave
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={this.closeQueueDialog}
              >
                Stay
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Paper>
    );
  }

  toolbar() {
    const { classes, auth } = this.props;
    return (
      <Grid
        item
        xs={2}
        sm={1}
        container
        className={classes.toolbar}
        style={{
          backgroundColor: '#a3aab7',
          borderRadius: '7px 0px 0px 7px ',
        }}
      >
        <Tooltip title="Appointment History" placement="left">
          <IconButton
            className={classes.iconButton}
            color="primary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => {
              this.setState({ toolbarPage: 'appointmentHistory' });
              document.querySelector('#toolbar-section').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <AppointmentsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="About You" placement="left">
          <IconButton
            className={classes.iconButton}
            color="primary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => {
              this.setState({ toolbarPage: 'AboutMe' });
              document.querySelector('#toolbar-section').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <AboutYouIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Medical Profile" placement="left">
          <IconButton
            className={classes.iconButton}
            color="primary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => {
              this.setState({ toolbarPage: 'AboutMeMedical' });
              document.querySelector('#toolbar-section').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <MedicalInfoIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Pharmacy Information" placement="left">
          <IconButton
            className={classes.iconButton}
            color="primary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => {
              this.setState({ toolbarPage: 'PharmacyInformation' });
              document.querySelector('#toolbar-section').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <Badge
              color="error"
              variant="dot"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              // overlap="circle"
              invisible={
                auth.patient.pharmacy.premiumPharmacy ||
                auth.patient.pharmacy.faxNumber
              }
            >
              <PharmaciesIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Tooltip title="Payment History" placement="left">
          <IconButton
            className={classes.iconButton}
            color="primary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => {
              this.setState({ toolbarPage: 'paymentHistory' });
              document.querySelector('#toolbar-section').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <PaymentHistoryIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Manage Subscriptions" placement="left">
          <IconButton
            className={classes.iconButton}
            color="primary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => {
              this.setState({ toolbarPage: 'manageSubscriptions' });
              document.querySelector('#toolbar-section').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <LibraryBooks />
          </IconButton>
        </Tooltip>
        <Tooltip title="Patient Settings" placement="left">
          <IconButton
            className={classes.iconButton}
            color="primary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => {
              this.setState({ toolbarPage: 'patientSettings' });
              document.querySelector('#toolbar-section').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <PatientSettingsIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    );
  }

  toolbarMobile() {
    const { classes } = this.props;
    return (
      <Grid
        item
        container
        xs={12}
        justify="center"
        alignItems="center"
        className={classes.toolBarMobile}
      >
        <Grid
          item
          container
          justify="center"
          className={classes.iconButtonMobile}
        >
          <IconButton
            color="primary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => {
              this.setState({ toolbarPage: 'appointmentHistory' });
              document.querySelector('#toolbar-section').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <AppointmentsIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          container
          justify="center"
          className={classes.iconButtonMobile}
        >
          <IconButton
            color="primary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => {
              this.setState({ toolbarPage: 'AboutMe' });
              document.querySelector('#toolbar-section').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <AboutYouIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          container
          justify="center"
          className={classes.iconButtonMobile}
        >
          <IconButton
            color="primary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => {
              this.setState({ toolbarPage: 'AboutMeMedical' });
              document.querySelector('#toolbar-section').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <MedicalInfoIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          container
          justify="center"
          className={classes.iconButtonMobile}
        >
          <IconButton
            color="primary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => {
              this.setState({ toolbarPage: 'PharmacyInformation' });
              document.querySelector('#toolbar-section').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <PharmaciesIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          container
          justify="center"
          className={classes.iconButtonMobile}
        >
          <IconButton
            color="primary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => {
              this.setState({ toolbarPage: 'paymentHistory' });
              document.querySelector('#toolbar-section').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <PaymentHistoryIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          container
          justify="center"
          className={classes.iconButtonMobile}
        >
          <IconButton
            color="primary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => {
              this.setState({ toolbarPage: 'manageSubscriptions' });
              document.querySelector('#toolbar-section').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <LibraryBooks />
          </IconButton>
        </Grid>
        <Grid
          item
          container
          justify="center"
          className={classes.iconButtonMobile}
        >
          <IconButton
            color="primary"
            variant="outlined"
            disabled={this.state.disableToolbar}
            onClick={() => {
              this.setState({ toolbarPage: 'patientSettings' });
              document.querySelector('#toolbar-section').scrollIntoView({
                behavior: 'smooth',
              });
            }}
          >
            <PatientSettingsIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  handleCloseAlert = () => {
    this.setState({ openDismissAlert: false });
  };

  handleAccountTransferSubmit = async (values) => {
    this.setState({ accountTransferSubmitButton: true });
    values.memberId = this.state.birthdayMember._id;
    const response = await axios.post(
      '/api/profile/patient/account-transfer-email',
      values
    );
    if (response.data) {
      this.props.reset();
      this.setState({
        openBirthdayDialog: false,
        accountTransferSubmitButton: false,
      });
    } else {
      this.setState({ accountTransferSubmitButton: false });
    }
  };

  handleAccountTransferDismiss = async () => {
    this.setState({ accountTransferDismissButton: true });
    const response = await axios.post(
      '/api/profile/patient/dismiss-account-transfer',
      {
        mainAccount: this.props.auth._id,
        member: this.state.birthdayMember._id,
      }
    );
    if (response.data) {
      this.props.fetchUser();
      this.setState({
        openDismissDialog: false,
        accountTransferDismissButton: false,
        openDismissAlert: response.data.message,
      });
    } else {
      this.setState({ accountTransferDismissButton: false });
    }
  };

  closeValidationDialog = () => {
    this.setState({ ohipValidationAttempts: 0 });
  };

  closeQueueDialog = () => {
    this.setState({ openQueueDialog: false });
  };

  render() {
    const { handleSubmit, classes } = this.props;
    const { toolbarPage } = this.state;
    return (
      <Fragment>
        <CssBaseline />
        <IdleTimer
          portal="Patient"
          component="Dashboard"
          history={this.props.history}
        />
        <main className={classes.layout}>
          {this.mainPaper()}
          <br />
          {this.props.auth.finishedProfile && (
            <div>
              <br />
              <Paper className={classes.toolbarPaper}>
                <Grid
                  container
                  direction="row"
                  style={{
                    marginTop: 0,
                    width: '100%',
                  }}
                >
                  {this.toolbar()}
                  <Grid item xs={12} sm={11} id="toolbar-section">
                    {' '}
                    <form
                      id="patient_info_update"
                      className={classes.form}
                      onSubmit={handleSubmit((values) => {
                        values.id = this.props.selectedUser._id
                          ? this.props.selectedUser._id
                          : this.props.auth._id;
                        this.props.editProfile(values, this.props.history);
                        this.props.selectedUserChange({
                          fullName: this.props.selectedUser
                            ? this.props.selectedUser.fullName
                            : this.state.mainUser,
                          id: this.props.selectedUser
                            ? this.props.selectedUser._id
                            : this.state.mainId,
                        });
                      })}
                    >
                      {toolbarPage === 'AboutMe' && (
                        <Grid item className={classes.renderArea}>
                          <PatientProfile
                            changePage={() =>
                              this.setState({ toolbarPage: 'EditProfile' })
                            }
                            disableToolbar={() =>
                              this.setState({ disableToolbar: true })
                            }
                          />
                        </Grid>
                      )}
                      {toolbarPage === 'EditProfile' && (
                        <Grid item className={classes.renderArea}>
                          <ProfileDashboard
                            changePage={() =>
                              this.setState({ toolbarPage: 'AboutMe' })
                            }
                            disableToolbar={() =>
                              this.setState({ disableToolbar: false })
                            }
                            initialValues={{
                              profileForm: {
                                country_code: '+1',
                              },
                            }}
                          />
                        </Grid>
                      )}
                      {toolbarPage === 'AboutMeMedical' && (
                        <Grid item className={classes.renderArea}>
                          <MedicalInformation
                            disableToolbar={(value) =>
                              this.setState({ disableToolbar: value })
                            }
                          />
                        </Grid>
                      )}
                      {toolbarPage === 'PharmacyInformation' && (
                        <Grid item className={classes.renderArea}>
                          <PharmacyInformation
                            auth={this.props.auth}
                            medicalData={this.props.medicalData}
                            disableToolbar={() =>
                              this.setState({ disableToolbar: false })
                            }
                          />
                        </Grid>
                      )}
                      {toolbarPage === 'paymentHistory' && (
                        <Grid item className={classes.renderArea}>
                          <PaymentHistory
                            payments={this.props.payments}
                            auth={this.props.auth}
                          />
                        </Grid>
                      )}
                      {toolbarPage === 'appointmentHistory' && (
                        <Grid item className={classes.renderArea}>
                          <AppointmentHistory
                            appointmentInQueueId={
                              this.state.appointmentInQueueId
                            }
                            pharmacyPage={() =>
                              this.setState({
                                toolbarPage: 'PharmacyInformation',
                              })
                            }
                          />
                        </Grid>
                      )}
                      {toolbarPage === 'manageSubscriptions' && (
                        <Grid item className={classes.renderArea}>
                          <ManageSubscriptions
                            payments={this.props.payments}
                            auth={this.props.auth}
                            changePage={(page) =>
                              this.setState({
                                toolbarPage: page,
                              })
                            }
                          />
                        </Grid>
                      )}
                      {toolbarPage === 'patientSettings' && (
                        <Grid item className={classes.renderArea}>
                          <PatientSettings auth={this.props.auth} />
                        </Grid>
                      )}
                    </form>
                  </Grid>
                </Grid>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  open={this.state.openDismissAlert}
                  onClose={this.handleCloseAlert}
                  message={this.state.openDismissAlert}
                  action={
                    <IconButton size="small" onClick={this.handleCloseAlert}>
                      <Close fontSize="small" color="secondary" />
                    </IconButton>
                  }
                />
              </Paper>
            </div>
          )}
        </main>
        {this.toolbarMobile()}
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.familyMemberEmail) {
    errors.familyMemberEmail = 'Please enter a valid email address';
  }
  if (!values.confirmFamilyMemberEmail) {
    errors.confirmFamilyMemberEmail = 'Please enter a valid email address';
  }
  if (values.confirmFamilyMemberEmail !== values.familyMemberEmail) {
    errors.confirmFamilyMemberEmail = 'Email addresses must match';
    errors.familyMemberEmail = 'Email addresses must match';
  }
  return errors;
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    medicalData: state.medicalData,
    medicalProfileCompletion: state.medicalProfileCompletion,
    payments: state.payments,
    appointments: state.appointments,
    form: state.form,
    appointmentParams: state.appointmentParams,
    selectedUser: state.selectedUser,
    selectedUserMedical: state.selectedUserMedical,
    timerValues: state.timerValues,
    workingHours: state.workingHours,
    newAdultFamilyMembers: state.auth.newAdultFamilyMembers,
  };
}

Dashboard = connect(mapStateToProps, actions)(withRouter(Dashboard));

Dashboard = reduxForm({
  validate,
  form: 'dashboardUpdateForm',
  destroyOnUnmount: false,
})(Dashboard);

export default withStyles(styles)(Dashboard);
