import React, { Component, Fragment } from 'react';
import { Paper, Typography, Grid, Button } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const styles = (theme) => ({
  layout: {
    width: 'auto',
    height: '100%',
    //  display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down(350 + theme.spacing.unit * 3 * 2)]: {
      width: 350,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    flexGrow: 1,
    flexDirection: 'row',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
});

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Fragment>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" align="center" color="primary">
                  Privacy Policy
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  padding: '10px',
                  height: '400px',
                  overflowY: 'scroll',
                }}
              >
                <Typography variant="body2" color="primary">
                  <p>
                    The SnapMED Privacy Policy was updated on September 28th,
                    2022.
                  </p>
                  <p>
                    <strong>Your Privacy Rights</strong>
                    <br />
                    SnapMED recognizes the importance of privacy and the
                    sensitivity of personal information including personal
                    health information (PHI). This Privacy Policy outlines how
                    we manage your personal information and safeguard your
                    privacy. This Privacy Policy summarizes how SnapMED
                    collects, uses, discloses, retains, disposes of, destroys
                    and protects information as well as data (information that
                    does not identify an individual) in the course of providing
                    our services. We are committed to complying with this
                    Privacy Policy and with applicable Provincial and Federal
                    privacy legislation.
                  </p>
                  <p>
                    Information collected by SnapMED itself is not intended to
                    be personal health information (PHI). However, the
                    information that you provide to health care practitioners
                    through the SnapMED platform is PHI. Note that this Privacy
                    Policy does not apply to the collection, use, retention,
                    disposal, destruction, and protection of PHI by Canadian
                    certified physicians or other healthcare practitioners.
                    Canadian certified physicians and other healthcare
                    practitioners are subject to privacy legislation and
                    professional requirements that govern their management of
                    information, including information they collect, use and
                    retain in the course of providing services through the
                    SnapMED platform. If you have any questions regarding the
                    treatment of your PHI, you may inquire directly of any
                    Canadian certified physician or other healthcare
                    practitioner to whom you are referred. You are entitled to
                    privacy in accordance with the relevant laws in the
                    jurisdiction in which you reside as that is the jurisdiction
                    in which services are to be provided to you through the
                    SnapMED platform.
                  </p>
                  <p>
                    SnapMED reserves the right to amend the Privacy Policy at
                    any time without notice. The date of the last amendment will
                    be indicated at the top of this page. Users are encouraged
                    to review the Privacy Policy regularly to be aware of the
                    changes.
                  </p>
                  <p>
                    <strong>Why Does SnapMed Need Personal Information?</strong>
                    <br />
                    SnapMED collects personal information as required to set up
                    a user account. This information can be used to authenticate
                    your future usage of the platform (such as date of birth,
                    place of residence, phone number, etc.). Additionally,
                    SnapMED reserves the right to customize the information
                    displayed on the platform by using the information provided
                    in your user account. SnapMED has an agreement with each of
                    the healthcare practitioners who provide services through
                    the SnapMED platform. SnapMED uses your personal information
                    for the purposes of providing the SnapMED services.
                  </p>
                  <p>
                    Your personal information is collected and accessed by the
                    healthcare practitioner who is providing you with a
                    consultation. Each healthcare practitioner remains
                    responsible to use personal health information in accordance
                    with the applicable privacy and security laws as well as the
                    professional regulations related to their healthcare
                    profession. As part of its continuous development and
                    improvement plans, SnapMED may collect information that is
                    non-identifying. Only when necessary to manage the platform,
                    certain personal information may be used, most commonly for
                    statistical operation or process improvements.
                  </p>
                  <p>
                    SnapMED does not record the audio or video transmissions
                    between users and healthcare practitioners. SnapMED does not
                    permit users to record the audio or video transmissions
                    between users and healthcare practitioners. You are
                    prohibited from recording the audio or video being
                    transmitted between you and any practitioner on SnapMED’s
                    platform or website.
                  </p>
                  <p>
                    <strong>
                      How do we collect your personal information?
                    </strong>
                    <br />
                    On the platform, you have control to upload, enter, and
                    store your personal information, including personal health
                    information. You also have control to upload, enter, and
                    store the information of a legally dependent individual (or
                    an individual for whom you are a legally authorized
                    substitute decision maker) who has their own account.
                    Healthcare practitioners may request personal information
                    (including PHI) from SnapMED users. Personal information is
                    collected when a user provides it during a consultation or
                    through entering/uploading the information on the SnapMED
                    platform.
                  </p>
                  <p>
                    The following information will be available on the platform
                    that will be visible to the health care practitioner:
                    <br />
                    • Name
                    <br />
                    • Address
                    <br />
                    • Phone Number
                    <br />
                    • E-mail address
                    <br />
                    • Gender Identity
                    <br />
                    • Sex assigned at Birth
                    <br />
                    • Date of birth
                    <br />
                    • Province of Residence
                    <br />
                    • Medical Information (as provided by the user for the
                    purpose of the consultation)
                    <br />
                  </p>
                  <p>
                    Additionally,physicians’ notes from any previous medical
                    consultations through the SnapMED platform may also be
                    available for healthcare practitioners to view. This
                    information is used for the purpose of providing the service
                    as requested by the user through the platform. Information
                    can also be used to generate medical notes, prescriptions or
                    other medical records as necessary.Additionally, information
                    may be used by other healthcare practitioners, such as
                    pharmacists, to prepare and deliver their services as
                    requested by the user.As needed for their own professional
                    record keeping and use, healthcare practitioners may export
                    or print information that they have gathered through the
                    SnapMED platform.
                  </p>
                  <p>
                    <strong>Consent</strong>
                    <br />
                    By uploading, entering and storing information into the
                    SnapMED platform, you consent to the use and disclosure of
                    your personal information and personal health information
                    for the purpose of providing services through SnapMED and
                    for any purposes described in the Terms of Use and this
                    Privacy Policy.
                  </p>
                  <p>
                    <strong>Use of Your Information</strong>
                    <br />
                    SnapMED can use your personal information to comply with
                    applicable regulatory and legal requirements. SnapMED may
                    also use your personal information for its internal quality
                    improvement to help improve the services provided on the
                    SnapMED platform including customizing the experience,
                    providing content, and advertising.
                  </p>
                  <p>
                    <strong>
                      Information released to the Physicians prior to
                      Consultation
                    </strong>
                    <br />
                    At the initial request of consultation, you will be asked to
                    input your medical symptoms, as well as other information
                    that may include vital signs, and applicable images. In
                    addition, your name and other personal and medical profile
                    information will be accessible to the healthcare
                    practitioner. This information is provided to the healthcare
                    practitioner to identify if the consultation is eligible
                    through the platform. Only one physician or healthcare
                    practitioner will have access to this information prior to
                    the start of the call. If the physician/provider accepts the
                    consultation, the user is then able to accept the start of
                    the call, deny the call or to discontinue the call once
                    started. The physician or healthcare practitioner may, after
                    the call, share the user’s information within the circle of
                    the patient’s care as permitted by applicable regulations to
                    provide additional healthcare services if the user so
                    elects.
                  </p>
                  <p>
                    <strong>SnapMed Communication with Users</strong>
                    <br />
                    Information provided by the user may also be used to
                    communicate with the user or when facilitating third party
                    services such as securely sending a prescription to a
                    pharmacy. Information may also be used by these third party
                    services to contact and verify the user’s identification.
                    Under certain circumstances, healthcare practitioners may
                    determine that your health condition warrants going to
                    another provider for an appropriate medical appointment, or
                    to a hospital or emergency room. In those circumstances, the
                    healthcare practitioner or SnapMED personnel have the right
                    to contact you or other emergency services as appropriate.
                    SnapMED and its healthcare practitioners are not a
                    replacement for a family physician or for emergency medical
                    services.
                  </p>
                  <p>
                    <strong>Disclosure of your Personal Information</strong>
                    <br />
                    SnapMED will not disclose or sell any information to a third
                    party except in the following circumstances:
                    <br />
                    • When we are required or authorized by law to do so, for
                    example if a court issues a subpoena;
                    <br />
                    • When you have explicitly or implicitly consented to the
                    disclosure;
                    <br />
                    • When the services we are providing to you requires us to
                    give your information to third parties (e.g. to a pharmacist
                    who is filling your prescription) your consent will be
                    implied, unless you tell us otherwise;
                    <br />
                    • Where it is necessary to establish or collect fees.
                    <br />
                    • If we engage a third party to provide administrative
                    services to us (such as computer back-up services or
                    archival file storage) and the third party is bound by our
                    Privacy Policy;
                    <br />
                    • To the resulting entity, if we are involved in a merger or
                    acquisition of our company; or
                    <br />
                    • If the information is Publicly Available Personal
                    Information, as it is defined under the Personal Information
                    Protection and Electronic Documents Act. <br />
                    SnapMED also has the right to use your personal information
                    to defend itself in any legal claims and investigation.
                    SnapMED retains the right to provide your personal
                    information for the purpose of investigations of fraud or
                    crimes related to your use of SnapMED.
                    <br />
                  </p>
                  <p>
                    <strong>Updating Your Information</strong>
                    <br />
                    Your information is stored in your SnapMED dashboard for as
                    long as you retain an account with SnapMED. Healthcare
                    practitioners will use their own record keeping mechanisms
                    to keep track of the relevant information needed to comply
                    with their professional responsibilities. In the event that
                    the user account is terminated, you will be provided with
                    thirty days to print and copy the relevant notes and medical
                    information. The healthcare practitioner will also be
                    notified of the user account that will be terminated to
                    ensure that the healthcare practitioner has updated his/her
                    own records as needed. After a ten year period, all personal
                    information in terminated accounts will be destroyed. Any
                    data retained by other healthcare practitioners, including
                    pharmacies, are subject to applicable regulations and their
                    own privacy policies.
                    <br />
                    Upon termination of a user account, SnapMED is no longer
                    required to provide you access to the information stored
                    under your profile. You may request access to your
                    healthcare information from the applicable healthcare
                    practitioner that you consulted through the SnapMED
                    platform. The healthcare practitioner will be subject to
                    access requirements under the rules of their professional
                    college and the access to information legislation applicable
                    to their services.
                  </p>
                  <p>
                    <strong>Storing Your Information</strong>
                    <br />
                    Your information is stored in your SnapMed dashboard for as
                    long as you retain an account with SnapMed. Health care
                    practitioners will use their own record keeping mechanisms
                    to keep track of the relevant information needed to comply
                    with their professional responsibilities. In the event that
                    the user account is terminated, you will be provided with
                    thirty days to print and copy the relevant notes and medical
                    information. The health care practitioner will also be
                    notified of the user account that will be terminated to
                    ensure that the health care practitioner has updated his/her
                    own records as needed. After a ten-year period, all personal
                    information in terminated accounts will be destroyed.
                  </p>
                  <p>
                    Upon termination of a user account, SnapMed is no longer
                    required to provide you access to the information stored
                    under your profile. You may request access to your health
                    care information from the applicable health care
                    practitioner you consulted in SnapMed. The health care
                    practitioner will be subject to access requirements under
                    the rules of their professional college and the access to
                    information legislation applicable to their services.
                  </p>
                  <p>
                    <strong>Taking Responsibility for Your Information</strong>
                    <br />
                    Each user should take responsibility for their privacy.
                    Please act with caution with respect to the sharing of your
                    personal information. SnapMED will not be responsible if you
                    choose to download, print, or share the records containing
                    your personal information. You are not permitted to share
                    your user ID and password with anyone else. You will be
                    required to change your password every so often. The SnapMED
                    platform provides the following choices for privacy options:
                    <br />• You have a choice of which communication tool to use
                    (i.e. chat service, video or audio call).
                    <br />• You have the option to turn off the video and/or
                    audio features at any time on the calling page.
                    <br />• You have the option to not reveal all information to
                    certain healthcare practitioners. However, deciding not to
                    reveal information that is relevant to the consultation may
                    lead to inaccurate diagnoses or advice.
                  </p>
                  <p>
                    <strong>Is My Personal Information Secure?</strong>
                    <br />
                    SnapMED takes all reasonable precautions to ensure that your
                    personal information is kept safe from loss, unauthorized
                    access, modification or disclosure. Among the steps taken to
                    protect your information are:
                    <br />• Premises security;
                    <br />• Restricted file access to personal information;
                    <br />• Use of challenge questions (personal questions about
                    you) during calls with users to authenticate the user;
                    <br />
                    •Deploying technological safeguards like security software
                    and firewalls to prevent hacking or unauthorized computer
                    access; and
                    <br />
                    • Internal password and security policies.
                    <br />
                    SnapMED has highly prioritized the security of information.
                    The information on its platform is stored electronically in
                    a computer system with restrictions. These systems are
                    housed in a physical space that has high security.
                    Appropriate technical personnel are monitoring information
                    safeguards to protect personal information from loss, theft
                    and unauthorized access. Strict office practices are in
                    place to ensure that SnapMED staff have been trained and are
                    well versed on the safeguarding of personal information.
                    SnapMED and healthcare practitioners may communicate with
                    the user in multiple forms including email, text, telephone,
                    etc. These are not 100% secure and your use of these media
                    with SnapMED constitutes your consent to the risk associated
                    with the use of these media. If you have a concern about the
                    security of transferring personal information, please notify
                    us so that we can determine with you the most appropriate
                    way to transfer information.
                    <br />
                    SnapMEDmay at times collect location data though the use of
                    GPS technology. This allows SnapMED to provide the
                    appropriate services. Your computers’ IP addresses help
                    SnapMED monitor the security and activity of the account.
                  </p>
                  <p>
                    SnapMed has highly prioritized the security of information.
                    The information on its platform is sorted electronically in
                    a computer system with restrictions. These systems are
                    housed in physical space that have high security.
                    Appropriate technical personnel are monitoring information
                    safeguards to protect personal information from loss, theft
                    and unauthorized access. Strict office practices are in
                    place to ensure that SnapMed staff have been trained and are
                    well versed on the safeguarding of personal information.
                    SnapMed and health care practitioners may communicate with
                    the user in multiple forms including e-mail, text,
                    telephone, etc. These are not 100% secure and your use of
                    these media with SnapMed constitutes your consent to the
                    risk associated with use of these media. If you have a
                    concern about the security of transferring personal
                    information, please notify us so that we can determine with
                    you the most appropriate way to transfer information.
                  </p>
                  <p>
                    SnapMed may at times collect location data though the use of
                    GPS technology. This allows SnapMed to provide the
                    appropriate services. Your computers’ IP addresses help
                    SnapMed with the security and activity of the account.
                  </p>
                  <p>
                    <strong>Access to Your Personal Information</strong>
                    <br />
                    You have access to your information in the user dashboard.
                    You may also request access to your personal information
                    from the applicable health care practitioner who is the
                    health information custodian.
                  </p>
                  <p>
                    <strong>Correcting Errors</strong>
                    <br />
                    You may update and modify your information under the profile
                    page of your account. If SnapMed holds information about you
                    and you can establish that it is not accurate, complete and
                    up-to-date, SnapMed will take reasonable steps to correct
                    it. Any information that was posted prior to a consultation,
                    which the health care practitioner reviewed as part of the
                    consultation is considered permanent and will remain as part
                    of the consultation note until a change is approved by a
                    health care practitioner as set out below. To request that
                    incorrect information be modified, you will have to submit a
                    written request and the health practitioner who provided the
                    consult will need to approve the change prior to any
                    modification. Such safeguards are necessary to protect the
                    accuracy of the nature of the consultation.
                  </p>
                  <p>
                    <strong>
                      Can I be denied access to my Personal Information?
                    </strong>
                    <br />
                    Your rights to access your personal information are not
                    absolute. We may deny access when denial is permitted by
                    applicable privacy law and the professional regulations
                    applicable to the health care practitioner. If we deny your
                    request for access to, or refuse a request to correct
                    information, we shall provide you with an explanation.
                  </p>
                  <p>
                    <strong>Can I request anonymity?</strong>
                    <br />
                    No. In order to provide the services contemplated by the
                    SnapMed platform, anonymity is not permitted.
                  </p>
                  <p>
                    <strong>Web Site</strong>
                    <br />
                    On our website, like most other commercial websites, we may
                    monitor traffic patterns, site usage and related site
                    information in order to optimize our web service. We may
                    provide aggregated information to third parties, but these
                    statistics do not include any identifiable personal
                    information. SnapMED may at times choose to link or post
                    content from a third party website. SnapMED does not endorse
                    nor benefit from the referral to those websites. SnapMED is
                    also not responsible for the terms and privacy policies of
                    such websites. Users are recommended to review the privacy
                    policy on each website prior to using it.
                  </p>
                  <p>
                    <strong>Other Websites/Linked Services</strong>
                    <br />
                    This website may include links to other websites or services
                    that require you to provide personal information such as
                    credit card billing services. Those organizations are
                    responsible for the personal information they collect.
                    SnapMed does not control and is not responsible for those
                    services. Your use of those other websites/services is
                    subject to the Terms of Use for those sites. If the user
                    elects to use pharmacy services, SnapMED will honour the
                    user’s choice of pharmacy and nothing in this Privacy Policy
                    shall be construed as creating a partnership or any other
                    type of relationship with that pharmacy. Pharmacies made
                    available on the SnapMED platform are responsible to provide
                    their services to the user directly.
                  </p>
                  <p>
                    <strong>Cookies and similar technologies</strong>
                    <br />
                    As part of SnapMED’s commitment to understand our users,
                    “cookies” and other technologies such as pixel tags and web
                    beacons may be integrated with the SnapMED platform, email
                    messages and advertisements. Cookies are small files that
                    are sent to your device that are then used to monitor you
                    and remember certain information about you. These tools and
                    the results are used to enhance and personalize the user
                    experience. Users have the ability to control the
                    technologies by refusing to accept cookies through the
                    setting in the user’s web browser. SnapMED may also install
                    cookies in our ads to better understand the effectiveness of
                    our messaging. Users who choose to use the platform and
                    accept cookies, are consenting to the use of cookies.
                  </p>

                  <p>
                    <strong>Changes to this Privacy Policy</strong>
                    <br />
                    Since SnapMED regularly reviews all of its policies and
                    procedures, we may change our Privacy Policy from time to
                    time without notice or liability..
                  </p>
                  <p>
                    <strong>Requests for Access</strong>
                    <br />
                    If you have any questions, or wish to access your personal
                    information, please write to our Chief Compliance Officer at
                    SnapMED, 202-444 Concession St., Hamilton, Ontario, L9A 1C2.
                    When communicating with SnapMED, please include your contact
                    information so that we may respond as needed. If you are not
                    satisfied with our response, the Privacy Commissioner of
                    Canada can be reached at 112 Kent Street, Ottawa, Ontario,
                    K1A 1H3, 1-800-282-1376.
                  </p>
                  <p>v1.1</p>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ marginTop: '10px' }}
              >
                {this.props.auth.name ? (
                  <Link style={{ textDecoration: 'none' }} to="/dashboard">
                    <Button variant="contained" color="primary">
                      Back
                    </Button>
                  </Link>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.close()}
                  >
                    Close
                  </Button>
                )}
              </Grid>
            </Grid>
          </Fragment>
        </Paper>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

PrivacyPolicy = connect(mapStateToProps)(PrivacyPolicy);

export default withStyles(styles)(PrivacyPolicy);
