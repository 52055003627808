import React, { Component, Fragment } from 'react';
import { Typography, Grid } from '@material-ui/core';
import OfficialStripePaymentPrompt from './CheckoutFormYearlyFamilyPlan';
import { Elements } from 'react-stripe-elements';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { withRouter } from 'react-router-dom';
import { Button, Dialog } from '@material-ui/core';
import { Link } from 'react-router-dom';

class MonthlyFamilyPlan extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        <Grid container spacing={24} justify="center">
          <Grid item xs={8}>
            <Typography
              variant="subtitle"
              color="primary"
              style={{ float: 'right' }}
            >
              Monthly Family Plan Membership:
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Button variant="contained" color="primary">
              <Link
                style={{ color: 'white', textDecoration: 'none' }}
                to="/PaymentPlanMembership/MonthlyFamilyPlan"
              >
                Select
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default MonthlyFamilyPlan;
