export default function(state = {}, action) {
  switch (action.type) {
    case 'FETCH_MEDICAL_DATA':
      return action.payload || false;
    case 'logout':
      return action.payload || false;
    default:
      return state;
  }
}
