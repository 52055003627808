import React, { Component, Fragment } from 'react';
import {
  reduxForm,
  formValueSelector,
  clearFields,
  Field,
  FormSection
} from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  CssBaseline,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  ListItemText,
  Select,
  Chip,
  Paper,
  Typography,
  TextField,
  Grid,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  Divider,
  FormLabel,
  Menu,
  IconButton,
  Dialog,
  DialogTitle,
  Slide
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TextButton from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import InfoIcon from '@material-ui/icons/Info';
import { useDispatch } from 'react-redux';
import * as actions from '../../../../../actions'

const styles = theme => ({
  dateTypography: {
    marginTop: '1%'
  },
  dateFieldLeft: {
    padding: 4,
    paddingLeft: 10
  },
  dateFieldRight: {
    padding: 4,
    paddingRight: 10
  },
  iconButton: {
    padding: 0
  },
  resize: {
    fontSize: 10
  }
});

const SicknoteComment = ({
  input,
  name,
  label,
  type,
  meta: { touched, error }
}) => (
    <TextField
      style={{ width: '80%', marginLeft: '5%' }}
      multiline
      {...input}
      //required
      type={type}
      label={label}
      id={name}
      margin="normal"
    />
  );

const DateField = ({
  input,
  name,
  font,
  label,
  type,
  meta: { touched, error },
  disabled,
}) => (
    <TextField
      {...input}
      variant="outlined"
      label={label}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        classes: {
          input: font
        }
      }}
      style={{ width: '99%' }}
      type={type}
      disabled={disabled}
    />
  );

const TextField1 = ({ input, name, label, type, meta: { touched, error } }) => (
  <TextField
    {...input}
    variant="outlined"
    style={{ width: '99%' }}
    label={label}
    InputLabelProps={{
      shrink: true
    }}
    type={type}
  />
);

const TextField2 = ({ input, name, label, type, meta: { touched, error } }) => (
  <TextField
    {...input}
    multiline
    variant="outlined"
    label={label}
    InputLabelProps={{
      shrink: true
    }}
    style={{ width: '99%' }}
    type={type}
  />
);

const CheckboxField = ({
  name,
  input,
  label,
  type,
  meta: { touched, error }
}) => (
    <div>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              {...input}
              id={name}
              color="primary"
              checked={input.value ? true : false}
            />
          }
          label={<Typography style={{ fontSize: '0.875rem' }}>{label}</Typography>}
          labelPlacement='end'
        />
      </FormControl>
    </div>
  );

let FinalCheck = ({ name, input, label, type }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              {...input}
              name={name}
              //disabled={this.props.state.drugInteractionCheck && this.props.state.controlledSubstanceCheck}
              checked={input.value ? true : false}
              onClick={dispatch(actions.finalizeSchoolSickNoteAction(input.value))}
              color="primary"
            />
          }
        />
      </FormControl>
    </div>
  );
};


class SchoolNoteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSevere: false,
      openSerious: false,
      openModerate: false,
      openMild: false,
      openNegligible: false,
    };
  }

  renderFields(name, type, label, comp, disabled) {
    if (comp === 'Checkbox') {
      return (
        <Field
          name={name}
          type={type}
          label={label}
          component={CheckboxField}
        />
      );
    }
    if (comp === 'FinalCheck') {
      return (
        <Field
          name={name}
          type={type}
          label={label}
          component={FinalCheck}
        />
      );
    }
    if (comp === 'Date') {
      return (
        <Field
          name={name}
          type={type}
          label={label}
          component={DateField}
          font={this.props.classes.resize}
          disabled={disabled}
        />
      );
    }
    if (comp === 'Text') {
      return (
        <Field name={name} type={type} label={label} component={TextField1} />
      );
    }
    if (comp === 'Multiline') {
      return (
        <Field name={name} type={type} label={label} component={TextField2} />
      );
    }
  }

  renderSevere() {
    return (
      <Dialog
        open={this.state.openSevere}
        onClose={() => {
          this.setState({ openSevere: false });
        }}
      >
        <Typography style={{ margin: '5%' }}>
          Completely Unable to function at any academic level (e.g., completely
          restricted mobility, unable to attend any classes, write any tests/
          examinations of fulfill any academic obligations.
        </Typography>
      </Dialog>
    );
  }

  renderSerious() {
    return (
      <Dialog
        open={this.state.openSerious}
        onClose={() => {
          this.setState({ openSerious: false });
        }}
      >
        <Typography style={{ margin: '5%' }}>
          Unable to fulfill academic obligation with significant impact on
          performance (e.g., unable to attend classes, unable to write a test/
          examination)
        </Typography>
      </Dialog>
    );
  }
  renderModerate() {
    return (
      <Dialog
        open={this.state.openModerate}
        onClose={() => {
          this.setState({ openModerate: false });
        }}
      >
        <Typography style={{ margin: '5%' }}>
          May be able to fulfill some of the academic obligation but performance
          will be considerable affected (e.g., able to attend classes, unable to
          concentrate for long periods, assignments may be late)
        </Typography>
      </Dialog>
    );
  }
  renderMild() {
    return (
      <Dialog
        open={this.state.openMild}
        onClose={() => {
          this.setState({ openMild: false });
        }}
      >
        <Typography style={{ margin: '5%' }}>
          Likely able to fulfill academic obligation but performance will likely
          be affected to a minor degree with mild impairment and minimal
          symptoms
        </Typography>
      </Dialog>
    );
  }
  renderNegligible() {
    return (
      <Dialog
        open={this.state.openNegligible}
        onClose={() => {
          this.setState({ openNegligible: false });
        }}
      >
        <Typography style={{ margin: '5%' }}>
          Unlikely to have any significant effect on ability to fulfill academic
          obligation
        </Typography>
      </Dialog>
    );
  }

  handleDisable(degree, form) {
    let disable = true;
    switch (degree) {
      case 'severe': 
        if (form.values && form.values.schoolNoteForm && form.values.schoolNoteForm.severe_checkbox === true) {
          disable = false;
        };
        break;
      case 'serious': 
        if (form.values && form.values.schoolNoteForm && form.values.schoolNoteForm.serious_checkbox === true) {
          disable = false;
        };
        break;
      case 'moderate': 
      if (form.values && form.values.schoolNoteForm && form.values.schoolNoteForm.moderate_checkbox === true) {
        disable = false;
      };
      break;
      case 'mild': 
      if (form.values && form.values.schoolNoteForm && form.values.schoolNoteForm.mild_checkbox === true) {
        disable = false;
      };
      break;
      case 'negligible': 
      if (form.values && form.values.schoolNoteForm && form.values.schoolNoteForm.negligible_checkbox === true) {
        disable = false;
      };
      break;
    }
    return disable;
  };

  render() {
    //does a child compoent of a form use the same form?

    const { classes, formValues } = this.props;
    return (
      <Slide direction="right" in={true}>
        <div
          style={{
            margin: 'auto',
            width: '90%'
          }}
        >
          <FormSection name="schoolNoteForm">
            <Grid container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
              style={{
                padding: '1rem',
                height: '36rem',
                overflowY: 'auto',
                margin: 0,
                borderBottom: '1px solid gray'
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ textAlign: "center" }}
                >
                  Verification of Student Illness
              </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="caption"
                >
                  1) Degree of Incapacitation of Academic Functioning (Select one)
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                spacing={1}
                alignItems="center"
              >
                <Grid item xs={10} sm={2}>
                  {this.renderFields(
                    'severe_checkbox',
                    'input',
                    'Severe',
                    'Checkbox'
                  )}
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      this.setState({ openSevere: true });
                    }}
                  >
                    <InfoIcon />
                  </IconButton>
                  {this.renderSevere()}
                </Grid>
                <Grid item xs={6} sm={4} className={classes.dateFieldLeft}>
                  {this.renderFields(
                    'severe_start_date',
                    'date',
                    'Start Date',
                    'Date',
                    this.handleDisable('severe', formValues),
                  )}
                </Grid>
                <Grid item xs={6} sm={4} className={classes.dateFieldRight}>
                  {this.renderFields('severe_end_date', 'date', 'End Date', 'Date', this.handleDisable('severe', formValues))}
                </Grid>
                <Grid item xs={10} sm={2}>
                  {this.renderFields(
                    'serious_checkbox',
                    'input',
                    'Serious',
                    'Checkbox'
                  )}
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      this.setState({ openSerious: true });
                    }}
                    style={{ left: 0 }}
                  >
                    <InfoIcon />
                  </IconButton>
                  {this.renderSerious()}
                </Grid>
                <Grid item xs={6} sm={4} className={classes.dateFieldLeft}>
                  {this.renderFields(
                    'serious_start_date',
                    'date',
                    'Start Date',
                    'Date',
                    this.handleDisable('serious', formValues)
                  )}
                </Grid>
                <Grid item xs={6} sm={4} className={classes.dateFieldRight}>
                  {this.renderFields(
                    'serious_end_date',
                    'date',
                    'End Date',
                    'Date',
                    this.handleDisable('serious', formValues)
                  )}
                </Grid>
                <Grid item xs={10} sm={2}>
                  {this.renderFields(
                    'moderate_checkbox',
                    'input',
                    'Moderate',
                    'Checkbox'
                  )}
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      this.setState({ openModerate: true });
                    }}
                    style={{ left: 0 }}
                  >
                    <InfoIcon />
                  </IconButton>
                  {this.renderModerate()}
                </Grid>
                <Grid item xs={6} sm={4} className={classes.dateFieldLeft}>
                  {this.renderFields(
                    'moderate_start_date',
                    'date',
                    'Start Date',
                    'Date',
                    this.handleDisable('moderate', formValues)
                  )}
                </Grid>
                <Grid item xs={6} sm={4} className={classes.dateFieldRight}>
                  {this.renderFields(
                    'moderate_end_date',
                    'date',
                    'End Date',
                    'Date',
                    this.handleDisable('moderate', formValues)
                  )}
                </Grid>
                <Grid item xs={10} sm={2}>
                  {this.renderFields(
                    'mild_checkbox',
                    'input',
                    'Mild',
                    'Checkbox'
                  )}
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      this.setState({ openMild: true });
                    }}
                    style={{ left: 0 }}
                  >
                    <InfoIcon />
                  </IconButton>
                  {this.renderMild()}
                </Grid>
                <Grid item xs={6} sm={4} className={classes.dateFieldLeft}>
                  {this.renderFields(
                    'mild_start_date',
                    'date',
                    'Start Date',
                    'Date',
                    this.handleDisable('mild', formValues)
                  )}
                </Grid>
                <Grid item xs={6} sm={4} className={classes.dateFieldRight}>
                  {this.renderFields(
                    'mild_end_date',
                    'date',
                    'End Date',
                    'Date',
                    this.handleDisable('mild', formValues)
                  )}
                </Grid>
                <Grid item xs={10} sm={2}>
                  {this.renderFields(
                    'negligible_checkbox',
                    'input',
                    'Negligible',
                    'Checkbox'
                  )}
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => {
                      this.setState({ openNegligible: true });
                    }}
                    style={{ left: 0 }}
                  >
                    <InfoIcon />
                  </IconButton>
                  {this.renderNegligible()}
                </Grid>
                <Grid item xs={6} sm={4} className={classes.dateFieldLeft}>
                  {this.renderFields(
                    'negligible_start_date',
                    'date',
                    'Start Date',
                    'Date',
                    this.handleDisable('negligible', formValues)
                  )}
                </Grid>
                <Grid item xs={6} sm={4} className={classes.dateFieldRight}>
                  {this.renderFields(
                    'negligible_end_date',
                    'date',
                    'End Date',
                    'Date',
                    this.handleDisable('negligible', formValues)
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  {this.renderFields(
                    'chronic_check',
                    'input',
                    'This is a chronic condition.',
                    'Checkbox'
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {this.renderFields(
                    'fully_recovered_check',
                    'input',
                    'Patient has fully recovered.',
                    'Checkbox'
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    2) Frequency/Timeline of contact with patient relevant to
                    present illness
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.dateTypography} variant="body2">
                    Once only
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} style={{ marginLeft: '3%', marginRight: '5%' }}>
                  {this.renderFields(
                    'once_only_date',
                    'date',
                    'Visit Date',
                    'Date'
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.dateTypography} variant="body2">
                    Multiple/Ongoing Visits
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} style={{ marginLeft: '3%', marginRight: '5%' }}>
                  {this.renderFields(
                    'multiple_dates_text',
                    'text',
                    'Visit Dates (separated by comma)',
                    'Text'
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ marginLeft: '3%', marginRight: '5%', marginTop: '5%' }}
                >
                  {this.renderFields(
                    'additional_comments_text',
                    'text',
                    '3) Additonal Comments',
                    'Multiline'
                  )}
                </Grid>
              </Grid>
            </Grid>
          </FormSection>
          <Grid container
            direction="row"
            alignItems="center"
            spacing={1}
            style={{
              padding: "1rem"
            }}
          >
            <Grid item xs={11} style={{ textAlign: "right" }}>
              <Typography variant="subtitle2">
                Finalize School Sick Note
                </Typography>
            </Grid>

            <Grid item xs={1}>
              {this.renderFields(
                'finalize_schoolnote',
                'input',
                'test',
                'FinalCheck'
              )}</Grid>
          </Grid>

        </div>
      </Slide>
    );
  }
}

function mapStateToProps(state) {
  return {
    formValues: state.form.noteForm,
  };
}

SchoolNoteForm = connect(
  mapStateToProps,
  actions
)(SchoolNoteForm);

SchoolNoteForm = reduxForm({
  //validate,
  form: 'noteForm',
  destroyOnUnmount: false
})(SchoolNoteForm);

SchoolNoteForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SchoolNoteForm);
