import React, { Component } from 'react';
import axios from 'axios';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import { InsertDriveFile } from '@material-ui/icons';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      imgExtensions: ['.jpg', '.jpeg', '.png'],
      files: props.files,
    };
  }

  componentDidUpdate = () => {
    if (this.props.open !== this.state.open) {
      this.setState({
        open: this.props.open,
      });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.onClose && typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  async openFile(path) {
    const response = await axios.get(path, { responseType: 'blob' });
    if (response.data) {
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = path.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  }

  render() {
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={this.state.open}
        onClose={this.handleClose}
      >
        <DialogTitle>
          <Typography variant="h5" color="primary">
            Uploaded Files
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12}>
            {this.state.files.length > 0 &&
              this.state.files.map((file, index) => (
                <List>
                  <ListItem
                    key={index}
                    button
                    onClick={() => this.openFile(file)}
                  >
                    <ListItemAvatar>
                      {this.state.imgExtensions.includes(
                        file.slice(file.lastIndexOf('.'))
                      ) ? (
                        <Avatar src={file} />
                      ) : (
                        <Avatar>
                          <InsertDriveFile color="primary" />
                        </Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={file.slice(file.lastIndexOf('/') + 1)}
                    />
                  </ListItem>
                </List>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default FileUpload;
