export const FETCH_USER = 'fetch_user';
export const FETCH_REGISTRATION = 'fetch_registration';
export const LOGIN = 'login';
export const FETCH_PROFILE = 'fetch_profile';
export const FETCH_ABOUT_YOU = 'fetch_about_you';
export const FETCH_MEDICAL_PROFILE_COMPLETION =
  'fetch_medical_profile_completion';
export const WIZARD_PROFILE = 'wizard_profile';
export const REGISTER_USER = 'register_user';
export const DOCTOR_NOTE = 'doctor_note';
export const FETCH_APPOINTMENT = 'fetch_appointment';
export const FETCH_APPOINTMENT_ID = 'FETCH_APPOINTMENT_ID';
export const PATIENT = 'PATIENT';
export const SET_PATIENT_ID = 'set_patient_id';
export const SET_PAYMENT_INTENT_ID = 'SET_PAYMENT_INTENT_ID';
export const ISSUE = 'issue';
export const APPOINTMENT = 'APPOINTMENT';
export const DOCTORJAAS = 'jaas_doctor';
export const SUBMIT_APPOINTMENT_NOTES = 'submit_appointment_notes';
export const PAYMENTHISTORY = 'payment-history';
export const APPOINTMENTHISTORY = 'appointment-history';
export const FETCH_PRICE = 'fetch_price';
export const PATIENT_ROOM = 'patient_room';
export const REFRESH_SESSION = 'refresh_session';
export const SELECTED_USER = 'selected_user';
export const SELECTED_USER_MEDICAL = 'selected_user_medical';
export const SMART_FAMILY_PLAN = 'smart_family_plan';
export const FINALIZE_PRESCRIPTION = 'finalize_prescription';
export const FINALIZE_SICK_NOTE = 'finalize_sick_note';
export const FINALIZE_SCHOOL_SICK_NOTE = 'finalize_school_sick_note';
export const DRUG_INTERACTION_CHECK = 'drug_interaction_check';
export const CONTROLLED_SUBSTANCE_CHECK = 'controlled_substances_check';
export const PHOTO_ID_VALIDATION_CHECK = 'photo_id_validation_check';
export const SAVED_TEXT_CHAT = 'saved_text_chat';
export const ALLERGIES_OTHER_FIELD = 'allergies_other_field';
export const MEDICAL_CONDITIONS_OTHER_FIELD = 'medical_conditions_other_field';
export const MEDICAL_PROCEDURES_OTHER_FIELD = 'medical_procedures_other_field';
export const CURRENT_MEDICATION_OTHER_FIELD = 'current_medication_other_field';
export const TIMER_DURATION = 'timer_duration';
export const WORKING_HOURS = 'working_hours';
export const CREDIT_CARD_LIST = 'CREDIT_CARD_LIST';
export const SMART_APPOINTMENT_CODE = 'smart_appointment_code';
export const DIAGNOSTIC_CODE = 'diagnostic_code';
export const DELIVERIES_LIST = 'deliveries_list';
export const PHARMACY_PROMO_LIST = 'pharmacy_promo_list';
export const DELIVERY_STATUSES_LIST = 'delivery_statuses_list';
export const PHARMACY_PAYMENTS = 'pharmacy_payments';
export const NOTIFICATION_LIST = 'notification_list';
export const RESET_APPT_PARAMS = 'reset_appt_params';

// Promo Code Types
export const PROMO_CODE_ERROR = 'promo_code_error';
export const PROMO_CODE_LOADING = 'promo_code_loading';
export const PROMO_CODE_SUCCESS = 'promo_code_success';
export const KEEP_SYMPTOMS_DATA = 'keep_symptoms_data';
