export default [
  {
    label: 'Ear/Eye/Throat',
    name: 'symptoms_8',
    type: 'checkbox',
    id: 'symptoms_8',
    component: 'input'
  },
  {
    label: 'Abdominal pain',
    name: 'symptoms_9',
    type: 'checkbox',
    id: 'symptoms_9',
    component: 'input'
  },
  {
    label: 'Skin problems',
    name: 'symptoms_10',
    type: 'checkbox',
    id: 'symptoms_10',
    component: 'input'
  },
  {
    label: 'Allergies/Allergic reaction',
    name: 'symptoms_11',
    type: 'checkbox',
    id: 'symptoms_11',
    component: 'input'
  },
  {
    label: 'Sexual health issues',
    name: 'symptoms_12',
    type: 'checkbox',
    id: 'symptoms_12',
    component: 'input'
  },
  {
    label: 'Urinary infection',
    name: 'symptoms_13',
    type: 'checkbox',
    id: 'symptoms_13',
    component: 'input'
  },
  {
    label: 'Joint pain',
    name: 'symptoms_14',
    type: 'checkbox',
    id: 'symptoms_14',
    component: 'input'
  }
];
