import { SMART_FAMILY_PLAN } from '../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case SMART_FAMILY_PLAN:
      return action.payload || false;
    default:
      return state;
  }
}
