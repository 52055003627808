import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';

export function AvailableDoctorInfoDialog({ open, doctor, onClose }) {
  let name = '',
    number = '',
    email = '';

  if (doctor && doctor.name) {
    name = `${doctor.name.first}`;
    number = doctor.mobilePhone;
    email = doctor.email;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Doctor {name} Information</DialogTitle>
      <DialogContent>
        <Typography>Email: {email}</Typography>
        <Typography>Phone: {number}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
