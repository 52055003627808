import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AboutYouForm from '../aboutyou/AboutYouForm';
import MedicalProcedureForm from '../medicalProcedures/MedicalProcedureForm';
import CurrentMedicationForm from '../medication/CurrentMedicationForm';
import CurrentMedicalConditionForm from '../medicalCondition/CurrentMedicalConditionForm';
import PreferredPharmacyForm from '../preferredPharmacy/PreferredPharmacyForm';
import WizardProfileReview from './WizardProfileReview';
import WizardProfileCompletionAcknowledgement from './WizardProfileCompletionAcknowledgement';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../actions';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import _ from 'lodash';

//actual labels of the steps
function getSteps() {
  return [
    'About You',
    'Current Medication',
    'Medical Conditions',
    'Medical Procedures',
    'Preferred Pharmacy',
    'Review and Confirm',
  ];
}

class WizardForm extends Component {
  constructor(props) {
    super(props);

    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 0,
    };
  }

  nextPage() {
    //this.props.showFormObject(this.props.form.wizard.values);
    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  render() {
    const { page } = this.state;
    const name = this.props.match.params.name; // TODO: change 'name' to 'id' in routing.

    const steps = getSteps();

    const onSubmit = (values) => {
      // convert medical info to array of strings containing name
      let medicalAllergies = [],
        currentMedicalCondition = [],
        currentMedication = [],
        otherAllergies = [],
        medicalProcedures = [],
        id = name;
      Object.entries(values).forEach(([key, val]) => {
        if (key.includes('medical_allergy_') && val.name) {
          medicalAllergies = [val.name, ...medicalAllergies];
        } else if (key.includes('current_medical_condition_') && val.name) {
          currentMedicalCondition = [val.name, ...currentMedicalCondition];
        } else if (key.includes('current_medication_') && val.name) {
          currentMedication = [val.name, ...currentMedication];
        } else if (key.includes('other_allergy_') && val.name) {
          otherAllergies = [val.name, ...otherAllergies];
        } else if (key.includes('medical_procedure_') && val.name) {
          medicalProcedures = [val.name, ...medicalProcedures];
        }
      });

      // convert Y/N question values to boolean
      const isTobaccoSmoker = values.isTobaccoSmoker === 'isTobaccoSmoker_yes';
      const isSubstanceUser = values.isSubstanceUser === 'isSubstanceUser_yes';
      const isAlcoholConsumer =
        values.isAlcoholConsumer === 'isAlcoholConsumer_yes';

      // if substance user, defined substance
      let substance;
      if (isSubstanceUser) substance = values.substance_input_field;

      // get other values if option was selected
      let currentMedicalConditionOtherField,
        currentMedicationOtherField,
        medicalAllergiesOtherField,
        medicalProceduresOtherField,
        otherAllergiesOtherField;
      if (values.medical_allergy_Other)
        medicalAllergiesOtherField = values.medical_allergies_other_field;
      if (values.other_allergy_Other)
        otherAllergiesOtherField = values.other_allergies_other_field;
      if (values.current_medication_Other)
        currentMedicationOtherField = values.current_medication_other_field;
      if (values.current_medical_condition_Other)
        currentMedicalConditionOtherField =
          values.current_medical_condition_other_field;
      if (values.medical_procedure_Other)
        medicalProceduresOtherField = values.medical_procedures_other_field;

      // submit to server
      this.props.submitWizard(
        {
          medicalAllergies,
          currentMedicalCondition,
          currentMedication,
          otherAllergies,
          medicalProcedures,
          isAlcoholConsumer,
          isSubstanceUser,
          isTobaccoSmoker,
          substance,
          currentMedicalConditionOtherField,
          currentMedicationOtherField,
          medicalAllergiesOtherField,
          medicalProceduresOtherField,
          otherAllergiesOtherField,
          id,
        },
        this.props.history
      );
    };

    return (
      <div>
        <Grid container direction="row" xs={12}>
          <Grid
            item
            xs={12}
            style={{
              width: '70vw',
            }}
          >
            <Stepper
              activeStep={page}
              alternativeLabel
              style={{
                backgroundColor: '#caebf2',
                marginBottom: 10,
                maxWidth: '30rem',
                margin: 'auto',
              }}
            >
              {steps.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel></StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
        </Grid>

        <div>
          {page === 0 && <AboutYouForm onSubmit={this.nextPage} />}
          {page === 1 && (
            <CurrentMedicationForm
              previousPage={this.previousPage}
              onSubmit={this.nextPage}
            />
          )}
          {page === 2 && (
            <CurrentMedicalConditionForm
              previousPage={this.previousPage}
              onSubmit={this.nextPage}
            />
          )}
          {page === 3 && (
            <MedicalProcedureForm
              previousPage={this.previousPage}
              onSubmit={this.nextPage}
            />
          )}
          {page === 4 && (
            <WizardProfileReview
              previousPage={this.previousPage}
              user={name}
              formResult={this.props.formResult}
              onSubmit={onSubmit}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    form: state.form,
    formResult: state.wizard,
    medicalAllergyArray: state.medicalAllergyArray,
    otherAllergyArray: state.otherAllergyArray,
  };
}

WizardForm = connect(mapStateToProps, actions)(withRouter(WizardForm));

WizardForm = reduxForm({
  //validate: validate,
  form: 'wizard',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(WizardForm);

export default WizardForm;
