import axios from 'axios';
import { registerField, unregisterField, clearFields, touch } from 'redux-form';
import { WIZARD_PROFILE } from './types';
import { FETCH_MEDICAL_PROFILE_COMPLETION } from './types';

var formIdIncrement = 1;

export const addFormItem =
  (formCategory, label, formFields, form) => (dispatch) => {
    formIdIncrement++;
    var item = {
      label: label,
      id: formCategory + formIdIncrement.toString(),
      name: formCategory + formIdIncrement.toString(),
      type: 'text',
    };

    formFields.push(item);
    dispatch(
      registerField(form, formCategory + formIdIncrement.toString(), 'text')
    );
    dispatch(touch(form, formCategory + formIdIncrement.toString()));
    dispatch(
      unregisterField(form, formCategory + formIdIncrement.toString(), 'text')
    );

    dispatch({ type: 'add_form_item', payload: item });
  };

export const removeAboutYouFormField =
  (name, formFields, form) => async (dispatch) => {
    for (var i = 0; i < formFields.length; i++) {
      if (formFields[i].name === name) {
        formFields.splice(i, 1);
      }
    }
    dispatch(unregisterField(form, name));
    dispatch(clearFields(form, false, false, name));
    dispatch({ type: 'delete_form_item', payload: name });
  };

export const submitWizard = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/profile/patient/medical-form', values);

  if (res.data) {
    dispatch({ type: FETCH_MEDICAL_PROFILE_COMPLETION, payload: true });
    history.push('/completed');
  } else {
    const url = '/medicalprofileform/123';
    //+ res.data.name.split(" ").join("_")
    history.push(url);
  }

  dispatch({ type: WIZARD_PROFILE, payload: res.data });
};
