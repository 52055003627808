import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import {
  CssBaseline,
  Paper,
  Grid,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import {
  Dashboard,
  LocalShipping,
  Settings
} from '@material-ui/icons'
import ActiveDeliveries from './ActiveDeliveries';
import DeliveredPrescriptions from './DeliveredPrescriptions';
import PendingDeliveries from './PendingDeliveries';
import CanceledDeliveries from './CanceledDeliveries';

const styles = theme => ({
  // layout: {
  //   overflow: 'hidden',
  //   marginLeft: theme.spacing.unit,
  //   marginRight: theme.spacing.unit,
  //   [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
  //     marginLeft: theme.spacing.unit * 6,
  //     marginRight: theme.spacing.unit * 6
  //   },
  //   maxWidth: 850
  // },
  sectionContent: {
    height: '80vh',
  },
  paper: {
    height: '100%',
    padding: '30px',
    overflow: 'auto'
  },
  sectionTab: { 
    margin: 'auto 10px',
    borderRight: 'solid lightgray 1px'
  }
});


class DeliveriesSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'Active'
    }
  }
  
  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue })
  }

  renderTabContent () {
    const { selectedTab } = this.state;
    switch(selectedTab) {
      case 'Delivered':
        return <DeliveredPrescriptions />;
      case 'Pending':
        return <PendingDeliveries />;
      case 'Canceled':
        return <CanceledDeliveries />
      default:
        return <ActiveDeliveries />;
    }
  }

  render() {
    const { selectedTab } = this.state;
    const { classes, auth } = this.props;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper>
            <Tabs
              value={selectedTab}
              onChange={this.handleTabChange}
              indicatorColor='primary'
              textColor='primary'
            >
              <Grid item xs={1} className={classes.sectionTab}>
                <Typography
                  color='primary'
                  align='center'
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)'
                  }}
                >
                  Deliveries
                </Typography>
              </Grid>
              <Tab label='Active' value='Active'/>
              <Tab label='Pending' value='Pending'/>
              <Tab label='Delivered' value='Delivered'/>
              <Tab label='Canceled' value='Canceled'/>
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12} className={classes.sectionContent}>
          <Paper className={classes.paper}>
            {this.renderTabContent()}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

DeliveriesSection = connect(mapStateToProps, actions)(withRouter(DeliveriesSection));

export default withStyles(styles)(DeliveriesSection)