export default function (state = {}, action) {
  switch (action.type) {
    case 'tok-persist':
      return Object.assign({}, state, {
        jaasCredentials: action.payload,
      });

    default:
      return state;
  }
}
