if (process.env.NODE_ENV === 'development') {
  module.exports = {
    REACT_APP_STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
    REACT_APP_HOST_NAME: process.env.REACT_APP_HOST_NAME,
    REACT_APP_VERSION: process.env.REACT_APP_VERSION,
    REACT_APP_ENV: process.env.NODE_ENV,
    REACT_APP_JAAS_APP_ID: process.env.REACT_APP_JAAS_APP_ID,
  };
} else {
  module.exports = {
    REACT_APP_STRIPE_KEY: '@REACT_APP_STRIPE_KEY@',
    REACT_APP_HOST_NAME: '@REACT_APP_HOST_NAME@',
    REACT_APP_VERSION: '@REACT_APP_VERSION@',
    REACT_APP_ENV: '@REACT_APP_ENV@',
    REACT_APP_JAAS_APP_ID: '@REACT_APP_JAAS_APP_ID@',
  };
}
