import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography, Grid, Button, Slide } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
  },
  pageMargins: {
    marginBottom: '30px',
  },
  signatureSection: {
    margin: '10px 0px',
  },
  signature: {
    border: '5px solid aliceblue',
    borderRadius: '5px',
    padding: '5px',
  },
  signatureButton: {
    padding: '10px',
  },
});

class DoctorProfile extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid container direction="row" className={classes.pageMargins}>
              <Grid
                item
                container
                style={{ marginTop: '5%' }}
                alignItems="center"
              >
                <Grid item xs={9} sm={10}>
                  <Typography variant="h5" color="primary">
                    Your Profile
                  </Typography>
                </Grid>
                <Grid item container xs={3} sm={2} justify="flex-end">
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ width: '70px' }}
                    onClick={() => {
                      this.props.changePage('EditProfile');
                      this.props.disableToolbar();
                    }}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item container>
                  <Grid item xs={12}>
                    <Typography variant="body1">Dr.</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {`${this.props.auth.name.first} ${this.props.auth.name.last}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Email</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={classes.textField}
                    style={{
                      overflow: 'hidden',
                    }}
                  >
                    <Typography variant="h6" color="primary">
                      {this.props.auth.email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">CPSO</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {this.props.auth.CPSO}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Date of Birth</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {this.props.auth.dateOfBirth.substring(0, 10)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Gender</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {this.props.auth.gender}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Spoken Languages</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {this.props.auth.spokenLanguages.join(', ')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Mobile Phone Number</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {this.props.auth.mobilePhone}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Street Address</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {this.props.auth.address}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">City</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {this.props.auth.city}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Province/Region</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {this.props.auth.province}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Postal Code</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {this.props.auth.zipCode}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Country</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {this.props.auth.country}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  className={classes.signatureSection}
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Typography variant="body1">Signature</Typography>
                  </Grid>

                  {this.props.auth.signatureConfirmed && (
                    <Grid
                      item
                      container
                      xs={6}
                      justify="center"
                      className={classes.signature}
                    >
                      <img
                        id="current-signature"
                        src={this.props.auth.signature}
                        width="130px"
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    xs={6}
                    className={
                      this.props.auth.signatureConfirmed
                        ? classes.signatureButton
                        : null
                    }
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.props.changePage('EditSignature');
                      }}
                    >
                      {this.props.auth.signatureConfirmed
                        ? 'Change your signature'
                        : 'Create signature'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </main>
        </Slide>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

DoctorProfile = connect(mapStateToProps)(withRouter(DoctorProfile));

export default withStyles(styles)(DoctorProfile);
