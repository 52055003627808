export class EventEmitter {
  #listeners = new Map();

  on(event, listener) {
    const prev = this.#listeners.get(event);
    if (prev) this.#listeners.set(event, [...prev, listener]);
    else this.#listeners.set(event, [listener]);
  }

  off(event, listener) {
    if (listener) {
      const listeners = this.#listeners.get(event);
      if (Array.isArray(listeners)) {
        this.#listeners.set(
          event,
          listeners.filter((val) => val !== listener)
        );
      } else throw new Error('Listeners was not an array');
    } else {
      this.#listeners.delete(event);
    }
  }

  emit(event, data = '') {
    const listeners = this.#listeners.get(event);
    if (Array.isArray(listeners)) {
      listeners.forEach((listener) => {
        if (typeof listener === 'function') {
          listener(data);
        }
      });
    }
  }

  once(event, listener) {
    const wrappedListener = (...args) => {
      listener.apply(this, args);
      this.off(event, wrappedListener);
    };
    this.on(event, wrappedListener);
  }
}
